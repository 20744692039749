import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { RegisterSummary } from './components/RegisterSummary'
import { PreRegister } from './components/PreRegister'
import { Register, InitialFieldsType } from './components/Register'
import { BackgroundWrapper } from '../../shared/BackgroundWrapper'
import { registerSteps } from './static-data/RegisterData'
import { AxiosResponse } from 'axios'
import { ValidateRegistrationResult } from '@shared/contracts/models'
import registerAppImg from '@images/registerAppImg.png'
import { useLocation } from 'react-router'

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:first-child': {
      width: '60%',
    },

    '&:last-child': {
      justifyContent: 'flex-end',
      width: '40%',
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },

    [theme.breakpoints.only('md')]: {
      '&:first-child': {
        width: '55%',
      },
      '&:last-child': {
        width: '45%',
      },
    },

    [theme.breakpoints.down('sm')]: {
      '&:first-child': {
        justifyContent: 'center',
        width: '100%',
      },
      '&:last-child': {
        width: 0,
        display: 'none',
      },
    },
  },
  registerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '808px',
    width: '760px',
    padding: '32px 72px',
    margin: '32px',
    borderRadius: '7px',
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0 7px 9px 3px rgba(105,105,105,0.03), 0 2px 3px 0 rgba(66,65,65,0.5)',

    [theme.breakpoints.down('md')]: {
      padding: '28px',
      margin: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '42px 32px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px',
      margin: '24px',
    },
  },
}))

export const RegisterPage: React.FC = () => {
  const search = useLocation().search
  const styles = useStyles()
  const { PREPARATION, FORM, SUMMARY } = registerSteps
  const [formData, setFormData] = useState<InitialFieldsType>({})
  const [formAPIResponse, setFormAPIResponse] = useState<
    AxiosResponse<ValidateRegistrationResult>
  >()
  const [toggleSection, setToggleSection] = useState(PREPARATION)

  const query = new URLSearchParams(search)
  const token = query.get('token') || undefined

  if (token && toggleSection === PREPARATION) {
    setToggleSection(FORM)
  }

  const displayedSection = () => {
    switch (toggleSection) {
      case PREPARATION:
        return <PreRegister nextPage={() => setToggleSection(FORM)} />
      case FORM:
        return (
          <Register
            formData={formData}
            setFormData={setFormData}
            setFormApiResponse={setFormAPIResponse}
            nextPage={() => setToggleSection(SUMMARY)}
            token={token}
          />
        )
      case SUMMARY:
        if (formAPIResponse) {
          return (
            <RegisterSummary
              formData={formData}
              formAPIResponse={formAPIResponse}
              prevPage={() => setToggleSection(FORM)}
              token={token}
            />
          )
        }
    }
  }

  return (
    <BackgroundWrapper>
      <div className={styles.section}>
        <div className={styles.registerContainer}>{displayedSection()}</div>
      </div>
      <div className={styles.section}>
        <img src={registerAppImg} alt="register img" />
      </div>
    </BackgroundWrapper>
  )
}
