import React from 'react'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'
import Button from '@shared/components/modals/Modal/Button'
import { News } from '@shared/contracts/news'
import useStyles from './SingleNewsModal.style'
import Icon from '@shared/components/modals/Modal/Icon'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

export const SingleNewsModal: React.FC<{
  show: boolean
  close: () => void
  news: News
}> = ({ show, close, news }) => {
  const styles = useStyles()

  const { t } = useTranslation()

  const { title, message } = news

  return (
    <Dialog
      aria-describedby="form-modal"
      open={show}
      scroll="paper"
      maxWidth="md"
      fullWidth
      classes={{ paper: styles.dialog }}
      onClose={close}
    >
      <DialogContent classes={{ root: styles.content }}>
        <div className={styles.icon}>
          <Icon type="info" />
        </div>
        <h1>{title}</h1>
        <ReactQuill
          theme="bubble"
          readOnly={true}
          value={JSON.parse(message ?? '')}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close} className={styles.btn}>
          {t('CLOSE')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
