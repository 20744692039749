import React, { useRef, useEffect, useState } from 'react'
import { Scrollbar } from 'react-scrollbars-custom'
import useStyles, { SCROLL_SIZE } from './ScrollBar.style'

interface ICustomScrollBarProps {
  maxWidth?: number | string
  maxHeight?: number | string
  autoHeight?: boolean
  minHeight?: number | string
  minWidth?: number | string
  wrapperProps?: object
}

const BASE_HEIGHT = 100

export const CustomScrollBar: React.FC<ICustomScrollBarProps> = ({
  children,
  maxHeight = 'none',
  minHeight = 'none',
  autoHeight = true,
  maxWidth = 'none',
  minWidth = 'none',
  wrapperProps,
}) => {
  const [height, setHeight] = useState(BASE_HEIGHT)
  const styles = useStyles()
  const childrenRef = useRef(null) as any
  const childrenHeight = childrenRef?.current?.offsetHeight
  useEffect(() => {
    if (autoHeight) {
      setHeight((childrenHeight || BASE_HEIGHT) + 20)
    }
  }, [childrenHeight, autoHeight])

  return (
    <Scrollbar
      style={{
        width: '100%',
        height: height,
        maxHeight,
        minHeight,
        maxWidth,
        minWidth,
        boxSizing: 'border-box',
      }}
      trackXProps={{
        renderer: props => {
          const { elementRef, style, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              className={`${styles.track} ${styles.trackHorizontal}`}
              style={{ ...style, left: 0, height: SCROLL_SIZE, bottom: 10 }}
            />
          )
        },
      }}
      trackYProps={{
        renderer: props => {
          const { elementRef, style, ...restProps } = props
          return (
            <div
              {...restProps}
              ref={elementRef}
              className={`${styles.track} ${styles.trackVertical}`}
              style={{ ...style, width: SCROLL_SIZE, top: 0 }}
            />
          )
        },
      }}
      thumbXProps={{
        renderer: props => {
          const { elementRef, ...restProps } = props
          return (
            <div {...restProps} ref={elementRef} className={styles.thumb} />
          )
        },
      }}
      thumbYProps={{
        renderer: props => {
          const { elementRef, ...restProps } = props
          return (
            <div {...restProps} ref={elementRef} className={styles.thumb} />
          )
        },
      }}
    >
      <div style={{ height: 'auto' }} ref={childrenRef} {...wrapperProps}>
        {children}
      </div>
    </Scrollbar>
  )
}
