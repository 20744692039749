import React, { useState, useEffect } from 'react'
import {
  ClickAwayListener,
  Collapse,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core'
import { headerBg, navHeight, textSecondaryColor } from '@mbok/styles/constants'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useLoadingState } from '@shared/hooks/loadingState'
import CloseIcon from '@material-ui/icons/Close'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'

const useStyles = makeStyles((theme: Theme) => ({
  navExpandMenu: {
    left: 0,
    right: 0,
    position: 'absolute',
    background: headerBg,
    zIndex: 10,
  },
  childWrapper: {
    display: 'flex',
  },
  child: {
    padding: theme.spacing(0, 6),
  },
  closeIcon: {
    cursor: 'pointer',
    maxHeight: 40,
    padding: theme.spacing(2),
  },
  loading: {
    '&:hover': {
      cursor: 'default',
      border: `1.7px solid ${textSecondaryColor + colorOpacityParser(35)}`,
    },
  },
}))

interface NavExpandMenuProps {
  expanded?: boolean
  loading?: boolean
  clickElementRender?: (
    expandIcon: React.ReactElement,
    clickHandler: () => void
  ) => React.ReactElement
  positionTop?: number
  closeBtn?: () => void
}

export const NavExpandMenu: React.FC<NavExpandMenuProps> = ({
  children,
  expanded,
  loading,
  clickElementRender,
  positionTop = navHeight,
  closeBtn,
}) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()

  const [_expanded, setExpanded] = useState(false)

  const isLoading = useLoadingState(loading ?? false)

  useEffect(() => {
    setExpanded(!!expanded)
  }, [expanded])

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!expanded) {
          setExpanded(false)
        }
      }}
    >
      <div>
        {clickElementRender &&
          clickElementRender(
            <div>{_expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>,
            () => {
              !isLoading && setExpanded(prev => !prev)
            }
          )}
        <Collapse
          style={{ top: positionTop }}
          in={_expanded}
          className={styles.navExpandMenu}
          collapsedHeight={0}
        >
          <div className={styles.childWrapper}>
            <div
              style={{
                width: '100%',
                padding: closeBtn
                  ? theme.spacing(0, 0, 0, 6)
                  : theme.spacing(0, 6),
              }}
            >
              {children}
            </div>
            {closeBtn && (
              <div
                className={styles.closeIcon}
                onClick={() => {
                  closeBtn()
                  setExpanded(false)
                }}
              >
                <CloseIcon />
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </ClickAwayListener>
  )
}
