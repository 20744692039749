import React, { useMemo } from 'react'
import { CircularProgress, Collapse, Fade, Paper } from '@material-ui/core'
import StyledButton from '@shared/components/Button'
import { useLoadingState } from '@shared/hooks/loadingState'
import useStyles from './DetailsCard.style'

export interface IDetailsWrapperProps {
  title: string
  Icon: React.FC<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>
  iconSize?: number
  customButtons?: React.ReactNode
  buttonHandler?: () => void
  buttonText?: string
  secondaryButtonHandler?: () => void
  secondaryButtonText?: string
  loading?: boolean
  disableLoader?: boolean
  minHeight?: number
  wrapperMinHeight?: number
  headerClass?: string
}

export const DETAILS_CARD_CONTENT_MIN_HEIGHT = 180

export const DetailsCardWrapper: React.FC<IDetailsWrapperProps> = ({
  Icon,
  iconSize,
  title,
  children,
  loading,
  disableLoader = false,
  buttonHandler,
  buttonText,
  secondaryButtonHandler,
  secondaryButtonText,
  customButtons,
  minHeight = DETAILS_CARD_CONTENT_MIN_HEIGHT,
  headerClass,
  wrapperMinHeight,
}) => {
  const styles = useStyles()
  const isLoading = useLoadingState(!!loading)

  const iconCustomSize = useMemo(() => {
    return iconSize
      ? {
          minWidth: iconSize,
          minHeight: iconSize,
          Width: iconSize,
          Height: iconSize,
        }
      : {}
  }, [iconSize])

  return (
    <Paper
      variant="outlined"
      className={styles.card}
      style={wrapperMinHeight ? { minHeight: wrapperMinHeight } : {}}
    >
      {!disableLoader && (
        <Fade in={isLoading}>
          <div className={styles.loader}>
            {isLoading && <CircularProgress />}
          </div>
        </Fade>
      )}
      <div className={`${styles.header} ${headerClass || ''}`}>
        <div className={styles.title}>
          {<Icon className={styles.icon} style={iconCustomSize} />}
          <b>{title}</b>
        </div>
        <div>
          {buttonText && buttonHandler && (
            <StyledButton
              variant="outlined"
              onClick={() => buttonHandler()}
              size="small"
              className={styles.btn}
              color="primary"
            >
              {buttonText}
            </StyledButton>
          )}
          {secondaryButtonText && secondaryButtonHandler && (
            <StyledButton
              variant="outlined"
              onClick={() => secondaryButtonHandler()}
              size="small"
              className={`${styles.btn} ${styles.secondaryBtn}`}
              color="primary"
            >
              {secondaryButtonText}
            </StyledButton>
          )}
          {!isLoading && (customButtons ?? null)}
        </div>
      </div>
      <div style={{ minHeight }}>
        {disableLoader ? (
          <>{children}</>
        ) : (
          <Collapse in={!isLoading}>
            <>{!isLoading && children}</>
          </Collapse>
        )}
      </div>
    </Paper>
  )
}
