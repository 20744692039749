import { getAllThunk } from '../helpers/thunks/getAllThunk'
import { EntityReducers } from '../EntityReducers'
import { useThunkApi } from '../../../apiClient/apiClient'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  UpdateAgreementEmailPayload,
  UpdateAgreementAddressPayload,
} from './types'

import {
  AgreementListItem,
  AgreementListItemPagedResponse,
} from '@shared/contracts/models'

export const userAgreementsGetAll = getAllThunk<
  AgreementListItem,
  undefined,
  AgreementListItemPagedResponse
>(EntityReducers.userAgreements, ({ thunkApi }) =>
  useThunkApi(
    {
      config: {
        method: 'get',
        url: `Agreements`,
      },
    },
    thunkApi
  )
)

export const updateAgreementCorrespondenceAddress = createAsyncThunk<
  UpdateAgreementAddressPayload,
  UpdateAgreementAddressPayload
>(
  `${[EntityReducers.userAgreements]}/updateCorrespondenceAddress`,
  async ({ id, ...data }, thunkApi) => {
    const [execute] = useThunkApi<UpdateAgreementAddressPayload>(
      {
        config: {
          method: 'put',
          url: `agreements/${id}/correspondenceAddress`,
          data,
        },
      },
      thunkApi
    )
    await execute()
    return { id, ...data }
  }
)

export const updateAgreementEmail = createAsyncThunk<
  UpdateAgreementEmailPayload,
  UpdateAgreementEmailPayload
>(
  `${[EntityReducers.userAgreements]}/updateEmail`,
  async ({ id, ...data }, thunkApi) => {
    const [execute] = useThunkApi<UpdateAgreementEmailPayload>(
      {
        config: {
          method: 'put',
          url: `agreements/${id}/email`,
          data,
        },
      },
      thunkApi
    )
    await execute()
    return { id, ...data }
  }
)
