import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { ConsentStatus, DocumentType } from '@shared/contracts/models'
import { values } from 'ramda'

export const userConsentsSelector = (state: RootState) => state.userConsents

export const allConsentsSelector = createSelector(userConsentsSelector, state =>
  values(state.entities)
)

export const consentsLoadingSelector = createSelector(
  userConsentsSelector,
  consents => {
    return consents.loadingPending > 0
  }
)

export const notAcceptedRequiredConsentsSelector = createSelector(
  allConsentsSelector,
  consents => {
    return consents.filter(
      consent =>
        consent &&
        consent.mandatory &&
        consent.status !== ConsentStatus.Accepted
    )
  }
)

export const notSetOptionalConsentsSelector = createSelector(
  allConsentsSelector,
  consents => {
    return consents.filter(
      consent =>
        consent && !consent.mandatory && consent.status === ConsentStatus.NotSet
    )
  }
)

export const hasAcceptedOrSetAllConsentsSelector = createSelector(
  allConsentsSelector,
  notAcceptedRequiredConsentsSelector,
  notSetOptionalConsentsSelector,
  (allConsents, notAcceptedConsents, notSetOptionalConsents) => {
    return (
      allConsents.length > 0 &&
      notAcceptedConsents.length === 0 &&
      notSetOptionalConsents.length === 0
    )
  }
)

export const hasNotAcceptedRequiredOrSetConsentsSelector = createSelector(
  notAcceptedRequiredConsentsSelector,
  notSetOptionalConsentsSelector,
  (consents, notSetConsents) => {
    return consents.length > 0 || notSetConsents.length > 0
  }
)

export const electronicContactMandatoryConsentsSelector = createSelector(
  allConsentsSelector,
  consents => {
    var electronicContactConsents = consents.filter(
      consent =>
        consent &&
        !consent.mandatory &&
        consent.documentType === DocumentType.ElectronicContact
    )

    return electronicContactConsents.map(element =>
      Object.assign({}, element, { mandatory: true })
    )
  }
)

export const hasAcceptedElectronicContactConsentsSelector = createSelector(
  electronicContactMandatoryConsentsSelector,
  consents => {
    return (
      consents &&
      consents.length === 1 &&
      consents[0]?.status === ConsentStatus.Accepted
    )
  }
)
