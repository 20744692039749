import React from 'react'
import { Divider, Grid } from '@material-ui/core'
import { TableCellContent } from '@shared/components/TableCell'
import useStyles from './Document.style'
import { MeasurementPointListItem } from '@shared/contracts/models'
import { useTranslation } from 'react-i18next'

interface IDocumentProps {
  address: JSX.Element | string
  selectedPoint: MeasurementPointListItem
  invoiceTypeContent: JSX.Element
  tariff: string
}

export const Document: React.FC<IDocumentProps> = ({
  address,
  selectedPoint,
  invoiceTypeContent,
  tariff,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const { number, lastConsumptionValue, consumptionUnit } = selectedPoint

  return (
    <>
      <Grid container direction="column" className={styles.table}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} className={styles.gridElement}>
            <TableCellContent
              header={t('MY_CONTRACTS.FIELD_NAMES.POINT_ADDRESS')}
              content={address}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={styles.gridElement}>
            <TableCellContent
              header={t('MY_CONTRACTS.FIELD_NAMES.MEASUREMENT_POINT_NUMBER')}
              content={number || ''}
            />
          </Grid>
        </Grid>
        <Divider className={styles.divider} />
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            md={lastConsumptionValue ? 4 : 6}
            className={`${styles.gridElement} ${styles.flexEndAlignment}`}
          >
            <TableCellContent
              header={t('MY_CONTRACTS.FIELD_NAMES.INVOICE')}
              content={invoiceTypeContent}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={lastConsumptionValue ? 2 : 6}
            className={`${styles.gridElement} ${styles.flexEndAlignment}`}
          >
            <TableCellContent
              header={t('MY_CONTRACTS.FIELD_NAMES.TARIFF_GROUP')}
              content={tariff}
            />
          </Grid>
          {lastConsumptionValue ? (
            <Grid
              item
              sm={12}
              md={6}
              className={`${styles.gridElement} ${styles.flexEndAlignment}`}
            >
              <TableCellContent
                header={t('MY_CONTRACTS.FIELD_NAMES.ENERGY_CONSUMPTION')}
                content={lastConsumptionValue + ' ' + consumptionUnit}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  )
}
