import { Theme, createStyles, makeStyles } from '@material-ui/core'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '0 0 16px',
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'left',
      textAlign: 'left',
      minHeight: '17px',
      margin: '0 0 4px 20px',
      '& p': {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        gap: '3.5px',
        marginRight: '8px',
        fontSize: 12,
        fontWeight: 800,
        color: theme.palette.text.primary,
        letterSpacing: '0.08px',
        [theme.breakpoints.down('xs')]: {
          margin: 0,
        },
        '& span': {
          opacity: 0.25,
          [theme.breakpoints.down('xs')]: {
            fontSize: 10,
          },
        },
      },
      '& div > p': {
        margin: 0,
        fontSize: 12,
        color: theme.palette.error.main,
        fontWeight: theme.typography.fontWeightBold,

        [theme.breakpoints.down('xs')]: {
          margin: 0,
        },
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        marginLeft: 0,
      },
    },
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '50px',
      '& p': {
        margin: 0,
        fontSize: 11,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: '0.08px',
        textAlign: 'center',
      },
      '& button': {
        fontSize: 16,
        width: '44%',
      },
      [theme.breakpoints.down('xs')]: {
        '& p': {
          fontSize: 10,
        },
        '& button': {
          fontSize: 12,
          width: '44%',
        },
      },
    },
    unselectedButton: {
      height: '50px',
      border: 'transparent',
      borderRadius: '7px',
      backgroundColor: '#f5f5f5',
      boxShadow: 'none',
      color: theme.palette.text.secondary + colorOpacityParser(75),
      fontWeight: 700,
      letterSpacing: ' 0.11px',
      lineHeight: '16px',
      overflow: 'hidden',
      transition: 'color 0.2s, background-color 0.2s',
      textTransform: 'none',
      '&:hover': {
        opacity: 1,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        border: 'transparent',
        boxShadow: 'none',
      },
    },
    selectedButton: {
      height: '50px',
      border: `2.5px solid transparent`,
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
      borderRadius: '7px',
      boxShadow: 'none',
      fontWeight: 700,
      letterSpacing: ' 0.11px',
      lineHeight: '16px',
      overflow: 'hidden',
      transition: 'color 0.2s, background-color 0.2s',
      textTransform: 'none',
      '&:hover': {
        opacity: 1,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        border: 'transparent',
        boxShadow: 'none',
      },
    },
  })
)
