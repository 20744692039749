export enum OfferType {
  advertisement = 'Advertisement',
  information = 'Information',
  reminder = 'ConsentReminder',
}

export enum ImageType {
  top = 'Toptile',
  card = 'Card',
  banner = 'Banner',
}

export enum ImageSize {
  mobile = 'Mobile',
  desktop = 'Desktop',
}

export interface OfferImage {
  imageUrl: string
  type: ImageType
  size: ImageSize
}

export interface Offer {
  title: string
  redirectUrl: string
  priorityScore: number
  adType: OfferType
  images: OfferImage[]
}
