/**
 * mBok API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AgreementStatus = 'Active' | 'Confirmed' | 'Historic'

export const AgreementStatus = {
  Active: 'Active' as AgreementStatus,
  Confirmed: 'Confirmed' as AgreementStatus,
  Historic: 'Historic' as AgreementStatus,
}
