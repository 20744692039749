import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import plTranslation from './pl.json'

const resources = {
  pl: { global: plTranslation },
}

export default () => {
  i18n.use(initReactI18next).init({
    resources,
    defaultNS: 'global',
    lng: 'pl',
    fallbackLng: ['pl'],
    interpolation: {
      escapeValue: false,
    },
  })
  return i18n
}
