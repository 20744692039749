import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

const SETTINGS_ACION_BTN_WIDTH = 188
const SETTINGS_ACION_BTN_HEIGHT = 39

const EMAIL_NOTIFICATIONS = 500

export default makeStyles((theme: Theme) =>
  createStyles({
    settingsActionButton: {
      width: SETTINGS_ACION_BTN_WIDTH,
      height: SETTINGS_ACION_BTN_HEIGHT,
      fontSize: 14,
      border: '2.7px solid #DAD9D9',
      color: tableTextColor,
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3),
      },
    },
    sectionWrapper: {
      marginBottom: theme.spacing(8.75),
      display: 'flex',
      flexWrap: 'wrap',
    },
    settingsWrapper: {
      marginTop: theme.spacing(4.25),
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    emailNotificationsContainer: {
      flex: `0 0 ${EMAIL_NOTIFICATIONS}px`,
      marginRight: theme.spacing(10),
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        flex: `1 0 100%`,
        marginBottom: theme.spacing(4.25),
      },
    },
    marketingConsentsContainer: {
      flex: '1 1',
      [theme.breakpoints.down('md')]: {
        flex: `1 0 100%`,
      },
    },
    togglerWrapper: {
      paddingLeft: theme.spacing(8),
      flex: '1 1',
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
        marginTop: theme.spacing(2),
        flex: `1 0 100%`,
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    toggler: {
      marginRight: theme.spacing(4.5),
      '& p': {
        margin: theme.spacing(0, 3),
      },
    },
    header: {
      flexWrap: 'wrap',
      '& div > b': {
        whiteSpace: 'pre-wrap !important',
      },
    },
    pointTitle: {
      color: '#415467',
      fontSize: 17,
      lineHeight: '17px',
      letterSpacing: 0.6,
      whiteSpace: 'pre-wrap',
      display: 'flex',
      flexWrap: 'wrap',
      '& span': {
        '&:first-child': {
          marginRight: theme.spacing(1.5),
        },
        '&:last-child': {
          color: tableTextColor,
          fontWeight: 600,
        },
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3),
      },
    },
    linkWrapper: {
      marginTop: theme.spacing(4.5),
    },
    addressWrapper: {
      display: 'inline-flex',
      flexDirection: 'column',
    },
    changePasswordWrapper: {
      [theme.breakpoints.down('xs')]: {
        alignSelf: 'center',
      },
    },
    emptyContracts: {
      height: 180,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: 19,
      color: tableTextColor,
      opacity: 0.55,
    },
  })
)
