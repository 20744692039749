import {
  FormControl,
  RadioGroup as MaterialRadioGroup,
  RadioGroupProps as MaterialRadioGroupProps,
  Typography,
} from '@material-ui/core'
import React from 'react'
import Radio from '../Radio'

export interface Option {
  value: string
  label: string | React.ReactElement<any, any>
}

interface RadioGroupProps {
  label: string
  options: Option[]
  headingClass?: string
}

export const RadioGroup: React.FC<
  RadioGroupProps & MaterialRadioGroupProps
> = ({
  label,
  name,
  value,
  onChange,
  options,
  headingClass = '',
  className,
}) => {
  return (
    <div className={className && className}>
      <Typography className={headingClass && headingClass}>{label}</Typography>
      <FormControl component="fieldset">
        <MaterialRadioGroup name={name} value={value} onChange={onChange}>
          {options.map((option: Option) => (
            <Radio value={option.value} label={option.label} key={option.value} />
          ))}
        </MaterialRadioGroup>
      </FormControl>
    </div>
  )
}
