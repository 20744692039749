import { useThunkApi } from '@core/apiClient/apiClient'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { handleLoadingAction } from '../entity/helpers/thunks/entityThunksFactory'
import { StoreReducers } from '../StoreReducers'
import { OffersState } from './offers'
import { Offer } from './types'

export const getOffers = createAsyncThunk<Offer[]>(
  `${[StoreReducers.offers]}/getOffers`,
  async (_, thunkApi) => {
    const [execute] = useThunkApi<Offer[]>(
      {
        config: {
          method: 'get',
          url: `offers`,
        },
      },
      thunkApi
    )
    const response = await execute()
    return response.data
  }
)

export const getOffersExtraReducers = (
  builder: ActionReducerMapBuilder<OffersState>
) => {
  handleLoadingAction(getOffers, builder)
  builder.addCase(getOffers.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
    state.offers = payload
  })
}
