import { reducer as oidc } from '@axa-fr/react-oidc-redux'
import { combineReducers } from '@reduxjs/toolkit'
import { EntityReducers } from './entity/EntityReducers'
import { testSlice } from './entity/TestReducer'
import { persisteCustomerNumbersReducer } from './entity/customerNumbers/customerNumbers'
import { StoreReducers } from './StoreReducers'
import { userAccountSlice } from './user/userAccount'
import { administrationSlice } from './administration/administration'
import { persistUiReducer } from './ui/ui'
import { paymentsSlice } from './payments/payments'
import { policiesSlice } from './policies/policies'
import { offersSlice } from './offers/offers'
import { userConsentsSlice } from './entity/userConsents/userConsents'
import { userAgreementsSlice } from './entity/userAgreements/userAgreements'
import { documentsSlice } from './entity/documents/documents'
import { readingsSlice } from './entity/readings/readings'
import { measurementPointsSlice } from './entity/measurementPoints/measurementPoints'
import { userNotificationsSlice } from './entity/userNotifications/userNotifications'
import { newsSlice } from './entity/news/news'
import { persistedSettingsReducer } from './persistedSettings/persisted'
import { trustedMfaDevicesSlice } from './trustedMfaDevices/trustedMfaDevices'

const rootReducer = combineReducers({
  [EntityReducers.test]: testSlice.reducer,
  [EntityReducers.userConsents]: userConsentsSlice.reducer,
  [EntityReducers.userAgreements]: userAgreementsSlice.reducer,
  [EntityReducers.customerNumbers]: persisteCustomerNumbersReducer,
  [EntityReducers.documents]: documentsSlice.reducer,
  [EntityReducers.readings]: readingsSlice.reducer,
  [EntityReducers.measurementPoints]: measurementPointsSlice.reducer,
  [EntityReducers.news]: newsSlice.reducer,
  [EntityReducers.userNotifications]: userNotificationsSlice.reducer,
  oidc,
  [StoreReducers.userAccount]: userAccountSlice.reducer,
  [StoreReducers.ui]: persistUiReducer,
  [StoreReducers.payments]: paymentsSlice.reducer,
  [StoreReducers.policies]: policiesSlice.reducer,
  [StoreReducers.persistedSettings]: persistedSettingsReducer,
  [StoreReducers.offers]: offersSlice.reducer,
  [StoreReducers.administration]: administrationSlice.reducer,
  [StoreReducers.trustedMfaDevices]: trustedMfaDevicesSlice.reducer,
})
export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
