import { useCallback } from 'react'
import { useAnalyticsState } from './hooks/useAnalyticsState'
import { useEventsQueue } from './hooks/useEventsQueue'
import { usePageViews } from './hooks/usePageViews'
import { initializeGa } from './utils/initializeGa'
import { deactivateGa } from './utils/deactivateGa'
import { trackCustomEvent } from './utils/trackCustomEvent'

export const useAnalytics = () => {
  const [, sendEventsFromQueue] = useEventsQueue()
  const [analyticsState, setAnalyticsState] = useAnalyticsState()
  usePageViews()

  const enableGa = useCallback(
    (newState?: boolean) => {
      if (newState) {
        setAnalyticsState(newState)
      }

      if (newState === false) {
        setAnalyticsState(newState)
        deactivateGa()
        sendEventsFromQueue()
      } else if (analyticsState || newState === true) {
        initializeGa()
        sendEventsFromQueue()
      }
    },
    [analyticsState, sendEventsFromQueue, setAnalyticsState]
  )

  const _trackCustomEvent = useCallback(
    (event: any) => {
      trackCustomEvent(event, analyticsState)
    },
    [analyticsState]
  )

  type useAnalyticsReturnType = [
    typeof _trackCustomEvent,
    boolean,
    typeof enableGa
  ]

  return [_trackCustomEvent, analyticsState, enableGa] as useAnalyticsReturnType
}
