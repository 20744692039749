import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

const MIN_HEIGHT = 140
const MIN_WIDTH = 280

const FILTERS_HEADER_ICON_SIZE = 20

const BTN_WIDTH = 172
const BTN_HEIGHT = 42

export default makeStyles((theme: Theme) =>
  createStyles({
    appHeader: {
      fontSize: 20,
    },
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    paper: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(7),
      position: 'relative',
      minWidth: MIN_WIDTH,
      minHeight: MIN_HEIGHT,
    },
    filters: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    filtersHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
      '&:first-child': {
        '& svg': {
          height: FILTERS_HEADER_ICON_SIZE,
          margin: theme.spacing(0, 4, 0, 0),
          width: 'auto',
          fill: theme.palette.primary.main,
        },
        '& h2': {
          margin: 0,
          fontWeight: 700,
          fontSize: 19,
          color: tableTextColor,
          letterSpacing: 0.16,
        },
      },
    },
    filtersOptions: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& button': {
        marginLeft: theme.spacing(1),
        width: BTN_WIDTH,
        height: BTN_HEIGHT,
        fontWeight: 700,
        fontSize: 14,
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(1, 0, 0),
          width: '100%',
        },
      },
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        flexDirection: 'column',
      },
    },
    logoContainer: {
      display: 'flex',
    },
    logo: {
      width: '100%',
      alignSelf: 'flex-start',
      maxWidth: 100,
    },
  })
)
