import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'
import { DETAILS_CARD_CONTENT_MIN_HEIGHT } from '@shared/components/DetailsCard/DetailsCard'

const BTN_WIDTH = 172

export default makeStyles((theme: Theme) =>
  createStyles({
    headerContent: {
      margin: theme.spacing(1, 0, 1, 9),
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',

      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        width: '100%',
      },
    },
    buttons: {
      flexWrap: 'wrap',
      '& button': {
        padding: theme.spacing(2, 0),
        margin: theme.spacing(2, 0),
        width: BTN_WIDTH,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        flexDirection: 'column',
        '& button': {
          width: '100%',
          padding: theme.spacing(2, 0),
        },
      },
    },
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(9),
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    resizableContainer: {
      overflowY: 'hidden',
      transition: 'height 0.8s ease-in-out',
    },
    empty: {
      margin: 0,
      height: DETAILS_CARD_CONTENT_MIN_HEIGHT + 20,
      fontSize: 19,
      color: tableTextColor,
      opacity: 0.55,
    },
  })
)
