import React from 'react'
import { BackgroundWrapper } from '@shared/BackgroundWrapper'
import { useStyles } from './NotFound.styles'
import { Animation } from '@shared/components/Animation'
import { Animation404Src } from '@shared/static-data/staticData'

export const NotFoundPage: React.FC = () => {
  const styles = useStyles()

  return (
    <BackgroundWrapper>
      <div className={styles.wrapper}>
        <Animation src={Animation404Src} customClass={styles.animation} />
        <div className={styles.content}>
          <h2>Przepraszamy - strona, której szukasz nie istnieje</h2>
        </div>
      </div>
    </BackgroundWrapper>
  )
}
