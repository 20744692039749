import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './DocumentLink.style'

export interface ISettingProps {
  title: string
  onClick: () => void
}

export const DocumentLink: React.FC<ISettingProps> = ({
  title,
  onClick,
  ...props
}) => {
  const styles = useStyles()
  return (
    <Grid className={styles.wrapper} item {...props} xs={12} sm={6} lg={3}>
      <Typography className={styles.title} onClick={onClick}>
        {title}
      </Typography>
    </Grid>
  )
}
