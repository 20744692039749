import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

const TOGGLER_MIN_WIDTH = 120

const PARAGRAPH_WIDTH = 60

const TOGGLE_ICON_CONTAINER_SIZE = 30
const TOGGLE_ICON_SIZE = 20

export default makeStyles((theme: Theme) =>
  createStyles({
    toggler: {
      display: 'flex',
      alignItems: 'center',
      minWidth: TOGGLER_MIN_WIDTH,
      '& p': {
        minWidth: PARAGRAPH_WIDTH,
        margin: theme.spacing(0, 2),
        fontSize: 17,
        color: tableTextColor,
        textAlign: 'center',
        letterSpacing: 0.04,
        fontWeight: theme.typography.fontWeightRegular,
        '& span': {
          fontWeight: 800,
        },
      },
      '& div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: TOGGLE_ICON_CONTAINER_SIZE,
        minWidth: TOGGLE_ICON_CONTAINER_SIZE,
        height: TOGGLE_ICON_CONTAINER_SIZE,
        minHeight: TOGGLE_ICON_CONTAINER_SIZE,
        borderRadius: '100%',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        transition: 'background-color 0.2s ease-in-out',
        '& img': {
          width: TOGGLE_ICON_SIZE,
          height: TOGGLE_ICON_SIZE,
          [theme.breakpoints.down('xs')]: {
            width: TOGGLE_ICON_SIZE - 4,
            height: TOGGLE_ICON_SIZE - 4,
          },
        },
      },
      [theme.breakpoints.down('xs')]: {
        '& p': {
          margin: theme.spacing(0, 1),
          fontSize: 15,
          minWidth: PARAGRAPH_WIDTH - 4,
        },
        '& div': {
          width: TOGGLE_ICON_CONTAINER_SIZE - 4,
          minWidth: TOGGLE_ICON_CONTAINER_SIZE - 4,
          height: TOGGLE_ICON_CONTAINER_SIZE - 4,
          minHeight: TOGGLE_ICON_CONTAINER_SIZE - 4,
        },
      },
    },
  })
)
