import {
  lighten,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { primaryColor, textSecondaryColor } from '@mbok/styles/constants'
import CustomScrollBar from '@shared/components/ScrollBar'
import { getMonth } from 'date-fns'
import { pl } from 'date-fns/locale'
import React, { useMemo } from 'react'
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  BarChart,
  Tooltip,
  Rectangle,
  RectangleProps,
} from 'recharts'
import useStyles from './Chart.style'

const CURRENT_MONTH = getMonth(new Date())
const BAR_WIDTH = 32

const PAST_YEAR_COLOR = '#F0F0F0'
const CURR_YEAR_BORDER = '#686868'

const CustomizedAxisXTick: React.FC<{
  x: number
  y: number
  stroke: number
  payload: {
    value: string
  }
}> = ({ payload, x, y }) => {
  const labelWidth = BAR_WIDTH * 1.25
  return (
    <g transform={`translate(${x},${y})`}>
      <foreignObject
        x={-labelWidth / 2}
        y={0}
        dy={16}
        fill={textSecondaryColor}
        width={labelWidth}
        height={40}
      >
        <div
          style={{
            textTransform: 'capitalize',
            width: '100%',
            textAlign: 'center',
            position: 'fixed',
            paddingBottom: 2,
            fontSize: 16,
            fontWeight: 600,
            ...(CURRENT_MONTH.toString() === payload.value
              ? {
                  fontWeight: 800,
                  borderBottom: `5px solid ${primaryColor}`,
                }
              : {}),
          }}
        >
          {pl.localize
            ?.month(payload.value, { width: 'abbreviated' })
            ?.toUpperCase()}
        </div>
      </foreignObject>
    </g>
  )
}

const CustomizedAxisYTick: React.FC<{
  x: number
  y: number
  stroke: number
  payload: {
    value: string
  }
}> = ({ payload, x, y }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-4}
        y={0}
        dy={5}
        textAnchor="end"
        fill={textSecondaryColor}
        style={{
          fontWeight: 600,
        }}
      >
        {payload.value}
      </text>
    </g>
  )
}

export interface IReading {
  month: string
  past: number
  current: number
}

export interface IChartProps {
  readings: IReading[]
}

const CHART_SMALL_HEIGHT = 380
const CHART_MEDIUM_HEIGHT = 420
const CHART_LARGE_HEIGHT = 460
const CHART_X_LARGE_HEIGHT = 540

export const Chart: React.FC<IChartProps> = ({ readings }) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const largeScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const barWidth = mediumScreen ? BAR_WIDTH - 12 : BAR_WIDTH

  const chartHeight = useMemo(() => {
    if (smallScreen) return CHART_SMALL_HEIGHT
    else if (mediumScreen) return CHART_MEDIUM_HEIGHT
    else if (largeScreen) return CHART_LARGE_HEIGHT
    return CHART_X_LARGE_HEIGHT
  }, [largeScreen, mediumScreen, smallScreen])

  // added here because using hooks inside is impossible
  const CustomTooltip: React.FC<{
    active: boolean
    payload: { value: string }[]
    label: string
  }> = ({ active, payload, label }) => {
    if (active) {
      return (
        <Paper variant="outlined" className={styles.tooltip}>
          <Typography className={styles.tooltipLabel}>
            {pl.localize?.month(label)}
          </Typography>
          <div className={styles.tooltipData}>
            <Typography variant="caption" className={styles.tooltipHint}>
              Poprzedni rok
            </Typography>
            <Typography className={styles.value}>
              {payload && payload[0]?.value} kWh
            </Typography>
          </div>
          <div className={styles.tooltipData}>
            <Typography variant="caption" className={styles.tooltipHint}>
              Bieżący rok
            </Typography>
            <Typography
              className={styles.value}
              style={{
                color: primaryColor,
              }}
            >
              {payload && payload[1]?.value} kWh
            </Typography>
          </div>
        </Paper>
      )
    }

    return null
  }

  const CustomBar: React.FC<RectangleProps> = ({ ...props }) => {
    const hasValue = props?.height && props.height > 10
    const fill = hasValue
      ? props?.fill
      : lighten(props?.fill || primaryColor, 0.4)
    return <Rectangle radius={hasValue ? 5 : 0} fill={fill} {...props} />
  }

  const containerMinWidth = useMemo(() => {
    const baseWidth = 200
    return baseWidth + (smallScreen ? barWidth * 2 * readings.length : 0)
  }, [readings.length, barWidth, smallScreen])

  return (
    <>
      <div className={styles.chartUnit}>
        <Typography variant="caption">Zużycie</Typography>
        <Typography variant="caption">[kWh]</Typography>
      </div>
      <CustomScrollBar>
        <ResponsiveContainer
          width="100%"
          height={chartHeight}
          minWidth={containerMinWidth}
        >
          <BarChart
            data={readings}
            barGap={0}
            margin={{
              top: 20,
              left: 0,
              right: 20,
              bottom: 20,
            }}
            barSize={barWidth}
          >
            <CartesianGrid
              vertical={false}
              strokeDasharray="1 0"
              stroke="#5F76FD"
              strokeWidth={2}
              strokeOpacity={0.15}
            />
            <XAxis
              axisLine={false}
              tickLine={false}
              dataKey="month"
              tick={CustomizedAxisXTick}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={CustomizedAxisYTick}
            />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              isAnimationActive={false}
              content={CustomTooltip}
            />
            <Bar
              dataKey="past"
              minPointSize={6}
              fill={PAST_YEAR_COLOR}
              shape={CustomBar}
            />
            <Bar
              dataKey="current"
              fill={primaryColor}
              minPointSize={6}
              shape={CustomBar}
            />
          </BarChart>
        </ResponsiveContainer>
      </CustomScrollBar>
      <div className={styles.chartLegend}>
        <div className={styles.legendItem}>
          <div
            style={{
              background: PAST_YEAR_COLOR,
            }}
          />
          <span>Poprzedni rok</span>
        </div>
        <div className={styles.legendItem}>
          <div
            style={{
              background: primaryColor,
              borderColor: CURR_YEAR_BORDER,
            }}
          />
          <span>Bieżący rok</span>
        </div>
      </div>
    </>
  )
}
