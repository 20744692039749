import {
  Button,
  Collapse,
  LinearProgress,
  makeStyles,
  Theme,
} from '@material-ui/core'
import {
  ValidateRegistrationResult,
  ValidationErrorEnum,
} from '@shared/contracts/models'
import { RegistrationFormFields } from '@shared/contracts/registrationFormFields'
import { AxiosResponse } from 'axios'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { forEachObjIndexed, isEmpty, reduce } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useApi } from '../../../core/apiClient/apiClient'
import {
  accountTypes,
  ErrorCodes,
  ErrorCodeTypes,
  ErrorMsg,
  fieldNames,
  registerData,
  commercialAccountData,
  invoiceValueData,
  roles,
  mfaChannels,
  phoneNumberData,
} from '../static-data/RegisterData'
import { extractFormValues } from '../utils/formValueMapper'
import { ValidationErrorModal } from './modals/ValidationErrorModal'
import {
  DisplayInput,
  DisplayPeselOrNipInput,
  DisplayRegisterConsents,
} from './RegisterFields'
import { SubmitButton } from './SubmitButton'
import Autocomplete from '@shared/components/Autocomplete'
import { ErrorPosition } from '@shared/components/modals/Modal/TextField/TextField'
import { SelectiveButtons } from '@shared/components/SelectiveButtons/SelectiveButtons'
import PhoneNumberInput from '@shared/components/PhoneNumberInput/PhoneNumberInput'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneNumberConfirmationModal from './modals/PhoneNumberConfirmationModal'
import { MfaContactType } from '@shared/contracts/mfaContactType'
import { phoneFormatter } from '@shared/utils/phoneFormatter'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& h1': {
      height: '52px',
      margin: '0 0 4px',
      fontSize: 30,
      letterSpacing: '0.35px',
      lineHeight: '38px',
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightRegular,
    },
    '& h2': {
      margin: '0 0 24px',
      fontSize: 18,
      letterSpacing: '0.13px',
      lineHeight: '20px',
      textAlign: 'left',
    },
    '& h3': {
      margin: '0 0 20px',
      fontSize: 18,
      letterSpacing: '0.13px',
      lineHeight: '20px',
      textAlign: 'center',
    },
    '& p.description': {
      textAlign: 'left',
      fontSize: 12,
      fontWeight: 800,
      color: theme.palette.text.primary,
      margin: '0 0 20px 20px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  loader: {
    margin: '8px 0',
    height: '4px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    width: '90%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '8px',
    },
  },
  linkButton: {
    '& button': {
      color: theme.palette.primary.main,
      marginTop: '8px',
      height: '28px',
      fontWeight: 700,
      fontSize: 17,
      textTransform: 'unset',
      transition: 'color 0.2s ease-in-out',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '& button': {
        color: theme.palette.primary.dark,
      },
    },
    textDecoration: 'none',
    outline: 'none',
  },
  popper: {
    marginTop: -20,
    position: 'absolute',
  },
  autocompleteWrapper: {
    position: 'relative',
    '& p': {
      fontSize: 12,
      fontWeight: 800,
      letterSpacing: '0.08px',
      textTransform: 'uppercase',
      marginLeft: 16,
      opacity: '1.0 !important',
    },
  },
  inputRoot: {
    '&[class*="MuiInput-root"]': {
      borderRadius: 7,
      height: 50,
    },
  },
  collapse: {
    margin: '0 0 16px',
  },
}))

export interface InitialFieldsType {
  [key: string]: string | boolean | string[]
}

interface RegisterProps {
  formData: InitialFieldsType
  setFormData: React.Dispatch<React.SetStateAction<InitialFieldsType>>
  setFormApiResponse: React.Dispatch<
    React.SetStateAction<AxiosResponse<ValidateRegistrationResult> | undefined>
  >
  nextPage: () => void
  token?: string
}

const {
  TAX_NUMBER,
  CUSTOMER_NUMBER,
  PHONE,
  ACCOUNT_TYPE,
  INVOICE_VALUE,
} = fieldNames
const { NOT_SELECTED } = accountTypes

const initialValues = reduce<string, { [fieldName: string]: string | boolean }>(
  (acc, field) => {
    const isAccountType: boolean = field === ACCOUNT_TYPE

    return {
      ...acc,
      ...(isAccountType ? { [field]: NOT_SELECTED } : { [field]: '' }),
    }
  },
  {},
  Object.values(fieldNames)
)

export const Register: React.FC<RegisterProps> = ({
  formData,
  setFormData,
  setFormApiResponse,
  nextPage,
  token,
}) => {
  const [executeGetData] = useApi<RegistrationFormFields>({
    omitCustomerNumber: true,
    omitConsents: true,
    config: {
      method: 'get',
      url: 'Accounts/autocompleteRegistrationForm/' + token,
    },
  })

  const [isTokenValid, setIsTokenOk] = useState<boolean>(false)
  const [isQuickRegistration, setIsQuickRegistration] = useState({
    phone: false,
    rest: false,
  })
  const [initialFormValues, setInitialFormValues] = useState<InitialFieldsType>(
    initialValues
  )

  useEffect(() => {
    const getData = async () => {
      try {
        const apiReponse = await executeGetData()
        const {
          accountType,
          phoneNumber,
          phonePrefix,
          customerNumber,
          email,
        } = apiReponse.data
        setInitialFormValues((prevState: InitialFieldsType) => ({
          ...prevState,
          Phone: (phoneNumber ?? '').replace(/\s/g, ''),
          PhonePrefix: phonePrefix ?? '',
          Email: email ?? '',
          CustomerNumber: customerNumber ?? '',
          Type: (accountType?.toLowerCase() as accountTypes) ?? NOT_SELECTED,
        }))
        setIsTokenOk(true)
        setIsQuickRegistration({
          phone: Boolean(!!phoneNumber && !!phonePrefix),
          rest: Boolean(customerNumber?.length > 0 && email?.length > 0),
        })
      } catch {}
    }

    getData()
  }, [executeGetData])

  const styles = useStyles()
  const { header, subHeader, mfa, inputs } = registerData
  const { enqueueSnackbar } = useSnackbar()

  const [showErrModal, setShowErrModal] = useState(false)
  const [
    showConfirmPhoneNumberModal,
    setShowConfirmPhoneNumberModal,
  ] = useState(false)
  const [errCode, setErrCode] = useState<ErrorCodeTypes>(ErrorCodes.empty)

  const [executeRegister, registerLoading] = useApi<ValidateRegistrationResult>(
    {
      omitCustomerNumber: true,
      omitConsents: true,
      config: {
        method: 'post',
        url: 'Accounts/validateRegistration',
      },
    }
  )

  const onFormSubmit = async (
    values: InitialFieldsType,
    validateForm: (values: {}) => {},
    setFieldError: (field: string, err: string) => void
  ) => {
    const validate = await validateForm(values)

    if (isEmpty(validate)) {
      const formValues = extractFormValues(values)
      executeRegister({
        data: {
          ...formValues,
          PhonePrefix:
            formValues.MFAContactType === 'Sms' ||
            String(formValues.Phone).length
              ? formValues.PhonePrefix
              : '',
        },
      })
        .then(response => {
          const { data } = response
          if (data.validationSuccessful) {
            setFormApiResponse && setFormApiResponse(response)
            setFormData(values)
            if (
              values.MFAContactType === MfaContactType.Sms &&
              !isQuickRegistration.phone
            ) {
              setShowConfirmPhoneNumberModal(true)
            } else {
              nextPage()
            }
          } else {
            const {
              CustomerNotFound,
              FakeTaxNumber,
              TaxNumberUsed,
              CustomerNotActive,
              SegmentNotActive,
              AdditionalValidationFailed,
            } = ValidationErrorEnum
            switch (data.errorReason) {
              case FakeTaxNumber:
                enqueueSnackbar('Błędny numer PESEL/NIP', {
                  variant: 'error',
                })
                setFieldError(TAX_NUMBER, 'Błędny numer PESEL/NIP')
                setErrCode(ErrorCodes.FakeTaxNumber)
                setShowErrModal(true)
                break
              case CustomerNotFound:
                enqueueSnackbar(
                  'Sprawdź, czy podany numer płatnika jest poprawny',
                  {
                    variant: 'error',
                  }
                )
                setFieldError(
                  CUSTOMER_NUMBER,
                  'Sprawdź, czy podany numer płatnika jest poprawny'
                )
                setFieldError(
                  TAX_NUMBER,
                  'Sprawdź, czy podany numer PESEL/NIP jest poprawny'
                )
                setErrCode(ErrorCodes.CustomerNotFound)
                break
              case TaxNumberUsed:
                enqueueSnackbar('Podany numer PESEL/NIP został już użyty', {
                  variant: 'error',
                })
                setFieldError(
                  TAX_NUMBER,
                  'Podany numer PESEL/NIP został już użyty'
                )
                setErrCode(ErrorCodes.TaxNumberUsed)
                setShowErrModal(true)
                break
              case CustomerNotActive:
                enqueueSnackbar('Podany numer płatnika nie jest aktywny', {
                  variant: 'error',
                })
                setFieldError(
                  CUSTOMER_NUMBER,
                  'Podany numer płatnika nie jest aktywny'
                )
                setErrCode(ErrorCodes.CustomerNotActive)
                setShowErrModal(true)
                break
              case SegmentNotActive:
                setErrCode(ErrorCodes.SegmentNotActive)
                setShowErrModal(true)
                break
              case AdditionalValidationFailed:
                enqueueSnackbar(
                  'Podana kwota jest nieprawidłowa. Sprawdź ponownie jej poprawność na fakturze lub skontaktuj się z bok@polenergia.pl opisując problem',
                  {
                    variant: 'error',
                  }
                )
                setFieldError(
                  INVOICE_VALUE,
                  'Podana kwota jest nieprawidłowa. Sprawdź ponownie jej poprawność na fakturze lub skontaktuj się z bok@polenergia.pl opisując problem'
                )
                setErrCode(ErrorCodes.AdditionalValidationFailed)
                break
              default:
                setErrCode(ErrorCodes.empty)
                enqueueSnackbar(ErrorMsg.noConnectionOrServerError, {
                  variant: 'error',
                })
            }
          }
        })
        .catch(err => {
          if (!err.status || err.status === 500) {
            enqueueSnackbar(ErrorMsg.noConnectionOrServerError, {
              variant: 'error',
            })
          }

          forEachObjIndexed((val, key) => {
            setFieldError(key as string, val)
            enqueueSnackbar(val, {
              variant: 'error',
            })
          }, err.response)
        })
    }
  }

  const validateCustomFields = (values: InitialFieldsType) => {
    const errors: Partial<InitialFieldsType> = {}
    if (values.Type === accountTypes.COMMERCIAL) {
      if (values.Position === '') {
        errors.Position = 'Wybierz stanowisko'
      } else if (!roles.some(role => values.Position === role)) {
        errors.Position = 'Wybierz wartość z dostępnego słownika'
      }
    }
    if (values.MFAContactType === '') {
      errors.MFAContactType = 'Wybierz kanał komunikacji dla MFA'
    }
    if (values.Phone !== '' || values.MFAContactType === mfaChannels[0].value) {
      if (
        values.Phone === '' ||
        !isValidPhoneNumber(`${values.PhonePrefix}${values.Phone}`) ||
        values.Phone.toString().length < 9
      ) {
        errors.Phone = 'Wpisz poprawny numer telefonu'
      }
    }
    return errors
  }

  return (
    <div className={styles.container}>
      <Formik
        validate={values => validateCustomFields(values)}
        initialValues={isEmpty(formData) ? initialFormValues : formData}
        enableReinitialize
        onSubmit={() => {}}
      >
        {({
          setFieldValue,
          submitForm,
          getFieldProps,
          getFieldMeta,
          values,
          validateForm,
          setFieldError,
        }) => (
          <>
            <Form className={styles.formContainer}>
              <h1>{header}</h1>
              <h3>{subHeader}</h3>
              <p className="description">{mfa.description}</p>
              <SelectiveButtons
                name={fieldNames.MFA_CONTACT_TYPE}
                label={mfa.label}
                options={mfaChannels}
                helper={mfa.channel.helper}
                error={mfa.errorMsg()}
              />
              {inputs.map((input, index) => {
                const { name } = input
                return (
                  <Fragment key={name}>
                    {index === 0 || index === 2 ? (
                      <DisplayInput
                        input={{ ...input, disable: isQuickRegistration.rest }}
                        getFieldProps={getFieldProps}
                      />
                    ) : (
                      <DisplayInput
                        input={input}
                        getFieldProps={getFieldProps}
                      />
                    )}
                    {input.customNextField === PHONE ? (
                      <PhoneNumberInput
                        name={phoneNumberData.name}
                        phonePrefixName={phoneNumberData.phonePrefix}
                        label={phoneNumberData.title}
                        placeholder={phoneNumberData.placeholder}
                        persistedPhoneNumber={String(values.Phone)}
                        persistedPrefix={String(values.PhonePrefix)}
                        disabled={isQuickRegistration.phone}
                        optional={
                          values.MFAContactType !== mfaChannels[0].value
                        }
                      />
                    ) : null}
                    {input.customNextField === TAX_NUMBER ? (
                      <DisplayPeselOrNipInput
                        getFieldProps={getFieldProps}
                        getFieldMeta={getFieldMeta}
                        setFieldValue={setFieldValue}
                      />
                    ) : null}
                    {input.customNextField === TAX_NUMBER &&
                    getFieldProps(ACCOUNT_TYPE).value ===
                      accountTypes.COMMERCIAL &&
                    !isTokenValid ? (
                      <DisplayInput
                        input={invoiceValueData}
                        getFieldProps={getFieldProps}
                      />
                    ) : null}
                  </Fragment>
                )
              })}
              <Collapse
                in={
                  getFieldProps(ACCOUNT_TYPE).value === accountTypes.COMMERCIAL
                }
              >
                <p className={styles.collapse}>
                  <Fragment>
                    {commercialAccountData.map(input => {
                      const { name } = input
                      return (
                        <Fragment key={name}>
                          <DisplayInput
                            input={input}
                            getFieldProps={getFieldProps}
                          />
                        </Fragment>
                      )
                    })}
                    <Autocomplete
                      name="Position"
                      options={roles}
                      stringify={item => {
                        return item ?? ''
                      }}
                      carousel={true}
                      getOptionSelected={(option, value) => {
                        return option === value
                      }}
                      onInputChange={(event, value, reason) => {
                        setFieldValue('Position', value)
                      }}
                      label="Funkcja"
                      initialValue={values.Position as string}
                      value={values.Position as string}
                      classes={{
                        popper: styles.popper,
                        root: styles.autocompleteWrapper,
                        inputRoot: styles.inputRoot,
                      }}
                      customNoOptionText="Brak funkcji pasujących do kryteriów"
                      errorPosition={ErrorPosition.Top}
                    />
                  </Fragment>
                </p>
              </Collapse>
              <DisplayRegisterConsents />
              <Collapse in={registerLoading}>
                <LinearProgress className={styles.loader} />
              </Collapse>
              <div className={styles.buttonsContainer}>
                <SubmitButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await submitForm()
                    await onFormSubmit(values, validateForm, setFieldError)
                  }}
                >
                  Dalej
                </SubmitButton>
                <Link type="button" to="/" className={styles.linkButton}>
                  <Button variant="text">Logowanie</Button>
                </Link>
              </div>
            </Form>
          </>
        )}
      </Formik>
      <ValidationErrorModal
        show={showErrModal}
        close={() => setShowErrModal(false)}
        errCode={errCode}
      />
      <PhoneNumberConfirmationModal
        show={showConfirmPhoneNumberModal}
        close={() => setShowConfirmPhoneNumberModal(false)}
        phoneNumber={`${formData.PhonePrefix} ${phoneFormatter(
          String(formData.Phone)
        )}`}
        nextPage={nextPage}
      />
    </div>
  )
}
