import React from 'react'
import { Button, Collapse } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'

import { HintPopover } from '../HintPopover/HintPopover'
import useStyles from './SelectiveButtons.style'
import {
  ISelectiveButtonsFormValues,
  SelectiveButtonsProps,
} from './SelectiveButtons.types'

export const SelectiveButtons: React.FC<SelectiveButtonsProps> = ({
  helper,
  label,
  options,
  error,
  name,
  onClick,
}) => {
  const styles = useStyles()
  const { setFieldValue, errors, touched } = useFormikContext<
    ISelectiveButtonsFormValues
  >()
  const [field] = useField(name)

  const handleClick = (option: string) => {
    onClick && onClick(option)
    setFieldValue(field.name, option)
  }

  return (
    <div className={styles.container}>
      {label && (
        <div className={styles.label}>
          <p>
            {label} {helper && <HintPopover content={helper.content} />}
          </p>
          <Collapse in={Boolean(errors[field.name] && touched[field.name])}>
            <p>{error}</p>
          </Collapse>
        </div>
      )}
      <div className={styles.buttonsContainer}>
        <Button
          classes={{
            root:
              field.value === options[0].value
                ? styles.selectedButton
                : styles.unselectedButton,
          }}
          onClick={() => handleClick(options[0].value)}
        >
          {options[0].name}
        </Button>
        <p>LUB</p>
        <Button
          classes={{
            root:
              field.value === options[1].value
                ? styles.selectedButton
                : styles.unselectedButton,
          }}
          onClick={() => handleClick(options[1].value)}
        >
          {options[1].name}
        </Button>
      </div>
    </div>
  )
}
