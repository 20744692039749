import { useAppSelector } from '@core/store/store'
import { NotFoundPage } from '@mbok/features/NotFound/NotFound'
import ServiceUnavailablePage from '@mbok/features/ServiceUnavailable'
import LoggedAppLayout from '@shared/components/LoggedAppLayout'
import { ILoggedAppLayoutProps } from '@shared/components/LoggedAppLayout/LoggedAppLayout'
import { applicationInServiceModeSelector } from '../store/administration/administrationSelectors'
import { valuesIn } from 'ramda'
import React, { useMemo, useState } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { ProtectedRoute } from '../auth/GuardedRoute'
import { AppPaths, IPath } from './routesPaths'
import { User } from 'oidc-client'

export const LayoutWrapperContext = React.createContext<
  React.Dispatch<React.SetStateAction<ILoggedAppLayoutProps | undefined>>
>(() => {})

const ApplicationContent: React.FC<{
  user: User | undefined
  layoutProps: ILoggedAppLayoutProps | undefined
}> = props => {
  const [paths] = useState(valuesIn<IPath>(AppPaths))
  const location = useLocation()
  const activeRoute = paths.find(path => path.path === location.pathname)

  const pathsSwitch = useMemo(
    () => (
      <Switch>
        {paths.map(({ protectedRoute, ...props }) => {
          return protectedRoute ? (
            <ProtectedRoute {...props} key={props.path} />
          ) : (
            <Route {...props} key={props.path} />
          )
        }, AppPaths)}
        <Route exact path="/">
          <Redirect to={AppPaths.home.path} />
        </Route>
        <Route path="/authentication"></Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    ),
    [paths]
  )

  return activeRoute?.protectedRoute && props.user ? (
    <LoggedAppLayout {...props.layoutProps}>{pathsSwitch}</LoggedAppLayout>
  ) : (
    pathsSwitch
  )
}

export const Routes = () => {
  const { user } = useAppSelector(state => state.oidc)

  const isApplicationInServiceMode = useAppSelector(
    applicationInServiceModeSelector
  )

  const [layoutProps, setLayoutProps] = useState<ILoggedAppLayoutProps>()

  return (
    <LayoutWrapperContext.Provider value={setLayoutProps}>
      {!isApplicationInServiceMode ? (
        <ApplicationContent layoutProps={layoutProps} user={user} />
      ) : (
        <ServiceUnavailablePage />
      )}
    </LayoutWrapperContext.Provider>
  )
}
