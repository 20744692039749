import { useThunkApi } from '@core/apiClient/apiClient'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { handleLoadingAction } from '../entity/helpers/thunks/entityThunksFactory'
import { StoreReducers } from '../StoreReducers'
import { TrustedMfaDevicesState } from './trustedMfaDevices'
import { Device } from '@shared/contracts/trustedMfaDevice'

export type RemoveTrustedMfaDevicePayload = {
  id: number
}

export const getTrustedMfaDevices = createAsyncThunk(
  `${[StoreReducers.userAccount]}/getTrustedMfaDevices`,
  async (_, thunkApi) => {
    const [execute] = useThunkApi<Device[]>(
      {
        config: {
          method: 'get',
          url: `Accounts/trustedMfaDevices`,
        },
        omitConsents: true,
      },
      thunkApi
    )
    const response = await execute()
    return response.data
  }
)

export const removeTrustedMfaDevice = createAsyncThunk<
  RemoveTrustedMfaDevicePayload,
  RemoveTrustedMfaDevicePayload
>(
  `${[StoreReducers.userAccount]}/removeTrustedMfaDevice`,
  async (payload, thunkApi) => {
    const [execute] = useThunkApi<unknown>(
      {
        config: {
          method: 'delete',
          url: `Accounts/trustedMfaDevices/${payload.id}`,
        },
        omitConsents: true,
      },
      thunkApi
    )
    await execute()
    return {
      id: payload.id,
    }
  }
)

export const getTrustedMfaDevicesExtraReducers = (
  builder: ActionReducerMapBuilder<TrustedMfaDevicesState>
) => {
  handleLoadingAction(getTrustedMfaDevices, builder)
  builder.addCase(getTrustedMfaDevices.fulfilled, (state, { payload }) => {
    state.devices = payload
    state.loadingPending > 0 && state.loadingPending--
  })
  handleLoadingAction(removeTrustedMfaDevice, builder)
  builder.addCase(removeTrustedMfaDevice.fulfilled, (state, { payload }) => {
    state.devices = state.devices.filter(device => device.id !== payload.id)
    state.loadingPending > 0 && state.loadingPending--
  })
}
