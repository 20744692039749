import { getAllThunk } from '../helpers/thunks/getAllThunk'
import { EntityReducers } from '../EntityReducers'
import { useThunkApi } from '@core/apiClient/apiClient'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Consent } from '@shared/contracts/models'

export type UpdateConsentPayload = {
  id: string
  isAccepted: boolean
}

export const userConsentsGetAll = getAllThunk<Consent>(
  EntityReducers.userConsents,
  ({ thunkApi }) =>
    useThunkApi(
      {
        config: {
          method: 'get',
          url: `accounts/consents`,
        },
        omitConsents: true,
      },
      thunkApi
    )
)

export const updateConsent = createAsyncThunk<
  UpdateConsentPayload,
  UpdateConsentPayload
>(
  `${[EntityReducers.userConsents]}/updateConsent`,
  async (payload, thunkApi) => {
    const [execute] = useThunkApi<unknown>(
      {
        config: {
          method: 'put',
          url: `accounts/consents/${payload.id}`,
          data: {
            isAccepted: payload.isAccepted,
          },
        },
      },
      thunkApi
    )
    await execute()
    return {
      id: payload.id,
      isAccepted: payload.isAccepted,
    }
  }
)
