const settingsInvoiceEmailAddress = 'settings-invoice-email-address'

export default {
  USER_CONTACT_EMAIL: 'settings-contact-email',
  USER_CONTACT_PHONE: 'settings-contact-phone',
  USER_MAIN_ADDRESS: 'settings-main-address',
  USER_CORRESPONDENCE_ADDRESS: 'settings-correspondence-address',
  SETTINGS_INVOICE_ADDRESS: 'settings-invoice-address',
  SETTINGS_INVOICE_EMAIL: settingsInvoiceEmailAddress,
  getAgreementAddressId: (agreementId: string) =>
    `settings-invoice-address-${agreementId}`,
  getAgreementEmailAddressId: (agreementId: string, index: number) =>
    `${settingsInvoiceEmailAddress}-${agreementId}-${index}`,
}
