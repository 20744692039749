import { CssBaseline, ThemeProvider } from '@material-ui/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import App from './App'
import { OidcProvider } from './core/auth/OidcProvider'
import store from './core/store/store'
import * as serviceWorker from './serviceWorker'
import Modal from '@shared/components/modals/Modal'
import theme from './styles/theme'
import { PersistGate } from 'redux-persist/integration/react'
import { SnackbarProvider } from 'notistack'
import i18n from '@i18n/index'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

i18n()

const MainComponent: React.FC = () => (
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <OidcProvider store={store} />
          <SnackbarProvider maxSnack={3}>
            <Modal />
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ThemeProvider>
)

if (module.hot) {
  module.hot.accept('./App', () => {
    ReactDOM.render(<MainComponent />, document.getElementById('root'))
  })
}

const persistor = persistStore(store)

ReactDOM.render(<MainComponent />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
