import { createSlice } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'
import { StoreReducers } from '../StoreReducers'
import storage from 'redux-persist/lib/storage'

export type ModalType =
  | 'error'
  | 'success'
  | 'info'
  | 'overpay'
  | 'environment'
  | 'report'
  | 'paymentWait'
  | 'paymentDone'

export interface UiState {
  modal: {
    isOpen: boolean
    type?: ModalType
    title?: string
    subtitle?: string | JSX.Element
    amount?: string | JSX.Element
    subtitle2?: string | JSX.Element
    text?: string | JSX.Element
    text2?: string
    withSubmitButton: boolean
    withCancelButton: boolean
    smallSubtitle: boolean
    subtitleWithMargin: boolean
    onSubmit?: () => void
    onCancel?: () => void
    submitButtonText?: string
    cancelButtonText?: string
  }
  alreadySavedFormFields: {
    id: string
    isAgreementEmail?: boolean
  }[]
  alreadySavedInvoiceTypeRequests: string[]
  cookiesSettingsDialog: {
    isOpen: boolean
  }
  isChangePhoneAndModifyMfaPath: boolean
}

const initialState: UiState = {
  modal: {
    isOpen: false,
    type: undefined,
    withCancelButton: true,
    withSubmitButton: false,
    onSubmit: undefined,
    onCancel: undefined,
    title: undefined,
    subtitle: undefined,
    text: undefined,
    text2: undefined,
    smallSubtitle: false,
    subtitleWithMargin: false,
    submitButtonText: undefined,
    cancelButtonText: undefined,
  },
  alreadySavedFormFields: [],
  alreadySavedInvoiceTypeRequests: [],
  cookiesSettingsDialog: {
    isOpen: false,
  },
  isChangePhoneAndModifyMfaPath: false,
}

export const uiSlice = createSlice({
  name: StoreReducers.ui,
  initialState,
  reducers: {
    hideModal: state => {
      state.modal.isOpen = false
    },
    showModal: (
      state,
      action: {
        payload: {
          type?: ModalType
          withCancelButton?: boolean
          withSubmitButton?: boolean
          title?: string
          subtitle?: string | JSX.Element
          text?: string | JSX.Element
          text2?: string
          smallSubtitle?: boolean
          subtitleWithMargin?: boolean
          onSubmit?: () => void
          onCancel?: () => void
          submitButtonText?: string
          cancelButtonText?: string
        }
      }
    ) => {
      state.modal = {
        ...initialState.modal,
        isOpen: true,
        ...action.payload,
      }
    },
    addSavedFormField: (
      state,
      { payload }: { payload: { id: string; isAgreementEmail?: boolean } }
    ) => {
      state.alreadySavedFormFields = [...state.alreadySavedFormFields, payload]
    },
    addSaveInvoiceTypeRequests: (state, { payload }: { payload: string }) => {
      state.alreadySavedInvoiceTypeRequests = [
        ...state.alreadySavedInvoiceTypeRequests,
        payload,
      ]
    },
    removeSavedFormField: (
      state,
      { payload }: { payload: { id?: string } }
    ) => {
      state.alreadySavedFormFields = state.alreadySavedFormFields.filter(
        field => field.id !== payload.id
      )
    },
    switchCookiesDialog: (state, action: { payload: boolean }) => {
      state.cookiesSettingsDialog = {
        isOpen: action.payload,
      }
    },
    setIsChangePhoneAndModifyMfaPath: (state, action: { payload: boolean }) => {
      state.isChangePhoneAndModifyMfaPath = action.payload
    },
  },
})

export const uiActions = uiSlice.actions

const persistConfig: PersistConfig<UiState> = {
  key: StoreReducers.ui,
  storage: storage,
  whitelist: ['alreadySavedInvoiceTypeRequests'],
}

export const persistUiReducer = persistReducer(persistConfig, uiSlice.reducer)
