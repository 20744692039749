import { ReactComponent as FiltrSvg } from '@images/icons/myInvoices/Filtr.svg'
import { Paper } from '@material-ui/core'
import { StyledButton } from '@shared/components/Button'
import React, { useCallback, useState } from 'react'
import { FilterType, useInvoicesDataSource } from './invoicesDataSource'
import { AccountingDocumentStatusFilter } from '@shared/contracts/models'
import { Grid } from '@material-ui/core'
import logoAutopay from '@images/logo_autopay.svg'
import {
  InvoiceTypeFilter,
  MeasurementPointFilter,
} from './components/FilterComponents'
import DocsList from './components/InvoicesList'
import useStyles from './MyInvoices.style'

export const MyInvoices: React.FC = () => {
  const styles = useStyles()
  const { Paid, Unpaid } = AccountingDocumentStatusFilter

  const {
    invoices,
    loadInvoices,
    filter,
    sort,
    paginationState,
    payments,
    measurementPoints,
  } = useInvoicesDataSource()

  const [filtersState, setFiltersState] = useState<FilterType>({
    typeOfDoc: filter.filterBy.typeOfDoc,
    measurementPoint: filter.filterBy.measurementPoint,
  })

  const filterInvoices = useCallback(() => {
    filter.setFilterBy({
      typeOfDoc: filtersState.typeOfDoc,
      measurementPoint: filtersState.measurementPoint,
    })
    loadInvoices.loadPaidInvoices(false, {
      start: 0,
      type: filtersState.typeOfDoc,
      measurementPointId: filtersState.measurementPoint,
    })
    loadInvoices.loadUnpaidInvoices(false, {
      start: 0,
      type: filtersState.typeOfDoc,
      measurementPointId: filtersState.measurementPoint,
    })
  }, [filtersState, loadInvoices, filter])

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Paper variant="outlined" classes={{ root: styles.paper }}>
            <div className={styles.filters}>
              <div className={styles.filtersHeader}>
                <FiltrSvg style={{ width: 24, height: 24 }} />
                <h2>Filtry</h2>
              </div>
              <div className={styles.filtersOptions}>
                <MeasurementPointFilter
                  filtersState={filtersState}
                  setFiltersState={setFiltersState}
                  measurementPoints={measurementPoints}
                />
                <InvoiceTypeFilter
                  filtersState={filtersState}
                  setFiltersState={setFiltersState}
                />
                <StyledButton
                  variant="outlined"
                  status="default"
                  onClick={() => {
                    if (
                      filtersState.measurementPoint !==
                        filter.filterBy.measurementPoint ||
                      filtersState.typeOfDoc !== filter.filterBy.typeOfDoc
                    )
                      filterInvoices()
                  }}
                >
                  Filtruj
                </StyledButton>
              </div>
            </div>
            <>
              <DocsList
                invoices={invoices.unpaid}
                loading={invoices.unpaidLoading}
                paymentLoading={payments.loading}
                statusType={Unpaid}
                pageSize={paginationState.pageSize}
                pagination={paginationState.paginationUnpaid}
                sort={sort.sortTypeUnpaid}
                setSort={sort.setSortTypeUnpaid}
                loadInvoices={loadInvoices.loadUnpaidInvoices}
                filter={filter.filterBy}
                defaultFilter={filter.defaultFilterValues}
              />
              <DocsList
                invoices={invoices.paid}
                loading={invoices.paidLoading}
                statusType={Paid}
                pageSize={paginationState.pageSize}
                pagination={paginationState.paginationPaid}
                sort={sort.sortTypePaid}
                setSort={sort.setSortTypePaid}
                loadInvoices={loadInvoices.loadPaidInvoices}
                filter={filter.filterBy}
              />
            </>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined" classes={{ root: styles.paper }}>
            <p>
              Partnerem w zakresie płatności elektronicznych Polenergia
              Dystrybucja sp. z o.o. jest Autopay S.A.
            </p>
            <div className={styles.logoContainer}>
              <img
                src={logoAutopay}
                alt="Autopay - płatności online"
                className={styles.logo}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
