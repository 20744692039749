import { useAppSelector } from '@core/store/store'
import { getUserManager } from '@axa-fr/react-oidc-core'
import { useCallback } from 'react'

export const useLogout = () => {
  const oidcState = useAppSelector(state => state.oidc)

  return useCallback(() => {
    const userManager = getUserManager()
    const token = oidcState.user?.id_token
    userManager.createSignoutRequest({ id_token_hint: token }).then(req => {
      window.location.href = req.url
    })
  }, [oidcState.user])
}
