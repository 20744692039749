import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core'
import { secondaryColor } from '@mbok/styles/constants'

const height = 20
const widht = height
const size = height
const borderColor = '#E2E8ED'

export default makeStyles((theme: Theme) => ({
  icon: {
    width: widht,
    height: height,
    borderRadius: size,
    border: `2px solid ${borderColor}`,
    backgroundColor: 'white',
  },
  checkedIcon: {
    width: widht,
    height: height,
    borderRadius: size,
    boxShadow: 'inset 0 0 0 3px white',
    border: `1px solid ${borderColor}`,
    backgroundColor: secondaryColor,
  },
  wrapper: {
    alignItems: 'flex-start',
  },
  label: {
    marginTop: 8,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 14,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}))
