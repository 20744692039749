import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { ImageType } from './types'

export const offersStateSelector = (state: RootState) => state.offers

export const paymentsLoadingSelector = createSelector(
  offersStateSelector,
  state => state.loadingPending > 0
)

export const getOffersSelector = createSelector(
  offersStateSelector,
  state => state.offers
)

export const getDesktopOffersSelector = createSelector(
  getOffersSelector,
  offers => {
    const filteredOffers = offers
      ?.filter(item => item.images.some(item => item.size === 'Desktop'))
      .map(item => ({
        ...item,
        images: item.images.filter(item => item.size === 'Desktop'),
      }))
    return filteredOffers?.sort((a, b) => a.priorityScore - b.priorityScore)
  }
)

export const getDesktopOffersByTypeSelector = (type: ImageType) =>
  createSelector(
    getDesktopOffersSelector,
    offers =>
      offers &&
      offers
        .filter(item => item.images.some(item => item.type === type))
        .map(item => ({
          ...item,
          images: item.images.filter(item => item.type === type),
        }))
  )
