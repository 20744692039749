import { useThunkApi } from '@core/apiClient/apiClient'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { Document, DocumentType } from '@shared/contracts/models'
import { handleLoadingAction } from '../entity/helpers/thunks/entityThunksFactory'
import { StoreReducers } from '../StoreReducers'
import { PoliciesState } from './policies'

export const getPolicyDocument = createAsyncThunk<Document, DocumentType>(
  `${[StoreReducers.policies]}/getPolicyDocument`,
  async (type, thunkApi) => {
    const [execute] = useThunkApi<Document>(
      {
        config: {
          method: 'get',
          url: `Documents/${type}`,
        },
      },
      thunkApi
    )
    const response = await execute()
    return response.data
  }
)

export const getPoliciesExtraReducers = (
  builder: ActionReducerMapBuilder<PoliciesState>
) => {
  handleLoadingAction(getPolicyDocument, builder)
  builder.addCase(getPolicyDocument.fulfilled, (state, { payload }) => {
    state.documents = [
      ...state.documents.filter(doc => doc.type !== payload.type),
      payload,
    ]
    state.loadingPending > 0 && state.loadingPending--
  })
}
