import React from 'react'
import { Typography, TypographyProps } from '@material-ui/core'
import useStyles from './Text.style'

export interface IText extends Omit<TypographyProps, 'title'> {
  title?: boolean
  small?: boolean
}

const Text: React.FC<IText> = ({
  title = false,
  small = false,
  children,
  className = '',
  ...props
}) => {
  const styles = useStyles()
  const getTitleClass = () => {
    if (title && small) return styles.titleSmall
    if (title) return styles.title
    if (small) return styles.subtitleSmall
    return styles.subtitle
  }
  return (
    <Typography className={`${getTitleClass()} ${className}`} {...props}>
      {children}
    </Typography>
  )
}

export default Text
