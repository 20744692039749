import { customerNumbersGetAll } from '@core/store/entity/customerNumbers/customerNumbersThunks'
import { hasAcceptedOrSetAllConsentsSelector } from '@core/store/entity/userConsents/userConsentsSelectors'
import { userConsentsGetAll } from '@core/store/entity/userConsents/userConsentsThunks'
import { actionsInterceptorMiddleware } from '@core/store/middleware/actionsInterceptorMiddleware'
import { Consent } from '@shared/contracts/models'
import axiosStatic, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { showModal } from '@core/store/ui/actions'
import { indexBy, prop } from 'ramda'
import { useCallback, useState } from 'react'
import { IThunkApi } from '../store/entity/helpers/thunks/entityThunksFactory'
import { RootState } from '../store/rootReducer'
import { SORRY, UNKNOW_ERROR_OCCURED } from './strings'
import store from '../store/store'
import { administrationActions } from '@core/store/administration/administration'

export interface IApiClientConfig {
  config: AxiosRequestConfig
  notSecured?: boolean
  omitCustomerNumber?: boolean
  omitConsents?: boolean
}

export class AppError extends Error {
  constructor(
    public status: number,
    public response: {
      [fieldName: string]: string
    }
  ) {
    super('')
  }
}

export interface CustomErrorResponse {
  message: string
  traceId: string
}

export type ApiClientReturnType<ResponseType> = [
  (
    additionalConfig?: AxiosRequestConfig
  ) => Promise<AxiosResponse<ResponseType>>,
  boolean?,
  ResponseType?
]

const validateStatus = () => {
  return true
}

const getExecute = <ResponseType>(
  { config, notSecured }: IApiClientConfig,
  token?: string,
  customerNumber?: string,
  additionalAxiosConfig?: AxiosRequestConfig,
  loadingSetter?: (state: boolean) => void,
  dataSetter?: (data: ResponseType) => void
) => {
  if (loadingSetter) {
    loadingSetter(true)
  }

  return axiosStatic
    .request<ResponseType>({
      ...config,
      ...additionalAxiosConfig,
      validateStatus,
      baseURL: process.env.REACT_APP_API_URL,
      data: {
        ...config.data,
        ...additionalAxiosConfig?.data,
      },
      params: {
        customerNumber,
        ...config?.params,
        ...additionalAxiosConfig?.params,
      },
      ...(notSecured
        ? {}
        : {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
    })
    .then(resp => {
      const status = resp.status
      const correctCall = status >= 200 && status < 300 // default

      if (correctCall) {
        if (dataSetter) {
          dataSetter(resp.data)
        }
        return resp
      }

      const isServerError = status === 500
      const isServerUnavailable = status === 503
      const traceId = ((resp as AxiosResponse<unknown>) as AxiosResponse<
        CustomErrorResponse
      >).data.traceId

      if (isServerUnavailable) {
        store.dispatch(administrationActions.setApplicationServiceModeOn())
      } else if (isServerError) {
        store.dispatch(
          showModal({
            title: SORRY,
            subtitle: UNKNOW_ERROR_OCCURED,
            type: 'error',
            withCancelButton: true,
            withSubmitButton: true,
            onSubmit: () => {
              store.dispatch(
                showModal({
                  type: 'report',
                  text: `Błąd ${traceId}`,
                })
              )
            },
          })
        )
      }

      throw new AppError(status, resp.data as {})
    })
    .finally(() => {
      if (loadingSetter) loadingSetter(false)
    })
}

const getCustomerNumber = async (
  ids: string[],
  selectedCustomerNumber?: string
): Promise<string> => {
  let allCustomerNumbers = ids
  if (!allCustomerNumbers || allCustomerNumbers.length === 0) {
    ;[allCustomerNumbers] = (
      await actionsInterceptorMiddleware.addActionsPromise([
        customerNumbersGetAll.fulfilled.type,
      ])
    ).action.payload
  }

  return selectedCustomerNumber &&
    allCustomerNumbers.includes(selectedCustomerNumber)
    ? selectedCustomerNumber
    : allCustomerNumbers[0]
}

const blockUntilConsentsAgreed = async (
  hasAcceptedConsents: boolean
): Promise<void> => {
  if (!hasAcceptedConsents) {
    const [consents] = (
      await actionsInterceptorMiddleware.addActionsPromise([
        userConsentsGetAll.fulfilled.type,
      ])
    ).action.payload as [Consent[]]

    const notAcceptedConsent = hasAcceptedOrSetAllConsentsSelector({
      userConsents: {
        entities: indexBy(prop('id'), consents),
      },
    } as any)

    return blockUntilConsentsAgreed(!!notAcceptedConsent)
  }

  return
}

export const useApi = <ResponseType, ApiResponseType = ResponseType>(
  config: IApiClientConfig,
  dataMapper?: (apiResponse: ApiResponseType) => ResponseType
): ApiClientReturnType<ResponseType> => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ResponseType>()
  const [baseConfig] = useState<IApiClientConfig>(config)

  const execute = useCallback(
    async (additionalConfig?: AxiosRequestConfig) => {
      const state = store.getState()
      let number = state.customerNumbers.selectedCustomerNumber
      const ids = state.customerNumbers.ids

      if (!baseConfig.omitCustomerNumber) {
        number = await getCustomerNumber(ids as string[], number)
      }

      if (!baseConfig.omitConsents) {
        await blockUntilConsentsAgreed(
          hasAcceptedOrSetAllConsentsSelector(state)
        )
      }

      const user = state.oidc.user

      return getExecute<ResponseType>(
        baseConfig,
        user?.access_token,
        number,
        additionalConfig,
        setLoading,
        (data: ResponseType | ApiResponseType) => {
          dataMapper
            ? setData(dataMapper(data as ApiResponseType))
            : setData(data as ResponseType)
        }
      )
    },
    [baseConfig, dataMapper]
  )

  return [execute, loading, data]
}

export const useThunkApi = <ResponseType>(
  config: IApiClientConfig,
  { getState }: IThunkApi
): ApiClientReturnType<ResponseType> => {
  const state: RootState = getState() as RootState
  const { user } = state.oidc
  const { selectedCustomerNumber, ids } = state.customerNumbers
  const consentsState = hasAcceptedOrSetAllConsentsSelector(state)

  const execute = async (additionalConfig?: AxiosRequestConfig) => {
    let number = selectedCustomerNumber
    if (!config.omitCustomerNumber) {
      number = await getCustomerNumber(ids as string[], selectedCustomerNumber)
    }

    if (!config.omitConsents) {
      await blockUntilConsentsAgreed(consentsState)
    }

    return getExecute<ResponseType>(
      config,
      user?.access_token,
      number,
      additionalConfig
    )
  }

  return [execute]
}
