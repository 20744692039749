import { useApi } from '@core/apiClient/apiClient'
import { Consents } from '@shared/components/Consents'
import { Consent } from '@shared/contracts/models'
import { Field, FieldInputProps, FieldMetaProps, FieldProps } from 'formik'
import { useSnackbar } from 'notistack'
import { isEmpty } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import {
  CustomInput,
  CustomPasswordInput,
  PeselOrNipInput,
} from '../../../shared/components/Input'
import {
  ErrorMsg,
  fieldNames,
  InputType,
  TaxInputType,
  taxNumberData,
} from '../static-data/RegisterData'

const { ACCOUNT_TYPE, TAX_NUMBER } = fieldNames

export const validateFunction = (
  value: string,
  input: InputType | TaxInputType,
  accType: string
): string => {
  if (input.validation && input.validation(value, accType)) {
    return input.errorMsg(accType)
  }
  return ''
}

interface DisplayInputProps {
  input: InputType
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>
}

export const DisplayInput: React.FC<DisplayInputProps> = ({
  input,
  getFieldProps,
}) => {
  const { name } = input
  return (
    <Field
      name={name}
      validate={(value: string) => {
        const accType: string = getFieldProps(ACCOUNT_TYPE).value
        return validateFunction(value, input, accType)
      }}
    >
      {({
        field,
        meta: { error, touched },
        form: { submitCount },
      }: FieldProps) => {
        const showError = Boolean(error) && (touched || submitCount > 0)
        if (input.password) {
          return (
            <CustomPasswordInput
              field={field}
              showError={showError}
              inputData={input}
            />
          )
        }
        return (
          <CustomInput
            field={field}
            showError={showError}
            inputData={input}
            errMessage={error}
            optional={input.optional}
          />
        )
      }}
    </Field>
  )
}

interface DisplayPeselOrNipInputProps {
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>
  getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
}

export const DisplayPeselOrNipInput: React.FC<DisplayPeselOrNipInputProps> = ({
  getFieldProps,
  getFieldMeta,
  setFieldValue,
}) => {
  return (
    <Field
      name={taxNumberData.name}
      validate={(value: string) => {
        const accType: string = getFieldProps(ACCOUNT_TYPE).value
        return validateFunction(value, taxNumberData, accType)
      }}
    >
      {({ form: { submitCount } }: FieldProps) => {
        const fieldData = {
          field: getFieldProps(TAX_NUMBER),
          input: taxNumberData,
        }
        const showError = Boolean(
          getFieldMeta(TAX_NUMBER).error &&
            (getFieldMeta(TAX_NUMBER).touched || submitCount > 0)
        )
        const accType: string = getFieldProps(ACCOUNT_TYPE).value
        return (
          <PeselOrNipInput
            setFieldValue={setFieldValue}
            fieldData={fieldData}
            showError={showError}
            accType={accType}
            errMessage={getFieldMeta(TAX_NUMBER).error}
          />
        )
      }}
    </Field>
  )
}

export const DisplayRegisterConsents = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [consents, setConsents] = useState<Consent[]>([])

  const [executeGetConsents] = useApi<Consent[]>({
    omitCustomerNumber: true,
    omitConsents: true,
    config: {
      method: 'get',
      url: 'Accounts/consents',
    },
  })

  const getConsents = useCallback(async () => {
    try {
      const response = await executeGetConsents()
      setConsents(response.data)
      return response.data
    } catch (err) {
      if (!err.status || err.status !== 200) {
        enqueueSnackbar(ErrorMsg.noConnectionOrServerError, {
          variant: 'error',
        })
      }
      return err
    }
  }, [enqueueSnackbar, executeGetConsents])

  useEffect(() => {
    if (isEmpty(consents)) {
      getConsents()
    }
  }, [consents, getConsents])

  return <Consents consents={consents} />
}
