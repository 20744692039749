import React, { useCallback, useEffect, useState } from 'react'
import { useDispatchOnCustomerNumberChange } from '@core/store/customerNumberDispatcher'
import {
  getUsageByAllMeasurementPoints,
  getUsageByMeasurementPoints,
  readingsLoadingSelector,
  allReadingsSelector,
} from '@core/store/entity/readings/readingsSelectors'
import { readingsGetAll } from '@core/store/entity/readings/readingsThunks'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import { Collapse, Divider, Typography } from '@material-ui/core'
import DetailsCardWrapper from '@shared/components/DetailsCard'
import { isEmpty, keys, reduce, toPairs, values } from 'ramda'
import Chart from './components/Chart'
import { ReactComponent as ReadingsSvg } from '@images/icons/myReadings/Readings.svg'

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { ToggleButton } from '@shared/components/ToggleButton'
import Toggler from '@shared/components/Toggler'
import { measurementPointsGetAll } from '@core/store/entity/measurementPoints/measurementPointsThunks'
import {
  measurementPointsDictionarySelector,
  measurementPointsLoadingSelector,
} from '@core/store/entity/measurementPoints/measurementPointsSelectors'
import useStyles from './MyReadings.style'
import { AddressParser } from '@shared/components/AddressParser'
import StyledButton from '@shared/components/Button'

import RequestProsumentMeasurementPointReportModal from './components/modals/RequestProsumentMeasurementPointReportModal'

interface IReading {
  month: string
  past: number
  current: number
}

enum ReadingsType {
  all = 2,
  single = 1,
}

export const MyReadings: React.FC = () => {
  const styles = useStyles()
  const dispatch = useAppDispatch()

  const [readingsType, setReadingsType] = React.useState(ReadingsType.single)
  const [index, setIndex] = useState(0)
  const [showByPoint, setShowByPoint] = useState(true)

  const handleTypeChange = (_: React.MouseEvent, newType: ReadingsType) => {
    newType && setReadingsType(newType)
  }

  const loadData = useCallback(() => {
    dispatch(readingsGetAll())
    dispatch(measurementPointsGetAll())
  }, [dispatch])

  const readingsByMonth = useAppSelector(getUsageByAllMeasurementPoints)
  const readingsByPoint = useAppSelector(getUsageByMeasurementPoints)
  const measurementPoints = useAppSelector(measurementPointsDictionarySelector)
  const account = useAppSelector(state => ({
    data: state.userAccount.account
  }))

  const readingsLoading = useAppSelector(readingsLoadingSelector)
  const measurementPointsLoading = useAppSelector(
    measurementPointsLoadingSelector
  )

  const allReadings = useAppSelector(allReadingsSelector)
  const emptyReadings = isEmpty(allReadings)

  const [showRequestProsumerMeasurementsDataModal, setShowRequestProsumerMeasurementsDataModal] = useState(false)

  useDispatchOnCustomerNumberChange(loadData)

  const parseValuesForGrid = useCallback(
    (readings: typeof readingsByPoint) => {
      return reduce(
        (acc, [month, values]) => {
          return [
            ...acc,
            {
              month,
              ...values,
            } as IReading,
          ]
        },
        [] as IReading[],
        toPairs(readings)
      )
    },
    [readingsByPoint]
  )

  useEffect(() => {
    setShowByPoint(readingsType === ReadingsType.single)
  }, [readingsType])

  const getReadingsByPoint = useCallback(() => {
    const selectedPoint = values(readingsByPoint)[index]
    return parseValuesForGrid(selectedPoint)
  }, [readingsByPoint, index, parseValuesForGrid])

  const getReadingsByMonth = useCallback(() => {
    return parseValuesForGrid(readingsByMonth)
  }, [readingsByMonth, parseValuesForGrid])

  const getReadings = useCallback(() => {
    return showByPoint ? getReadingsByPoint() : getReadingsByMonth()
  }, [getReadingsByMonth, getReadingsByPoint, showByPoint])

  const getSelectedPoint = useCallback(() => {
    return measurementPoints[keys(readingsByPoint)[index]]
  }, [readingsByPoint, index, measurementPoints])

  const getTotalCountMeasurementPoints = useCallback(() => {
    return keys(readingsByPoint).length
  }, [readingsByPoint])

  return (
    <>
      <DetailsCardWrapper
        title="Historia zużycia"
        Icon={ReadingsSvg}
        iconSize={32}
        loading={readingsLoading || measurementPointsLoading}
        customButtons={
          <div className={styles.headerContent}>
            <div className={styles.buttonsWrapper}>
              <div className={styles.buttons}>
                <span style={{ marginRight: '15px' }}>Raport:</span>
                <ToggleButtonGroup
                  value={readingsType}
                  exclusive
                  onChange={handleTypeChange}
                >
                  <ToggleButton
                    value={ReadingsType.all}
                    aria-label="left aligned"
                  >
                    Dla płatnika
                  </ToggleButton>
                  <ToggleButton
                    value={ReadingsType.single}
                    aria-label="left aligned"
                  >
                    Dla punktu
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              {!(readingsLoading || measurementPointsLoading) &&
                !emptyReadings &&
                showByPoint && (
                  <div className={styles.name}>
                    <p>Punkt poboru:</p>
                    <span>
                      {getSelectedPoint()?.name ?? getSelectedPoint()?.number}
                    </span>
                  </div>
                )}
            </div>
            {!(readingsLoading || measurementPointsLoading) &&
              !emptyReadings &&
              showByPoint &&
              getTotalCountMeasurementPoints() > 1 && (
                <div className={styles.toggler}>
                  <Toggler
                    total={getTotalCountMeasurementPoints()}
                    setIndex={setIndex}
                    index={index}
                  />
                </div>
              )}
          </div>
        }
      >
        {showByPoint && !emptyReadings ? <Divider /> : null}
        <Collapse in={showByPoint && !emptyReadings}>
          <div className={styles.pointInfo}>
            <div>
              <Typography className={styles.infoLabel} variant="caption">
                Adres punktu
              </Typography>
              <span className={styles.infoValue}>
                <AddressParser
                  a1={getSelectedPoint()?.addressLine1}
                  a2={getSelectedPoint()?.addressLine2}
                />
              </span>
            </div>
            <div>
              <Typography className={styles.infoLabel} variant="caption">
                Numer punktu poboru
              </Typography>
              <span className={styles.infoValue}>
                {getSelectedPoint()?.number}
              </span>
            </div>
            {getSelectedPoint()?.isProsumer && (
              <div>
                <Typography className={styles.infoLabel} variant="caption">
                  Zamów dane pomiarowe
                </Typography>
                <span className={styles.infoValue}>
                  <StyledButton
                    variant="outlined"
                    onClick={() => setShowRequestProsumerMeasurementsDataModal(prev => !prev)}
                    size="small"
                    className={styles.prosumerBtn}
                    color="primary"
                  >
                    Zamów
                  </StyledButton>
                </span>
              </div>
            )}
          </div>
        </Collapse>
        {emptyReadings ? (
          <h2 className={styles.emptyReadings}>Brak historii zużycia</h2>
        ) : (
          <Chart readings={getReadings()}></Chart>
        )}
      </DetailsCardWrapper>
      {showRequestProsumerMeasurementsDataModal && (
        <RequestProsumentMeasurementPointReportModal
          setShow={setShowRequestProsumerMeasurementsDataModal}
          userData={{
            userName: account.data?.name,
            userNumber:  account.data?.customerNumber,
            userContactEmail:  account.data?.correspondenceEmail
          }}
          pointData={{
            pointName: getSelectedPoint()?.name,
            pointNumber: getSelectedPoint()?.number,
          }}
        />
      )}
    </>
  )
}
