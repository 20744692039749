import React, { useCallback, useMemo } from 'react'
import { NavExpandMenu } from '../NavExpandMenu'
import { ReactComponent as PhoneSvg } from '@images/icons/Phone.svg'
import useStyles from './DownloadMobileApp.style'
import { isMobile, isIOS, isAndroid } from 'react-device-detect'
import { useMobileAppSeen } from './hooks/useMobileAppSeen'
import { useTranslation } from 'react-i18next'
import mobileAppImg from '@images/app_icon.png'

export const DownloadMobileApp: React.FC<{ positionTop?: number }> = React.memo(
  ({ positionTop }) => {
    const styles = useStyles()
    const { t } = useTranslation()

    const [mobileAppNavSeen, setMobileAppNavSeen] = useMobileAppSeen()

    const appLink = useMemo(() => {
      if (isIOS) {
        return process.env.REACT_APP_IOS_APP_LINK
      }
      if (isAndroid) {
        return process.env.REACT_APP_ANDROID_APP_LINK
      }
      return ''
    }, [])

    const showNav = useCallback(() => {
      if (isMobile && !mobileAppNavSeen && appLink) {
        return true
      }
      return false
    }, [mobileAppNavSeen, appLink])

    return (
      <NavExpandMenu
        expanded={showNav()}
        positionTop={positionTop}
        closeBtn={() => setMobileAppNavSeen(true)}
      >
        <div className={styles.container}>
          <img
            className={styles.icon}
            src={mobileAppImg}
            alt="Aplikacja Mobilna"
          />
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <PhoneSvg />
              <p>{t('DOWNLOAD_MOBILE_APP.header')}</p>
            </div>
            <div className={styles.content}>
              <p className={styles.contentMsg}>
                {t('DOWNLOAD_MOBILE_APP.content')}
                <br />
                <a href={appLink} target="_blank" rel="noopener noreferrer">
                  {t('DOWNLOAD_MOBILE_APP.CLICK_AND_DOWNLOAD')}
                </a>
              </p>
            </div>
          </div>
        </div>
      </NavExpandMenu>
    )
  }
)
