import { useApi } from '@core/apiClient/apiClient'
import { useAppDispatch } from '@core/store/store'
import {
  addSaveInvoiceTypeRequests,
  hideModal,
  showModal,
} from '@core/store/ui/actions'
import { ReactComponent as PencilSvg } from '@images/icons/other/EditPencil.svg'
import { ReactComponent as EditSvg } from '@images/icons/other/EditPencil.svg'
import { Dictionary } from '@reduxjs/toolkit'
import Toggler from '@shared/components/Toggler'
import {
  Account,
  AgreementListItem,
  InvoiceType,
  MeasurementPointListItem,
  TranslatedAgreementType,
} from '@shared/contracts/models'
import { format, parseISO } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DocumentDetails from './DocumentDetails'
import Document from './Document'
import useStyles from './MyDocumentsTable.style'
import ChangeMeasurementPointNameModal from '../modals/ChangeMeasurementPointNameModal'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useTranslation } from 'react-i18next'
import { AddressParser } from '@shared/components/AddressParser'
import { isEmpty } from 'ramda'
import { EditFieldForm } from '@mbok/features/MySettings/components/forms'
import { validateEmailForm } from '@mbok/features/MySettings/components/forms/EditFieldForm/EditFieldForm.utils'
import Modal from '@shared/components/modals/Modal'
import Icon from '@shared/components/modals/Modal/Icon'
import Button from '@shared/components/modals/Modal/Button'

interface MyDocumentsTableProps {
  measurementPoints: {
    data: MeasurementPointListItem[]
    loading: boolean
    selectedIndex: number
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
    reloadData: (
      customerNumberChanged: boolean,
      selectedIndex?: number | undefined
    ) => void
  }
  account?: Account
  agreements?: Dictionary<AgreementListItem>
  details: boolean
  alreadySavedInvoiceTypeRequests: string[],
  activateEInvoiceHandler?: () => void
}

enum ModalContent {
  FirstPage,
  EmailField,
}

export const MyDocumentsTable: React.FC<MyDocumentsTableProps> = ({
  measurementPoints,
  account,
  agreements,
  details,
  alreadySavedInvoiceTypeRequests,
  activateEInvoiceHandler,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [
    selectedPoint,
    setSelectedPoint,
  ] = useState<MeasurementPointListItem | null>(null)
  const [showNameModal, setShowNameModal] = useState(false)

  const [showInvoiceTypeChangeModal, setShowInvoiceTypeChangeModal] = useState(
    false
  )

  const { addressLine1, addressLine2 } = selectedPoint ?? {}

  const { data, selectedIndex, setSelectedIndex, reloadData } =
    measurementPoints ?? {}

  useEffect(() => {
    selectedIndex && setSelectedIndex(selectedIndex)
  }, [selectedIndex, setSelectedIndex])

  useDeepCompareEffect(() => {
    data && setSelectedPoint(data[selectedIndex])
  }, [selectedIndex, setSelectedPoint, data])

  const [executeInvoiceTypeChange] = useApi({
    omitCustomerNumber: true,
    omitConsents: true,
    config: {
      method: 'put',
    },
  })

  const getAgreement = useMemo(() => {
    const agreement =
      selectedPoint && agreements && agreements[selectedPoint?.agreementId]
    return agreement
  }, [selectedPoint, agreements])

  const agreementTariff = useMemo(() => {
    const agreement = getAgreement
    const tariff =
      agreement && agreement.parameters?.find(param => param.type === 'Tariff')
    return tariff?.value ?? t('MY_CONTRACTS.EMPTY_GROUP')
  }, [getAgreement, t])

  const settlementType = useMemo(() => {
    const agreement = getAgreement
    const tariff =
      agreement &&
      agreement.parameters?.find(param => param.type === 'SettlementType')
    return tariff?.value ?? ''
  }, [getAgreement])

  const agreementType = useMemo(() => {
    const agreement = getAgreement
    const type = agreement && agreement.agreementType
    const translatedType = type ? TranslatedAgreementType[type] : '-'
    return translatedType
  }, [getAgreement])

  const agreementEndDate = useMemo(() => {
    const agreement = getAgreement
    const dateTo = agreement && agreement.dateTo
    return dateTo ? format(parseISO(dateTo), 'dd.MM.yyyy') : 'Nieokreślony'
  }, [getAgreement])

  const agreementCorrespondenceAddress = useMemo(() => {
    const agreement = getAgreement
    const address = (
      <AddressParser
        a1={agreement?.correspondenceAddressLine1}
        a2={agreement?.correspondenceAddressLine2}
      />
    )
    return address
  }, [getAgreement])

  const agreementCorrespondenceEmail = useMemo(() => {
    const agreement = getAgreement
    const email =
      agreement?.emailAddresses && !isEmpty(agreement?.emailAddresses)
        ? agreement?.emailAddresses[0]
        : t('MY_CONTRACTS.EMPTY_EMAIL')
    return email
  }, [getAgreement, t])

  const invoiceType = useMemo(() => {
    const agreement = getAgreement
    return agreement?.invoiceType
  }, [getAgreement])

  const changeInvoiceType = useCallback(
    async (email: string) => {
      const agreement = getAgreement
      const id = agreement?.agreementId
      if (id && selectedPoint?.addressLine1 && selectedPoint?.addressLine2) {
        await executeInvoiceTypeChange({
          url: `Agreements/${id}/invoiceType`,
          data: {
            invoiceType: InvoiceType.Email,
            newEmailAddress: email,
          },
        })
        dispatch(addSaveInvoiceTypeRequests(getAgreement?.agreementId ?? ''))
      }
    },
    [getAgreement, executeInvoiceTypeChange, dispatch, selectedPoint]
  )

  const showSuccessModal = useCallback(
    (selectedIndex: number) => {
      dispatch(
        showModal({
          type: 'success',
          title: t('MY_CONTRACTS.MODAL.THANKS_FOR_CHANGE'),
          subtitle: t('MODAL_MESSAGES.REQUEST_ACCEPTED'),
          text: t('MY_CONTRACTS.MODAL.INVOICE_TYPE_WILL_CHANGE'),
          withCancelButton: true,
          onCancel: () => {
            dispatch(hideModal())
            selectedIndex !== -1 && reloadData(false, selectedIndex)
          },
        })
      )
    },
    [dispatch, t, reloadData]
  )

  const invoiceTypeContent = useMemo(() => {
    return (
      <div className={styles.invoiceTypeContent} onClick={() => {}}>
        {invoiceType === InvoiceType.Email ? (
          <span>{t('MY_CONTRACTS.EMAIL_INVOICE')}</span>
        ) : (
          <>
            <span>{t('MY_CONTRACTS.PAPER_INVOICE')}</span>
            <div onClick={activateEInvoiceHandler}>
              <EditSvg />
              <span>{t('MY_CONTRACTS.CHANGE')}</span>
            </div>
          </>
        )}
      </div>
    )
  }, [invoiceType, styles, t, activateEInvoiceHandler])

  const onFormSubmit = async (
    { field1: email }: { field1: string },
    selectedIndex: number
  ) => {
    setShowInvoiceTypeChangeModal(false)
    changeInvoiceType(email)
    showSuccessModal(selectedIndex)
  }

  const [showModalState, setShowModalState] = useState<ModalContent>(
    ModalContent.FirstPage
  )

  return (
    <>
      <Modal isOpen={showInvoiceTypeChangeModal}>
        <div className={styles.modal}>
          <Icon type={'environment'} />
          {showModalState === ModalContent.FirstPage ? (
            <>
              <h1>{t('MY_CONTRACTS.MODAL.CHANGE_INVOICE_TYPE')}</h1>
              <h3>{t('MY_CONTRACTS.MODAL.THANKS')}</h3>
              <div className={styles.modalButtonsWrapper}>
                <Button onClick={() => setShowInvoiceTypeChangeModal(false)}>
                  Zrezygnuj
                </Button>
                <Button
                  type="save"
                  onClick={() => setShowModalState(ModalContent.EmailField)}
                >
                  Potwierdź
                </Button>
              </div>
            </>
          ) : (
            <>
              <h2>
                {selectedPoint?.name ?? (
                    <AddressParser a1={addressLine1} a2={addressLine2} />
                  ) ??
                  selectedPoint?.number}
              </h2>
              <h3>
                Podaj adres e-mail, na który ma być wysyłana od teraz e-faktura
                dla tego punktu
              </h3>
              <EditFieldForm
                onCancel={() => {
                  setShowInvoiceTypeChangeModal(false)
                  setShowModalState(ModalContent.FirstPage)
                }}
                onSubmit={values => {
                  onFormSubmit(values, selectedIndex)
                  setShowModalState(ModalContent.FirstPage)
                }}
                validate={validateEmailForm}
                title={''}
                field1={{
                  ...{ label: 'E-mail do e-faktur', name: 'E-mail' },
                  value:
                    agreementCorrespondenceEmail !==
                    t('MY_CONTRACTS.EMPTY_EMAIL')
                      ? agreementCorrespondenceEmail
                      : '',
                }}
                submitBtnText="Potwierdź"
                cancelBtnText="Zrezygnuj"
                saveBtnVisible
              />
            </>
          )}
        </div>
      </Modal>
      {details ? (
        <>
          {data?.length > 1 && (
            <Toggler
              className={styles.toggler}
              total={data?.length ?? 0}
              index={selectedIndex}
              setIndex={setSelectedIndex}
            />
          )}
          {account && selectedPoint && (
            <DocumentDetails
              name={account.name ?? ''}
              selectedPointNumber={selectedPoint?.number ?? ''}
              address={<AddressParser a1={addressLine1} a2={addressLine2} />}
              invoiceType={invoiceType as 'Paper' | 'Email'}
              agreementType={agreementType}
              invoiceTypeContent={invoiceTypeContent}
              tariff={agreementTariff}
              endDate={agreementEndDate}
              settlementType={settlementType}
              correspondenceEmail={agreementCorrespondenceEmail}
              correspondenceAddress={agreementCorrespondenceAddress}
            />
          )}
        </>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.measurementPoint}>
              <p>{t('MY_CONTRACTS.MEASUREMENT_POINT')}</p>
              <span>{selectedPoint?.name ?? selectedPoint?.number}</span>
              <div onClick={() => setShowNameModal(prev => !prev)}>
                <PencilSvg />
                <p>{t('MY_CONTRACTS.CHANGE_NAME')}</p>
              </div>
            </div>
            {data?.length > 1 && (
              <div className={styles.togglerContainer}>
                <Toggler
                  className={styles.toggler}
                  total={data?.length ?? 0}
                  index={selectedIndex}
                  setIndex={setSelectedIndex}
                />
              </div>
            )}
          </div>
          {selectedPoint && (
            <Document
              address={<AddressParser a1={addressLine1} a2={addressLine2} />}
              selectedPoint={selectedPoint}
              invoiceTypeContent={invoiceTypeContent}
              tariff={agreementTariff}
            />
          )}
          {showNameModal &&
            selectedPoint?.measurementPointId &&
            selectedPoint?.number && (
              <ChangeMeasurementPointNameModal
                show={showNameModal}
                setShow={setShowNameModal}
                pointData={{
                  id: selectedPoint?.measurementPointId,
                  name: selectedPoint?.name,
                  number: selectedPoint?.number,
                  reloadData,
                  selectedIndex,
                }}
              />
            )}
        </>
      )}
    </>
  )
}
