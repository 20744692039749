/**
 * mBok API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DocumentType =
  | 'PrivacyPolicy'
  | 'Terms'
  | 'ElectronicContact'
  | 'PhoneContact'
  | 'CookiesPolicy'
  | 'Faq'
  | 'EInvoice'
  | 'EInvoiceTerms'
  | 'EInvoiceTermsAcceptance'

export const DocumentType = {
  PrivacyPolicy: 'PrivacyPolicy' as DocumentType,
  Terms: 'Terms' as DocumentType,
  ElectronicContact: 'ElectronicContact' as DocumentType,
  PhoneContact: 'PhoneContact' as DocumentType,
  CookiesPolicy: 'CookiesPolicy' as DocumentType,
  Faq: 'Faq' as DocumentType,
  EInvoice: 'EInvoice' as DocumentType,
  EInvoiceTerms: 'EInvoiceTerms' as DocumentType,
  EInvoiceTermsAcceptance: 'EInvoiceTermsAcceptance' as DocumentType
}
