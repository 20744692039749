import { useAppDispatch } from '@core/store/store'
import {
  createTransaction,
  createTransactionFromBalance,
  CreateTransactionFromBalancePayload,
} from '@core/store/payments/paymentsThunks'
import { SecuredOrderItem } from '@shared/contracts/models'
import { useCallback, useState } from 'react'

export const useCreateTransaction = () => {
  const [isCreatingTransaction, setIsCreatingTransaction] = useState(false)
  const dispatch = useAppDispatch()

  return useCallback(
    (
      transactions: SecuredOrderItem[],
      btnRenderer: (
        loading: boolean,
        saveFunction: () => void
      ) => React.ReactNode
    ) => {
      const saveFn = async () => {
        setIsCreatingTransaction(true)
        await dispatch(createTransaction(transactions))
        setIsCreatingTransaction(false)
      }

      return btnRenderer(isCreatingTransaction, saveFn)
    },
    [dispatch, isCreatingTransaction]
  )
}

export const useCreateTransactionFromBalance = () => {
  const [isCreatingTransaction, setIsCreatingTransaction] = useState(false)
  const dispatch = useAppDispatch()

  return useCallback(
    (
      balanceData: CreateTransactionFromBalancePayload,
      btnRenderer: (
        loading: boolean,
        saveFunction: () => void
      ) => React.ReactNode | void
    ) => {
      const saveFn = async () => {
        setIsCreatingTransaction(true)
        await dispatch(createTransactionFromBalance(balanceData))
        setIsCreatingTransaction(false)
      }

      return btnRenderer(isCreatingTransaction, saveFn)
    },
    [dispatch, isCreatingTransaction]
  )
}
