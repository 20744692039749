import React from 'react'
import { BackgroundWrapper } from '@shared/BackgroundWrapper'
import { useStyles } from './ServiceUnavailable.styles'
import { ReactComponent as UnavailableSvg } from '@images/502v2.svg'

export const ServiceUnavailablePage: React.FC = () => {
  const styles = useStyles()

  return (
    <BackgroundWrapper>
      <div className={styles.wrapper}>
        <div className={styles.iconContainer}>
          <div className={styles.container}>
            <UnavailableSvg className={styles.icon} />
          </div>
          <div className={styles.content}>
            <h2>Dzień dobry!</h2>
            <div className={styles.unavaiableContent}>
              Cały czas zmieniamy się dla Ciebie! <br />
              <strong>
                Obecnie przeprowadzamy prace serwisowe. Wrócimy wkrótce.
                <br />
              </strong>
              Za utrudnienia przepraszamy.
            </div>

            <div className="author">Zespół Polenergia Dystrybucja</div>
          </div>
        </div>
      </div>
    </BackgroundWrapper>
  )
}
