import { createMuiTheme } from '@material-ui/core/styles'

import {
  primaryColor,
  errorRedColor,
  textPrimaryColor,
  lightColor,
  borderColor,
  warningColor,
  backdropBg,
  textSecondaryColor,
  tableTextColor,
} from './constants'

export const theme = createMuiTheme({
  spacing: 4,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'h1, h2, h3, h4, h5': {
          margin: '10px 0',
        },
        'body, html': {
          height: '100%',
        },
        '.main-root': {
          height: '100%',
        },
        textarea: {
          fontFamily: `"Mulish", "Helvetica", "Arial", sans-serif`,
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: '42px',
      },
    },
    MuiPaper: {
      root: {
        padding: '15px 20px',
        backgroundColor: 'white',
        position: 'relative',
      },
      outlined: {
        border: `1.7px solid ${borderColor}`,
        padding: '20px',
      },
      rounded: {
        borderRadius: '8px',
      },
    },
    MuiPopover: {
      paper: {
        padding: 0,
        borderRadius: '7px',
        border: `1.7px solid ${borderColor}`,
        boxShadow: '0 7px 9px 3px rgba(105,105,105,0.03)',
        color: tableTextColor,
        '& ul': {
          padding: 0,
          '& li': {
            padding: '12px ',
          },
        },
      },
    },
    MuiDialog: {
      paper: {
        margin: '20px 0',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: backdropBg,
      },
    },
  },
  typography: {
    fontFamily: `"Mulish", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  palette: {
    primary: {
      main: primaryColor,
      light: lightColor,
    },
    secondary: {
      main: warningColor,
    },
    error: {
      main: errorRedColor,
    },
    text: {
      primary: textPrimaryColor,
      secondary: textSecondaryColor,
    },
  },
  shape: {
    borderRadius: 10,
  },
})

export default theme
