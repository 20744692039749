import React from 'react'
import { ReactComponent as FirstSvg } from '@images/icons/register/First.svg'
import { ReactComponent as SecondSvg } from '@images/icons/register/Second.svg'
import { ReactComponent as ThirdSvg } from '@images/icons/register/Third.svg'
import { ReactComponent as FourthSvg } from '@images/icons/register/Fourth.svg'
import { ReactComponent as FifthSvg } from '@images/icons/register/Fifth.svg'
import { ReactComponent as InvoiceSvg } from '@images/icons/register/Invoice.svg'

export enum registerSteps {
  PREPARATION = 'preparation',
  FORM = 'form',
  SUMMARY = 'summary',
}

//PreRegister

export interface PreRegisterRow {
  icon?: JSX.Element
  title?: JSX.Element
  doc?: JSX.Element
}

export interface RowDivision {
  left: PreRegisterRow
  right?: PreRegisterRow
}
export interface PreRegister {
  subtitle: string
  rows: RowDivision[]
}

export const preRegister: PreRegister = {
  subtitle: 'Przygotuj proszę następujące dane:',
  rows: [
    {
      left: {
        icon: <FirstSvg />,
        title: (
          <>
            <h3>NUMER PŁATNIKA</h3>
            <p>
              Numer płatnika to Twój indywidualny <span>8-cyfrowy </span>
              numer, który znajdziesz na fakturze za prąd.
            </p>
          </>
        ),
      },
      right: {
        doc: <InvoiceSvg />,
      },
    },
    {
      left: {
        icon: <SecondSvg />,
        title: (
          <h3>
            NUMER PESEL <span>(OSOBA PRYWATNA)</span> / NIP <span>(FIRMA)</span>
          </h3>
        ),
      },
    },
    {
      left: {
        icon: <ThirdSvg />,
        title: (
          <h3>
            ADRES E-MAIL{' '}
            <span>
              Wybierz z rozwagą adres e-mail (brak możliwości zmiany po
              założeniu konta)
            </span>
          </h3>
        ),
      },
    },
    {
      left: {
        icon: <FourthSvg />,
        title: <h3>NUMER TELEFONU</h3>,
      },
      right: {
        icon: <FifthSvg />,
        title: <h3>SILNE HASŁO</h3>,
      },
    },
  ],
}

//Register Form

export type ErrorCodeTypes = 'CNF' | 'FTN' | 'TNU' | 'CNA' | 'SNA' | ''

export const ErrorCodes = {
  CustomerNotFound: 'CNF' as ErrorCodeTypes,
  FakeTaxNumber: 'FTN' as ErrorCodeTypes,
  TaxNumberUsed: 'TNU' as ErrorCodeTypes,
  CustomerNotActive: 'CNA' as ErrorCodeTypes,
  SegmentNotActive: 'SNA' as ErrorCodeTypes,
  AdditionalValidationFailed: 'AVF' as ErrorCodeTypes,
  empty: '' as ErrorCodeTypes,
}

export enum fieldNames {
  MFA_CONTACT_TYPE = 'MFAContactType',
  EMAIL = 'Email',
  CUSTOMER_NUMBER = 'CustomerNumber',
  TAX_NUMBER = 'TaxNumber',
  PASSWORD = 'Password',
  CONFIRMED_PASSWORD = 'ConfirmedPassword',
  PHONE = 'Phone',
  PHONE_PREFIX = 'PhonePrefix',
  ACCOUNT_TYPE = 'Type',
  FIRST_NAME = 'Name',
  LAST_NAME = 'Surname',
  ROLE = 'Position',
  INVOICE_VALUE = 'AdditionalValidationValue',
  MFA_CODE = 'MfaCode',
}

export enum accountTypes {
  COMMERCIAL = 'commercial',
  INDIVIDUAL = 'individual',
  NOT_SELECTED = 'accountNotSelected',
}

// password

export enum passReqNames {
  DIGIT = 'digit',
  LETTERS = 'letters',
  LENGTH = 'length',
}

export const mfaChannels = [
  { value: 'Sms', name: 'Telefon' },
  { value: 'Email', name: 'E-Mail' },
]

interface PasswordReqType {
  [key: string]: { title: string; regex: RegExp }
}

export const PasswordRequirements: PasswordReqType = {
  [passReqNames.DIGIT]: {
    title: '1 cyfra',
    regex: /^(.*[0-9]+.*)$/,
  },
  [passReqNames.LETTERS]: {
    title: 'Wielka i mała litera',
    regex: /^((.*[a-z]+.*[A-Z]+.*)|(.*[A-Z]+.*[a-z]+.*))$/,
  },
  [passReqNames.LENGTH]: { title: '8 znaków', regex: /^(.{8,})$/ },
}

// register

export interface InputType {
  name: string
  title: string
  placeholder: string
  errorMsg: (accType?: string) => string
  password?: boolean
  customNextField?: string
  validation?: (el: string, accType?: string) => boolean
  optional?: boolean
  helper?: {
    content: string
  }
  disable?: boolean
}

export interface Section {
  header: string
  subHeader: string
  inputs: InputType[]
}

export interface RegisterDataTypes extends Section {
  mfa: {
    description: string
    label: string
    errorMsg: () => string
    channel: {
      helper: {
        content: JSX.Element
      }
    }
  }
}

export const registerData: RegisterDataTypes = {
  header: 'Rejestracja',
  subHeader: 'Bezpieczeństwo',

  mfa: {
    description:
      'UWAGA: Wymagane jest logowanie dwuskładnikowe. Wybierz kanał na który będziemy dostarczać kody służące do logowania lub innych ważnych czynności.',
    label: 'Kanał komunikacji dwuskładniowej',
    errorMsg: () => 'Wybierz kanał komunikacji dla MFA',
    channel: {
      helper: {
        content: (
          <span>
            Czym jest MFA (Multi Factor Authentication)? <br />
            Jest to wieloskładnikowe uwierzytelnienie (weryfikacja) Użytkownika
            podczas logowania i innych wrażliwych czynnościach wykonywanych w
            aplikacji. Uwierzytelnienie Użytkownika następuje na podstawie
            znajomości loginu, hasła oraz dodatkowego kodu otrzymywanego drogą
            mailową lub w wiadomości SMS, w zależności od wybranego przez
            Użytkownika kanału komunikacji
            <br />
            <br />
            Jak to działa? <br />
            Podczas logowania (następującego po aktywacji MFA) po podaniu adresu
            e-mail i hasła wymagane będzie wpisanie 6-cyfrowego kodu
            uwierzytelniającego. Kod ten zostanie dostarczony drogą mailową lub
            w wiadomości SMS. Podczas wprowadzania kodu istnieje możliwość
            zapamiętania urządzenia, na którym wprowadzono kod. Pozwoli to na
            logowanie na tym urządzeniu bez użycia MFA przez 6 kolejnych
            miesięcy.
          </span>
        ),
      },
    },
  },
  inputs: [
    {
      name: fieldNames.EMAIL,
      title: 'E-MAIL',
      placeholder: 'Wpisz adres e-mail',
      errorMsg: () => 'Wpisz poprawny adres e-mail',
      validation: el => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(el),
    },
    {
      name: fieldNames.PASSWORD,
      title: 'HASŁO',
      placeholder: 'Wpisz hasło',
      errorMsg: () => 'Wpisz poprawne hasło',
      password: true,
      validation: el => {
        return (
          !PasswordRequirements[passReqNames.DIGIT].regex.test(el) ||
          !PasswordRequirements[passReqNames.LETTERS].regex.test(el) ||
          !PasswordRequirements[passReqNames.LENGTH].regex.test(el)
        )
      },
      customNextField: fieldNames.PHONE,
    },
    {
      name: fieldNames.CUSTOMER_NUMBER,
      title: 'NUMER PŁATNIKA',
      placeholder: 'Wpisz numer płatnika',
      errorMsg: () => 'Wpisz 8-cyfrowy numer płatnika',
      customNextField: fieldNames.TAX_NUMBER,
      validation: el => !/^(([0-9]|[*]){8})$/i.test(el),
    },
  ],
}

export const commercialAccountData: InputType[] = [
  {
    name: fieldNames.FIRST_NAME,
    title: 'IMIĘ',
    placeholder: 'Wpisz imię',
    errorMsg: () => 'Wpisz poprawne imię',
    validation: (el, accType) => {
      if (accType === accountTypes.COMMERCIAL) {
        return el.length < 3
      }
      return false
    },
  },
  {
    name: fieldNames.LAST_NAME,
    title: 'NAZWISKO',
    placeholder: 'Wpisz nazwisko',
    errorMsg: () => 'Wpisz poprawne nazwisko',
    validation: (el, accType) => {
      if (accType === accountTypes.COMMERCIAL) {
        return el.length < 3
      }
      return false
    },
  },
]

export interface PhoneNumberInputType {
  name: string
  phonePrefix: string
  title: string
  placeholder: string
}

export const phoneNumberData: PhoneNumberInputType = {
  name: fieldNames.PHONE,
  phonePrefix: fieldNames.PHONE_PREFIX,
  title: 'TELEFON',
  placeholder: 'Wpisz numer telefonu',
}

export interface TaxInputType {
  name: string
  title: (accType: string) => string
  placeholder: (accType: string) => string
  errorMsg: (accType?: string) => string
  validation?: (el: string, accType: string) => boolean
}

export const taxNumberData: TaxInputType = {
  name: fieldNames.TAX_NUMBER,
  title: accType => {
    if (accType === accountTypes.INDIVIDUAL) {
      return 'NUMER PESEL'
    } else if (accType === accountTypes.COMMERCIAL) {
      return 'NIP'
    }
    return 'RODZAJ KONTA'
  },
  placeholder: accType => {
    if (accType === accountTypes.INDIVIDUAL) {
      return 'Wpisz PESEL'
    } else if (accType === accountTypes.COMMERCIAL) {
      return 'Wpisz NIP'
    }
    return ''
  },
  errorMsg: accType => {
    if (accType === accountTypes.INDIVIDUAL) {
      return 'Wpisz poprawny numer PESEL'
    } else if (accType === accountTypes.COMMERCIAL) {
      return 'Wpisz poprawny 10-cio znakowy NIP, bez łączników'
    }
    return 'Wybierz rodzaj konta'
  },
  validation: (el, accType) => {
    if (accType === accountTypes.INDIVIDUAL) {
      return !/^([0-9]{11})$/i.test(el)
    } else if (accType === accountTypes.COMMERCIAL) {
      return el.length !== 10 || !/^[A-Z0-9-]+$/i.test(el)
    }
    return true
  },
}

export const invoiceValueData: InputType = {
  name: fieldNames.INVOICE_VALUE,
  title: 'KWOTA BRUTTO FAKTURY',
  placeholder: 'Wpisz kwotę brutto ostatniej faktury prądowej lub zbiorczej',
  helper: {
    content:
      'Prosimy o wprowadzenie kwoty brutto z jednej z 3 ostatnich otrzymanych faktur. W przypadku kiedy posiadasz więcej niż 1 punkt poboru zwróć uwagę aby podać kwotę brutto z jednej z trzech faktur o najwyższym numerze.',
  },
  errorMsg: () => 'Wpisz poprawną kwotę faktury w formacie 1234,56',
  validation: el => !/^\s*-?\d+([\d,]\d{1,2})?\s*$/.test(el),
}

export enum AdditionalValidationType {
  SEND_INVOICE = 1,
  SEND_TOKEN = 2,
}

export const roles: string[] = [
  'Administrator',
  'Dyrektor',
  'Inspektor',
  'Kierownik',
  'Księgowy',
  'Pełnomocnik',
  'Prezes',
  'Prokurent',
  'Przedstawiciel',
  'Specjalista',
  'Właściciel',
  'Współwłaściciel',
  'Członek Zarządu',
  'Energetyk',
  'Zarządca',
]

export enum ErrorMsg {
  noConnectionOrServerError = 'Coś poszło nie tak, spróbuj ponownie za chwilę bądź skontaktuj się z infolinią',
}

// combined form fields

export const registerFormFields: (InputType | TaxInputType)[] = [
  ...registerData.inputs,
  taxNumberData,
]
