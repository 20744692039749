import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { EntityReducers } from '../EntityReducers'
import {
  getBaseState,
  getEntityAdapterReducers,
} from '../helpers/entityReducerHelpers'
import { getAllExtraReducers } from '../helpers/thunks/getAllThunk'
import {
  userNotificationsGetAll,
  updateUserNotification,
} from './userNotificationsThunks'
import { handleLoadingAction } from '../helpers/thunks/entityThunksFactory'
import { addUserNotificationsSideEffects } from './userNotificationsSideEffects'
import { NotificationSetting } from '@shared/contracts/models'

const entityAdapter = createEntityAdapter<NotificationSetting>()

export const userNotificationsSlice = createSlice({
  name: EntityReducers.userConsents,
  initialState: {
    ...getBaseState(entityAdapter),
  },
  reducers: {
    ...getEntityAdapterReducers<NotificationSetting>(entityAdapter),
  },
  extraReducers: builder => {
    getAllExtraReducers<NotificationSetting>(
      userNotificationsGetAll,
      builder,
      entityAdapter
    )
    handleLoadingAction(updateUserNotification, builder)
    builder.addCase(updateUserNotification.fulfilled, (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
      const entity = state.entities[payload.id]
      state.entities = {
        ...state.entities,
        [payload.id]: {
          ...(entity as NotificationSetting),
          isActive: payload.isActive,
        },
      }
    })
  },
})

addUserNotificationsSideEffects()

export const userNotificationActions = userNotificationsSlice.actions
