import { makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

const BTN_WIDTH = 124
const EMPTY_READINGS_HEIGHT = 200
const PROSUMER_BTN_MIN_HEIGHT = 34
const PROSUMER_BTN_WIDTH = 220

export default makeStyles((theme: Theme) => ({
  headerContent: {
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 0, 5),
    margin: theme.spacing(1, 0),
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  buttonsWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(0, 6, 0, 0),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'flex-start',
      margin: 0,
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    '& button': {
      padding: theme.spacing(1.5, 4),
    },
    [theme.breakpoints.down('sm')]: {
      '& button': {
        padding: theme.spacing(1, 2),
        width: BTN_WIDTH,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& button': {
        width: BTN_WIDTH - 12,
      },
    },
    flexWrap: 'wrap',
  },
  name: {
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: theme.spacing(1, 2, 1, 0),
    '& p': {
      margin: theme.spacing(0, 3.75, 0, 0),
    },
    '& span': {
      color: tableTextColor,
      fontWeight: 700,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  toggler: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      alignSelf: 'flex-start',
    },
  },
  pointInfo: {
    display: 'flex',
    margin: theme.spacing(7, 0),
    color: tableTextColor,
    '& div': {
      marginRight: theme.spacing(12),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& div': {
        margin: theme.spacing(0, 0, 3, 0),
      },
    },
  },
  infoLabel: {
    fontSize: 14,
    display: 'block',
    opacity: 0.55,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  infoValue: {
    fontSize: 18,
    letterSpacing: 0.6,
    fontWeight: 700,
    display: 'block',
    opacity: 0.75,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  emptyReadings: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    height: EMPTY_READINGS_HEIGHT,
    fontSize: 19,
    opacity: 0.55,
    color: tableTextColor,
  },
  prosumerBtn: {
    width: PROSUMER_BTN_WIDTH,
    minHeight: PROSUMER_BTN_MIN_HEIGHT,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    whiteSpace: 'normal',
    alignItems: 'left',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
  },
}))
