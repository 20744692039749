import React, { useCallback, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { NavExpandMenu } from '../NavExpandMenu'
import CustomScrollBar from '../ScrollBar'
import { SingleCustomerNumberData } from './SingleCustomerNumberData'
import { useDataSource } from './dataSource'
import Loader from 'react-loader-spinner'
import { textSecondaryColor, bgColor } from '@mbok/styles/constants'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  customerNum: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 12px 0 16px',
    width: '300px',
    height: '36px',
    border: `1.7px solid ${textSecondaryColor + colorOpacityParser(35)}`,
    borderRadius: '7px',
    backgroundColor: bgColor,
    fontSize: 14,
    textTransform: 'unset',
    letterSpacing: '0.1px',
    color: textSecondaryColor,
    transition: 'border 0.2s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      border: `1.7px solid ${textSecondaryColor}`,
    },
    '& div': {
      '&:first-child': {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '&:last-child': {
        height: '24px',
        marginLeft: '4px',
        opacity: 0.5,
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '252px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '200px',
      fontSize: 12,
    },
  },
}))

export const CustomerSelector = React.memo(() => {
  const styles = useStyles()
  const [menuState, setMenuState] = useState(false)

  const {
    selectedCustomerNumber,
    customers,
    balance,
    measurementPoints,
    loading,
    setNewCustomerNumber,
  } = useDataSource()

  const handleOnClick = useCallback(
    (customerNumber: string) => {
      setMenuState(true)
      setNewCustomerNumber(customerNumber)
      setTimeout(() => {
        setMenuState(false)
      }, 300)
    },
    [setNewCustomerNumber]
  )

  return (
    <NavExpandMenu
      expanded={menuState}
      loading={loading}
      clickElementRender={(expandElement, clickHandler) => {
        return (
          <div onClick={clickHandler} className={styles.customerNum}>
            {loading ? (
              <Loader type="ThreeDots" color="black" height={24} width={20} />
            ) : (
              <div>
                <span>{customers[0]?.name ?? ''}</span>
              </div>
            )}
            {expandElement}
          </div>
        )
      }}
    >
      <CustomScrollBar>
        <div className={styles.container}>
          <>
            {customers.map(customer => {
              const { name, customerNumber } = customer
              const balanceDisplay = customerNumber
                ? balance[customerNumber]
                : 0
              return (
                <SingleCustomerNumberData
                  key={customerNumber}
                  selected={customerNumber === selectedCustomerNumber}
                  click={() => {
                    customerNumber && handleOnClick(customerNumber)
                  }}
                  customer={{
                    name,
                    customerNumber,
                  }}
                  balance={balanceDisplay ?? 0}
                  measurementPoints={measurementPoints.data ?? []}
                  loading={measurementPoints.loading ?? false}
                  loadPoints={() =>
                    customer.customerNumber &&
                    measurementPoints.get(customer.customerNumber)
                  }
                />
              )
            })}
          </>
        </div>
      </CustomScrollBar>
    </NavExpandMenu>
  )
})
