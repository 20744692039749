import { useThunkApi } from '@core/apiClient/apiClient'
import {
  News,
  NewsPagedResponse,
  NewsTypeFilter,
} from '@shared/contracts/models'
import { EntityReducers } from '../EntityReducers'
import { BaseGetAllPayload, getAllThunk } from '../helpers/thunks/getAllThunk'

export interface GetNewsPayload extends BaseGetAllPayload {
  type?: NewsTypeFilter
  start?: number
  limit?: number
}

export const newsGetAll = getAllThunk<News, GetNewsPayload, NewsPagedResponse>(
  EntityReducers.news,
  ({ thunkApi, payload }) =>
    useThunkApi(
      {
        config: {
          method: 'get',
          url: 'News',
          params: payload,
        },
      },
      thunkApi
    )
)
