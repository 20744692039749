/**
 * mBok API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ValidationErrorEnum =
  | 'FakeTaxNumber'
  | 'CustomerNotFound'
  | 'TaxNumberUsed'
  | 'CustomerNotActive'
  | 'SegmentNotActive'

export const ValidationErrorEnum = {
  FakeTaxNumber: 'FakeTaxNumber' as ValidationErrorEnum,
  CustomerNotFound: 'CustomerNotFound' as ValidationErrorEnum,
  TaxNumberUsed: 'TaxNumberUsed' as ValidationErrorEnum,
  CustomerNotActive: 'CustomerNotActive' as ValidationErrorEnum,
  SegmentNotActive: 'SegmentNotActive' as ValidationErrorEnum,
  AdditionalValidationFailed: 'AdditionalValidationFailed' as ValidationErrorEnum,
}
