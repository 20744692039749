import { makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

export default makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    boxSizing: 'border-box',
    '&:not(:last-child)': {
      borderRight: '1.8px solid #5F76FD1A',
    },
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(8),
    },
  },
  title: {
    fontSize: 25,
    lineHeight: '31px',
    letterSpacing: 0.2,
    tableTextColor: tableTextColor,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(6),
    },
  },
  messageWrapper: {
    paddingTop: theme.spacing(3),
  },
  emailWrapper: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    '& span': {
      color: '#7c8a97',
      textAlign: 'center',
    },
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
    },
  },
  buttonsWrapper: {
    marginTop: theme.spacing(12),
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
      width: '100%',
      flexDirection: 'column-reverse',
      '& button': {
        width: '100%',
      },
    },
  },
  secondField: {
    marginTop: theme.spacing(5),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 16)
  },
}))
