import { EntityAdapter, EntityState } from '@reduxjs/toolkit'
import { EntityReducers } from '../EntityReducers'

export interface IEntityAdapterConfig<T, S = null> {
  entityName: EntityReducers
  selectId?: (el: T) => string | number
  sortComparer?: (a: T, b: T) => number
  additionalState?: S
  additionalReducers?: {
    [type: string]: (state: S & BaseState<T>, action: any) => void
  }
}

export interface LoadingState {
  loadingPending: number
  error: string | null
  total: number
}

export const initialLoadingState: LoadingState = {
  loadingPending: 0,
  error: null,
  total: 0,
}

export interface BaseState<T> extends LoadingState, EntityState<T> {}

export type ExtendedBaseState<T, S> = BaseState<T> & S

export const getEntityAdapterReducers = <T>(adapter: EntityAdapter<T>) => ({
  addOne: adapter.addOne,
  removeOne: adapter.removeOne,
  updateOne: adapter.updateOne,
  upsertOne: adapter.updateOne,
  addMany: adapter.addMany,
  removeMany: adapter.removeMany,
  updateMany: adapter.updateMany,
  upsertMany: adapter.upsertMany,
  removeAll: adapter.removeAll,
  setAll: adapter.setAll,
})

export const getBaseState = <T, S = null>(
  adapter: EntityAdapter<T>,
  additionalState?: S
) =>
  ({
    ...adapter.getInitialState(),
    ...initialLoadingState,
    ...additionalState,
  } as ExtendedBaseState<T, S>)
