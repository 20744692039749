import { Typography } from '@material-ui/core'
import React from 'react'

interface Classes {
  wrapper?: string
  heading?: string
  text?: string
}

interface SingleMeasurementPointProps {
  classes?: Classes
  heading?: string
  measurementPoint: string | React.ReactElement<any, any>
}

export const SingleMeasurementPoint: React.FC<SingleMeasurementPointProps> = ({
  classes = {},
  heading,
  measurementPoint,
}) => {
  return (
    <div className={classes?.wrapper}>
      {heading && (
        <Typography className={classes?.heading}>{heading}</Typography>
      )}
      {classes?.text ? (
        <span className={classes?.text}>{measurementPoint}</span>
      ) : (
        <>{measurementPoint}</>
      )}
    </div>
  )
}
