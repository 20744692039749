import { useApi } from '@core/apiClient/apiClient'
import { getSelectedCustomerNumberSelector } from '@core/store/entity/customerNumbers/customerNumbersSelectors'
import { useAppSelector } from '@core/store/store'
import { useCallback } from 'react'

export const useCacheReload = () => {
  const [execute] = useApi({
    config: {
      url: 'accounts/refresh',
      method: 'post',
    },
    omitConsents: true,
    omitCustomerNumber: true,
  })

  const customerNumber = useAppSelector(getSelectedCustomerNumberSelector)
  const { isLoadingUser } = useAppSelector(state => state.oidc)

  const cacheReload = useCallback(() => {
    if (!!customerNumber && !isLoadingUser) {
      execute()
    }
  }, [customerNumber, execute, isLoadingUser])

  return cacheReload
}
