import React, { useCallback, useMemo } from 'react'
import { useAppDispatch } from '@core/store/store'
import { Dialog } from '@material-ui/core'
import { useStyles } from './CookiesConsent.style'
import { useCookiesSeen } from './hooks/useCookiesSeen'
import { ReactComponent as CookieSvg } from '@images/icons/other/Cookies.svg'
import { StyledButton } from '../Button'
import { useAnalytics } from '@core/analytics/useAnalytics'
import { DocumentType } from '@shared/contracts/models'
import { switchCookiesDialog } from '@core/store/ui/actions'

export const CookiesConsent: React.FC<{ positionTop?: number }> = React.memo(
  ({ positionTop }) => {
    const styles = useStyles()
    const dispatch = useAppDispatch()

    // eslint-disable-next-line
    const [, googleAnalytics, toggleGA] = useAnalytics()
    const [cookieAccepted, setCookieAccepted] = useCookiesSeen()

    const handleCookieAccept = useCallback(
      (analytics: boolean) => {
        toggleGA(analytics)
        setCookieAccepted(true)
        dispatch(switchCookiesDialog(false))
      },
      [setCookieAccepted, toggleGA, dispatch]
    )

    const mojaPolenergiaURL = useMemo(() => {
      return 'https://moja.polenergia.pl/'
    }, [])

    const cookiesURL = useMemo(() => {
      return (
        process.env.REACT_APP_API_URL +
        `Documents/${DocumentType.CookiesPolicy}/pdf`
      )
    }, [])

    const privacyTermsURL = useMemo(() => {
      return (
        process.env.REACT_APP_API_URL +
        `Documents/${DocumentType.PrivacyPolicy}/pdf`
      )
    }, [])

    return (
      <>
        <Dialog open={!cookieAccepted} className={styles.dialog}>
          <div className={styles.textContainer}>
            <figure className={styles.cookieIcon}>
              <CookieSvg />
            </figure>
            <span className={styles.mainContent}>
              <p>
                Drogi użytkowniku,
                <br />
                prosimy o zapoznanie się z poniższymi informacjami. Mają one na
                celu umożliwienie udzielenia dobrowolnej, świadomej i wyraźnej
                zgody.
              </p>
              <p>
                Polenergia Dystrybucja sp. z o.o. z siedzibą w Warszawie
                wykorzystuje pliki cookies i inne podobne technologie (dalej:
                „Cookies”), aby umożliwić prawidłowe działanie strony
                internetowej{' '}
                <a
                  href={mojaPolenergiaURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Moja&nbsp;Polenergia
                </a>
                , ulepszać ją, a&nbsp;także aby analizować ruch na stronie oraz
                udostępniać informacje o&nbsp;sposobie korzystania z niej przez
                użytkowników swoim partnerom w&nbsp;obszarze mediów
                społecznościowych, reklamy i marketingu. Użytkownik może
                zaakceptować wszystkie Cookies, odrzucić wszystkie Cookies poza
                niezbędnymi, lub wybrać niektóre z nich, zaznaczając w zakładce
                „Ustawienia cookies” poniżej odpowiednie kategorie plików
                Cookies używanych na naszej stronie. Więcej informacji o tym,
                jak Polenergia Dystrybucja sp. z o.o. wykorzystuje Cookies,
                znajduje się w&nbsp;
                <a href={cookiesURL} target="_blank" rel="noopener noreferrer">
                  Polityce&nbsp;Cookies
                </a>
                .
              </p>
              <p>
                Więcej informacji o zasadach przetwarzania danych osobowych
                przez Polenergia Dystrybucja sp. z o.o. na stronie internetowej
                znajduje się w&nbsp;
                <a
                  href={privacyTermsURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Polityce&nbsp;prywatności
                </a>
                .
              </p>
            </span>
          </div>
          <div className={styles.buttonsContainer}>
            <StyledButton
              variant="outlined"
              onClick={() => dispatch(switchCookiesDialog(true))}
            >
              Ustawienia cookies
            </StyledButton>
            <StyledButton
              variant="outlined"
              onClick={() => handleCookieAccept(false)}
            >
              Odrzuć opcjonalne
            </StyledButton>
            <StyledButton
              variant="outlined"
              onClick={() => handleCookieAccept(true)}
            >
              Akceptuj wszystkie
            </StyledButton>
          </div>
        </Dialog>
      </>
    )
  }
)

export default CookiesConsent
