import { Dialog, DialogContent, DialogTitle, Switch } from '@material-ui/core'
import { useAppSelector } from '@core/store/store'
import Button from '@shared/components/modals/Modal/Button'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { DocumentType } from '@shared/contracts/models'
import { useStyles } from './CookiesSettings.style'

interface CookiesSettingsProps {
  close: () => void
  analytics: boolean
  handleCookieAccept: (analytics: boolean) => void
}

export const CookiesSettings: React.FC<CookiesSettingsProps> = ({
  close,
  analytics,
  handleCookieAccept,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const { isOpen } = useAppSelector(state => state.ui.cookiesSettingsDialog)

  const [analyticsCheckState, setAnalyticsCheckState] = useState(false)

  const cookiesURL =
    process.env.REACT_APP_API_URL +
    `Documents/${DocumentType.CookiesPolicy}/pdf`

  // Update state whenever props change
  useEffect(() => {
    setAnalyticsCheckState(analytics)
  }, [analytics])

  return (
    <>
      <Dialog
        aria-describedby="optional-cookies"
        open={isOpen}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            return close
          }
        }}
        maxWidth="sm"
        fullWidth
        className={styles.dialog}
      >
        <DialogTitle className={styles.dialogTitle} disableTypography>
          {t('COOKIES.title')}
        </DialogTitle>
        <DialogContent className={styles.contentContainer}>
          <p className={styles.mainContent}>
            Zaznaczając pola zamieszczone poniżej, wyrażasz zgodę na
            wykorzystywanie następujących kategorii plików cookies (opis
            poszczególnych kategorii znajdą Państwo w&nbsp;
            <a href={cookiesURL} target="_blank" rel="noopener noreferrer">
              Polityce&nbsp;Cookies
            </a>
            .)
          </p>
          <div className={styles.cookieCard}>
            <div className={styles.cookiesTitle}>
              <h3>{t('COOKIES.REQUIRED_COOKIES')}</h3>
              <span className={styles.switchStateTitle}>
                {t('COOKIES.MANDATORY')}
              </span>
            </div>
            <label className={styles.cookieContainer}>
              <div className={styles.switchContainer}>
                <h4>{t('COOKIES.AUTHENTICATION')}</h4>
                <Switch checked={true} disabled />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: `${t('COOKIES.GOAL')}: ${t('COOKIES.LOGIN_ABILITY')}`,
                }}
              />
            </label>
          </div>

          <div className={styles.cookieCard}>
            <div className={styles.cookiesTitle}>
              <h3>{t('COOKIES.ANALYTICS_COOKIES')}</h3>
              <span className={styles.switchStateTitle}>
                {t(
                  analyticsCheckState
                    ? 'COOKIES.TURNED_ON'
                    : 'COOKIES.TURNED_OFF'
                )}
              </span>
            </div>
            <label className={styles.cookieContainer}>
              <div className={styles.switchContainer}>
                <h4>{t('COOKIES.GOOGLE_ANALYTICS')}</h4>
                <Switch
                  checked={analyticsCheckState}
                  onChange={e => setAnalyticsCheckState(e.target.checked)}
                  color="primary"
                />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: `${t('COOKIES.GOAL')}: ${t('COOKIES.USER_ANALYSIS')}`,
                }}
              />
            </label>
          </div>

          <div className={styles.buttonsContainer}>
            <Button variant="outlined" onClick={close}>
              {t('CANCEL')}
            </Button>
            <Button
              type="save"
              onClick={() => handleCookieAccept(analyticsCheckState)}
            >
              {t('SAVE')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
