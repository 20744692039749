import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Dialog, DialogContent } from '@material-ui/core'

import StyledButton from '@shared/components/Button'
import waves from '@images/polenergiaWaves.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      backgroundSize: '100% 35%',
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url("${waves}")`,
      [theme.breakpoints.down('xs')]: {
        backgroundImage: 'none',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: '20px 0 40px',

      '& h1': {
        margin: '0 0 16px',
        fontSize: 35,
        textAlign: 'center',
        letterSpacing: '0.29px',
        fontWeight: theme.typography.fontWeightRegular,
      },
      '& h3': {
        margin: '0 0 32px',
        fontSize: 18,
        textAlign: 'center',
        letterSpacing: '0.05px',
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightLight,
      },
      '& h2': {
        margin: '0 0 24px',
        fontSize: 25,
        textAlign: 'center',
        letterSpacing: '0.17px',
        color: theme.palette.text.secondary,
        fontWeight: 700,
        opacity: 0.55,
      },
      [theme.breakpoints.down('sm')]: {
        padding: '20px 0',
      },
      [theme.breakpoints.down('xs')]: {
        '& h1': {
          fontSize: 28,
        },
        '& h3': {
          fontSize: 16,
        },
        '& h2': {
          margin: '0 0 24px',
          fontSize: 16,
        },
      },
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      '& button': {
        height: '48px',
        fontSize: 17,
        fontWeight: 700,
      },
      [theme.breakpoints.down('sm')]: {
        '& button': {
          fontSize: 15,
        },
      },
      [theme.breakpoints.down('xs')]: {
        '& button': {
          height: '38px',
          fontSize: 14,
        },
      },
    },
  })
)

interface PhoneNumberConfirmationModalProps {
  show: boolean
  phoneNumber: string
  close: () => void
  nextPage: () => void
}

const PhoneNumberConfirmationModal: React.FC<PhoneNumberConfirmationModalProps> = ({
  show,
  phoneNumber,
  close,
  nextPage,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <Dialog
      aria-describedby="phone-number-confirmation-modal"
      open={show}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      classes={{ paper: styles.contentWrapper }}
    >
      <DialogContent className={styles.content}>
        <h2>{t('REGISTER_PAGE.PHONE_NUMBER_CONFIRM_MODAL.TITLE')}</h2>
        <h1>{phoneNumber}</h1>
        <h3>{t('REGISTER_PAGE.PHONE_NUMBER_CONFIRM_MODAL.EXPLANATION')}</h3>
        <div className={styles.buttonsWrapper}>
          <StyledButton
            color="primary"
            variant="outlined"
            onClick={() => close()}
          >
            {t('CHANGE_DATA')}
          </StyledButton>
          <StyledButton
            color="primary"
            variant="contained"
            onClick={() => nextPage()}
          >
            {t('CONFIRM')}
          </StyledButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PhoneNumberConfirmationModal
