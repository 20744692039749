/**
 * mBok API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AccountingDocumentStatus = 'Paid' | 'PendingPayment' | 'Unpaid'

export const AccountingDocumentStatus = {
  Paid: 'Paid' as AccountingDocumentStatus,
  PendingPayment: 'PendingPayment' as AccountingDocumentStatus,
  Unpaid: 'Unpaid' as AccountingDocumentStatus,
}

export const TranslatedAccountingDocumentStatus = {
  [AccountingDocumentStatus.Paid]: 'Zapłacona',
  [AccountingDocumentStatus.PendingPayment]: 'Oczekująca',
  [AccountingDocumentStatus.Unpaid]: 'Niezapłacona',
}
