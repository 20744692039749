import { makeStyles } from '@material-ui/core'
import { textPrimaryColor } from '@mbok/styles/constants'

const border = '1.8px solid #E2E8ED'
export default makeStyles(theme => ({
  wrapper: {
    borderTop: '1.8px solid #E2E8ED',
    paddingTop: theme.spacing(6),
  },
  emailWrapper: {
    width: '100%',
    alignItems: 'initial',
    margin: 0,
    paddingTop: theme.spacing(5),
    marginTop: theme.spacing(5),
    borderTop: '1.8px solid #E2E8ED',
  },
  measurementPointsHeading: {
    marginBottom: theme.spacing(4),
  },
  singleMeasurementPointHeading: {
    color: textPrimaryColor,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '20px',
    letterSpacing: 0.1,
  },
  singleMeasurementPointText: {
    color: 'rgba(29, 72, 116, 0.55)',
  },
  messages: {
    borderBottom: border,
    padding: theme.spacing(0, 0, 8)
  }
}))
