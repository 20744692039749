import React, { useCallback, useEffect, useState } from 'react'
import { ReactComponent as HappySvg } from '@images/icons/topCards/Happy.svg'
import { ReactComponent as SadSvg } from '@images/icons/topCards/Sad.svg'
import { ReactComponent as WeirdSvg } from '@images/icons/topCards/Weird.svg'
import { Theme, useTheme } from '@material-ui/core'
import {
  AccountBalance,
  AccountingDocumentListItem,
  SecuredOrderItem,
} from '@shared/contracts/models'
import { differenceInDays, formatDistance, parseISO } from 'date-fns'
import { isFuture } from 'date-fns/esm'
import { pl } from 'date-fns/locale'
import { useCreateTransactionFromBalance } from '@shared/hooks/createTransaction'
import { SummaryCardWrapper } from './SummaryCardWrapper'
import { useAppDispatch } from '@core/store/store'
import { amountParser } from '@shared/utils/balanceParser'
import HintPopover from '../HintPopover'
import {
  errorRedColor,
  primaryColor,
  tableTextColor,
  textPrimaryColor,
  textSecondaryColor,
  warningColor,
} from '@mbok/styles/constants'
// import ExcessPaymentReturnModal from './ExcessPaymentReturnModal'
// import { hideModal, showModal } from '@core/store/ui/actions'

const HintContent = (
  <span style={{ color: tableTextColor }}>
    <ul style={{ listStyleType: 'none', marginBottom: 0, marginTop: 0 }}>
      <li>
        System płatności umożliwia dokonanie jednorazowej transakcji w wysokości
        nieprzekraczającej równowartości 1000 euro (przy zastosowaniu średniego
        kursu NBP obowiązującego w dniu dokonywania transakcji).
      </li>
      <li>
        <span style={{ color: primaryColor, fontWeight: 700 }}>
          Kolor zielony{' '}
        </span>
        oznacza, że wszystkie dokumenty masz zapłacone. Dziękujemy.
      </li>
      <li>
        <span style={{ color: errorRedColor, fontWeight: 700 }}>
          Kolor czerwony{' '}
        </span>
        oznacza, że w prezentowanym saldzie znajduje się przynajmniej jeden
        dokument, którego termin płatności został przekroczony.
      </li>
      <li>
        <span style={{ color: warningColor, fontWeight: 700 }}>
          Kolor pomarańczowy{' '}
        </span>
        oznacza, że w prezentowanym saldzie znajduje się przynajmniej jeden
        dokument, któremu zbliża się termin płatności i jest krótszy niż 5 dni.
      </li>
      <li>
        <span style={{ color: textPrimaryColor, fontWeight: 700 }}>
          Kolor niebieski{' '}
        </span>
        oznacza, że na Twoim koncie rozliczeniowym wystąpiła nadpłata. Może ona
        być spowodowana np. podwójną płatnością lub wystawioną korektą faktury.{' '}
        <span style={{ color: textSecondaryColor, fontWeight: 700 }}>
          Nie martw się!{' '}
        </span>
        Pozostawiona nadpłata zostanie zaliczona na poczet Twoich przyszłych
        rozliczeń.
      </li>
    </ul>
  </span>
)

interface BalanceCardProps {
  balanceData?: AccountBalance
  documents?: AccountingDocumentListItem[]
  loading?: boolean
}

export const BalanceCard: React.FC<BalanceCardProps> = ({
  balanceData,
  documents,
  loading,
}) => {
  const theme = useTheme<Theme>()
  // const [showModalState, setShowModalState] = useState(false)
  const [cardData, setCardData] = useState<{
    icon: React.FC<
      React.SVGProps<SVGSVGElement> & { title?: string | undefined }
    >
    headerMessage: string | React.ReactNode
    balanceColor: string
    balance: number
    buttonHandler?: () => void
    buttonMessage: string
  }>({
    icon: HappySvg,
    headerMessage: '',
    balanceColor: 'inherit',
    balance: 0,
    buttonMessage: '',
  })

  const dispatch = useAppDispatch()

  const createTransactionFromBalance = useCreateTransactionFromBalance()

  const getTransactions = useCallback((): SecuredOrderItem[] => {
    const transactions = documents
      ?.filter(i => i.paymentToken && i.totalAmount && i.accountingDocumentId)
      .map(i => ({ paymentToken: i.paymentToken, amount: i.totalAmount }))
    return transactions || []
  }, [documents])

  useEffect(() => {
    if (!balanceData) return
    const { balance, activeDocuments, oldestUnpaidDueDate } = balanceData

    const isOverdue = balance < 0
    const isToPay = balance > 0

    const oldestUnpaidLessEqualsThan5days =
      differenceInDays(parseISO(oldestUnpaidDueDate), new Date()) <= 5

    if (isOverdue) {
      setCardData({
        icon: HappySvg,
        headerMessage: 'O! Wygląda na to, że masz nadpłatę',
        balanceColor: theme.palette.text.primary,
        balance: Math.abs(balance),
        // buttonHandler: () => {
        //   dispatch(
        //     showModal({
        //       type: 'overpay',
        //       subtitle:
        //         'Na Twoim płatniku jest nadpłata i zostanie ona rozliczona na poczet Twoich przyszłych płatności.',
        //       withCancelButton: true,
        //       withSubmitButton: true,
        //       cancelButtonText: 'Nie, chciałbym zwrot',
        //       submitButtonText: 'Super! Zmniejsz rachunek',
        //       onSubmit: () => dispatch(hideModal()),
        //       onCancel: () => {
        //         dispatch(hideModal())
        //         setTimeout(() => {
        //           setShowModalState(true)
        //         }, 500)
        //       },
        //     })
        //   )
        // },
        buttonMessage: '', //'Sprawdź'
      })
    } else if (oldestUnpaidDueDate && isFuture(parseISO(oldestUnpaidDueDate))) {
      setCardData({
        icon: oldestUnpaidLessEqualsThan5days ? WeirdSvg : HappySvg,
        headerMessage: 'Masz zbliżającą się płatność',
        balanceColor: oldestUnpaidLessEqualsThan5days
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
        balance: Math.abs(balance),
        buttonMessage: 'Zapłać',
        buttonHandler: () => {
          createTransactionFromBalance(
            { activeDocuments, totalAmount: balance },
            (_, saveFn) => saveFn()
          )
        },
      })
    } else if (isToPay) {
      setCardData({
        icon: SadSvg,
        headerMessage: (
          <>
            Masz nieuregulowane płatności {activeDocuments > 0 && 'w ilości '}
            <span style={{ fontWeight: 800 }}>
              {`${activeDocuments} szt` || ''}
            </span>
            {oldestUnpaidDueDate && (
              <>
                {'. '}Od terminu płatności minęło{' '}
                <span style={{ fontWeight: 800 }}>
                  {formatDistance(new Date(), parseISO(oldestUnpaidDueDate), {
                    locale: pl,
                  })}{' '}
                </span>
              </>
            )}
          </>
        ),
        balanceColor: theme.palette.error.main,
        balance,
        buttonMessage: 'Zapłać',
        buttonHandler: () => {
          createTransactionFromBalance(
            { activeDocuments, totalAmount: balance },
            (_, saveFn) => saveFn()
          )
        },
      })
    } else {
      setCardData({
        icon: HappySvg,
        headerMessage: 'Dobra robota, wszystko opłacone!',
        balanceColor: theme.palette.primary.main,
        balance,
        buttonMessage: '',
      })
    }
  }, [
    balanceData,
    theme,
    createTransactionFromBalance,
    getTransactions,
    dispatch,
  ])

  return (
    <>
      <SummaryCardWrapper
        title={<HintPopover title="Moje saldo" content={HintContent} topCard />}
        Icon={cardData.icon}
        customIconPosition
        rows={[
          {
            header: <>{cardData.headerMessage}</>,
          },
          {
            header: 'Łączne saldo',
            content: (
              <span style={{ color: cardData.balanceColor }}>
                {amountParser(cardData.balance)} zł
              </span>
            ),
            buttonHandler: cardData.buttonHandler,
            buttonText: cardData.buttonMessage,
          },
        ]}
        loading={loading}
      ></SummaryCardWrapper>
      {/* <ExcessPaymentReturnModal
        show={showModalState}
        close={() => setShowModalState(false)}
        showNextModal={() =>
          dispatch(
            showModal({
              title: 'Dziękujemy za zlecenie usługi!',
              subtitle: 'UWAGA: Proces zwrotu nadpłaty może trwać do 14 dni',
              smallSubtitle: true,
              withCancelButton: true,
              cancelButtonText: 'Zamknij',
              onCancel: () => dispatch(hideModal()),
            })
          )
        }
        balance={cardData.balance.toFixed(2).toString()}
      /> */}
    </>
  )
}
