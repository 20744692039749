import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

export default makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(10, 15),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(5, 8),
      },
    },
    content: {
      padding: 0,
      marginBottom: theme.spacing(11),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h1': {
        fontSize: 25,
        letterSpacing: 0.2,
        margin: theme.spacing(0, 0, 12),
        textAlign: 'center',
      },
      '& div': {
        width: '100%',
        textAlign: 'left',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(8),
        '& h1': {
          marginBottom: theme.spacing(8),
        },
      },
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
    },
    btn: {
      width: 148,
      height: 40,
      margin: '0 auto',
      border: '2.7px solid #DAD9D9',
      color: tableTextColor,
      fontSize: 16,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  })
)
