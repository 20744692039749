import React, { useEffect, useState } from 'react'
import { AppError, useApi } from '@core/apiClient/apiClient'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  MeasurementPointListItem,
  MeasurementPointsDetails,
  ValidateRegistrationResult,
} from '@shared/contracts/models'
import { AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'
import { isEmpty } from 'ramda'
import { accountTypes, ErrorMsg, fieldNames, AdditionalValidationType } from '../static-data/RegisterData'
import { extractFormValues } from '../utils/formValueMapper'
import { CustomerNumberSelectionModal } from './modals/CustomerNumberSelectionModal'
import RegisterConfirmationModal from './modals/RegisterConfirmationModal'
import { InitialFieldsType } from './Register'
import { SubmitButton } from './SubmitButton'
import MeasurementPoint from '@shared/components/MeasurementPoint'
import { ReactComponent as MyAccSvg } from '@images/icons/register/MyAccSummary.svg'
import CustomScrollBar from '@shared/components/ScrollBar'
import { Button } from '@shared/components/Button'
import MeasurementPointsModal from '@shared/components/customersSelector/modal/MeasurementPointsModal'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& h1': {
      margin: '0 0 24px',
      fontSize: 30,
      letterSpacing: '0.35px',
      lineHeight: '38px',
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightLight,
    },
  },
  person: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
    marginBottom: '24px',
    borderBottom: '1.8px solid #E2E8ED',
    '& svg': {
      width: '62px',
      height: '62px',
    },
    '& h2': {
      margin: '0 16px',
      fontSize: 22,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0.15px',
    },
  },
  dataSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '24px',
    '& h3': {
      margin: '0 0 8px',
      fontSize: 14,
      fontWeight: 800,
      letterSpacing: '0.08px',
    },
    '& p': {
      margin: 0,
      fontSize: 16,
      letterSpacing: '0.13px',
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    [theme.breakpoints.only('md')]: {
      flexDirection: 'column',
      '& h3': {
        fontSize: 14,
        marginLeft: '8px',
      },
      '& p': {
        marginLeft: '8px',
        fontSize: 16,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& h3': {
        fontSize: 13,
      },
      '& p': {
        fontSize: 15,
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& h3': {
        marginLeft: '8px',
      },
      '& p': {
        marginLeft: '8px',
        fontSize: 16,
      },
    },
  },
  divider: {
    margin: '0 4px',
    width: 0,
    borderLeft: '1.8px solid #E2E8ED',
    [theme.breakpoints.only('md')]: {
      borderLeft: 'none',
      width: '100%',
      margin: '12px 0',
      borderBottom: '1.8px solid #E2E8ED',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 8px',
    },
    [theme.breakpoints.down('xs')]: {
      borderLeft: 'none',
      width: '100%',
      margin: '12px 0',
      borderBottom: '1.8px solid #E2E8ED',
    },
  },
  measurementPoint: {
    '& h4': {
      margin: '0 0 8px',
      fontSize: 12,
      fontWeight: 800,
      letterSpacing: '0.08px',
    },
  },
  pointsWrapper: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  pointsDisplay: {
    '& p': {
      margin: theme.spacing(0, 0, 2),
    },
  },
  numberSpan: {
    fontWeight: 700,
  },
  seePointsSpan: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    transition: 'color 0.2s ease-in-out',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}))

interface RegisterSummaryProps {
  formData: InitialFieldsType
  formAPIResponse: AxiosResponse<ValidateRegistrationResult>
  prevPage: () => void
  token?: string
}

export const RegisterSummary: React.FC<RegisterSummaryProps> = ({
  formData,
  formAPIResponse,
  prevPage,
  token,
}) => {
  const styles = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const { COMMERCIAL, INDIVIDUAL } = accountTypes
  const { EMAIL, TAX_NUMBER, CUSTOMER_NUMBER, ACCOUNT_TYPE } = fieldNames
  const { SEND_TOKEN, SEND_INVOICE } = AdditionalValidationType

  const [accCreated, setAccCreated] = useState(false)
  const [mainCustomerNumberDetails, setMainCustomerNumberDetails] = useState<
    MeasurementPointsDetails
  >()
  const [restCustomerNumbersDetails, setRestCustomerNumbersDetails] = useState<
    MeasurementPointsDetails[]
  >([])

  const [selectedAdditionalNumbers, setSelectedAdditionalNumbers] = useState<
    string[]
  >([])

  const responseData = formAPIResponse.data
  const numberOfMainPoints =
    mainCustomerNumberDetails?.measurementPointItems?.length

  // Check account type to select correct API endpoint
  const checkAccType = ():string | undefined => {
    if (formData[ACCOUNT_TYPE] === COMMERCIAL) {
      return "/createB2BAccount"
    } else if (formData[ACCOUNT_TYPE] === INDIVIDUAL) {
      return ""
    }
  }

  const [executeRegister] = useApi<ValidateRegistrationResult>({
    omitCustomerNumber: true,
    omitConsents: true,
    config: {
      method: 'post',
      url: `Accounts${checkAccType()}`,
    },
  })

  if(token === undefined) {
    token = ""
  }

  let {InvoiceValue, name, surname, position, ...formDataWithoutAdditionals} = formData

  const additionalValidationTypeCheck = () => {
    if(formData.AdditionalValidationValue !== ""){
      return SEND_INVOICE
    }
    return SEND_TOKEN
  }

  const additionalValidationValueCheck = () => {
    if(formData.AdditionalValidationValue !== ""){
      return formData.AdditionalValidationValue
    }
    return token
  }

  const createAcc = async () => {
    if (formData[ACCOUNT_TYPE] === COMMERCIAL) {
      executeRegister({
        data: {
          ...extractFormValues(formDataWithoutAdditionals),
          CustomerNumbers: [
            mainCustomerNumberDetails?.customerNumber,
            ...selectedAdditionalNumbers,
          ],
          name: name,
          surname: surname,
          position: position,
          AdditionalValidationType: additionalValidationTypeCheck(),
          AdditionalValidationValue: additionalValidationValueCheck()
        },
      })
      .then(() => {
        setAccCreated(true)
      })
      .catch((err: AppError) => {
        enqueueSnackbar(ErrorMsg.noConnectionOrServerError, {
          variant: 'error',
        })
      })
    } else if (formData[ACCOUNT_TYPE] === INDIVIDUAL) {
      executeRegister({
        data: {
          ...extractFormValues(formDataWithoutAdditionals),
          CustomerNumbers: [
            mainCustomerNumberDetails?.customerNumber,
            ...selectedAdditionalNumbers,
          ],
        },
      })
      .then(() => {
        setAccCreated(true)
      })
      .catch((err: AppError) => {
        enqueueSnackbar(ErrorMsg.noConnectionOrServerError, {
          variant: 'error',
        })
      })
    }
  }

  useEffect(() => {
    const selectedNumber = responseData.measurementDetailsPerCustomer?.find(
      details => details.customerNumber === formData[CUSTOMER_NUMBER]
    )
    const restNumbers = responseData.measurementDetailsPerCustomer?.filter(
      details => details.customerNumber !== formData[CUSTOMER_NUMBER]
    )

    if (restNumbers) {
      setRestCustomerNumbersDetails(restNumbers)
    }
    if (selectedNumber) {
      setMainCustomerNumberDetails(selectedNumber)
    }
  }, [formData, CUSTOMER_NUMBER, responseData.measurementDetailsPerCustomer])

  return (
    <>
      {accCreated && (
        <RegisterConfirmationModal
          show={accCreated}
          email={formData[EMAIL] as string}
        />
      )}
      <div className={styles.container}>
        <h1>Podsumowanie rejestracji</h1>
        <div className={styles.person}>
          <MyAccSvg />
          <h2>{mainCustomerNumberDetails?.customerName}</h2>
        </div>
        <div className={styles.dataSection}>
          <div>
            <h3>E-MAIL</h3>
            <p>{formData[EMAIL]}</p>
          </div>
          <div className={styles.divider}></div>
          <div>
            <h3>{formData[ACCOUNT_TYPE] === COMMERCIAL ? 'NIP' : 'PESEL'}</h3>
            <p>{formData[TAX_NUMBER]}</p>
          </div>
          <div className={styles.divider}></div>
          <div>
            <h3>NUMER&nbsp;PŁATNIKA</h3>
            <p>{formData[CUSTOMER_NUMBER]}</p>
          </div>
        </div>
        {!isEmpty(mainCustomerNumberDetails) && numberOfMainPoints && (
          <div className={styles.measurementPoint}>
            <h4>
              {numberOfMainPoints} {numberOfMainPoints < 2 ? 'PUNKT' : 'PUNKTY'}{' '}
              POBORU
            </h4>
            <div className={styles.pointsWrapper}>
              <CustomScrollBar maxHeight={344}>
                {mainCustomerNumberDetails?.measurementPointItems?.map(
                  (point, i) => {
                    const key = point.measurementPointId
                      ? point.measurementPointId + i
                      : i
                    return <MeasurementPoint key={key} {...point} />
                  }
                )}
              </CustomScrollBar>
            </div>
          </div>
        )}
        {!isEmpty(restCustomerNumbersDetails) && (
          <CustomerNumberSelectionModal
            accType={formData[ACCOUNT_TYPE] as string}
            customerNumbersDetails={restCustomerNumbersDetails}
            selectedNumbers={selectedAdditionalNumbers}
            setSelectedCustomerNumbers={numbers =>
              setSelectedAdditionalNumbers(numbers)
            }
          />
        )}
        {selectedAdditionalNumbers.map(number => {
          const numberDetails = restCustomerNumbersDetails.filter(
            el => el.customerNumber === number
          )
          const customer = numberDetails[0].customerName
          const points = numberDetails[0].measurementPointItems
          return (
            <MeasurementPointsDisplay
              number={number}
              points={points}
              customer={customer}
            />
          )
        })}
        <SubmitButton
          color="primary"
          variant="contained"
          onClick={() => {
            Boolean(mainCustomerNumberDetails) && createAcc()
          }}
        >
          Dalej
        </SubmitButton>
        <Button
          style={{ padding: '2px 8px', margin: '0 auto' }}
          color="primary"
          onClick={() => prevPage()}
        >
          Zmień dane
        </Button>
      </div>
    </>
  )
}

interface MeasurementPointsDisplay {
  number: string
  points: MeasurementPointListItem[]
  customer: string
}

export const MeasurementPointsDisplay: React.FC<MeasurementPointsDisplay> = ({
  number,
  points,
  customer,
}) => {
  const styles = useStyles()
  const [showMeasurementPointsModal, setShowMeasurementPointsModal] = useState(
    false
  )

  return (
    <div className={styles.pointsDisplay}>
      <p>
        <span className={styles.numberSpan}>{number}: </span>
        <span
          className={styles.seePointsSpan}
          onClick={() => setShowMeasurementPointsModal(true)}
        >
          {points.length} {points.length > 1 ? 'punkty' : 'punkt'} poboru
        </span>{' '}
      </p>
      <MeasurementPointsModal
        show={showMeasurementPointsModal}
        close={() => setShowMeasurementPointsModal(false)}
        customer={{ name: customer, number }}
        loading={false}
        points={points}
      />
    </div>
  )
}
