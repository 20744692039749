import React from 'react'
import { ReactComponent as ContractSvg } from '@images/icons/topCards/Contract.svg'
import { SummaryCardWrapper } from './SummaryCardWrapper'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  indicatorWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  statusDot: {
    width: '14px',
    height: '14px',
    borderRadius: '100%',
    display: 'inline-block',
    marginRight: theme.spacing(1.25),
  },
  active: {
    background: theme.palette.primary.main,
  },
  historic: {
    background: theme.palette.error.main,
  },
}))

interface MyDocumentsCardProps {
  activeDocs?: number
  oldDocs?: number
  loading?: boolean
}

export const MyDocumentsCard: React.FC<MyDocumentsCardProps> = ({
  activeDocs,
  oldDocs,
  loading,
}) => {
  const styles = useStyles()
  return (
    <SummaryCardWrapper
      title="Moje umowy"
      Icon={ContractSvg}
      rows={[
        {
          header: 'Aktywne umowy',
          content: (
            <div className={styles.indicatorWrapper}>
              <div className={`${styles.active} ${styles.statusDot}`} />
              <b>{activeDocs || 0}</b>
            </div>
          ),
        },
        {
          header: 'Historyczne umowy',
          content: (
            <div className={styles.indicatorWrapper}>
              <div className={`${styles.historic} ${styles.statusDot}`} />
              <b>{oldDocs || 0}</b>
            </div>
          ),
        },
      ]}
      loading={loading}
    ></SummaryCardWrapper>
  )
}
