import { makeStyles, Theme } from '@material-ui/core'
import { primaryColor } from '@mbok/styles/constants'
export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    maxHeight: '100%',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '76%',
    borderRadius: 10,
    backgroundColor: '#DAEEEC',
    marginBottom: theme.spacing(12),
    flexFlow: "column",
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  container: {
    width: '60%',
    padding:40,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      width: '92%',
      marginBottom: theme.spacing(4),
    },
    '& svg': {
      minHeight:300,
      [theme.breakpoints.down('xs')]: {
        minHeight:200,
      },
    }
  },
  icon: {
    width: '100%',
    opacity: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding:"40px",
    
    '& h2': {
      margin: 0,
      fontSize: 44,
      textAlign: 'left',
      color: primaryColor,
      fontWeight: 800,
      paddingBottom:"20px",
      [theme.breakpoints.down('xs')]: {
        fontSize: 28,
      },
    },
    
    [theme.breakpoints.down('md')]: {
      '& h2': {
        fontSize: 36,
        
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& h2': {
        fontSize: 32,
      },
    },
  },
  unavaiableContent: {
      fontSize: 24,
      fontWeight: 600,
      marginBottom:20,
      '& strong': {
        fontWeight:800
      }
    },
}))
