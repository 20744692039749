import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'

export const policiesSelector = (state: RootState) => state.policies

export const policiesLoadingSelector = createSelector(
  policiesSelector,
  state => state.loadingPending > 0
)

export const getDocuments = createSelector(policiesSelector, state =>
  state.documents.filter(doc => doc.pdfAvailable)
)

export const getNoPdfDocuments = createSelector(policiesSelector, state =>
  state.documents.filter(doc => !doc.pdfAvailable)
)
