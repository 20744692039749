import { makeStyles } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

const ICON_SIZE = 14

export default makeStyles(theme => ({
  wrapper: {
    width: 'auto',
    boxSizing: 'border-box',
    marginRight: theme.spacing(2),
    '&:not(:first-child)': {
      paddingLeft: theme.spacing(15),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      marginBottom: theme.spacing(2),
    },
    '&:not(:last-child)': {
      paddingRight: theme.spacing(15),
      borderRight: '1.8px solid #5F76FD1A',
      [theme.breakpoints.down('xs')]: {
        borderRight: 'none',
        paddingRight: 0,
      },
    },
  },
  title: {
    fontSize: 17,
    lineHeight: '21px',
    letterSpacing: 0.6,
    width: '100%',
    color: tableTextColor,
  },
  breakWord: {
    wordBreak: 'break-all',
  },
  noBorder: {
    borderRight: 'none !important',
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
  },
  label: {
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0.6,
    color: tableTextColor,
    '& p': {
      opacity: 0.55,
    },
  },
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    marginLeft: theme.spacing(),
    cursor: 'pointer',
  },
}))
