import { makeStyles } from '@material-ui/core'

const SWITCH_CONTAINER_WIDTH = 40
const SWITCH_CONTAINER_HEIGHT = 22

export default makeStyles(theme => ({
  wrapper: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  title: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    marginLeft: theme.spacing(2.25),
    letterSpacing: 0.1,
    width: `calc(100% - ${theme.spacing(2.25) + SWITCH_CONTAINER_WIDTH}px)`,
    '& svg': {
      minWidth: 12,
    },
  },
  row: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3.75),
    },
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: SWITCH_CONTAINER_HEIGHT,
    width: SWITCH_CONTAINER_WIDTH,
  },
}))
