import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { Button } from '@shared/components/Button'
import MeasurementPoint from '@shared/components/MeasurementPoint'
import { MeasurementPointListItem } from '@shared/contracts/models'
import { isEmpty } from 'ramda'
import React from 'react'
import useStyles from './MeasurementPointsModal.style'

const MEASUREMENT_POINTS = 'Punkty poboru'
const CUSTOMER = 'Płatnik'
const CUSTOMER_NUMBER = 'Numer płatnika'
const CLOSE = 'Zamknij'

interface MeasurementPointsModalProps {
  show: boolean
  close: () => void
  points: MeasurementPointListItem[]
  loading: boolean
  customer: {
    name: string
    number: string
  }
}

export const MeasurementPointsModal: React.FC<MeasurementPointsModalProps> = ({
  show,
  close,
  points,
  loading,
  customer,
}) => {
  const styles = useStyles()

  return (
    <Dialog
      aria-describedby="form-modal"
      open={show}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      onClose={close}
      className={styles.dialog}
      transitionDuration={500}
    >
      <DialogTitle className={styles.title} disableTypography>
        <h1>{MEASUREMENT_POINTS}</h1>
        <p>
          {CUSTOMER}: {customer.name}
        </p>
        <p>
          {CUSTOMER_NUMBER}: {customer.number}
        </p>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Collapse in={!loading && !isEmpty(points)} {...{ timeout: 700 }}>
          <div className={styles.measurementPointsWrapper}>
            {points.map((point, i) => {
              const key = point.measurementPointId
                ? point.measurementPointId + i
                : i
              return <MeasurementPoint key={key} {...point} />
            })}
          </div>
        </Collapse>
      </DialogContent>
      <DialogActions className={styles.buttonsContainer}>
        <Button variant="outlined" onClick={close}>
          {CLOSE}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
