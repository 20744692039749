import { makeStyles, Theme } from '@material-ui/core'
import { eInvoiceModalHeight } from '@shared/components/forms/eInvoice/style'
export const eInvoiceModalWidth = 720

export default makeStyles((theme: Theme) => ({
  modalWrapper: {
    width: `${eInvoiceModalWidth}px !important`,
    maxHeight: `${eInvoiceModalHeight}px`,
  },
}))
