import { makeStyles, Theme, createStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      '& .MuiPaper-root': {
        padding: '28px 40px 48px',
        [theme.breakpoints.down('sm')]: {
          paddingBottom: '24px',
        },
        [theme.breakpoints.down('xs')]: {
          padding: '20px 32px 24px',
        },
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: 0,

      '& h1': {
        margin: '0 0 16px',
        fontSize: 30,
        letterSpacing: '0.29px',
        lineHeight: '38px',
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightMedium,

        [theme.breakpoints.down('sm')]: {
          fontSize: 28,
        },
      },
    },
    input: {
      width: '80%',
      margin: '0 0 16px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    buttonContainer: {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'space-between',
      width: '80%',
      padding: 0,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexDirection: 'column-reverse',
      },
    },
    singleButtonContainer: {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      width: '80%',
      padding: 0,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexDirection: 'column-reverse',
      },
    },
    button: {
      margin: 0,
      height: '48px',
      width: '44%',
      fontSize: 17,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
        marginBottom: '16px',
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
  })
)
