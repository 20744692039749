import React, { useState, useCallback } from 'react'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'
import StyledButton, { Button } from '@shared/components/Button'
import { useApi } from '@core/apiClient/apiClient'
import useStyles from './RequestProsumentMeasurementPointReportModal.style'
import { useTranslation } from 'react-i18next'
import { AcceptElectronicContact } from '../../consents/AcceptElectronicContact'
import { useSnackbar } from 'notistack'

interface RequestProsumentMeasurementPointReportModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  userData: {
    userName?: string
    userNumber?: string
    userContactEmail?: string
  }
  pointData: {
    pointNumber?: string
    pointName?: string
  }
}

export const RequestProsumentMeasurementPointReportModal: React.FC<RequestProsumentMeasurementPointReportModalProps> = ({
  setShow,
  userData,
  pointData,
}) => {
  const { t } = useTranslation()
  const { pointNumber, pointName } = pointData
  const { userName, userNumber, userContactEmail } = userData

  const styles = useStyles()

  const [executeRequestForProsumerData] = useApi({
    omitConsents: true,
    omitCustomerNumber: true,
    config: {
      method: 'post',
      url: `accounts/sendTickerForPVReport`,
    },
  })

  const [showRequestModal, setShowRequestModal] = useState(true)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const [
    electronicContactConsentAccepted,
    setElectronicContactConsentAccepted,
  ] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const requestForProsumerData = useCallback(async () => {
    try {
      await executeRequestForProsumerData({
        data: {
          MeasurementPointNumber: pointNumber,
          ClientName: userName,
          ClientNumber: userNumber,
          ClientEmail: userContactEmail,
        },
      }).then(() => {
        setShowSuccessMessage(prev => !prev)
        setShowRequestModal(false)
      })
    } catch (err) {
      console.log(err)
      enqueueSnackbar(
        'Niestety, wysyłanie zamówienia się nie powiodło, spróbuj ponownie później.',
        { variant: 'error' }
      )
    }
  }, [
    executeRequestForProsumerData,
    setShowSuccessMessage,
    enqueueSnackbar,
    pointNumber,
    userContactEmail,
    userName,
    userNumber,
  ])

  return (
    <>
      <Dialog
        aria-describedby="form-modal"
        open={showRequestModal && electronicContactConsentAccepted}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        className={styles.contentWrapper}
        onClose={() => setShow(false)}
      >
        <DialogContent className={styles.content}>
          <h1>Zamów dane pomiarowe</h1>
          <p>
            Zamów raport z godzinowymi danymi pomiarowymi obejmującymi wolumeny
            energii elektrycznej zarówno pobranej z jak i wprowadzonej do sieci.
          </p>
          <p>
            Na życzenie przygotujemy dla Ciebie zestawienie dla punktu poboru
            {pointName ? <b> "{pointName}" </b> : <b> "{pointNumber}" </b>} i
            prześlemy je do 14 dni na Twój e-mail kontaktowy.
          </p>
          <br />
          <br />
        </DialogContent>
        <DialogActions className={styles.buttonContainer}>
          <Button
            variant="outlined"
            className={styles.button}
            onClick={() => {
              setShowRequestModal(false)
              setShow(false)
            }}
          >
            {t('MY_CONTRACTS.MODAL.CLOSE')}
          </Button>
          <StyledButton
            color="primary"
            variant="contained"
            className={styles.button}
            onClick={() => {
              requestForProsumerData()
            }}
          >
            Zamów
          </StyledButton>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-describedby="form-modal"
        open={showSuccessMessage}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        className={styles.contentWrapper}
        onClose={() => {
          setShowSuccessMessage(false)
        }}
      >
        <DialogContent className={styles.content}>
          <h1>Zamówiłeś dane pomiarowe</h1>
          <p>
            Dziękujemy za zamówienie danych pomiarowych. Zostaną one dostarczone
            na e-mail kontaktowy do 14 dni.
          </p>
          <br />
          <br />
        </DialogContent>
        <DialogActions className={styles.singleButtonContainer}>
          <Button
            variant="outlined"
            className={styles.button}
            onClick={() => {
              setShowSuccessMessage(false)
              setShowRequestModal(false)
              setShow(false)
            }}
          >
            {t('MY_CONTRACTS.MODAL.CLOSE')}
          </Button>
        </DialogActions>
      </Dialog>

      <AcceptElectronicContact
        consentAccepted={electronicContactConsentAccepted}
        setConsentAccepted={setElectronicContactConsentAccepted}
      />
    </>
  )
}
