import { createSlice } from '@reduxjs/toolkit'
import {
  initialLoadingState,
  LoadingState,
} from '../entity/helpers/entityReducerHelpers'
import { Document } from '@shared/contracts/models'
import { StoreReducers } from '../StoreReducers'
import { getPoliciesExtraReducers } from './policiesThunks'

export interface PoliciesState extends LoadingState {
  documents: Document[]
}

const initialState: PoliciesState = {
  ...initialLoadingState,
  documents: [],
}

export const policiesSlice = createSlice({
  name: StoreReducers.policies,
  initialState,
  reducers: {
    resetState: state => {
      state = initialState
    },
  },
  extraReducers: builder => {
    getPoliciesExtraReducers(builder)
  },
})

export const policyActions = policiesSlice.actions
