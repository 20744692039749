import React, { useCallback, useEffect, useState } from 'react'
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useTheme,
  CircularProgress,
} from '@material-ui/core'
import {
  AccountingDocumentListItem,
  AccountingDocumentStatus,
} from '@shared/contracts/models'
import { format, parseISO } from 'date-fns'
import {
  lightGrey,
  tableAmountColor,
  tableTextColor,
} from '@mbok/styles/constants'
import { useSavePdf } from '@shared/hooks/pdfSaver'
import CustomScrollBar from '@shared/components/ScrollBar'
import { useCreateTransaction } from '@shared/hooks/createTransaction'
import { trackEventOutsideComponent } from '@core/analytics/trackEventOutsideComponent'
import { DETAILS_CARD_CONTENT_MIN_HEIGHT } from '@shared/components/DetailsCard/DetailsCard'
import { amountParser } from '@shared/utils/balanceParser'
import { ReactComponent as PreviewDocSvg } from '@images/icons/other/PreviewDoc.svg'
import { NotesAndOther } from '@mbok/features/MyInvoices/components/InvoicesList/InvoicesList'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'
import HintPopover from '@shared/components/HintPopover'

const HintContent = (
  <span>
    <ul style={{ listStyleType: 'none', marginBottom: 0, marginTop: 0 }}>
      <li>
      System płatności umożliwia dokonanie jednorazowej transakcji w wysokości nieprzekraczającej równowartości 1000 euro (przy zastosowaniu średniego kursu NBP obowiązującego w dniu dokonywania transakcji).
      </li>
    </ul>
  </span>
)

const paymentsTableHeaders = {
  headers: [
    {
      name: (
        <>
          Numer
          <br />
          dokumentu
        </>
      ),
    },
    {
      name: (
        <>
          Zużycie
          <br />
          energii
        </>
      ),
    },
    {
      name: (
        <>
          Kwota
          <br />
          (brutto)
        </>
      ),
    },
    {
      name: (
        <>
          Termin
          <br />
          płatności
        </>
      ),
    },
    {
      name: <>Do zapłaty</>,
    },
    {
      name: <HintPopover title="Łączna kwota zaznaczoncyh" content={HintContent} topCard />,
    },
  ],
}

const PAY_CELL_WIDTH = 124

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      display: 'flex',
      minHeight: '250px',
      width: '40%',
      border: '1px solid black',
    },
    table: {
      margin: theme.spacing(0, 0, 2),
      minWidth: 700,
      borderRadius: 8,
      '& .MuiTableCell-root': {
        border: 0,
      },
      '& .MuiTableCell-head': {
        padding: '12px 8px',
      },
      '& .MuiTableCell-body': {
        alignItems: 'center',
        padding: '12px 8px',
      },
    },
    headerCell: {
      backgroundColor: lightGrey,
      color: tableTextColor + colorOpacityParser(55),
      fontSize: 14,
      letterSpacing: '0.61px',
      lineHeight: '14px',
      fontWeight: theme.typography.fontWeightBold,
    },
    bodyCell: {
      color: tableTextColor + colorOpacityParser(75),
      fontSize: 15,
      letterSpacing: '0.61px',
      lineHeight: '20px',
    },
    toPayCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: PAY_CELL_WIDTH,
      '& div': {
        '& span': {
          '&:first-child': {
            fontSize: 13.5,
            marginRight: '20px',
            color: tableAmountColor,
            fontWeight: 800,
          },
          '&:last-child': {
            color: tableTextColor + colorOpacityParser(35),
            fontWeight: theme.typography.fontWeightBold,
          },
        },
      },
    },
    downloadIcon: {
      '& svg': {
        width: '24px',
        height: '24px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        transition: 'color 0.2s ease-in-out',
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
    },
    price: {
      fontWeight: 700,
    },
    empty: {
      height: DETAILS_CARD_CONTENT_MIN_HEIGHT,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 19,
      color: tableTextColor,
      opacity: 0.55,
    },
  })
)

export interface MyPaymentsTableProps {
  myPayments?: Array<AccountingDocumentListItem>
}

export const MyPaymentsTable: React.FC<MyPaymentsTableProps> = ({
  myPayments,
}) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()

  return (
    <>
      <TableContainer style={{ height: '100%', minHeight: 180 }}>
        <CustomScrollBar>
          <Table
            className={`${styles.table}`}
            stickyHeader
            aria-label="paymentsTable"
          >
            <TableHead>
              <TableRow>
                {paymentsTableHeaders.headers.map(({ name }, i) => (
                  <TableCell key={i} className={styles.headerCell}>
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {myPayments?.map(payment => {
                const {
                  accountingDocumentId,
                  documentNumber,
                  consumptionValue,
                  consumptionUnit,
                  balance,
                  dueDate,
                  status,
                  paymentToken,
                  totalAmount,
                  pdfAvailable,
                  type,
                } = payment

                const balanceColor = () => {
                  switch (status) {
                    case AccountingDocumentStatus.Paid:
                      return tableAmountColor
                    case AccountingDocumentStatus.Unpaid:
                      return theme.palette.error.main
                    default:
                      return tableAmountColor
                  }
                }

                const consumption =
                  (type && NotesAndOther.includes(type)) ||
                  consumptionValue === null
                    ? 'Nie dotyczy'
                    : consumptionValue !== undefined &&
                      consumptionValue !== null &&
                      consumptionUnit
                    ? consumptionValue + ' ' + consumptionUnit
                    : ''

                return (
                  <TableRow key={accountingDocumentId} tabIndex={-1}>
                    <TableCell className={styles.bodyCell}>
                      {documentNumber}
                    </TableCell>
                    <TableCell
                      className={styles.bodyCell}
                      style={{
                        minWidth: 104,
                        fontSize:
                          (type && NotesAndOther.includes(type)) ||
                          consumptionValue === null
                            ? 14
                            : 15,
                      }}
                    >
                      {consumption}
                    </TableCell>
                    <TableCell
                      className={styles.bodyCell}
                      style={{ minWidth: 108 }}
                    >
                      {amountParser(totalAmount) + ' zł'}
                    </TableCell>
                    <TableCell className={styles.bodyCell}>
                      {dueDate && format(parseISO(dueDate), 'dd.MM.yyyy')}
                    </TableCell>
                    <TableCell
                      className={styles.bodyCell}
                      style={{ minWidth: 112 }}
                    >
                      <span
                        style={{ color: balanceColor() }}
                        className={styles.price}
                      >
                        {status === AccountingDocumentStatus.Unpaid
                          ? amountParser(balance) + ' zł'
                          : ''}
                      </span>
                    </TableCell>
                    <ToPayCell
                      paymentStatus={status}
                      balance={balance}
                      accountingDocumentId={accountingDocumentId}
                      paymentToken={paymentToken}
                      pdfAvailable={pdfAvailable}
                    />
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </CustomScrollBar>
      </TableContainer>
    </>
  )
}

export const ToPayCell: React.FC<{
  paymentStatus?: string
  balance?: number
  accountingDocumentId?: string
  paymentToken?: string
  pdfAvailable?: boolean
}> = ({
  paymentStatus,
  balance,
  accountingDocumentId,
  paymentToken,
  pdfAvailable,
}) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()

  const [paymentState, setPaymentState] = useState<{
    status: string
    style: { [key: string]: string | number }
  }>({ status: 'Zapłacono', style: {} })

  const { Paid, Unpaid } = AccountingDocumentStatus

  const savePdf = useSavePdf()

  const handlePaymentStatus = useCallback(() => {
    switch (paymentStatus) {
      case Paid:
        setPaymentState({
          status: 'Zapłacono',
          style: { color: tableTextColor, opacity: 0.35 },
        })
        break
      case Unpaid:
        setPaymentState({
          status: 'Zapłać',
          style: {
            fontSize: 15,
            color: theme.palette.primary.main,
            fontWeight: 800,
            cursor: 'pointer',
          },
        })
        break
      default:
        setPaymentState({
          status: 'Oczekujące',
          style: { color: theme.palette.secondary.main },
        })
    }
  }, [Paid, Unpaid, setPaymentState, paymentStatus, theme.palette])

  useEffect(() => {
    handlePaymentStatus()
  }, [handlePaymentStatus])

  const createTransaction = useCreateTransaction()

  const { style, status } = paymentState
  const pdfLink = accountingDocumentId
    ? `AccountingDocuments/${accountingDocumentId}/pdf`
    : ''

  return (
    <TableCell className={styles.bodyCell}>
      <div
        className={styles.toPayCell}
        onClick={() => {
          trackEventOutsideComponent({ action: 'click', category: 'test' })
        }}
      >
        <div>
          {createTransaction(
            [{ paymentToken, amount: balance }],
            (loading, saveFn) => (
              <span
                style={style}
                onClick={() => {
                  if (!paymentToken || !balance || paymentStatus !== 'Unpaid')
                    return
                  return saveFn()
                }}
              >
                {loading ? <CircularProgress size={20} /> : <>{status}</>}
              </span>
            )
          )}
        </div>
        <div>
          {pdfAvailable &&
            accountingDocumentId &&
            savePdf(pdfLink, (loading, saveFn) =>
              loading ? (
                <CircularProgress size={24} />
              ) : (
                <div className={styles.downloadIcon} onClick={saveFn}>
                  <PreviewDocSvg />
                </div>
              )
            )}
        </div>
      </div>
    </TableCell>
  )
}
