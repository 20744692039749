import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'

export const paymentsSelector = (state: RootState) => state.payments

export const paymentsLoadingSelector = createSelector(
  paymentsSelector,
  state => state.loadingPending > 0
)

export const getOrderSelector = createSelector(
  paymentsSelector,
  state => state.order
)

export const isHashValid = createSelector(
  paymentsSelector,
  state => state.isHashValid
)
