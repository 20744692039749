import { useApi } from '@core/apiClient/apiClient'
import {
  electronicContactMandatoryConsentsSelector,
  hasAcceptedElectronicContactConsentsSelector
} from '@core/store/entity/userConsents/userConsentsSelectors'

import { userConsentsGetAll } from '@core/store/entity/userConsents/userConsentsThunks'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Theme,
} from '@material-ui/core'
import StyledButton, { Button } from '@shared/components/Button'
import { Consent } from '@shared/contracts/models'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { Consents } from '@shared/components/Consents'

interface AcceptElectronicContactProps {
  consentAccepted: boolean
  setConsentAccepted: React.Dispatch<React.SetStateAction<boolean>>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
  })
)

export const AcceptElectronicContact: React.FC<AcceptElectronicContactProps> = ({
  consentAccepted,
  setConsentAccepted
}) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const electronicContactConsent = useAppSelector(electronicContactMandatoryConsentsSelector) as Consent[]
  consentAccepted = useAppSelector(hasAcceptedElectronicContactConsentsSelector)
  setConsentAccepted(consentAccepted)

  const [show, setShow] = useState(!consentAccepted)

  const [executeSetConsent] = useApi({
    omitConsents: true,
    config: {
      method: 'put',
    },
  })

  const dispatch = useAppDispatch()

  const onSubmit = (
    values: { [id: string]: boolean },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (electronicContactConsent.every(consent => consent?.id && values[consent.id])) {
      setSubmitting(true)
      Promise.all(
        electronicContactConsent.map(consent => {
          return executeSetConsent({
            url: 'Accounts/consents/' + consent?.id,
            data: {
              isAccepted: consent?.id && values[consent.id],
            },
          })
        }))
        .then(() => {
          dispatch(userConsentsGetAll())
          setConsentAccepted(true)
        })
        .catch(() => {
          enqueueSnackbar(
            'Zapisywanie zgód nie powiodło się, spróbuj ponownie lub skontaktuj się z biurem obsługi jeśli sytuacja powtarza się.',
            { variant: 'error' }
          )
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  return (
    <Formik initialValues={{}} onSubmit={() => { }}>
      {({
        isValid,
        submitForm,
        submitCount,
        setSubmitting,
        isSubmitting,
        values,
      }) => (
        <Form>
          <Dialog
            aria-describedby="form-modal"
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open={show}
          >
            <DialogContent classes={{ root: styles.dialogContent }}>
              <div>
                {electronicContactConsent.length > 0 && (
                  <>
                    <h1>Potrzebujemy Twojej zgody</h1>
                    <p>Abyśmy mogli wysłać do Ciebie dane pomiarowe na kontaktowy adres e-mail, potrzebujemy Twojej zgody na kontakt drogę elektroniczną.</p>
                    <Consents consents={electronicContactConsent} />
                  </>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 4 }}
                variant="text"
                onClick={() => {
                  setShow(false)
                  setConsentAccepted(false)
                }}
              >
                {t('MY_CONTRACTS.MODAL.CLOSE')}
              </Button>
              <StyledButton
                color="primary"
                variant="contained"
                disabled={submitCount > 0 && !isValid}
                type="submit"
                onClick={() => {
                  if (!isSubmitting) {
                    submitForm().then(() => {
                      onSubmit(values, setSubmitting)
                    })
                  }
                }}
                loading={isSubmitting}
              >
                Zapisz
              </StyledButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  )
}
