import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { EntityReducers } from '../EntityReducers'
import {
  getBaseState,
  getEntityAdapterReducers,
} from '../helpers/entityReducerHelpers'
import { getAllExtraReducers } from '../helpers/thunks/getAllThunk'
import { documentsGetAll, GetDocumentsPayload } from './documentsThunks'
import { addDocumentsSideEffects } from './documentsSideEffects'
import {
  AccountingDocumentListItem,
  AccountingDocumentListItemPagedResponse,
} from '@shared/contracts/models'

const entityAdapter = createEntityAdapter<AccountingDocumentListItem>({
  selectId: document => document.accountingDocumentId ?? '',
})

export const documentsSlice = createSlice({
  name: EntityReducers.documents,
  initialState: {
    ...getBaseState(entityAdapter),
  },
  reducers: {
    ...getEntityAdapterReducers<AccountingDocumentListItem>(entityAdapter),
  },
  extraReducers: builder => {
    getAllExtraReducers<
      AccountingDocumentListItem,
      GetDocumentsPayload,
      AccountingDocumentListItemPagedResponse
    >(
      documentsGetAll,
      builder,
      entityAdapter,
      response => response.results ?? []
    )
  },
})

addDocumentsSideEffects()

export const documentsActions = documentsSlice.actions
