import { makeStyles, Theme } from '@material-ui/core'
import {
  bgColor,
  contentSidePadding,
  headerBg,
  navHeight,
} from '@mbok/styles/constants'
import { transparentize } from 'polished'
import bg from '@images/my-centrum-bg.png'

const CONTENT_MAX_WIDTH = 1400

const NOTIFICATION_SIZE = 36

const STATUS_CARDS_MAX_WIDTH = 1600

const TOGGLE_BTN_WIDTH = 180
const TOGGLE_BTN_HEIGHT = 32

export default makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    background: bgColor,
  },
  contentWrapper: {
    height: '100%',
    maxHeight: '100vh',
    flex: '1 1 auto',
    position: 'relative',
  },
  nav: {
    height: navHeight,
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    ...contentSidePadding(10, 15),
    backgroundColor: transparentize(0.25, headerBg),
    boxShadow: 'none',
    zIndex: 10,
    fontSize: 23,
    fontWeight: 600,
    transition: 'background-color 0.3s',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      padding: theme.spacing(2.5, 3, 4),
    },
  },
  navRight: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  notification: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: NOTIFICATION_SIZE,
    width: NOTIFICATION_SIZE,
    borderRadius: '24px',
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(0, 8),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 4),
    },
    '& svg': {
      height: NOTIFICATION_SIZE - 10,
      width: NOTIFICATION_SIZE - 10,
      paddingLeft: theme.spacing(0.5),
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuIcon: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(2.5),
    cursor: 'pointer',
  },
  scrolled: {
    backgroundColor: headerBg,
    boxShadow: '0 2px 2px -2px rgba(0,0,0,.2)',
  },
  topContainer: {
    width: '100%',
    ...contentSidePadding(70, 10),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  pageHeader: {
    background: `linear-gradient(270deg, rgba(242,246,245,0) 0%, ${transparentize(
      0.15,
      headerBg
    )} 100%)`,
    boxShadow: 'none',
    marginTop: theme.spacing(5),
  },
  additionalTopElements: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(6, 0),
  },
  mainElements: {
    maxWidth: CONTENT_MAX_WIDTH,
    ...contentSidePadding(40),
    [theme.breakpoints.down('xs')]: {
      ...contentSidePadding(32),
    },
  },
  toggleBtn: {
    alignSelf: 'center',
    padding: 0,
    fontSize: 15,
    width: TOGGLE_BTN_WIDTH,
    height: TOGGLE_BTN_HEIGHT,
    margin: theme.spacing(0, 0, 2),
  },
  statusCards: {
    display: 'flex',
    width: '100%',
    maxWidth: STATUS_CARDS_MAX_WIDTH,
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  wrapperContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  wrapperInner: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  statusCardsRow: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    '&:first-child': {
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      '&:first-child': {
        marginRight: 0,
        marginBottom: theme.spacing(4),
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:first-child': {
        marginBottom: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}))
