/**
 * mBok API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type OrderStatus =
  | 'New'
  | 'Pending'
  | 'Completed'
  | 'CompletedWithErrors'

export const OrderStatus = {
  New: 'New' as OrderStatus,
  Pending: 'Pending' as OrderStatus,
  Completed: 'Completed' as OrderStatus,
  CompletedWithErrors: 'CompletedWithErrors' as OrderStatus,
}
