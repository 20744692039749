import { resetState } from '@core/store/payments/actions'
import { getOrderSelector } from '@core/store/payments/paymentsSelectors'
import { getOrder, validateHash } from '@core/store/payments/paymentsThunks'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import { useLogout } from '@core/auth/useLogout'
import { showModal } from '@core/store/ui/actions'
import { ModalType } from '@core/store/ui/ui'
import { OrderStatus } from '@shared/contracts/models'
import { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { amountParser } from '@shared/utils/balanceParser'
import React from 'react'

export const useQuery = () => new URLSearchParams(useLocation().search)

export const useQueryParam = (param: string) => useQuery().get(param)

export const useClearParams = () => {
  const history = useHistory()

  return useCallback(
    () =>
      history.replace({
        search: '',
      }),
    [history]
  )
}

export const usePaymentsStatus = () => {
  const OrderID = useQueryParam('OrderID')
  const ServiceID = useQueryParam('ServiceID')
  const hash = useQueryParam('Hash')
  const order = useAppSelector(getOrderSelector)
  const dispatch = useAppDispatch()
  const clearParams = useClearParams()
  const logout = useLogout()

  const { t } = useTranslation()

  const openStatusModal = useCallback(() => {
    const modalSettings: {
      type: ModalType
      title: string
      subtitle: string | JSX.Element
    } = { type: 'paymentWait', title: '', subtitle: <></> }

    if (order?.orderStatus === OrderStatus.Completed) {
      modalSettings.type = 'paymentDone'
      modalSettings.title = t('PAYMENT.SUCCESS.TITLE')
      modalSettings.subtitle = (
        <Trans i18nKey="PAYMENT.SUCCESS.SUBTITLE">
          Your Payment for
          <span style={{ fontWeight: 900 }}>
            {' '}
            {{ amount: amountParser(order?.totalAmount, 2) }}
          </span>
          will be booked within 3 working days
        </Trans>
      )
    }

    if (order?.orderStatus === OrderStatus.CompletedWithErrors) {
      modalSettings.type = 'error'
      modalSettings.title = t('PAYMENT.FAILURE.TITLE')
      modalSettings.subtitle = t('PAYMENT.FAILURE.SUBTITLE', {
        amount: order.totalAmount,
      })
    }
    if (
      order?.orderStatus === OrderStatus.New ||
      order?.orderStatus === OrderStatus.Pending
    ) {
      modalSettings.type = 'paymentWait'
      modalSettings.title = t('PAYMENT.PENDING.TITLE')
      modalSettings.subtitle = t('PAYMENT.PENDING.SUBTITLE', {
        amount: order.totalAmount,
      })
    }

    dispatch(
      showModal({
        ...modalSettings,
        smallSubtitle: true,
        subtitleWithMargin: true,
        onCancel: () => {
          dispatch(resetState())
          clearParams()
        },
      })
    )
  }, [dispatch, order, t, clearParams])

  useEffect(() => {
    if (OrderID && ServiceID && hash) {
      dispatch(validateHash({ hash, values: [ServiceID, OrderID] }))
        .then(unwrapResult)
        .then(response => {
          if (!response.isHashValid) {
            return logout()
          }

          dispatch(getOrder(OrderID))
        })
        .catch()
    }
  }, [OrderID, ServiceID, hash, dispatch, logout])
  useEffect(() => {
    if (order) {
      openStatusModal()
    }
  }, [order, openStatusModal])
}
