/**
 * mBok API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AccountingDocumentType =
  | 'Invoice'
  | 'PrepaymentInvoice'
  | 'Forecast'
  | 'CollectiveInvoice'
  | 'InterestNote'
  | 'DebitNote'
  | 'CreditNote'
  | 'Other'
  | 'NoteCorrection'
  | 'InvoiceCorrection'

export const AccountingDocumentType = {
  Invoice: 'Invoice' as AccountingDocumentType,
  PrepaymentInvoice: 'PrepaymentInvoice' as AccountingDocumentType,
  Forecast: 'Forecast' as AccountingDocumentType,
  CollectiveInvoice: 'CollectiveInvoice' as AccountingDocumentType,
  InterestNote: 'InterestNote' as AccountingDocumentType,
  DebitNote: 'DebitNote' as AccountingDocumentType,
  CreditNote: 'CreditNote' as AccountingDocumentType,
  Other: 'Other' as AccountingDocumentType,
  NoteCorrection: 'NoteCorrection' as AccountingDocumentType,
  InvoiceCorrection: 'InvoiceCorrection' as AccountingDocumentType,
}

export const TranslatedAccountingDocumentType = {
  [AccountingDocumentType.Invoice]: 'Faktura rozliczeniowa',
  [AccountingDocumentType.PrepaymentInvoice]: 'Faktura zaliczkowa',
  [AccountingDocumentType.Forecast]: 'Prognoza',
  [AccountingDocumentType.CollectiveInvoice]: 'Faktura zbiorcza',
  [AccountingDocumentType.InterestNote]: 'Nota odsetkowa',
  [AccountingDocumentType.DebitNote]: 'Nota obciążeniowa',
  [AccountingDocumentType.CreditNote]: 'Nota uznaniowa',
  [AccountingDocumentType.Other]: 'Inna',
  [AccountingDocumentType.NoteCorrection]: 'Korekta noty',
  [AccountingDocumentType.InvoiceCorrection]: 'Korekta faktury',
}
