import { isValidPhoneNumber } from 'react-phone-number-input'
import {
  EditPhoneModalView,
  InitialFieldsType,
} from './EditMainPhoneForm.types'

export const validateFormFields = (
  values: InitialFieldsType,
  editPhoneModalView: EditPhoneModalView
) => {
  const errors: Partial<InitialFieldsType> = {}
  if (
    values.Phone === '' ||
    !isValidPhoneNumber(`${values.PhonePrefix}${values.Phone}`) ||
    values.Phone.toString().length < 9
  ) {
    errors.Phone = 'Wpisz poprawny numer telefonu'
  }
  if (
    editPhoneModalView === EditPhoneModalView.MFA_CODE &&
    values.MfaCode.toString().length !== 6
  ) {
    errors.MfaCode = 'Wpisz poprawny kod aktywacji MFA'
  }
  return errors
}
