import { makeStyles } from '@material-ui/core'

const WRAPPER_CARD_TYPE_HEIGHT = 340

export default makeStyles(theme => ({
  wrapper: {
    borderRadius: 8,
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
    width: '100%',
    '& div': {
      '&:not(.slick-dots)': {
        height: '100%',
      },
      '&.slick-list': {
        height: '100% !important',
      },
    },
    '& li': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: 8,
  },
  button: {
    marginBottom: theme.spacing(8),
  },
  adItem: {
    width: '100%',
    overflow: 'hidden',
    outline: 'none',
    '& h1': {
      padding: theme.spacing(10, 7, 0),
      margin: 0,
      fontWeight: 500,
      textAlign: 'left',
      color: theme.palette.common.white,
      fontSize: 31,
      letterSpacing: 0.3,

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 5, 0),
        fontSize: 26,
      },
      '& span': {
        fontWeight: 800,
        color: theme.palette.primary.main,
      },
    },
  },
  wrapperTopType: {},
  wrapperCardType: {
    backgroundColor: '#E2E4E3',
    border: '1.7px solid #E2E4E3',
    minHeight: WRAPPER_CARD_TYPE_HEIGHT + 44,
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      width: '76%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '84%',
      minHeight: WRAPPER_CARD_TYPE_HEIGHT - 140,
      height: WRAPPER_CARD_TYPE_HEIGHT - 140,
    },
    [theme.breakpoints.down(440)]: {
      width: '100%',
    },
  },
  wrapperBannerType: {},

  itemContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    borderRadius: 8,
    flexDirection: 'column',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },

  itemTopType: {},
  itemCardType: {
    [theme.breakpoints.between('sm', 'md')]: {
      backgroundPosition: 'left top',
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: '100% auto',
    },
  },
  itemBannerType: {},
}))
