import { createSlice } from '@reduxjs/toolkit'
import {
  initialLoadingState,
  LoadingState,
} from '../entity/helpers/entityReducerHelpers'
import { StoreReducers } from '../StoreReducers'

export interface AdministrationState extends LoadingState {
  applicationServiceMode: boolean
}

const initialState: AdministrationState = {
  ...initialLoadingState,
  applicationServiceMode: false,
}

export const administrationSlice = createSlice({
  name: StoreReducers.administration,
  initialState,
  reducers: {
    setApplicationServiceModeOn: state => {
      state.applicationServiceMode = true
    },
  },
})

export const administrationActions = administrationSlice.actions
