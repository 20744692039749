import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { News } from '@shared/contracts/models'
import { parseJSON } from 'date-fns'
import { props, values } from 'ramda'

export const newsSelector = (state: RootState) => state.news

export const getNewsLoadingSelector = createSelector(
  newsSelector,
  state => state.loadingPending > 0
)

export const getLatestNewsSelector = createSelector(newsSelector, state => {
  return (values(state.entities).filter(news => news) as News[]).sort((a, b) =>
    a.date && b.date ? +parseJSON(b.date) - +parseJSON(a.date) : 0
  )
})

export const getNewsByIdsSelector = (ids: string[]) =>
  createSelector(
    newsSelector,
    state => props(ids, state.entities).filter(news => news) as News[]
  )
