export enum EntityReducers {
  test = 'test',
  customerNumbers = 'customerNumbers',
  userConsents = 'userConsents',
  userAgreements = 'userAgreements',
  documents = 'documents',
  readings = 'readings',
  measurementPoints = 'measurementPoints',
  news = 'news',
  userNotifications = 'userNotifications',
}
