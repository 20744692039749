import i18n from '@i18n/index'
import { Address } from '@shared/utils/addressParser'

export type AddressErrors = Address

const postalCodeRegex = /^[0-9]{2}-[0-9]{3}$/i

export const postalCodeLength = 6


export const isPostalCodeValid = (postalCode: string) => {
  return postalCodeRegex.test(postalCode)
}

export const validateAddressForm = (values: Address) => {
  const errors: AddressErrors = {}
  if (!values.streetName) {
    errors.streetName = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  }
  if (!values.streetNumber) {
    errors.streetNumber = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  } else {
    if (values.streetNumber.length > 9)
      errors.streetNumber = i18n().t('FORM_ERRORS.MAX_LENGTH', { max: 9 })
  }

  if ((values.houseNumber ?? '').length > 9)
      errors.houseNumber = i18n().t('FORM_ERRORS.MAX_LENGTH', { max: 9 })

  if (!values.postalCode) {
    errors.postalCode = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  } else {
    if (!isPostalCodeValid(values.postalCode)) {
      errors.postalCode = i18n().t('FORM_ERRORS.POSTAL_CODE')
    }
  }
  if (!values.cityName) {
    errors.cityName = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  }

  return errors
}
