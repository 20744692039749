import React, { useState, useCallback } from 'react'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'
import Button from '@shared/components/modals/Modal/Button'
import { useApi } from '@core/apiClient/apiClient'
import { StyledInput } from '@shared/components/Input'
import useStyles from './ChangeMeasurementPointNameModal.style'
import { useTranslation } from 'react-i18next'

interface ChangeMeasurementPointNameModalProps {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  pointData: {
    id: string
    name?: string
    number: string
    reloadData: (
      customerNumberChanged: boolean,
      selectedIndex?: number | undefined
    ) => void
    selectedIndex: number
  }
}

export const ChangeMeasurementPointNameModal: React.FC<ChangeMeasurementPointNameModalProps> = ({
  show,
  setShow,
  pointData,
}) => {
  const { t } = useTranslation()
  const { id, name, number, reloadData, selectedIndex } = pointData

  const styles = useStyles()
  const [newName, setNewName] = useState(name ?? number ?? '')

  const nameError = useCallback(
    (newName: string) => {
      if (newName.length < 3) {
        return t('MY_CONTRACTS.MODAL.NAME_MIN_LENGTH_INFO')
      } else if (newName.length > 20) {
        return t('MY_CONTRACTS.MODAL.NAME_MAX_LENGTH_INFO')
      } else if (newName[0] === ' ') {
        return t('MY_CONTRACTS.MODAL.SPACEBAR')
      } else if (!/^[A-Za-z0-9-./,() ąĄćĆęĘłŁńŃóÓśŚżŻźŹ]+$/i.test(newName)) {
        return t('MY_CONTRACTS.MODAL.SYMBOLS_ERROR_INFO')
      }
      return ''
    },
    [t]
  )

  const [executeNameChange] = useApi({
    omitConsents: true,
    omitCustomerNumber: true,
    config: {
      method: 'put',
      url: `MeasurementPoints/${id}/name`,
    },
  })

  const nameChange = useCallback(
    async (name: string) => {
      if (!Boolean(nameError(name))) {
        try {
          await executeNameChange({
            data: {
              name,
            },
          })
        } catch (err) {
          console.log(err)
        } finally {
          setTimeout(() => {
            setShow(false)
            reloadData(false, selectedIndex)
          }, 300)
        }
      }
    },
    [executeNameChange, nameError, setShow, reloadData, selectedIndex]
  )

  return (
    <Dialog
      aria-describedby="form-modal"
      open={show}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      className={styles.contentWrapper}
      onClose={() => setShow(false)}
    >
      <DialogContent className={styles.content}>
        <h1>{t('MY_CONTRACTS.MODAL.CHANGE_MEASUREMENT_POINT_NAME')}</h1>
        <div className={styles.input}>
          <StyledInput
            value={newName}
            change={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setNewName(e.target.value)}
            errorMsg={nameError(newName)}
          />
        </div>
      </DialogContent>
      <DialogActions className={styles.buttonContainer}>
        <Button onClick={() => setShow(false)} className={styles.button}>
          {t('MY_CONTRACTS.MODAL.CLOSE')}
        </Button>
        <Button
          type="save"
          onClick={() => {
            nameChange(newName)
          }}
          className={styles.button}
          submit
        >
          {t('MY_CONTRACTS.MODAL.SAVE_CHANGE')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
