import { makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

const BULB_ICON_WRAPPER = 64
const PHONE_ICON_SIZE = 24

export default makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(7, 4),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 0),
    },
  },
  icon: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(3),
    },
    height: BULB_ICON_WRAPPER,
    width: BULB_ICON_WRAPPER,
    borderRadius: 12,
    boxShadow: '0 7px 9px 3px rgba(105,105,105,0.1)',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: tableTextColor,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& svg': {
      marginRight: theme.spacing(1),
      height: PHONE_ICON_SIZE,
      width: PHONE_ICON_SIZE,
    },
    '& p': {
      margin: 0,
      fontSize: 14,
      letterSpacing: 0.2,
      fontWeight: 700,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  contentMsg: {
    fontSize: 12,
    margin: 0,
    '& a': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      transition: 'color 0.2s ease-in-out',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      textDecoration: 'none',
    },
  },
}))
