import { makeStyles, Theme } from '@material-ui/core'

const CONSUMPTION_LINE_HEIGHT = 18

export default makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
  },
  tooltip: {
    padding: theme.spacing(3.75, 5),
  },
  tooltipHint: {
    color: theme.palette.text.hint,
    position: 'relative',
    top: 3,
  },
  tooltipLabel: {
    marginBottom: theme.spacing(1.25),
    textTransform: 'capitalize',
    fontWeight: 800,
  },
  tooltipData: {
    display: 'inline-block',
    '&:last-child': {
      marginLeft: theme.spacing(4),
    },
  },
  value: {
    fontWeight: 700,
    fontSize: 16,
  },
  chartUnit: {
    color: theme.palette.text.hint,
    display: 'flex',
    '& span': {
      lineHeight: `${CONSUMPTION_LINE_HEIGHT}px`,
      marginRight: theme.spacing(1),
    },
  },
  chartLegend: {
    display: 'flex',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  legendItem: {
    margin: theme.spacing(1, 0),
    fontWeight: 700,
    color: theme.palette.text.hint,
    '& div': {
      display: 'inline-block',
      width: '20px',
      height: '20px',
      borderRadius: 5,
      marginRight: theme.spacing(1.25),
      transform: 'translateY(25%)',
    },
    '& span': {
      marginRight: theme.spacing(4),
    },
  },
}))
