import i18n from '@i18n/index'

export interface ProvidedAddress {
  line1: string
  line2: string
}

interface Line1Address {
  streetName?: string
  streetNumber?: string
  houseNumber?: string
}

interface Line2Address {
  postalCode?: string
  cityName?: string
}

export type Address = Line1Address & Line2Address
const addressDelimiter = i18n().t('DELIMITER')
const houseNumberPrefix = i18n().t('PREFIXES.HOUSE_NUMBER')

const getSplittedAddressLine = (line: string) => {
  return line.split(addressDelimiter)
}

const isHouseNumber = (houseNumber?: string) => {
  return !!houseNumber
}

const explodeLine1 = (line: string) => {
  const exploded: Line1Address = {}
  const splittedAddressLine1 = getSplittedAddressLine(line)
  if (isHouseNumber(splittedAddressLine1[2])) {
    exploded.houseNumber = (splittedAddressLine1[2] ?? '').trim()
  }
  exploded.streetNumber = (splittedAddressLine1[1] ?? '').trim()
  exploded.streetName = (splittedAddressLine1[0] ?? '').trim()

  return exploded
}

const explodeLine2 = (line: string): Line2Address => {
  const exploded = line.split(' ')

  return {
    postalCode: exploded[0],
    cityName: exploded[1],
  }
}

export const explodeAddress = (address: ProvidedAddress): Address => {
  const exploded = {
    line1: explodeLine1(address.line1),
    line2: explodeLine2(address.line2),
  }

  return {
    streetName: exploded.line1.streetName,
    streetNumber: exploded.line1.streetNumber,
    houseNumber: exploded.line1.houseNumber,
    postalCode: exploded.line2.postalCode,
    cityName: exploded.line2.cityName,
  }
}

const implodeLine1 = (address: Address) => {
  let imploded = `${address.streetName} ${addressDelimiter} ${address.streetNumber} ${addressDelimiter} `
  imploded += address.houseNumber ? `${address.houseNumber}` : ''
  return imploded
}

const implodeLine2 = (address: Address) => {
  return `${address.postalCode} ${address.cityName}`
}

export const implodeAddress = (address: Address) => {
  return {
    field1: implodeLine1(address),
    field2: implodeLine2(address),
  }
}

export const stringifyAddressLine1 = (address: Address) => {
  return `${implodeLine1(address)}, ${implodeLine2(address)}`
}

export const getConfirmationAddress = (address: ProvidedAddress) => {
  const exploded = explodeAddress(address)

  return `${exploded.streetName} ${exploded.streetNumber}${
    exploded.houseNumber ? ` ${houseNumberPrefix} ${exploded.houseNumber}` : ''
  }\n${implodeLine2(exploded)}`
}

export const removeDelimiterFromAddressLine1 = (addressLine1?: string) => {
  const exploded = explodeLine1(addressLine1 ?? '')
  return `${exploded.streetName} ${exploded.streetNumber}${
    exploded.houseNumber ? ` ${houseNumberPrefix} ${exploded.houseNumber}` : ''
  }`
}
