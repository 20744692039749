import React, { useCallback, useEffect, useState } from 'react'
import { ReactComponent as CalendarSvg } from '@images/icons/topCards/Calendar.svg'
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core'
import { LinearProgress } from '@shared/components/LinearProgress'
import { AccountingDocumentListItem } from '@shared/contracts/models'
import { amountParser } from '@shared/utils/balanceParser'
import { closestTo, differenceInDays, format, parseISO } from 'date-fns'
import { isEmpty, reduce } from 'ramda'
import HintPopover from '../HintPopover'
import OfferCarousel from '@shared/components/OfferCarousel'
import { ImageType } from '@core/store/offers/types'
import {
  CARD_MIN_HEIGHT,
  CARD_WIDTH,
  SummaryCardWrapper,
} from './SummaryCardWrapper'

const HintContent = (
  <>
    Kliencie w tym miejscu możesz zobaczyć, czy Masz zbliżające się terminy
    płatności dokumentów. Pamiętaj, że kwota, którą tutaj wyświetlamy została
    już wliczona do kwoty salda które widzisz w okienku Salda obok. Jeżeli nie
    posiadasz zbliżających się dokumentów do zapłaty, to zobaczysz tutaj
    przyjemną grafikę, zdjęcie lub inny ciekawy komunikat.
  </>
)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentDate: {
      display: 'flex',
      alignItems: 'center',
    },
    progress: {
      flex: '1 1 auto',
      marginLeft: theme.spacing(2.5),
    },
    loader: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.common.white,
    },
    wrapper: {
      display: 'flex',
      maxWidth: CARD_WIDTH,
      width: CARD_WIDTH,
      minHeight: CARD_MIN_HEIGHT,
      borderRadius: 8,
      position: 'relative',
      marginRight: 0,
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
      [theme.breakpoints.only('sm')]: {
        minWidth: CARD_WIDTH - 64,
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 auto 8px',
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: CARD_MIN_HEIGHT + 24,
        width: '100%',
      },
    },
    topOfferWrapper: {
      [theme.breakpoints.only('lg')]: {
        maxWidth: CARD_WIDTH - 60,
        width: CARD_WIDTH - 60,
      },
      [theme.breakpoints.between(1280, 1400)]: {
        maxWidth: CARD_WIDTH - 88,
        width: CARD_WIDTH - 88,
      },
    },
  })
)

interface PaymentsCardProps {
  additionalBalance?: number
  documents?: AccountingDocumentListItem[]
  loading?: boolean
  dueDateState: Date | false | null
  setDueDateState: React.Dispatch<React.SetStateAction<Date | false | null>>
  offersLoaded: boolean
}

export const PaymentsCard: React.FC<PaymentsCardProps> = ({
  additionalBalance,
  documents,
  loading,
  dueDateState,
  setDueDateState,
  offersLoaded,
}) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()

  const [balance, setBalance] = useState(additionalBalance || 0)
  const [cardData, setCardData] = useState<{
    daysLeft: number
    amountColor: string
  }>({
    daysLeft: 0,
    amountColor: theme.palette.primary.main,
  })

  useEffect(() => {
    if (documents) {
      const datesArr = documents.map(el => {
        return parseISO(el.dueDate ?? '')
      })
      if (!isEmpty(datesArr)) {
        const date = closestTo(new Date(), datesArr)
        const daysLeft = differenceInDays(date, new Date())
        setDueDateState(date)
        setCardData({
          daysLeft: daysLeft,
          amountColor:
            daysLeft <= 5
              ? theme.palette.secondary.main
              : theme.palette.primary.main,
        })
      } else {
        setDueDateState(false)
      }
      const currBalance = reduce<AccountingDocumentListItem, number>(
        (acc, val) => {
          const sum = acc + (val.balance || 0)
          return sum
        },
        0,
        documents
      )
      setBalance(currBalance)
    }
  }, [
    documents,
    theme.palette.primary.main,
    theme.palette.secondary.main,
    setDueDateState,
  ])

  const { amountColor, daysLeft } = cardData

  const dueDateDisplay = useCallback(
    (dueDateState: Date) => {
      return (
        <SummaryCardWrapper
          title={
            <HintPopover title="Moje płatności" content={HintContent} topCard />
          }
          Icon={CalendarSvg}
          rows={[
            {
              header: (
                <span>
                  Następna płatność{` (${documents?.length}`}&nbsp;
                  {documents
                    ? `${documents.length === 1 ? 'dokument)' : 'dokumenty)'}`
                    : ''}
                </span>
              ),
              content: (
                <div className={styles.paymentDate}>
                  <span>{format(dueDateState, 'dd.MM.yyyy')}</span>
                  <LinearProgress
                    variant="determinate"
                    color={daysLeft <= 5 ? 'secondary' : 'primary'}
                    value={100 - daysLeft * 2}
                    className={styles.progress}
                  />
                </div>
              ),
            },
            {
              header: 'Łączna kwota',
              content: (
                <span
                  style={{
                    color: amountColor,
                  }}
                >
                  {amountParser(balance)} zł
                </span>
              ),
            },
          ]}
          loading={loading}
        ></SummaryCardWrapper>
      )
    },
    [daysLeft, styles, balance, documents, amountColor, loading]
  )

  return dueDateState === null || !offersLoaded ? (
    <div className={`${styles.loader} ${styles.wrapper}`}>
      <CircularProgress size={32} />
    </div>
  ) : (
    <>
      {dueDateState ? (
        dueDateDisplay(dueDateState)
      ) : offersLoaded ? (
        <div className={`${styles.wrapper} ${styles.topOfferWrapper}`}>
          <OfferCarousel type={ImageType.top} />
        </div>
      ) : (
        <div className={`${styles.loader} ${styles.wrapper}`} />
      )}
    </>
  )
}
