import React, { FunctionComponent } from 'react'
import { Field, useFormikContext } from 'formik'
import TextArea from '../TextArea'
import { ITextAreaProps } from '../TextArea/TextArea'

interface IFormikTextAreaProps extends Omit<ITextAreaProps, 'formError'> {}

const FormikTextArea: FunctionComponent<IFormikTextAreaProps> = ({
  name,
  required = false,
  disabled = false,
  ...props
}) => {
  const formikContext = useFormikContext()
  const formikErrors: {
    [key: string]: string
  } = formikContext.errors
  const formikTouchedFields: {
    [key: string]: string
  } = formikContext.touched
  const fieldError = formikTouchedFields[name] ? formikErrors[name] : undefined
  return (
    <Field
      required={required}
      name={name}
      as={TextArea}
      disabled={disabled}
      formError={fieldError}
      {...props}
    />
  )
}

export default FormikTextArea
