import { makeStyles, Theme } from '@material-ui/core'
import { textPrimaryColor } from '@mbok/styles/constants'

const border = '1.8px solid #E2E8ED'
export default makeStyles((theme: Theme) => ({
  measurementPoints: {
    borderTop: border,
    borderBottom: border,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
  },
  carouselOuterWrapper: {
    marginTop: theme.spacing(7),
  },
  consents: {
    paddingTop: theme.spacing(7),
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginBottom: theme.spacing(2),
      alignItems: 'flex-start',
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
  consent: {
    width: '100%',
    '&  .MuiFormControlLabel-label': {
      fontSize: 14,
      letterSpacing: 0.1,
      lineHeight: '20px',
      fontWeight: theme.typography.fontWeightBold,
      color: textPrimaryColor,
      marginTop: -3,
      '& span': {
        color: theme.palette.primary.main,
        fontWeight: 700,
      },
    },
  },
  measurementPointsHeading: {
    marginBottom: theme.spacing(4),
  },
}))
