import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core'
import {
  MeasurementPointListItem,
  MeasurementPointsDetails,
} from '@shared/contracts/models'
import { reduce, compose, keys, pickBy, isEmpty } from 'ramda'
import StyledCheckbox from '@shared/components/Checkbox'
import { accountTypes } from '../../static-data/RegisterData'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { textSecondaryColor } from '@mbok/styles/constants'
import MeasurementPoint from '@shared/components/MeasurementPoint'
import { ReactComponent as CheckedSvg } from '@images/icons/checkbox/Checked.svg'
import { ReactComponent as UncheckedSvg } from '@images/icons/checkbox/Unchecked.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiPaper-root': {
        padding: theme.spacing(7, 5),
      },
    },
    title: {
      padding: theme.spacing(0, 2),
      textAlign: 'center',
      marginBottom: theme.spacing(5),
      color: theme.palette.error.main,
      '& h2': {
        fontSize: 16,
        letterSpacing: 0.55,
        fontWeight: 700,
      },
      [theme.breakpoints.down('sm')]: {
        '& h2': {
          fontSize: 14,
        },
      },
    },
    content: {
      padding: theme.spacing(0, 1),
    },
    detailsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(5, 0),
      borderTop: '1.8px solid #E2E8ED',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '& p': {
        margin: theme.spacing(0, 7, 0, 4),
        fontWeight: 800,
        fontSize: 14,
        letterSpacing: 0.08,
      },
      '& span': {
        fontSize: 18,
        color: textSecondaryColor,
        letterSpacing: 0.13,
        fontWeight: theme.typography.fontWeightBold,
      },
      [theme.breakpoints.down('sm')]: {
        '& p': {
          margin: theme.spacing(0, 5, 0, 3),
        },
        '& span': {
          fontSize: 16,
        },
      },
    },
    toggler: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 800,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      transition: 'color 0.2s ease-in-out',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '& p': {
        margin: 0,
      },
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(2, 0, 0),
      },
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(6, 0, 0),
      '& button': {
        fontSize: 17,
        textAlign: 'center',
        letterSpacing: 0.31,
        fontWeight: theme.typography.fontWeightBold,
        [theme.breakpoints.down('xs')]: {
          fontSize: 14,
        },
        '&:first-child': {
          padding: theme.spacing(2, 4),
          marginBottom: theme.spacing(3),
          borderRadius: 24,
          color: theme.palette.common.white,
        },
        '&:last-child': {
          padding: theme.spacing(1),
          margin: 0,
          color: theme.palette.primary.main,
          transition: 'color 0.2s ease-in-out',
          '&:hover': {
            color: theme.palette.primary.dark,
            backgroundColor: 'transparent',
          },
        },
        textTransform: 'unset',
      },
    },
    footer: {
      marginBottom: theme.spacing(3),
      '& p': {
        margin: 0,
        fontSize: 14,
        letterSpacing: 0.1,
        fontWeight: theme.typography.fontWeightBold,
      },
      '& span': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        fontWeight: 700,
        transition: 'color 0.2s ease-in-out',
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(6),
      },
    },
  })
)

interface CustomerNumberSelectionModalProps {
  accType: string
  customerNumbersDetails: MeasurementPointsDetails[]
  setSelectedCustomerNumbers: (customerNumbers: string[]) => void
  selectedNumbers: string[]
}

export const CustomerNumberSelectionModal: React.FC<CustomerNumberSelectionModalProps> = ({
  accType,
  customerNumbersDetails,
  selectedNumbers,
  setSelectedCustomerNumbers,
}) => {
  const styles = useStyles()

  const [isOpen, setIsOpen] = useState(true)
  const [selectedNumbersState, setSelectedNumbersState] = useState(
    {} as {
      [customerNumber: string]: boolean
    }
  )

  const { COMMERCIAL } = accountTypes
  const NIPorPESEL = accType === COMMERCIAL ? 'NIP' : 'PESEL'
  const moreThanOnePoint = customerNumbersDetails.length > 1

  const setDefaultValues = useCallback(() => {
    const numbersValues = reduce(
      (acc, { customerNumber }) => ({
        ...acc,
        [customerNumber as string]: selectedNumbers.includes(customerNumber),
      }),
      {},
      customerNumbersDetails
    )
    setSelectedNumbersState(numbersValues)
  }, [customerNumbersDetails, selectedNumbers])

  useEffect(() => {
    setDefaultValues()
  }, [customerNumbersDetails, selectedNumbers, setDefaultValues])

  const handleClose = (setNumbers: boolean) => {
    if (setNumbers) {
      const selectedNumbers = compose(
        keys,
        pickBy(val => !!val)
      )(selectedNumbersState)
      setSelectedCustomerNumbers(selectedNumbers)
    } else {
      setDefaultValues()
    }
    setIsOpen(false)
  }

  return (
    <>
      <Dialog
        aria-describedby="form-modal"
        open={isOpen}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        onClose={() => {
          setIsOpen(false)
        }}
        className={styles.dialog}
        transitionDuration={500}
      >
        <DialogTitle className={styles.title}>
          Do tego numeru {NIPorPESEL}
          {moreThanOnePoint
            ? ' przypisane są dodatkowe numery płatnika'
            : ' przypisany jest dodatkowy numer płatnika'}
        </DialogTitle>
        <DialogContent className={styles.content}>
          {customerNumbersDetails.map(
            ({ customerNumber, measurementPointItems }) => (
              <CustomerNumberMeasurementPoints
                key={customerNumber}
                customerNumber={customerNumber}
                items={measurementPointItems}
                selectedNumbersState={selectedNumbersState}
                setSelectedNumbersState={setSelectedNumbersState}
              />
            )
          )}
        </DialogContent>
        <DialogActions className={styles.buttonsContainer}>
          <Button
            variant="contained"
            onClick={() => handleClose(true)}
            color="primary"
          >
            Dodaj{moreThanOnePoint ? ' wybrane numery' : ' wybrany numer'}
          </Button>
          <Button onClick={() => handleClose(false)} color="primary">
            Pomiń
          </Button>
        </DialogActions>
      </Dialog>
      <div className={styles.footer}>
        {isEmpty(selectedNumbers) ? (
          <p>
            Do tego numeru {NIPorPESEL}
            {moreThanOnePoint
              ? ' przypisane są dodatkowe numery płatnika'
              : ' przypisany jest dodatkowy numer płatnika'}
            {'. '}
            <span
              onClick={() => {
                setIsOpen(true)
              }}
            >
              Dodaj&nbsp;{moreThanOnePoint ? 'numery' : 'numer'}
            </span>
          </p>
        ) : (
          <>
            <p>
              Do konta przypisano{' '}
              {selectedNumbers.length > 1
                ? 'dodatkowe numery'
                : 'dodatkowy numer'}{' '}
              płatnika.{' '}
              <span
                onClick={() => {
                  setIsOpen(true)
                }}
              >
                Zmień&nbsp;wybór
              </span>
            </p>
          </>
        )}
      </div>
    </>
  )
}

interface CustomerNumberMeasurementPointsProps {
  customerNumber: string
  items: MeasurementPointListItem[]
  selectedNumbersState: {
    [customerNumber: string]: boolean
  }
  setSelectedNumbersState: React.Dispatch<
    React.SetStateAction<{
      [customerNumber: string]: boolean
    }>
  >
}

export const CustomerNumberMeasurementPoints: React.FC<CustomerNumberMeasurementPointsProps> = ({
  customerNumber,
  items,
  selectedNumbersState,
  setSelectedNumbersState,
}) => {
  const styles = useStyles()
  const [visible, setVisible] = useState(false)

  return (
    <>
      <div key={customerNumber} className={styles.detailsContainer}>
        <label className={styles.label}>
          <StyledCheckbox
            name={customerNumber}
            icon={<UncheckedSvg />}
            checkedIcon={<CheckedSvg />}
            checked={selectedNumbersState[customerNumber]}
            onChange={(_, checked) => {
              setSelectedNumbersState({
                ...selectedNumbersState,
                [customerNumber]: checked,
              })
            }}
            disableRipple
          />
          <p>Numer płatnika</p>
          <span>{customerNumber}</span>
        </label>
        <div className={styles.toggler} onClick={() => setVisible(!visible)}>
          {visible ? (
            <>
              <p>Schowaj punkty poboru</p> <ExpandLessIcon />
            </>
          ) : (
            <>
              <p>Wyświelt punkty poboru</p> <ExpandMoreIcon />
            </>
          )}
        </div>
      </div>
      <Collapse in={visible} timeout={650} style={{ padding: '0 8px' }}>
        {items.map((item, i) => {
          const key = item.measurementPointId ? item.measurementPointId + i : i
          return <MeasurementPoint key={key} {...item} />
        })}
      </Collapse>
    </>
  )
}
