import React, { useCallback, useState } from 'react'
import {
  Button,
  makeStyles,
  MenuItem,
  createStyles,
  Theme,
  FormControl,
  Select,
  Menu,
} from '@material-ui/core'
import {
  AccountingDocumentsSortType,
  AccountingDocumentTypeFilter,
  MeasurementPointListItem,
} from '@shared/contracts/models'
import {
  tableTextColor,
  textSecondaryColor,
  bgColor,
} from '@mbok/styles/constants'
import { FilterType, SortType } from '../invoicesDataSource'
import { GetDocumentsPayload } from '@mbok/core/store/entity/documents/documentsThunks'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginRight: '16px',
      '& > div': {
        border: `1.7px solid ${textSecondaryColor + colorOpacityParser(35)}`,
        borderRadius: '7px',
        backgroundColor: bgColor,
        textTransform: 'unset',
        letterSpacing: '0.1px',
        color: textSecondaryColor,
        transition: 'border 0.2s ease-in-out',
        '&:hover': {
          border: `1.7px solid ${textSecondaryColor}`,
        },
        '&:before, &:after': {
          display: 'none',
        },
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0, 3),
        width: '100%',
      },
    },
    select: {
      padding: theme.spacing(2, 6, 2, 4),
    },
    sort: {
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start !important',
      },
      '& h3': {
        margin: theme.spacing(0, 2, 0, 0),
        fontWeight: 700,
        fontSize: 18,
        color: tableTextColor,
        letterSpacing: '0.2px',
      },
      '& button': {
        padding: theme.spacing(1, 0, 1, 1),
        fontSize: 16,
        color: tableTextColor,
        textTransform: 'unset',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1, 0, 1, 0),
        },
      },
      '& svg': {
        height: '28px',
        margin: 0,
        fill: theme.palette.primary.main,
      },
    },
  })
)

//InvoiceType filter

const InvoiceFilters = [
  AccountingDocumentTypeFilter.All,
  AccountingDocumentTypeFilter.AllInvoices,
  AccountingDocumentTypeFilter.AllNotes,
  AccountingDocumentTypeFilter.CollectiveInvoice,
]

export const InvoiceTypeFilter: React.FC<{
  filtersState: FilterType
  setFiltersState: React.Dispatch<React.SetStateAction<FilterType>>
}> = ({ filtersState, setFiltersState }) => {
  const styles = useStyles()

  const translateFilter = useCallback(
    (typeOfDoc: AccountingDocumentTypeFilter) => {
      const {
        All,
        AllInvoices,
        AllNotes,
        CollectiveInvoice,
      } = AccountingDocumentTypeFilter

      switch (typeOfDoc) {
        case All:
          return 'Wszystkie dokumenty'
        case AllInvoices:
          return 'Wszystkie faktury'
        case AllNotes:
          return 'Wszystkie noty'
        case CollectiveInvoice:
          return 'Faktury zbiorcze'
      }
    },
    []
  )

  return (
    <FormControl
      className={styles.formControl}
      style={{
        minWidth: 216,
      }}
    >
      <Select
        classes={{ root: styles.select }}
        value={filtersState.typeOfDoc}
        onChange={e => {
          setFiltersState(prev => ({
            ...prev,
            typeOfDoc: e.target.value as AccountingDocumentTypeFilter,
          }))
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {InvoiceFilters.map(filter => {
          return (
            <MenuItem key={filter} value={filter}>
              {translateFilter(filter)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

//MeasurementPoints filter

export const MeasurementPointFilter: React.FC<{
  filtersState: FilterType
  setFiltersState: React.Dispatch<React.SetStateAction<FilterType>>
  measurementPoints: {
    data: (MeasurementPointListItem | undefined)[]
    loading: boolean
  }
}> = ({ filtersState, setFiltersState, measurementPoints }) => {
  const styles = useStyles()

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 240,
      },
    },
  }

  return (
    <FormControl
      className={styles.formControl}
      style={{
        minWidth: 240,
      }}
    >
      <Select
        classes={{ root: styles.select }}
        value={filtersState.measurementPoint}
        onChange={e => {
          setFiltersState(prev => ({
            ...prev,
            measurementPoint: e.target.value as string,
          }))
        }}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={MenuProps}
      >
        <MenuItem value="">Wszystkie punkty poboru</MenuItem>
        {measurementPoints && !measurementPoints.loading ? (
          measurementPoints.data?.map((point, i) => {
            return (
              <MenuItem
                key={point?.measurementPointId || i}
                value={point?.measurementPointId}
              >
                {point?.name ??
                  (point?.addressLine1 || point?.addressLine2
                    ? `${point?.addressLine1}, ${point?.addressLine2}`
                    : point?.number)}
              </MenuItem>
            )
          })
        ) : (
          <div>Loading</div>
        )}
      </Select>
    </FormControl>
  )
}

//SORT

const sortTypes = [
  { type: AccountingDocumentsSortType.DocumentDate, desc: true },
  { type: AccountingDocumentsSortType.DocumentDate, desc: false },
  { type: AccountingDocumentsSortType.TotalAmount, desc: true },
  { type: AccountingDocumentsSortType.TotalAmount, desc: false },
  { type: AccountingDocumentsSortType.DocumentNumber, desc: true },
  { type: AccountingDocumentsSortType.DocumentNumber, desc: false },
  { type: AccountingDocumentsSortType.DueDate, desc: true },
  { type: AccountingDocumentsSortType.DueDate, desc: false },
]

export const Sort: React.FC<{
  sort: SortType
  setSort: React.Dispatch<React.SetStateAction<SortType>>
  loadInvoices: (
    customerNumberChanged?: boolean | undefined,
    args?: GetDocumentsPayload | undefined
  ) => void
}> = ({ sort, setSort, loadInvoices }) => {
  const styles = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const translateSort = useCallback((sortBy: AccountingDocumentsSortType) => {
    const {
      DocumentDate,
      DocumentNumber,
      DueDate,
      TotalAmount,
    } = AccountingDocumentsSortType
    switch (sortBy) {
      case DocumentDate:
        return 'Data dokumentu'
      case DocumentNumber:
        return 'Numer dokumentu'
      case DueDate:
        return 'Termin zapłaty'
      case TotalAmount:
        return 'Kwota'
    }
  }, [])

  return (
    <>
      <div className={styles.sort}>
        <h3>Sortowanie:</h3>
        <Button
          aria-controls="sort-menu"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(e.currentTarget)
          }
        >
          {translateSort(sort.sortBy)} ({sort.sortDesc ? 'Malejąco' : 'Rosnąco'}
          ) {Boolean(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </div>
      <Menu
        id="sort-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        {sortTypes.map((sortItem, i) => {
          const { type, desc } = sortItem
          return (
            <MenuItem
              key={sortItem.type + i}
              onClick={() => {
                if (sort.sortBy !== type || sort.sortDesc !== desc) {
                  setSort({
                    sortBy: type,
                    sortDesc: desc,
                  })
                  loadInvoices(false, {
                    start: 0,
                    sortBy: type,
                    sortDesc: desc,
                  })
                }
                setAnchorEl(null)
              }}
            >
              {translateSort(type)} {desc ? '(Malejąco)' : '(Rosnąco)'}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
