import React from 'react'
import { Modal as BaseModal, Grid } from '@material-ui/core'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import { CLOSE, SAVE, SEND_REPORT, REPORT_SENT_SUCCESSFULLY } from './strings'
import { useApi } from '@core/apiClient/apiClient'
import { hideModal, showModal } from '@core/store/ui/actions'
import Button from './Button'
import ReportForm from './ReportForm'
import { IReportFormValues } from './ReportForm/ReportForm'
import Text from './Text'
import Icon from './Icon'
import icons, { ModalIconTypes } from './Icon/icons'
import useStyles from './Modal.style'
import { useSnackbar } from 'notistack'

interface IModalProps {
  isOpen?: boolean
  className?: string
}

const Modal: React.FC<IModalProps> = ({
  children,
  isOpen: isModalOpen,
  className = '',
}) => {
  const styles = useStyles()
  const {
    isOpen,
    type,
    withCancelButton,
    withSubmitButton,
    cancelButtonText,
    submitButtonText,
    subtitleWithMargin,
    onCancel,
    onSubmit,
    subtitle,
    smallSubtitle,
    title,
    text,
    text2,
  } = useAppSelector(state => state.ui.modal)
  const dispatch = useAppDispatch()
  const [executeSendReport, isReportSending] = useApi({
    omitConsents: true,
    config: {
      method: 'post',
      url: 'CustomerTickets',
    },
  })

  const { enqueueSnackbar } = useSnackbar()

  const onCancelClick = async () => {
    if (onCancel) await onCancel()
    dispatch(hideModal())
  }
  const onSubmitClick = async () => {
    if (onSubmit) return onSubmit()
    dispatch(hideModal())
  }

  const getButtonTitle = (buttonType: 'cancel' | 'save') => {
    if (buttonType === 'cancel') return CLOSE
    if (buttonType === 'save' && type === 'error') return SEND_REPORT
    return SAVE
  }

  const showButtons = withCancelButton || withSubmitButton
  const showText = !!title || !!subtitle
  const showIcon = type && Object.keys(icons).includes(type)

  const onReportFormSubmit = async (values: IReportFormValues) => {
    try {
      await executeSendReport({
        data: {
          title: values.title,
          content: values.description,
          ticketType: 'Other',
        },
      })
      dispatch(
        showModal({
          title: REPORT_SENT_SUCCESSFULLY,
          type: 'success',
          withCancelButton: true,
          withSubmitButton: false,
          onCancel: () => {
            dispatch(hideModal())
          },
        })
      )
    } catch (error) {
      enqueueSnackbar(
        'Wysyłanie zgłoszenia błędu nie powiodło się, spróbuj ponownie lub skontaktuj się z biurem obsługi jeśli sytuacja powtarza się.',
        { variant: 'error' }
      )
    }
  }

  const getContent = () => {
    if (children) return children
    if (type === 'report')
      return (
        <ReportForm
          callPending={!!isReportSending}
          onSubmit={onReportFormSubmit}
          onCancel={() => dispatch(hideModal())}
          err={text as string}
        />
      )
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        className={styles.content}
      >
        {showIcon && (
          <div className={styles.iconWrapper}>
            <Icon type={type as ModalIconTypes} />
          </div>
        )}
        {showText && (
          <Grid
            container
            direction="column"
            alignItems="center"
            className={styles.textWrapper}
          >
            {!!title && <Text title>{title}</Text>}
            {!!subtitle && (
              <Text
                small={type === 'info' || smallSubtitle}
                className={
                  type === 'error' && !subtitleWithMargin ? styles.noMargin : ''
                }
              >
                {subtitle}
              </Text>
            )}
            {!!text && <Text small>{text}</Text>}
            {!!text2 && <Text small>{text2}</Text>}
          </Grid>
        )}
        {showButtons && (
          <div className={styles.buttonsWrapper}>
            {withCancelButton && (
              <Button onClick={onCancelClick}>
                {cancelButtonText || getButtonTitle('cancel')}
              </Button>
            )}
            {withSubmitButton && (
              <Button type="save" onClick={onSubmitClick}>
                {submitButtonText || getButtonTitle('save')}
              </Button>
            )}
          </div>
        )}
      </Grid>
    )
  }

  return (
    <BaseModal
      open={isModalOpen !== undefined ? isModalOpen : isOpen}
      BackdropProps={{ className: styles.backdrop }}
      disableScrollLock={true}
    >
      <div className={`${styles.wrapper} ${className} `}>{getContent()}</div>
    </BaseModal>
  )
}

export default Modal
