import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  iframe: {
    border: 'none',
  },
}))

export const Animation: React.FC<{ src: string; customClass?: string }> = ({
  src,
  customClass,
}) => {
  const styles = useStyles()

  return (
    <iframe
      title="animation"
      className={`${customClass} ${styles.iframe}`}
      src={src}
    />
  )
}
