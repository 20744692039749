import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { EntityReducers } from '../EntityReducers'
import {
  getBaseState,
  getEntityAdapterReducers,
} from '../helpers/entityReducerHelpers'
import { getAllExtraReducers } from '../helpers/thunks/getAllThunk'
import { readingsGetAll } from './readingsThunks'
import { addReadingsSideEffects } from './readingsSideEffects'
import { Reading } from '@shared/contracts/models'

const entityAdapter = createEntityAdapter<Reading>()

export const readingsSlice = createSlice({
  name: EntityReducers.readings,
  initialState: {
    ...getBaseState(entityAdapter),
  },
  reducers: {
    ...getEntityAdapterReducers<Reading>(entityAdapter),
  },
  extraReducers: builder => {
    getAllExtraReducers<Reading>(
      readingsGetAll,
      builder,
      entityAdapter,
      response =>
        response.map((el, index) => {
          el.id = index
          return el
        })
    )
  },
})

addReadingsSideEffects()

export const readingsActions = readingsSlice.actions
