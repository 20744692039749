import React from 'react'
import {
  StyledButton as BaseButton,
  IStyledButtonProps,
} from '@shared/components/Button'
import useStyles from './Button.style'

export type ButtonType = 'close' | 'save'

export interface IButtonProps extends Omit<IStyledButtonProps, 'type'> {
  type?: ButtonType
  submit?: boolean
}

const Button: React.FC<IButtonProps> = ({
  children,
  type = 'close',
  onClick,
  submit,
  className,
  ...props
}) => {
  const styles = useStyles()
  const isCloseButton = type === 'close'
  return (
    <BaseButton
      variant={isCloseButton ? 'outlined' : 'contained'}
      color={isCloseButton ? undefined : 'primary'}
      className={`${
        isCloseButton ? styles.closeButton : styles.saveButton
      } ${className}`}
      onClick={onClick}
      type={submit ? 'submit' : undefined}
      {...props}
    >
      {children}
    </BaseButton>
  )
}

export default Button
