import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'

export const LAST_BUTTON_WIDTH = 128
const PDF_BTN_WIDTH = 76
const BTN_HEIGHT = 39
const CHECKBOX_SIZE = 32

export default makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(7, 0, 3),
      paddingTop: theme.spacing(7),
      borderTop: '1.8px solid #5F76FD1A',
      '& div': {
        display: 'flex',
        alignItems: 'center',
        '&:first-child': {
          '& svg': {
            width: 24,
            height: 24,
            margin: theme.spacing(0, 4, 0, 0),
            fill: theme.palette.primary.main,
          },
          '& h2': {
            margin: 0,
            fontWeight: 700,
            fontSize: 19,
            color: tableTextColor,
            letterSpacing: '0.16px',
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& div:first-child': {
          marginBottom: theme.spacing(1),
        },
        '& div:last-child': {
          marginLeft: theme.spacing(9),
        },
      },
      [theme.breakpoints.down('xs')]: {
        '& div:last-child': {
          marginLeft: 0,
          flexDirection: 'column',
        },
      },
    },
    resizableTableContainer: {
      overflowY: 'hidden',
      transition: 'height 0.8s ease-in-out',
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      top: 0,
      left: 0,
    },
    invoicesNotFound: {
      display: 'flex',
      alignItems: 'center',
      height: '102px',
      opacity: 0.55,
      fontSize: 19,
      color: tableTextColor,
      letterSpacing: '0.16px',
      fontWeight: theme.typography.fontWeightBold,
    },
    table: {
      margin: 0,
      minWidth: 1100,
      borderRadius: '8px',
      '& .MuiTableCell-root': {
        border: 0,
      },
      '& .MuiTableCell-body': {
        alignItems: 'center',
        padding: theme.spacing(3, 1),
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(3, 1, 2, 1),
        },
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        minWidth: 900,
      },
    },
    bodyCell: {
      color: tableTextColor + colorOpacityParser(75),
      fontSize: 16,
      letterSpacing: '0.61px',
      lineHeight: '20px',
    },
    checkboxStyles: {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      width: CHECKBOX_SIZE,
      minWidth: CHECKBOX_SIZE,
    },
    lastCell: {
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12,
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0px !important',
        display: 'inline-block',
        position: 'sticky',
        left: '50%',
        top: 0,
        transform: 'translateX(-50%)',
      },
    },
    buttonsCell: {
      display: 'flex',
      justifyContent: 'center',
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    pdfBtn: {
      '& svg': {
        width: '20px',
        height: '20px',
        color: 'black',
        cursor: 'pointer',
      },
      width: PDF_BTN_WIDTH,
      height: BTN_HEIGHT,
      '& button': {
        width: '100%',
        height: '100%',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: '19px',
        border: '2.7px solid #DAD9D9',
        textDecoration: 'none',
        outline: 'none',
        transition: 'border 0.2s ease-in-out',
      },
    },
    payInvoiceBtn: {
      width: LAST_BUTTON_WIDTH,
      height: BTN_HEIGHT,
      marginLeft: theme.spacing(4),
      '& button': {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontSize: 14,
        boxShadow: 'none',
      },
    },
    checkedIcon: {
      width: '14px',
      height: '14px',
      borderRadius: '3px',
      backgroundColor: theme.palette.primary.dark,
    },
    checkedCell: {
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    amountCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '42px',
      minWidth: '112px',
      margin: theme.spacing(0, 5),
      borderRadius: '5px',
      fontSize: 17,
      letterSpacing: '0.64px',
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 3),
      },
    },
    tableRow: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        display: 'inline-block',
      },
    },
    sumAmount: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '60px',
      color: '#566778',
      marginTop: theme.spacing(4),
      '& > div': {
        '& h3': {
          margin: theme.spacing(0, 0, 1),
          opacity: 0.8,
          fontSize: 14,
          letterSpacing: '0.2px',
        },
        '& p': {
          margin: 0,
          fontWeight: 700,
          fontSize: 21,
          color: tableTextColor,
          letterSpacing: '0.4px',
        },
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    payAllBtn: {
      marginLeft: theme.spacing(9),
      width: '152px',
      height: '42px',
      padding: theme.spacing(0, 1),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(2, 0, 0),
      },
    },
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(9),
    },
    pagination: {
      '& li': {
        '& button': {
          '&.MuiPaginationItem-textPrimary.Mui-selected': {
            color: theme.palette.common.white,
          },
        },
      },
    },
  })
)
