import { IReportFormErrors, IReportFormValues } from './ReportForm'
import { DESCRIPTION_LENGTH, REQUIRED, TITLE_LENGTH } from './strings'

export const MAX_TITLE_LENGTH = 80
export const MAX_DESCRIPTION_LENGTH = 500

export const validate = (values: IReportFormValues) => {
  const errors: IReportFormErrors = {}
  if (!values.title) {
    errors.title = REQUIRED
  } else if (values.title.length > MAX_TITLE_LENGTH) {
    errors.title = TITLE_LENGTH(MAX_TITLE_LENGTH)
  }
  if (!values.description) {
    errors.description = REQUIRED
  } else if (values.title.length > MAX_DESCRIPTION_LENGTH) {
    errors.title = DESCRIPTION_LENGTH(MAX_DESCRIPTION_LENGTH)
  }
  return errors
}
