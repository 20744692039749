import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  wrapper: {
    overflow: 'hidden',
    '& .slick-list': {
      height: '100% !important',
    },
  },
  toggler: {
    marginTop: theme.spacing(4),
    justifyContent: 'center',
  },
  emptyToggler: {
    height: 14
  }
}))
