import { createSlice } from '@reduxjs/toolkit'
import { Account } from '@shared/contracts/models'
import {
  initialLoadingState,
  LoadingState,
} from '../entity/helpers/entityReducerHelpers'
import { NewAddressPayload } from '../entity/userAgreements/types'
import { StoreReducers } from '../StoreReducers'
import { BaseAction } from '../types'
import { addUserAccountSideEffects } from './userAccountSideEffects'
import { getUserAccoutExtraReducers } from './userAccountThunks'

export interface UserAccountState extends LoadingState {
  account?: Account
  accessToken?: string
}

type RefreshContactPhoneNumberAction = BaseAction & {
  payload: {
    newPhoneNumber: string
  }
}

type RefreshCorrespondenceEmailAction = BaseAction & {
  payload: {
    newEmailAddress: string
  }
}

type RefreshAddressAction = BaseAction & {
  payload: NewAddressPayload
}

export const userAccountSlice = createSlice({
  name: StoreReducers.userAccount,
  initialState: {
    account: undefined,
    accessToken: undefined,
    ...initialLoadingState,
  } as UserAccountState,
  reducers: {
    refreshContactPhoneNumber: (
      state,
      action: RefreshContactPhoneNumberAction
    ) => {
      if (state.account && action.payload) {
        state.account.contactPhoneNumber = action.payload.newPhoneNumber
      }
    },
    refreshCorrespondenceEmail: (
      state,
      action: RefreshCorrespondenceEmailAction
    ) => {
      if (state.account && action.payload) {
        state.account.correspondenceEmail = action.payload.newEmailAddress
      }
    },
    refreshMainAddress: (state, action: RefreshAddressAction) => {
      if (state.account && action.payload) {
        state.account.mainAddressLine1 = action.payload.newAddressLine1
        state.account.mainAddressLine2 = action.payload.newAddressLine2
      }
    },
    refreshCorrespondenceAddress: (state, action: RefreshAddressAction) => {
      if (state.account && action.payload) {
        state.account.correspondenceAddressLine1 =
          action.payload.newAddressLine1
        state.account.correspondenceAddressLine2 =
          action.payload.newAddressLine2
      }
    },
    resetAccessToken: (state, action) => {
      state.accessToken = undefined
    },
  },
  extraReducers: builder => {
    getUserAccoutExtraReducers(builder)
  },
})

addUserAccountSideEffects()

export const userAccountActions = userAccountSlice.actions
