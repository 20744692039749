import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { values } from 'ramda'
import { AgreementStatus } from '@shared/contracts/agreementStatus'
import { InvoiceType } from '@shared/contracts/invoiceType'

export const userAgreementsSelector = (state: RootState) => state.userAgreements

export const userAgreementsLoadingSelector = createSelector(
  userAgreementsSelector,
  state => state.loadingPending > 0
)

export const userAgreementsDictionarySelector = createSelector(
  userAgreementsSelector,
  state => state.entities
)

export const userAgreementsDataSelector = createSelector(
  userAgreementsSelector,
  state => values(state.entities)
)

export const getActiveAgreementsSelector = createSelector(
  userAgreementsDataSelector,
  userAgreements =>
    userAgreements.filter(
      agreement => agreement?.status === AgreementStatus.Active
    )
)

export const getHistoricalAgreementsSelector = createSelector(
  userAgreementsDataSelector,
  userAgreements =>
    userAgreements.filter(
      agreement => agreement?.status === AgreementStatus.Historic
    )
)


export const getNoEInvoiceAgreementsSelector = createSelector(
  getActiveAgreementsSelector,
  userAgreements =>
    userAgreements.filter(
      agreement => agreement?.invoiceType !== InvoiceType.Email
    )
)

export const getEInvoiceAgreementsSelector = createSelector(
  getActiveAgreementsSelector,
  userAgreements =>
    userAgreements.filter(
      agreement => agreement?.invoiceType === InvoiceType.Email
    )
)
