import { AgreementListItem } from '@shared/contracts/agreementListItem'
import { MeasurementPointListItem } from '@shared/contracts/measurementPointListItem'
import { getAgreementsIds } from './measurementPoints'

const getAgreementsAssignedToEmail = (
  email: string,
  agreements: (AgreementListItem | undefined)[]
) => {
  return (
    agreements &&
    agreements.filter((agreement: AgreementListItem | undefined) => {
      return (
        agreement &&
        agreement?.emailAddresses &&
        agreement?.emailAddresses[0] === email
      )
    })
  )
}

const getAgreementsAssignedToOtherEmailThan = (
  email: string,
  agreements: (AgreementListItem | undefined)[]
) => {
  return (
    agreements &&
    agreements.filter((agreement: AgreementListItem | undefined) => {
      return (
        agreement &&
        agreement?.emailAddresses &&
        agreement?.emailAddresses[0] !== email
      )
    })
  )
}

const isEmailAssignedToAgreements = (
  email: string,
  agreements: (AgreementListItem | undefined)[]
) => {
  return getAgreementsAssignedToEmail(email, agreements).length > 0
}

const isMeasurementPointAssignedToAgreement = (
  measurementPoint: MeasurementPointListItem,
  agreements: (AgreementListItem | undefined)[]
) => {
  const agreementsFound = agreements?.find(
    (agreement: AgreementListItem | undefined) =>
      agreement?.agreementId === measurementPoint?.agreementId
  )
  return !!agreementsFound
}

const getMeasurementPointsAgreementsNumbers = (
  measurementPoints: MeasurementPointListItem[],
  agreements: (AgreementListItem | undefined)[]
) => {
  if (!agreements) {
    return undefined
  }
  const ids = getAgreementsIds(measurementPoints)
  return agreements
    .filter(
      (agreement: AgreementListItem | undefined) =>
        agreement && ids.includes(agreement.agreementId)
    )
    .map(
      (agreement: AgreementListItem | undefined) =>
        agreement && agreement.documentNumber
    )
}
const getUniqueEmails = (agreements: (AgreementListItem | undefined)[]) => {
  let emails: string[] = []
  agreements?.forEach(
    (agreement: AgreementListItem | undefined) =>
      (emails = [...emails, ...(agreement?.emailAddresses ?? [])])
  )

  return [...Array.from(new Set(emails))]
}

const isEInvoiceEmailsLimitExceeded = (
  agreements: (AgreementListItem | undefined)[],
  email: string, 
  correspondentialEmail: string | undefined
) => {
  const uniqueEmails = getUniqueEmails(agreements)
  if ((uniqueEmails.length === 2 && !uniqueEmails.includes(email))
    || (uniqueEmails.length === 1 && !uniqueEmails.includes(correspondentialEmail ?? ''))) {
    return true
  }
  return false
}

export {
  getAgreementsAssignedToEmail,
  isEmailAssignedToAgreements,
  isMeasurementPointAssignedToAgreement,
  getMeasurementPointsAgreementsNumbers,
  isEInvoiceEmailsLimitExceeded,
  getUniqueEmails,
  getAgreementsAssignedToOtherEmailThan,
}

