import { AxiosResponse } from 'axios'

export const getFileName = (response: AxiosResponse<Blob>) => {
  const contentDisposition = response.headers['content-disposition']
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(contentDisposition)
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '')
  }
  return ''
}
