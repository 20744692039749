import { actionsInterceptorMiddleware } from '@core/store/middleware/actionsInterceptorMiddleware'
import { USER_FOUND } from 'redux-oidc'
import { customerNumberActions } from '../customerNumbers/customerNumbers'
import { userNotificationsGetAll } from './userNotificationsThunks'

export const addUserNotificationsSideEffects = () => {
  actionsInterceptorMiddleware.addManyActionsCallback([
    {
      actionTypes: [USER_FOUND, customerNumberActions.setCustomerNumber.type],
      callback: ({ dispatch, state }) => {
        if (!state.userConsents.loadingPending) {
          dispatch(userNotificationsGetAll())
        }
      },
    },
  ])
}
