import React from 'react'
import { Grid } from '@material-ui/core'
import { TableCellContent } from '@shared/components/TableCell'
import { InvoiceType } from '@shared/contracts/models'
import useStyles from './DocumentDetails.style'
import { useTranslation } from 'react-i18next'

interface IDocumentDetailsProps {
  name: string
  selectedPointNumber: string
  address: JSX.Element | string
  invoiceType: string
  agreementType: string
  invoiceTypeContent: JSX.Element
  tariff: string
  endDate: string
  settlementType: string
  correspondenceEmail: string
  correspondenceAddress: JSX.Element
}

export const DocumentDetails: React.FC<IDocumentDetailsProps> = ({
  name,
  selectedPointNumber,
  address,
  invoiceType,
  agreementType,
  invoiceTypeContent,
  tariff,
  endDate,
  settlementType,
  correspondenceEmail,
  correspondenceAddress,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction="column" className={styles.table}>
      <Grid container>
        <Grid item xs={12} sm={6} md={7} className={styles.gridElement}>
          <TableCellContent
            header={t('MY_CONTRACTS.FIELD_NAMES.CUSTOMER_DATA')}
            content={name ?? ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5} className={styles.gridElement}>
          <TableCellContent
            header={
              invoiceType === InvoiceType.Email
                ? t('MY_CONTRACTS.FIELD_NAMES.EMAIL_INVOICES')
                : t('MY_CONTRACTS.FIELD_NAMES.ADDRESS_INVOICES')
            }
            content={
              invoiceType === InvoiceType.Email
                ? correspondenceEmail ?? 'Nie podano'
                : correspondenceAddress
            }
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={7} className={styles.gridElement}>
          <TableCellContent
            header={t('MY_CONTRACTS.FIELD_NAMES.AGREEMENT_TYPE')}
            content={agreementType}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5} className={styles.gridElement}>
          <TableCellContent
            header={t('MY_CONTRACTS.FIELD_NAMES.INVOICE')}
            content={invoiceTypeContent}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={7} className={styles.gridElement}>
          <TableCellContent
            header={t('MY_CONTRACTS.FIELD_NAMES.POINT_ADDRESS')}
            content={address}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5} className={styles.gridElement}>
          <TableCellContent
            header={t('MY_CONTRACTS.FIELD_NAMES.MEASUREMENT_POINT_NUMBER')}
            content={selectedPointNumber}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={3}
          md={4}
          className={`${styles.gridElement} ${styles.flexEndAlignment}`}
        >
          <TableCellContent
            header={t('MY_CONTRACTS.FIELD_NAMES.TARIFF_GROUP')}
            content={tariff}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className={`${styles.gridElement} ${styles.flexEndAlignment}`}
        >
          <TableCellContent
            header={t('MY_CONTRACTS.FIELD_NAMES.DOC_END')}
            content={endDate}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          className={`${styles.gridElement} ${styles.flexEndAlignment}`}
        >
          <TableCellContent
            header={t('MY_CONTRACTS.FIELD_NAMES.BILLING_PERIOD')}
            content={settlementType}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
