import { withStyles, Theme, withTheme } from '@material-ui/core'
import StyledButton from '@shared/components/Button'

export const SubmitButton = withStyles((theme: Theme) => ({
  root: {
    alignSelf: 'center',
    minWidth: '180px',
    height: '48px',
    fontSize: 17,
    marginBottom: '15px',
    marginTop: '20px',
    padding: '0 40px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))(withTheme(StyledButton))
