import { Card, CardHeader, CardContent, IconButton } from '@material-ui/core'
import React from 'react'
import useStyles from './SelectedMeasurementPoint.style'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CollectionPoint } from '@images/icons/other/CollectionPoint.svg'

interface SelectedMeasurementPointProps {
  number?: string
  onDelete?: () => void
  disabled?: boolean
  address?: string
  isDelete?: boolean
  className?: string
  empty?: boolean
}

export const autocompleteWrapperPadding = 28

export const SelectedMeasurementPoint: React.FC<SelectedMeasurementPointProps> = ({
  number,
  onDelete,
  disabled,
  address,
  isDelete = true,
  className,
  empty = false,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Card className={className}>
        {empty ? (
          <CardContent className={styles.emptyCard}>
            <span className={`${styles.avatar} ${styles.emptyCardAvatar}`}>
              <CollectionPoint />
            </span>
            <span>
              {t('MY_INVOICES.E_INVOICE.NO_MEASUREMENT_POINTS_SELECTED')}
            </span>
          </CardContent>
        ) : (
          <>
            <CardHeader
              avatar={
                <div className={styles.avatar}>
                  <CollectionPoint />
                </div>
              }
              title={
                number ? (
                  <div className={styles.title}>
                    <div className={styles.numberHeading}>
                      {t('MY_CONTRACTS.POINT_NUMBER')}
                    </div>
                    <div className={styles.number}>{number}</div>
                  </div>
                ) : (
                  ''
                )
              }
              action={
                isDelete && (
                  <IconButton
                    onClick={() => onDelete && onDelete()}
                    disabled={disabled}
                    className={styles.delete}
                  >
                    <Close />
                  </IconButton>
                )
              }
              className={styles.cardHeader}
            />
            <CardContent className={styles.cardContent}>{address}</CardContent>
          </>
        )}
      </Card>
    </React.Fragment>
  )
}
