import React from 'react'
import {
  SWITCH_OFF_COLOR,
  SWITCH_ON_COLOR,
  SWITCH_OFF_HANDLE_COLOR,
  SWITCH_ON_HANDLE_COLOR,
  SWITCH_HEIGHT,
  SWITCH_WIDTH,
  SWITCH_HANDLE_DIAMETER,
} from './Switch.style'

import SwitchBase, { ReactSwitchProps } from 'react-switch'

export interface ISwitchProps
  extends Omit<
    ReactSwitchProps,
    | 'uncheckedIcon'
    | 'checkedIcon'
    | 'offColor'
    | 'onColor'
    | 'offHandleColor'
    | 'onHandleColor'
  > {}

export const Switch: React.FC<ISwitchProps> = props => {
  return (
    <SwitchBase
      checkedIcon={false}
      uncheckedIcon={false}
      onColor={SWITCH_ON_COLOR}
      offColor={SWITCH_OFF_COLOR}
      onHandleColor={SWITCH_ON_HANDLE_COLOR}
      offHandleColor={SWITCH_OFF_HANDLE_COLOR}
      activeBoxShadow="0 0 0 0"
      width={SWITCH_WIDTH}
      height={SWITCH_HEIGHT}
      handleDiameter={SWITCH_HANDLE_DIAMETER}
      {...props}
    />
  )
}
