import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { values } from 'ramda'

export const userNotificationsSelector = (state: RootState) =>
  state.userNotifications

export const allNotificationssSelector = createSelector(
  userNotificationsSelector,
  state => values(state.entities)
)

export const notificationsLoadingSelector = createSelector(
  userNotificationsSelector,
  notifications => {
    return notifications.loadingPending > 0
  }
)
