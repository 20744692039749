import {
  PasswordRequirements,
  passReqNames,
  Section,
  fieldNames,
} from '../../Register/static-data/RegisterData'

export const NewPassData: Section = {
  header: 'Ustaw nowe hasło',
  subHeader:
    'Po zapisaniu automatycznie przeniesiemy Cię na stronę logowania do panelu Moja Polenergia',
  inputs: [
    {
      name: fieldNames.PASSWORD,
      title: 'NOWE HASŁO',
      placeholder: 'Wpisz nowe hasło',
      errorMsg: () => 'Wpisz poprawne hasło',
      validation: el => {
        return (
          !PasswordRequirements[passReqNames.DIGIT].regex.test(el) ||
          !PasswordRequirements[passReqNames.LETTERS].regex.test(el) ||
          !PasswordRequirements[passReqNames.LENGTH].regex.test(el)
        )
      },
    },
    {
      name: fieldNames.CONFIRMED_PASSWORD,
      title: 'POWTÓRZ NOWE HASŁO',
      placeholder: 'Wpisz ponownie nowe hasło',
      errorMsg: () => 'Hasło powinno być takie samo jak powyższe',
    },
    {
      name: fieldNames.MFA_CODE,
      title: 'WPROWADŹ KOD MFA',
      placeholder: 'Kod MFA',
      errorMsg: () => 'Kod MFA składa się z 6 cyfr, bez spacji i łączników',
    },
  ],
}
