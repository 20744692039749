import { makeStyles, Theme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import { duration } from '@material-ui/core/styles/transitions'
import { tableTextColor } from '@mbok/styles/constants'

const INPUT_HEIGHT = 40

const getInputStyles = (theme: Theme): CreateCSSProperties<{}> => ({
  height: INPUT_HEIGHT,
  width: '100%',
  border: '1.8px solid',
  borderColor: '#CECECE',
  backgroundColor: '#F9F9F9',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(1.5),
  boxSizing: 'border-box',
  transition: `border-color ${duration.standard / 1000}s`,
  borderRadius: 4,
  '& *': {
    width: '100%',
    height: '100%',
  },
  '& input': {
    fontSize: 15,
    letterSpacing: 0.2,
    color: tableTextColor,
    '&::placeholder': {
      color: tableTextColor,
      opacity: 0.55,
      letterSpacing: 0.2,
    },
  },
})

export default makeStyles(theme => ({
  input: {
    ...getInputStyles(theme),
  },
  inputError: {
    ...getInputStyles(theme),
    borderColor: theme.palette.error.main,
    '& .MuiInputBase-adornedStart': {
      border: 'none !important',
    },
    '& .MuiInputAdornment-positionStart': {
      width: 'initial !important',
      height: 'initial !important',
      marginLeft: theme.spacing(-5),
    },
  },
  label: {
    marginBottom: theme.spacing(2),
    color: tableTextColor,
    opacity: '0.55 !important',
    fontSize: 14,
    lineHeight: '18px',
    marginRight: theme.spacing(0.75),
  },
  errorMessageBottom: {
    width: 'auto',
    height: 16,
  },
}))
