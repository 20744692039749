import { useApi } from '@core/apiClient/apiClient'
import React from 'react'

export interface SearchResults {
  cityName: string
  streets: string[]
}

export const useEditAddressFormDataSource = (
  setIsAddressInDatabase: (isAddressInDatabase: boolean) => void
) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [cities, setCities] = React.useState<string[] | undefined>(undefined)
  const [streets, setStreets] = React.useState<string[] | undefined>(undefined)
  const [results, setResults] = React.useState<SearchResults[] | undefined>(
    undefined
  )

  const getCities = (results: SearchResults[]) => {
    return results.map((entry: SearchResults) => entry.cityName)
  }

  const getStreets = (city: string, results?: SearchResults[]) => {
    if (!results) {
      return undefined
    }
    for (const entry of results) {
      if (entry.cityName === city) {
        return entry.streets
      }
    }
    return undefined
  }

  const [executeGetAddresses] = useApi<SearchResults[]>({
    omitCustomerNumber: true,
    config: {
      method: 'get',
    },
  })

  const getAddresses = React.useCallback(
    (postalCode: string, city: string) => {
      setLoading(true)
      executeGetAddresses({ url: `Address/${postalCode}` })
        .then(response => {
          if (response.status === 404) {
            setResults(undefined)
            setCities(undefined)
            setStreets(undefined)
            setIsAddressInDatabase(false)
          }
          const addresses = response.data
          setResults(addresses)
          setCities(getCities(addresses))
          setStreets(city ? getStreets(city, addresses) : undefined)
          setIsAddressInDatabase(true)
        })
        .catch(() => {
          setCities(undefined)
          setStreets(undefined)
          setIsAddressInDatabase(false)
        })
      setLoading(false)
    },
    [executeGetAddresses, setIsAddressInDatabase]
  )

  return {
    address: {
      getAddresses,
      getCities,
      getStreets,
    },
    loading: {
      data: loading,
      set: setLoading,
    },
    cities: {
      data: cities,
      set: setCities,
    },
    streets: {
      data: streets,
      set: setStreets,
    },
    results,
  }
}
