import { makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

export default makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: theme.spacing(5),
  },
  subHeading: {
    color: tableTextColor,
    fontSize: 14,
    opacity: 0.55,
    lineHeight: '18px',
    letterSpacing: 0.61,
    marginBottom: theme.spacing(1),
  },
  text: {
    color: tableTextColor,
    opacity: 0.75,
    fontSize: 17,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.64,
    lineHeight: '21px',
    display: 'inline-block'
  },
  item: {
    padding: theme.spacing(0, 4.5),
    borderRight: '1.8px solid #E2E8ED',
  },
  correspondenceEmail: {
    '& *': {
      padding: 0,
      border: 'none',
    },
    marginTop: theme.spacing(2),
  },
  row: {
    '& > :first-child': {
      paddingLeft: 0,
    },
    '& > :last-child': {
      paddingRight: 0,
      borderRight: 'none',
    },
  },
}))
