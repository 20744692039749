import { AgreementListItem } from '@shared/contracts/agreementListItem'
import { MeasurementPointListItem } from '@shared/contracts/measurementPointListItem'
import { getConfirmationAddress } from './addressParser'

const getMeasurementPointsNumbers = (
  points: (MeasurementPointListItem | undefined)[]
): string[] => {
  return points.map(point => point?.number ?? '')
}

const getAgreementsIds = (
  items: (AgreementListItem | MeasurementPointListItem | undefined)[]
) => {
  return items.map(
    (agreement: AgreementListItem | MeasurementPointListItem | undefined) =>
      agreement?.agreementId
  )
}

const getMeasurementPointsFilteredByAgreements = (
  measurementPoints: MeasurementPointListItem[],
  agreements: (AgreementListItem | undefined)[]
) => {
  const agreementsIds = getAgreementsIds(agreements)
  return measurementPoints.filter((point: MeasurementPointListItem) =>
    agreementsIds.includes(point.agreementId)
  )
}

const getMeasurementPointsFilteredByEmail = (
  measurementPoints: MeasurementPointListItem[],
  agreements: (AgreementListItem | undefined)[],
  email: string
) => {
  // eslint-disable-next-line
  return measurementPoints.filter((point: MeasurementPointListItem) => {
    for (const agreement of agreements) {
      if (
        agreement?.agreementId === point.agreementId &&
        agreement.emailAddresses?.includes(email)
      ) {
        return point
      }
    }
  })
}

const stringifyMeasurementPoint = (option: MeasurementPointListItem) => {
  return getConfirmationAddress({
    line1: option.addressLine1 ?? '',
    line2: option.addressLine2 ?? '',
  })
}

export {
  getMeasurementPointsNumbers,
  getAgreementsIds,
  getMeasurementPointsFilteredByAgreements,
  stringifyMeasurementPoint,
  getMeasurementPointsFilteredByEmail,
}
