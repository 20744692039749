import i18n from '@i18n/index'
import React, { useMemo } from 'react'

const wrappedSpan = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  whiteSpace: 'normal',
} as React.CSSProperties

export const AddressParser: React.FC<{
  a1?: string | null
  a2?: string | null
}> = ({ a1 = '', a2 = '' }) => {
  const a1Line = useMemo(
    () =>
      a1 && (
        <span style={wrappedSpan}>
          {typeof a1 === 'string' && a1?.startsWith('inne') ? a1.replace(i18n().t('PREFIXES.OTHER_STREET_TYPE'), '') : `${a1}`}
        </span>
      ),
    [a1]
  )

  return (
    <>
      {Boolean(a1) || Boolean(a2) ? (
        <>
          {a1Line}
          {a2 && <span>{a2}</span>}
        </>
      ) : (
        <>{i18n().t('MY_SETTINGS.NOT_PROVIDED')}</>
      )}
    </>
  )
}
