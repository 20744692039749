import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router'
import { useAnalyticsState } from './useAnalyticsState'

export const usePageViews = () => {
  let location = useLocation()
  const [state] = useAnalyticsState()

  useEffect(() => {
    if (state) {
      ReactGA.event(location.pathname + location.search)
    }
  }, [location, state])
}
