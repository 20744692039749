import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const BTN_HEIGHT = 40
const BTN_WIDTH = 148

export default makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(5),
    },
    container: {
      padding: 0,
      marginBottom: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h1': {
        fontSize: 22,
        letterSpacing: 0.2,
        margin: theme.spacing(0, 0, 8),
        textAlign: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(8),
        '& h1': {
          marginBottom: theme.spacing(8),
        },
      },
    },
    content: {
      width: '100%',
      textAlign: 'center',
      fontSize: 14,
      '& ul': {
        textAlign: 'left',
        paddingLeft: 0,
        '& li': {
          marginBottom: theme.spacing(2),
        },
      },
    },
    btn: {
      width: BTN_WIDTH,
      height: BTN_HEIGHT,
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  })
)
