import React, { useState } from 'react'
import { makeStyles, Theme, useTheme } from '@material-ui/core'
import { Account, MeasurementPointListItem } from '@shared/contracts/models'
import { borderColor, tableTextColor } from '@mbok/styles/constants'
import { ToggleButton } from '../ToggleButton'
import CheckIcon from '@material-ui/icons/Check'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import MeasurementPointsModal from './modal/MeasurementPointsModal'
import StyledButton from '../Button'
import { useLoadingState } from '@shared/hooks/loadingState'
import { amountParser } from '@shared/utils/balanceParser'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '36px 48px 16px 0',
    padding: '20px 32px',
    minWidth: 356,
    maxWidth: 356,
    borderRadius: '7px',
    border: `1.7px solid ${borderColor}`,
    boxShadow: '0 7px 9px 3px rgba(105,105,105,0.03)',
  },
  header: {
    '& svg': {
      height: '14px',
      width: '14px',
      marginRight: '8px',
    },
  },
  button: {
    width: '100%',
    height: '36px',
    fontSize: 15,
    letterSpacing: '0.2px',
    fontWeight: theme.typography.fontWeightBold,
  },
  disabled: {
    cursor: 'default',
  },
  row: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    color: tableTextColor,
    letterSpacing: '0.57px',

    '&:first-child': {
      '& span': {
        opacity: 0.75,
      },
    },

    '& div': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: 15,
    },

    '& h1': {
      margin: 0,
      opacity: 0.55,
      fontSize: 13,
    },
    '& span': {
      opacity: 1,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  left: {
    textAlign: 'left',
    maxWidth: '56%',
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    maxWidth: '44%',
    justifyContent: 'space-between',
  },
  balance: {
    fontWeight: 700,
    fontSize: 21,
    letterSpacing: '0.4px',
  },
  measurementBtn: {
    display: 'flex',
    alignItems: 'center',
    opacity: 1,
    color: theme.palette.primary.main,
    height: '29.6px',
    transition: 'color 0.2s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
    '& svg': {
      marginRight: 0,
      marginLeft: '4px',
      width: '12px',
      height: '12px',
    },
  },
}))

interface SingleCustomerNumberDataProps {
  selected?: boolean
  click: () => void
  customer: Account
  balance: number
  measurementPoints: MeasurementPointListItem[]
  loading: boolean
  loadPoints: () => void
}

export const SingleCustomerNumberData: React.FC<SingleCustomerNumberDataProps> = ({
  selected,
  click,
  customer,
  balance,
  measurementPoints,
  loading,
  loadPoints,
}) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const [showModal, setShowModal] = useState(false)

  const isMeasurementPLoading = useLoadingState(loading)

  const showPoints = () => {
    setShowModal(true)
    loadPoints()
  }

  const { name, customerNumber } = customer

  const balanceColor =
    balance < 0
      ? theme.palette.text.primary
      : balance === 0
      ? theme.palette.primary.main
      : theme.palette.error.main

  return (
    <div className={styles.container}>
      <ToggleButton
        className={`${styles.button} ${selected && styles.disabled}`}
        onClick={() => !Boolean(selected) && click()}
        value={customerNumber}
        selected={Boolean(selected)}
      >
        {selected ? (
          <span className={styles.header}>
            <CheckIcon />
            Wybrany płatnik
          </span>
        ) : (
          <span>Wybierz płatnika</span>
        )}
      </ToggleButton>
      <div className={styles.row}>
        <div className={styles.left}>
          <h1>Nazwa płatnika</h1>
          <span>{name}</span>
        </div>
        <div className={styles.right}>
          <h1>Numer płatnika</h1>
          <span>{customerNumber ?? ''}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.left}>
          <h1>Łączne saldo</h1>
          <span className={styles.balance} style={{ color: balanceColor }}>
            {amountParser(Math.abs(balance))} zł
          </span>
        </div>
        <div className={styles.right}>
          <h1>Punkty poboru</h1>
          <span className={styles.measurementBtn} onClick={showPoints}>
            <StyledButton variant="text">
              Zobacz <ArrowForwardIosIcon />
            </StyledButton>
          </span>
        </div>
      </div>
      <MeasurementPointsModal
        show={showModal}
        close={() => setShowModal(false)}
        points={measurementPoints}
        loading={isMeasurementPLoading}
        customer={{
          name: customer.name ?? '',
          number: customer.customerNumber ?? '',
        }}
      />
    </div>
  )
}
