import {
  AccountingDocumentListItem,
  AccountingDocumentListItemPagedResponse,
  AccountingDocumentsSortType,
  AccountingDocumentStatusFilter,
  AccountingDocumentTypeFilter,
} from '@shared/contracts/models'
import { useThunkApi } from '../../../apiClient/apiClient'
import { EntityReducers } from '../EntityReducers'
import { BaseGetAllPayload, getAllThunk } from '../helpers/thunks/getAllThunk'

export interface GetDocumentsPayload extends BaseGetAllPayload {
  limit?: number
  sortBy?: AccountingDocumentsSortType
  sortDesc?: boolean
  status?: AccountingDocumentStatusFilter
  type?: AccountingDocumentTypeFilter
  start?: number
  measurementPointId?: string
}

export const documentsGetAll = getAllThunk<
  AccountingDocumentListItem,
  GetDocumentsPayload,
  AccountingDocumentListItemPagedResponse
>(EntityReducers.documents, ({ thunkApi, payload }) => {
  return useThunkApi(
    {
      config: {
        method: 'get',
        url: `AccountingDocuments`,
        params: payload,
      },
    },
    thunkApi
  )
})
