import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { ICustomerNumber } from '@shared/contracts/CustomerNumbers'
import { values } from 'ramda'

export const customerNumbersSelector = (state: RootState) =>
  state.customerNumbers

export const getCustomerNumbersLoadingSelector = createSelector(
  customerNumbersSelector,
  state => state.loadingPending > 0
)

export const getAllCustomerNumbersSelector = createSelector(
  customerNumbersSelector,
  state => values(state.entities) as ICustomerNumber[]
)

export const getSelectedCustomerNumberSelector = createSelector(
  customerNumbersSelector,
  state => state.selectedCustomerNumber
)
