import ReactGA from 'react-ga4'

export const initializeGa = () => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
    ReactGA.initialize(
      [{
        trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS,
        gaOptions: {
          debug: process.env.NODE_ENV === 'development',
          titleCase: false}
      }])
  }
}
