import { withStyles, Theme, withTheme } from '@material-ui/core'
import { ToggleButton as MaterialToggle } from '@material-ui/lab'

export const ToggleButton = withStyles((theme: Theme) => ({
  root: {
    border: 'none',
    marginRight: '15px',
    padding: '8px 40px',
    borderRadius: '4px !important',
    background: theme.palette.grey[200],
    color: theme.palette.text.secondary,
    textTransform: 'none',
  },
  selected: {
    backgroundColor: theme.palette.primary.main + ' !important',
    color: theme.palette.common.white + ' !important',
  },
}))(withTheme(MaterialToggle))
