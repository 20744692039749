import { useApi } from '@core/apiClient/apiClient'
import { useDispatchOnCustomerNumberChange } from '@core/store/customerNumberDispatcher'
import {
  getAllUnpaidDocumentsSelector,
  getDocumentsLoadingSelector,
} from '@core/store/entity/documents/documentsSelector'
import { paymentsLoadingSelector } from '@core/store/payments/paymentsSelectors'
import { getOffers } from '@core/store/offers/offersThunks'
import { documentsGetAll } from '@core/store/entity/documents/documentsThunks'
import { measurementPointsGetAll } from '@core/store/entity/measurementPoints/measurementPointsThunks'
import { newsGetAll } from '@core/store/entity/news/newsThunks'
import {
  getActiveAgreementsSelector,
  getHistoricalAgreementsSelector,
  userAgreementsDictionarySelector,
  userAgreementsLoadingSelector,
} from '@core/store/entity/userAgreements/userAgreementsSelectors'
import { userAgreementsGetAll } from '@core/store/entity/userAgreements/userAgreementsThunks'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  AccountBalance,
  AccountingDocumentListItem,
  AccountingDocumentsSortType,
} from '@shared/contracts/models'
import { useCallback, useState } from 'react'

export const useDataSource = () => {
  const dispatch = useAppDispatch()
  const account = useAppSelector(state => ({
    data: state.userAccount.account,
    loading: state.userAccount.loadingPending > 0,
  }))

  const [unreadNews, setUnreadNews] = useState(0)

  const historicalAgreements = useAppSelector(getHistoricalAgreementsSelector)
  const activeAgreements = useAppSelector(getActiveAgreementsSelector)
  const agreements = useAppSelector(userAgreementsDictionarySelector)

  const agreementsLoading = useAppSelector(userAgreementsLoadingSelector)

  const paymentsLoading = useAppSelector(paymentsLoadingSelector)

  const [balanceExecutor, balanceLoading, balanceData] = useApi<AccountBalance>(
    {
      config: {
        method: 'get',
        url: 'Accounts/balance',
      },
    }
  )

  const documentsLoading = useAppSelector(getDocumentsLoadingSelector)
  const unpaidDocuments: AccountingDocumentListItem[] = useAppSelector(
    getAllUnpaidDocumentsSelector
  )
  const loadData = useCallback(
    (customerNumberChanged: boolean) => {
      dispatch(
        newsGetAll({
          keepPrevious: !customerNumberChanged,
          type: 'Unread',
        })
      )
        .then(unwrapResult)
        .then(([payload]) => setUnreadNews(payload?.total ?? 0))
      dispatch(userAgreementsGetAll())
      dispatch(measurementPointsGetAll())
      dispatch(getOffers())
      dispatch(
        documentsGetAll({
          keepPrevious: !customerNumberChanged,
          limit: 50,
          sortBy: AccountingDocumentsSortType.DueDate,
          sortDesc: true,
        })
      )
      balanceExecutor()
    },
    [balanceExecutor, dispatch]
  )

  useDispatchOnCustomerNumberChange(loadData)

  return {
    account,
    payments: {
      loading: paymentsLoading,
    },
    agreements: {
      data: agreements,
      historicalAgreements,
      activeAgreements,
      loading: agreementsLoading,
    },
    balance: {
      data: balanceData,
      loading: balanceLoading,
    },
    documents: {
      unpaid: unpaidDocuments,
      loading: documentsLoading,
    },
    unreadNews,
  }
}
