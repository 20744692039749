import { useCallback } from 'react'
import { useAppSelector, useAppDispatch } from '../store'
import { persistedSettingsActions, persistedSettingsType } from './persisted'
import { getPersistedSettingState } from './selectors'

export const usePersistedSetting = (
  persistedSetting: persistedSettingsType
) => {
  const value = useAppSelector(getPersistedSettingState(persistedSetting))
  const dispatch = useAppDispatch()

  const setPersistedSettingState = useCallback(
    (value: boolean) => {
      dispatch(
        persistedSettingsActions.setPersistedSetting({
          persistedSetting,
          value,
        })
      )
    },
    [dispatch, persistedSetting]
  )

  return [value, setPersistedSettingState] as [
    typeof value,
    typeof setPersistedSettingState
  ]
}
