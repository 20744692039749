import React from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { InitialFieldsType, MFAModalView } from './EnableMFAForm.types'

export const mfaChannels = [
  { value: 'Sms', name: 'Telefon' },
  { value: 'Email', name: 'E-Mail' },
]

export const validateFormFields = (
  values: InitialFieldsType,
  mfaModalView: MFAModalView
) => {
  const errors: Partial<InitialFieldsType> = {}
  if (
    mfaModalView === MFAModalView.MFA_CHANNEL &&
    values.MFAContactType === ''
  ) {
    errors.MFAContactType = 'Wybierz kanał komunikacji dla MFA'
  }
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(String(values.Email)) &&
    values.MFAContactType === mfaChannels[1].value
  ) {
    errors.Email = 'Wpisz poprawny adres e-mail'
  }
  if (values.MFAContactType === mfaChannels[0].value) {
    if (
      values.Phone === '' ||
      !isValidPhoneNumber(`${values.PhonePrefix}${values.Phone}`) ||
      values.Phone.toString().length < 9
    ) {
      errors.Phone = 'Wpisz poprawny numer telefonu'
    }
  }
  if (
    mfaModalView === MFAModalView.ACIVATION_CODE &&
    values.MfaCode.toString().length !== 6
  ) {
    errors.MfaCode = 'Wpisz poprawny kod aktywacji MFA'
  }
  if (values.RememberDevice) {
    if (values.DeviceName.toString().length < 1) {
      errors.DeviceName = 'Podaj nazwę urządzenia do zapamiętania'
    }
  }
  return errors
}

export const mfaHelper: JSX.Element = (
  <span>
    Czym jest MFA (Multi Factor Authentication)? <br />
    Jest to wieloskładnikowe uwierzytelnienie (weryfikacja) Użytkownika podczas
    logowania i innych wrażliwych czynnościach wykonywanych w aplikacji.
    Uwierzytelnienie Użytkownika następuje na podstawie znajomości loginu, hasła
    oraz dodatkowego kodu otrzymywanego drogą mailową lub w wiadomości SMS, w
    zależności od wybranego przez Użytkownika kanału komunikacji
    <br />
    <br />
    Jak to działa? <br />
    Podczas logowania (następującego po aktywacji MFA) po podaniu adresu e-mail
    i hasła wymagane będzie wpisanie 6-cyfrowego kodu uwierzytelniającego. Kod
    ten zostanie dostarczony drogą mailową lub w wiadomości SMS. Podczas
    wprowadzania kodu istnieje możliwość zapamiętania urządzenia, na którym
    wprowadzono kod. Pozwoli to na logowanie na tym urządzeniu bez użycia MFA
    przez 6 kolejnych miesięcy.
  </span>
)
