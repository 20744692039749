import { isValidPhoneNumber } from 'react-phone-number-input'
import { InitialFieldsType, MFAModalView } from './ModifyMFAForm.types'
import { MfaContactType } from '@shared/contracts/mfaContactType'

export const mfaChannels = [
  { value: 'Sms', name: 'Telefon' },
  { value: 'Email', name: 'E-Mail' },
]

export const validateFormFields = (
  values: InitialFieldsType,
  mfaModalView: MFAModalView
) => {
  const errors: Partial<InitialFieldsType> = {}
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(String(values.Email)) &&
    values.MFAContactType === mfaChannels[1].value
  ) {
    errors.Email = 'Wpisz poprawny adres e-mail'
  }
  if (values.MFAContactType === mfaChannels[0].value) {
    if (
      values.Phone === '' ||
      !isValidPhoneNumber(`${values.PhonePrefix}${values.Phone}`) ||
      values.Phone.toString().length < 9
    ) {
      errors.Phone = 'Wpisz poprawny numer telefonu'
    }
  }
  if (
    mfaModalView === MFAModalView.MFA_CODE &&
    values.MfaCode.toString().length !== 6
  ) {
    errors.MfaCode = 'Wpisz poprawny kod aktywacji MFA'
  }
  return errors
}

export const returnNewMfaContactType = (
  mfaContactType: MfaContactType | undefined
): MfaContactType => {
  return mfaContactType === MfaContactType.Email
    ? MfaContactType.Sms
    : MfaContactType.Email
}
