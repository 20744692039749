import React, { useCallback, useState } from 'react'
import { isEmpty, isNil } from 'ramda'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'

import { useAppDispatch, useAppSelector } from '@core/store/store'
import { getAccessToken } from '@core/store/user/userAccountSelectors'
import {
  getUserAccount,
  updateAccountMainPhoneNumberFirstStep,
  updateAccountMainPhoneNumberSecondStep,
  updateAccountMainPhoneNumberThirdStep,
} from '@core/store/user/userAccountThunks'
import useStyles from './EditMainPhoneForm.style'
import {
  EditMainPhoneFormProps,
  EditPhoneModalView,
  InitialFieldsType,
} from './EditMainPhoneForm.types'
import { validateFormFields } from './EditMainPhoneForm.utils'
import { EditMainPhoneWithoutMFAForm } from './components/EditMainPhoneWithoutMFAForm/EditMainPhoneWithoutMFAForm'
import { EditMainPhoneIncludingMFAForm } from './components/EditMainPhoneIncludingMFAForm/EditMainPhoneIncludingMFAForm'
import Button from '@shared/components/modals/Modal/Button'
import { showModal } from '@core/store/ui/actions'
import { ResponseWithError } from '@shared/contracts/responseWithError'
import { userAccountActions } from '@core/store/user/userAccount'
import { uiActions } from '@core/store/ui/ui'
import { getIsChangePhoneAndModifyMfaPath } from '@core/store/ui/uiSelectors'

export const EditMainPhoneForm: React.FC<EditMainPhoneFormProps> = ({
  onCancel,
  mainPhoneNumber,
  mainPhonePrefix,
  isMfaEnabled,
  showUnexpectedErrorModal,
  mfaContactType,
  openModifyMfaForm,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getAccessToken)
  const isChangePhoneAndModifyMfaPath = useAppSelector(
    getIsChangePhoneAndModifyMfaPath
  )
  const [editPhoneModalView, setEditPhoneModalView] = useState<
    EditPhoneModalView
  >(EditPhoneModalView.INTRODUCTION)
  const [isFirstCycle, setIsFirstCycle] = useState<boolean>(true)

  const initialValues: InitialFieldsType = {
    Phone: mainPhoneNumber ?? '',
    PhonePrefix: mainPhonePrefix ?? '+48',
    MfaCode: '',
  }

  const showErrorModal = useCallback(
    (message: string) => {
      dispatch(
        showModal({
          type: 'error',
          title: t('MODAL_MESSAGES.SORRY'),
          subtitle: message,
          withCancelButton: true,
          withSubmitButton: true,
          onSubmit: () => {
            dispatch(
              showModal({
                type: 'report',
              })
            )
          },
        })
      )
    },
    [dispatch, t]
  )

  const handleOnCancel = () => {
    onCancel()
    setEditPhoneModalView(EditPhoneModalView.INTRODUCTION)
    dispatch(uiActions.setIsChangePhoneAndModifyMfaPath(false))
  }

  const handleFirstStep = async () => {
    if (isMfaEnabled) {
      const res = await dispatch(updateAccountMainPhoneNumberFirstStep())
      if ((res as ResponseWithError).error?.message) {
        showErrorModal((res as ResponseWithError).error!.message)
      }
      if (res.type.includes('rejected')) {
        showUnexpectedErrorModal()
      } else {
        setEditPhoneModalView(EditPhoneModalView.MFA_CODE)
      }
    } else {
      setEditPhoneModalView(EditPhoneModalView.PHONE_INPUT)
    }
  }

  const handleSecondStep = async (
    values: InitialFieldsType,
    setFieldError: (field: string, message: string) => void,
    validate: { MfaCode?: string },
    submitForm: (() => Promise<void>) & (() => Promise<any>)
  ) => {
    if (isMfaEnabled) {
      if (isFirstCycle) {
        if (isNil(validate.MfaCode)) {
          setEditPhoneModalView(EditPhoneModalView.PHONE_INPUT)
        }
      } else {
        submitForm()
        if (isEmpty(validate)) {
          const res = await dispatch(
            updateAccountMainPhoneNumberThirdStep({
              accessToken,
              mfaCode: values.MfaCode,
              newPhoneNumber: values.Phone,
              newPhoneNumberPrefix: values.PhonePrefix,
            })
          )
          if ((res as ResponseWithError).error?.message) {
            setFieldError('MfaCode', (res as ResponseWithError).error!.message)
          } else {
            dispatch(userAccountActions.resetAccessToken)
            dispatch(
              showModal({
                type: 'success',
                title: t(
                  'MY_SETTINGS.MY_ACCOUNT_DATA.CHANGE_MAIN_PHONE_SUCCESS'
                ),
                onCancel: () => {
                  if (isChangePhoneAndModifyMfaPath) {
                    openModifyMfaForm()
                  }
                },
              })
            )
            dispatch(getUserAccount())
            handleOnCancel()
          }
        }
      }
    }
  }

  const handleThirdStep = async (
    values: InitialFieldsType,
    setFieldError: (field: string, message: string) => void,
    submitForm: (() => Promise<void>) & (() => Promise<any>)
  ) => {
    if (isMfaEnabled) {
      submitForm()
      const res = await dispatch(
        updateAccountMainPhoneNumberSecondStep({
          accessToken,
          mfaCode: values.MfaCode,
          newPhoneNumber: values.Phone,
          newPhoneNumberPrefix: values.PhonePrefix,
        })
      )
      if ((res as ResponseWithError).error?.message) {
        setFieldError('Phone', (res as ResponseWithError).error!.message)
      } else {
        values.MfaCode = ''
        setIsFirstCycle(false)
        setEditPhoneModalView(EditPhoneModalView.MFA_CODE)
      }
    } else {
      submitForm()
      const res = await dispatch(
        updateAccountMainPhoneNumberThirdStep({
          newPhoneNumber: values.Phone,
          newPhoneNumberPrefix: values.PhonePrefix,
        })
      )
      if ((res as ResponseWithError).error?.message) {
        setFieldError('Phone', (res as ResponseWithError).error!.message)
      } else {
        dispatch(userAccountActions.resetAccessToken)
        dispatch(
          showModal({
            type: 'success',
            title: t('MY_SETTINGS.MY_ACCOUNT_DATA.CHANGE_MAIN_PHONE_SUCCESS'),
          })
        )
        dispatch(getUserAccount())
        handleOnCancel()
      }
    }
  }

  const handleEditMainPhone = (
    values: InitialFieldsType,
    validateForm: (
      values: InitialFieldsType,
      editPhoneModalView: EditPhoneModalView
    ) => {},
    submitForm: (() => Promise<void>) & (() => Promise<any>),
    setFieldError: (field: string, message: string) => void
  ) => {
    const validate = validateForm(values, editPhoneModalView)

    switch (editPhoneModalView) {
      case EditPhoneModalView.INTRODUCTION:
        handleFirstStep()
        break
      case EditPhoneModalView.MFA_CODE:
        handleSecondStep(values, setFieldError, validate, submitForm)
        break
      case EditPhoneModalView.PHONE_INPUT:
        handleThirdStep(values, setFieldError, submitForm)
        break
    }
  }

  return (
    <Grid className={styles.wrapper} container direction="column">
      <Typography className={styles.title}>
        {t('MY_SETTINGS.MY_ACCOUNT_DATA.CHANGE_PHONE_NUMBER')}
      </Typography>
      <Grid>
        <Formik
          initialValues={initialValues}
          validate={values => validateFormFields(values, editPhoneModalView)}
          onSubmit={() => {}}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            submitForm,
            setFieldValue,
            setTouched,
            setFieldError,
          }) => {
            return (
              <Form className={styles.form}>
                {isMfaEnabled ? (
                  <EditMainPhoneIncludingMFAForm
                    touched={touched}
                    errors={errors}
                    values={values}
                    editPhoneModalView={editPhoneModalView}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    mfaContactType={mfaContactType}
                    onEnterKeyPress={() => {
                      handleEditMainPhone(
                        values,
                        validateFormFields,
                        submitForm,
                        setFieldError
                      )
                    }}
                  />
                ) : (
                  <EditMainPhoneWithoutMFAForm
                    touched={touched['Phone']}
                    error={errors?.Phone}
                    phoneValue={String(values.Phone)}
                    phonePrefixValue={String(values.PhonePrefix)}
                    editPhoneModalView={editPhoneModalView}
                    onEnterKeyPress={() => {
                      handleEditMainPhone(
                        values,
                        validateFormFields,
                        submitForm,
                        setFieldError
                      )
                    }}
                  />
                )}
                <div className={styles.buttonsWrapper}>
                  <Button onClick={handleOnCancel}>{t('CLOSE')}</Button>
                  <Button
                    type="save"
                    onClick={e => {
                      e.preventDefault()
                      handleEditMainPhone(
                        values,
                        validateFormFields,
                        submitForm,
                        setFieldError
                      )
                    }}
                  >
                    {t('CHANGE')}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}
