import React from 'react'
import { Collapse, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { EditPhoneModalView } from '../../EditMainPhoneForm.types'
import { EditMainPhoneWithoutMFAFormProps } from './EditMainPhoneWithoutMFAForm.types'
import LabeledField from '@shared/components/InputWrapper/LabeledField'
import PhoneNumberInput from '@shared/components/PhoneNumberInput/PhoneNumberInput'

export const EditMainPhoneWithoutMFAForm: React.FC<EditMainPhoneWithoutMFAFormProps> = ({
  error,
  touched,
  phonePrefixValue,
  phoneValue,
  editPhoneModalView,
  onEnterKeyPress,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Collapse in={editPhoneModalView === EditPhoneModalView.INTRODUCTION}>
        <Typography style={{ textAlign: 'center' }}>
          {t('MY_SETTINGS.MY_ACCOUNT_DATA.DO_YOU_WANT_TO_CHANGE_PHONE_NUMBER')}
        </Typography>
      </Collapse>

      <Collapse in={editPhoneModalView === EditPhoneModalView.PHONE_INPUT}>
        <LabeledField
          title={t('MY_SETTINGS.MY_ACCOUNT_DATA.PHONE')}
          errorMsg={error}
          touched={touched}
        >
          <PhoneNumberInput
            name="Phone"
            phonePrefixName="PhonePrefix"
            optional={false}
            persistedPrefix={phonePrefixValue}
            persistedPhoneNumber={phoneValue}
            onEnterKeyPress={onEnterKeyPress}
          />
        </LabeledField>
      </Collapse>
    </>
  )
}
