import React from 'react'
import { Collapse, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import useStyles from '../../EditMainPhoneForm.style'
import { EditPhoneModalView } from '../../EditMainPhoneForm.types'
import { EditMainPhoneIncludingMFAFormProps } from './EditMainPhoneIncludingMFAForm.types'
import LabeledField from '@shared/components/InputWrapper/LabeledField'
import PhoneNumberInput from '@shared/components/PhoneNumberInput/PhoneNumberInput'
import { Input } from '@shared/components/Input'
import { MfaContactType } from '@shared/contracts/mfaContactType'

export const EditMainPhoneIncludingMFAForm: React.FC<EditMainPhoneIncludingMFAFormProps> = ({
  touched,
  errors,
  values,
  editPhoneModalView,
  setFieldValue,
  setTouched,
  mfaContactType,
  onEnterKeyPress,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Collapse in={editPhoneModalView === EditPhoneModalView.INTRODUCTION}>
        <Typography className={`${styles.paragraph} ${styles.paragraphBold}`}>
          {t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_MODIFICATION_INSTRUCTIONS')}
        </Typography>
        <Typography className={styles.paragraph}>
          {t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_MODIFICATION_INSTRUCTION_ONE', {
            mfaContactType:
              mfaContactType === MfaContactType.Sms
                ? 'numer telefonu'
                : 'adres e-mail',
          })}
          <br />
        </Typography>
        <Typography className={styles.paragraph}>
          {t('MY_SETTINGS.MY_ACCOUNT_DATA.EDIT_PHONE_INSTRUCTION_TWO')}
          <br />
        </Typography>
        <Typography className={styles.paragraph}>
          {t('MY_SETTINGS.MY_ACCOUNT_DATA.EDIT_PHONE_INSTRUCTION_THREE')}
          <br />
        </Typography>
      </Collapse>

      <Collapse in={editPhoneModalView === EditPhoneModalView.PHONE_INPUT}>
        <LabeledField
          title={t('MY_SETTINGS.MY_ACCOUNT_DATA.PHONE')}
          errorMsg={errors.Phone}
          touched={touched['Phone']}
        >
          <PhoneNumberInput
            name="Phone"
            phonePrefixName="PhonePrefix"
            optional={false}
            persistedPrefix={values.PhonePrefix}
            persistedPhoneNumber={values.Phone}
            onEnterKeyPress={onEnterKeyPress}
          />
        </LabeledField>
      </Collapse>

      <Collapse
        in={editPhoneModalView === EditPhoneModalView.MFA_CODE}
        timeout={{ enter: 650, exit: 350 }}
      >
        <LabeledField
          title={t('MY_SETTINGS.MY_ACCOUNT_DATA.ENTER_MFA_CODE')}
          errorMsg={errors.MfaCode}
          touched={touched['MfaCode']}
        >
          <div
            className={`${styles.input} ${
              errors.MfaCode && touched['MfaCode'] && styles.error
            }`}
          >
            <Input
              name="MfaCode"
              value={String(values.MfaCode)}
              type="number"
              placeholder={t('MY_SETTINGS.MY_ACCOUNT_DATA.ENTER_MFA_CODE')}
              onChange={e => setFieldValue('MfaCode', e.target.value)}
              onBlur={() => setTouched({ MfaCode: true })}
              onKeyPress={onEnterKeyPress}
              className={styles.numericInput}
            />
          </div>
        </LabeledField>
      </Collapse>
    </>
  )
}
