import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import registerBack from '@images/registerBack.jpg'
import registerBack2 from '@images/registerBack2.jpg'
import registerBack3 from '@images/registerBack3.jpg'
import registerBack4 from '@images/registerBack4.jpg'
import registerBack5 from '@images/registerBack5.jpg'
import CookiesConsent from './components/cookiesConsent'
import CookiesFloatingButton from './components/CookiesFloatingButton'
import DownloadMobileApp from './components/DownloadMobileApp'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("${registerBack5}"), linear-gradient(90deg, rgba(148,200,177,1) 0%, rgba(140,199,179,1) 35%, rgba(133,190,184,1) 100%)`,
    [theme.breakpoints.down(4000)]: {
      backgroundImage: `url("${registerBack4}"), linear-gradient(90deg, rgba(148,200,177,1) 0%, rgba(140,199,179,1) 35%, rgba(133,190,184,1) 100%)`,
    },
    [theme.breakpoints.down(3200)]: {
      backgroundImage: `url("${registerBack3}"), linear-gradient(90deg, rgba(148,200,177,1) 0%, rgba(140,199,179,1) 35%, rgba(133,190,184,1) 100%)`,
    },
    [theme.breakpoints.down('lg')]: {
      //1920
      backgroundImage: `url("${registerBack2}"), linear-gradient(90deg, rgba(148,200,177,1) 0%, rgba(140,199,179,1) 35%, rgba(133,190,184,1) 100%)`,
    },
    [theme.breakpoints.down('sm')]: {
      //960
      backgroundImage: `url("${registerBack}"), linear-gradient(90deg, rgba(148,200,177,1) 0%, rgba(140,199,179,1) 35%, rgba(133,190,184,1) 100%)`,
    },
    color: theme.palette.text.primary,
    margin: 0,
    padding: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))

export const BackgroundWrapper: React.FC = ({ children }) => {
  const styles = useStyles()

  return (
    <div className={styles.wrapper}>
      <DownloadMobileApp positionTop={0} />
      <CookiesConsent positionTop={0} />
      <CookiesFloatingButton />
      <div className={styles.container}>{children}</div>
    </div>
  )
}
