import React from 'react'
import { Input } from '@shared/components/Input'
import { Grid, StandardTextFieldProps, Typography } from '@material-ui/core'
import ErrorMessage from '../ErrorMessage'
import useStyles from './TextField.style'
import useAutocompleteStyles from '../../../../components/Autocomplete/Autocomplete.style'

export enum ErrorPosition {
  Top,
  Bottom,
  Disable,
}

export interface ITextFieldProps extends StandardTextFieldProps {
  name: string
  formError?: string
  wrapperClass?: string
  errorPosition?: ErrorPosition
  custom?: boolean
  autocomplete?: boolean
}

const TextField: React.FC<ITextFieldProps> = ({
  className = '',
  wrapperClass = '',
  formError,
  label,
  errorPosition = ErrorPosition.Top,
  custom = false,
  autocomplete = false,
  ...props
}) => {
  const styles = useStyles()
  const autocompleteStyles = useAutocompleteStyles()
  const errorStyle = autocomplete ? autocompleteStyles.inputError : styles.inputError;
  const formErrorStyle = formError ? errorStyle : '';
  return (
    <Grid container className={wrapperClass}>
      <Grid container alignItems="center" justify="flex-start">
        <Typography className={styles.label}>{label}</Typography>
        {errorPosition === ErrorPosition.Top && (
          <ErrorMessage title={formError} />
        )}
      </Grid>
      <Input
        className={` ${formErrorStyle}
        ${!custom ? styles.input : ''} ${className}`}
        {...props}
        inputProps={{
          ...props.inputProps,
          autoComplete: autocomplete ? 'new-password' : 'off',
        }}
      />
      {errorPosition === ErrorPosition.Bottom && (
        <Grid container alignItems="center" justify="flex-start">
          <ErrorMessage title={formError} className={styles.errorMessageBottom}/>
        </Grid>
      )}
    </Grid>
  )
}

export default TextField
