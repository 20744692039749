import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { persistedSettingsType } from './persisted'

export const persistedSettingsStateSelector = (state: RootState) =>
  state.persistedSettings

export const getPersistedSettingState = (
  persistedSetting: persistedSettingsType
) =>
  createSelector(
    persistedSettingsStateSelector,
    state => state[persistedSetting]
  )
