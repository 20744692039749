import React from 'react'
import Button from '@shared/components/modals/Modal/Button'
import useStyles from './Actions.style'

interface ActionsProps {
    cancelText: string
    submitText: string
    onCancel: () => void
    onSubmit?: () => void
    disabled?: boolean
}

export const Actions: React.FC<ActionsProps> = ({
    cancelText,
    submitText,
    onCancel,
    onSubmit,
    disabled = false
}) => {
  const styles = useStyles()
  return (
    <div className={styles.wrapper}>
      <Button onClick={onCancel}>{cancelText}</Button>
      <Button type="save" submit onClick={onSubmit} disabled={disabled}>
        {submitText}
      </Button>
    </div>
  )
}
