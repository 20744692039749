export const Animation404Src = '/404/demo/404.html'
export const AnimationNadplataSrc = '/nadplata/demo/zwrot.html'
export const AnimationBladSrc = '/blad/demo/blad.html'
export const AnimationSukcesSrc = '/sukces/demo/sukces.html'
export const AnimationInfowiatrakSrc = '/infowiatrak/demo/info.html'
export const AnimationSluchawkiSrc = '/sluchawki/demo/sluchawki.html'
export const AnimationWyslanieSrc = '/wysylanieMaila/demo/mail.html'
export const AnimationOczekiwaniePlatnoscSrc =
  '/platnoscOczekiwanie/demo/data.html'
export const AnimationDokonanaPlatnoscSrc = '/platnoscDone/demo/data.html'
export const AnimationScreenSrc = '/svg_2/demo/data.html'
export const AnimationPreRegisterSrc = '/preRegister/demo/data.html'
export const helpLinePhoneNumber = '+48 123 456 789'
