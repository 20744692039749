import {
  Radio as MaterialRadio,
  RadioProps as MaterialRadioProps,
} from '@material-ui/core'
import React from 'react'
import Label from '../Label'
import useStyles from './Radio.style'

interface RadioProps {
  label?: string | React.ReactElement<any, any>
}

export const Radio: React.FC<RadioProps & MaterialRadioProps> = ({
  value,
  label = '',
}) => {
  const styles = useStyles()

  return (
    <Label
      control={
        <MaterialRadio
          value={value}
          icon={<span className={styles.icon} />}
          checkedIcon={<span className={styles.checkedIcon} />}
        />
      }
      label={label}
      classes={{root: styles.wrapper, label: styles.label}}
    />
  )
}
