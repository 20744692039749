import { createSlice } from '@reduxjs/toolkit'
import {
  initialLoadingState,
  LoadingState,
} from '../entity/helpers/entityReducerHelpers'
import { OrderItem, Order } from '@shared/contracts/models'
import { StoreReducers } from '../StoreReducers'
import { getPaymentsExtraReducers } from './paymentsThunks'

export interface PaymentsState extends LoadingState {
  currentOrderId?: string
  paymentRedirectUrl?: string
  order?: Order
  orderItems: OrderItem[]
  isHashValid: boolean
}

const initialState: PaymentsState = {
  ...initialLoadingState,
  currentOrderId: undefined,
  paymentRedirectUrl: undefined,
  order: undefined,
  isHashValid: false,
  orderItems: [],
}

export const paymentsSlice = createSlice({
  name: StoreReducers.payments,
  initialState,
  reducers: {
    resetState: () => {
      return initialState
    },
  },
  extraReducers: builder => {
    getPaymentsExtraReducers(builder)
  },
})

export const paymentActions = paymentsSlice.actions
