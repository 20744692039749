import { useThunkApi } from '@core/apiClient/apiClient'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { Account, SetUpNewPasswordRequest } from '@shared/contracts/models'
import { handleLoadingAction } from '../entity/helpers/thunks/entityThunksFactory'
import { StoreReducers } from '../StoreReducers'
import {
  UpdateAddressPayload,
  UpdateCorrespondenceEmailPayload,
  UpdatePhoneNumberPayload,
} from './types'
import { errorToString } from '@shared/utils/errors'
import { UserAccountState } from './userAccount'
import {
  EnableMfaVerificationRequest,
  EnableMfaRequest,
} from '@shared/contracts/enableMfaRequest'
import { UpdateMfaContactTypeRequest } from '@shared/contracts/updateMfaContactTypeRequest'
import { AccessToken } from '@shared/contracts/accessToken'
import { UpdateAccountMainPhoneNumberRequest } from '@shared/contracts/updateAccountMainPhoneNumberRequest'

export const getUserAccount = createAsyncThunk(
  `${[StoreReducers.userAccount]}/getProfile`,
  async (_, thunkApi) => {
    const [execute] = useThunkApi<Account>(
      {
        config: {
          method: 'get',
          url: `accounts`,
        },
      },
      thunkApi
    )
    const response = await execute()
    return response.data
  }
)

export const updateCorrespondenceEmail = createAsyncThunk<
  UpdateCorrespondenceEmailPayload,
  UpdateCorrespondenceEmailPayload
>(
  `${[StoreReducers.userAccount]}/updateCorrespondenceEmail`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<UpdateCorrespondenceEmailPayload>(
      {
        config: {
          method: 'put',
          url: 'Accounts/updateCorrespondenceEmail',
          data,
        },
      },
      thunkApi
    )
    await execute()
    return data
  }
)

export const updateMainAddress = createAsyncThunk<
  UpdateAddressPayload,
  UpdateAddressPayload
>(
  `${[StoreReducers.userAccount]}/updateMainAddress`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<UpdateAddressPayload>(
      {
        config: {
          method: 'put',
          url: 'Accounts/updateMainAddress',
          data,
        },
      },
      thunkApi
    )
    await execute()
    return data
  }
)

export const updateCorrespondenceAddress = createAsyncThunk<
  UpdateAddressPayload,
  UpdateAddressPayload
>(
  `${[StoreReducers.userAccount]}/updateCorrespondenceAddress`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<UpdateAddressPayload>(
      {
        config: {
          method: 'put',
          url: 'Accounts/updateCorrespondenceAddress',
          data,
        },
      },
      thunkApi
    )
    await execute()
    return data
  }
)

export const updatePhoneNumber = createAsyncThunk<
  UpdatePhoneNumberPayload,
  UpdatePhoneNumberPayload
>(
  `${[StoreReducers.userAccount]}/updatePhoneNumber`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<UpdatePhoneNumberPayload>(
      {
        config: {
          method: 'put',
          url: 'Accounts/updatePhoneNumber',
          data,
        },
      },
      thunkApi
    )
    try {
      await execute()
    } catch (error) {
      throw new Error(errorToString(error as any))
    }
    return data
  }
)

export const updatePassword = createAsyncThunk<
  SetUpNewPasswordRequest,
  SetUpNewPasswordRequest
>(`${[StoreReducers.userAccount]}/updatePassword`, async (data, thunkApi) => {
  const [execute] = useThunkApi<SetUpNewPasswordRequest>(
    {
      config: {
        method: 'put',
        url: 'Accounts/setUpNewPassword',
        data,
      },
    },
    thunkApi
  )
  try {
    await execute()
  } catch (error) {
    throw new Error(errorToString(error as any))
  }
  return data
})

export const enableMfaVerification = createAsyncThunk<
  EnableMfaVerificationRequest,
  EnableMfaVerificationRequest
>(
  `${[StoreReducers.userAccount]}/enableMfaVerification`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<EnableMfaVerificationRequest>(
      {
        config: {
          method: 'post',
          url: 'Accounts/enableMfaVerification',
          data,
        },
      },
      thunkApi
    )
    try {
      await execute()
    } catch (error) {
      throw new Error(errorToString(error as any))
    }
    return data
  }
)

export const enableMfa = createAsyncThunk<EnableMfaRequest, EnableMfaRequest>(
  `${[StoreReducers.userAccount]}/enableMfa`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<EnableMfaRequest>(
      {
        config: {
          method: 'put',
          url: 'Accounts/enableMfa',
          data,
          withCredentials: true,
        },
      },
      thunkApi
    )
    try {
      await execute()
    } catch (error) {
      throw new Error(errorToString(error as any))
    }
    return data
  }
)

export const mfaContactTypeChangeFirstStep = createAsyncThunk(
  `${[StoreReducers.userAccount]}/mfaContactTypeChangeFirstStep`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<AccessToken>(
      {
        config: {
          method: 'get',
          url: 'Accounts/mfaContactTypeChangeFirstStep',
          withCredentials: true,
        },
      },
      thunkApi
    )
    try {
      const response = await execute()
      return response.data.actionAccessToken
    } catch (error) {
      throw new Error(errorToString(error as any))
    }
  }
)

export const mfaContactTypeChangeSecondStep = createAsyncThunk<
  UpdateMfaContactTypeRequest,
  UpdateMfaContactTypeRequest
>(
  `${[StoreReducers.userAccount]}/mfaContactTypeChangeSecondStep`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<UpdateMfaContactTypeRequest>(
      {
        config: {
          method: 'post',
          url: 'Accounts/mfaContactTypeChangeSecondStep',
          data,
          withCredentials: true,
        },
      },
      thunkApi
    )
    try {
      await execute()
    } catch (error) {
      throw new Error(errorToString(error as any))
    }
    return data
  }
)

export const mfaContactTypeChangeThirdStep = createAsyncThunk<
  UpdateMfaContactTypeRequest,
  UpdateMfaContactTypeRequest
>(
  `${[StoreReducers.userAccount]}/mfaContactTypeChangeThirdStep`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<UpdateMfaContactTypeRequest>(
      {
        config: {
          method: 'post',
          url: 'Accounts/mfaContactTypeChangeThirdStep',
          data,
          withCredentials: true,
        },
      },
      thunkApi
    )
    try {
      await execute()
    } catch (error) {
      throw new Error(errorToString(error as any))
    }
    return data
  }
)

export const updateAccountMainPhoneNumberFirstStep = createAsyncThunk(
  `${[StoreReducers.userAccount]}/updateAccountMainPhoneNumberFirstStep`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<AccessToken>(
      {
        config: {
          method: 'get',
          url: 'Accounts/updateAccountMainPhoneNumberFirstStep',
          withCredentials: true,
        },
      },
      thunkApi
    )
    try {
      const response = await execute()
      return response.data.actionAccessToken
    } catch (error) {
      throw new Error(errorToString(error as any))
    }
  }
)

export const updateAccountMainPhoneNumberSecondStep = createAsyncThunk<
  UpdateAccountMainPhoneNumberRequest,
  UpdateAccountMainPhoneNumberRequest
>(
  `${[StoreReducers.userAccount]}/updateAccountMainPhoneNumberSecondStep`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<UpdateAccountMainPhoneNumberRequest>(
      {
        config: {
          method: 'put',
          url: 'Accounts/updateAccountMainPhoneNumberSecondStep',
          data,
          withCredentials: true,
        },
      },
      thunkApi
    )
    try {
      await execute()
    } catch (error) {
      throw new Error(errorToString(error as any))
    }
    return data
  }
)

export const updateAccountMainPhoneNumberThirdStep = createAsyncThunk<
  UpdateAccountMainPhoneNumberRequest,
  UpdateAccountMainPhoneNumberRequest
>(
  `${[StoreReducers.userAccount]}/updateAccountMainPhoneNumberThirdStep`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<UpdateAccountMainPhoneNumberRequest>(
      {
        config: {
          method: 'put',
          url: 'Accounts/updateAccountMainPhoneNumberThirdStep',
          data,
          withCredentials: true,
        },
      },
      thunkApi
    )
    try {
      await execute()
    } catch (error) {
      throw new Error(errorToString(error as any))
    }
    return data
  }
)

export const getUserAccoutExtraReducers = (
  builder: ActionReducerMapBuilder<UserAccountState>
) => {
  handleLoadingAction(getUserAccount, builder)
  builder.addCase(getUserAccount.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
    state.account = payload
  })

  handleLoadingAction(updateCorrespondenceEmail, builder)
  builder.addCase(updateCorrespondenceEmail.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
  })
  handleLoadingAction(updateMainAddress, builder)
  builder.addCase(updateMainAddress.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
  })
  handleLoadingAction(updateCorrespondenceAddress, builder)
  builder.addCase(
    updateCorrespondenceAddress.fulfilled,
    (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
    }
  )
  handleLoadingAction(updatePhoneNumber, builder)
  builder.addCase(updatePhoneNumber.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
  })
  handleLoadingAction(updatePassword, builder)
  builder.addCase(updatePassword.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
  })
  handleLoadingAction(enableMfa, builder)
  builder.addCase(enableMfa.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
  })
  handleLoadingAction(enableMfaVerification, builder)
  builder.addCase(enableMfaVerification.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
  })
  handleLoadingAction(mfaContactTypeChangeFirstStep, builder)
  builder.addCase(
    mfaContactTypeChangeFirstStep.fulfilled,
    (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
      state.accessToken = payload
    }
  )
  handleLoadingAction(mfaContactTypeChangeSecondStep, builder)
  builder.addCase(
    mfaContactTypeChangeSecondStep.fulfilled,
    (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
    }
  )
  handleLoadingAction(mfaContactTypeChangeThirdStep, builder)
  builder.addCase(
    mfaContactTypeChangeThirdStep.fulfilled,
    (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
    }
  )
  handleLoadingAction(updateAccountMainPhoneNumberFirstStep, builder)
  builder.addCase(
    updateAccountMainPhoneNumberFirstStep.fulfilled,
    (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
      state.accessToken = payload
    }
  )
  handleLoadingAction(updateAccountMainPhoneNumberSecondStep, builder)
  builder.addCase(
    updateAccountMainPhoneNumberSecondStep.fulfilled,
    (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
    }
  )
  handleLoadingAction(updateAccountMainPhoneNumberThirdStep, builder)
  builder.addCase(
    updateAccountMainPhoneNumberThirdStep.fulfilled,
    (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
    }
  )
}
