import i18n from '@i18n/index'

export const capitalize = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1)

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const phoneRegex = /^(([+][0-9]{2}|[0]{2}[0-9]{2}){0,1}[1-9][0-9]{8})$/i

export interface ISingleFieldValues {
  field1: string
}

export interface ISingleFieldErrors {
  field1?: string
}

export const validateEmailForm = (values: ISingleFieldValues) => {
  const errors: ISingleFieldErrors = {}
  if (!values.field1) {
    errors.field1 = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  } else {
    if (!emailRegex.test(values.field1))
      errors.field1 = i18n().t('FORM_ERRORS.EMAIL')
  }
  return errors
}

export const validatePhoneForm = (values: ISingleFieldValues) => {
  const errors: ISingleFieldErrors = {}
  if (!values.field1) {
    errors.field1 = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  } else {
    if (!phoneRegex.test(values.field1))
      errors.field1 = i18n().t('FORM_ERRORS.PHONE_NUMBER')
  }
  return errors
}

export interface IDoubleFieldValues {
  field1: string
  field2: string
}

export interface IDoubleFieldErrors {
  field1?: string
  field2?: string
}

export const validateAddressForm = (values: IDoubleFieldValues) => {
  const errors: IDoubleFieldErrors = {}
  if (!values.field1) {
    errors.field1 = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  } else {
    if (values.field1.length < 7 || values.field1.length > 255)
      errors.field1 = i18n().t('FORM_ERRORS.LENGTH', { min: 7, max: 255 })
  }
  if (!values.field2) {
    errors.field2 = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  } else {
    if (values.field2.length < 7 || values.field2.length > 255)
      errors.field2 = i18n().t('FORM_ERRORS.LENGTH', { min: 7, max: 255 })
  }
  return errors
}
