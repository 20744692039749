import { useApi } from '@core/apiClient/apiClient'
import { useDispatchOnCustomerNumberChange } from '@core/store/customerNumberDispatcher'
import { measurementPointsDataSelector } from '@core/store/entity/measurementPoints/measurementPointsSelectors'
import {
  getActiveAgreementsSelector, 
  getEInvoiceAgreementsSelector,
  getHistoricalAgreementsSelector,
  userAgreementsDictionarySelector,
  userAgreementsLoadingSelector
} from '@core/store/entity/userAgreements/userAgreementsSelectors'
import { allConsentsSelector } from '@core/store/entity/userConsents/userConsentsSelectors'
import { updateConsent } from '@core/store/entity/userConsents/userConsentsThunks'
import { allNotificationssSelector } from '@core/store/entity/userNotifications/userNotificationsSelectors'
import {
  updateUserNotification,
  userNotificationsGetAll
} from '@core/store/entity/userNotifications/userNotificationsThunks'
import { getDocuments } from '@core/store/policies/policiesSelectors'
import { getPolicyDocument } from '@core/store/policies/policiesThunks'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import {
  getAlreadySavedFormFields,
  isErrorModalOpen as isModalOpen
} from '@core/store/ui/uiSelectors'
import ActivationPoint from '@shared/components/forms/eInvoice/ActivationPoint'
import {
  Document, DocumentType,
  MeasurementPointListItem, NotificationType
} from '@shared/contracts/models'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISwitch } from './components/forms/SwitchForm/SwitchForm'
import { Modals } from './MySettingsPage'

export const useMySettingsDataSource = () => {
  const [isAddressInDatabase, setIsAddressInDatabase] = useState<boolean | undefined>(undefined)
  const [documentsContent, setDocumentsContent] = useState(
    {} as {
      [consentType: string]: string
    }
  )

  const [executeGetDocuments] = useApi<Document>({
    omitCustomerNumber: true,
    omitConsents: true,
    config: {
      method: 'get',
    },
  })

  const [executeUpdateEInvoiceEmail] = useApi({
    omitCustomerNumber: true,
    config: {
      method: 'put',
      url: `MeasurementPoints/updateEInvoiceEmail`,
    },
  })

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const isErrorModalOpen = useAppSelector(isModalOpen)

  const alreadySavedFormFields = useAppSelector(getAlreadySavedFormFields)

  const historicalAgreements = useAppSelector(getHistoricalAgreementsSelector)
  const activeAgreements = useAppSelector(
    getActiveAgreementsSelector
  ).map(item => ({ ...item, emailAddresses: item?.emailAddresses || [] }))
  const agreements = useAppSelector(userAgreementsDictionarySelector)
  const agreementsLoading = useAppSelector(userAgreementsLoadingSelector)
  const eInvoiceAgreements = useAppSelector(getEInvoiceAgreementsSelector)

  const measurementPoints = useAppSelector(measurementPointsDataSelector)

  const consents = useAppSelector(allConsentsSelector)

  const notifications = useAppSelector(allNotificationssSelector)

  const policyDocuments = useAppSelector(getDocuments)

  const [openModalKey, setOpenModalKey] = useState<Modals | undefined>(
    undefined
  )

  const [correspondenceEmail, setCorrespondenceEmail] = useState<
    string | undefined
  >(undefined)

  const [selectedMeasurementPoints, setSelectedMeasurementPoints] = useState<
    MeasurementPointListItem[]
  >([])

  const [
    selectedActivationPointOption,
    setSelectedActivationPointOption,
  ] = useState<ActivationPoint | undefined>(undefined)

  const account = useAppSelector(state => ({
    data: state.userAccount.account,
    loading: state.userAccount.loadingPending > 0,
  }))

  const getNotificationSwitchLabel = (type?: NotificationType) => {
    switch (type) {
      case 'NewInvoice': {
        return t('MY_SETTINGS.EMAIL_NOTIFICATIONS.NEW_INVOICE')
      }
      case 'ClosePaymentDeadline': {
        return t('MY_SETTINGS.EMAIL_NOTIFICATIONS.UPCOMING_PAYMENT_DEADLINE')
      }
      case 'Vindication': {
        return t('MY_SETTINGS.EMAIL_NOTIFICATIONS.PAYMENT_ARREARS')
      }
      case 'News': {
        return t('MY_SETTINGS.EMAIL_NOTIFICATIONS.NEWS')
      }
      case 'PaymentDeadlineToday': {
        return t('MY_SETTINGS.EMAIL_NOTIFICATIONS.PAYMENT_DEADLINE_EXPIRATION')
      }
      default: {
        return t('MY_SETTINGS.EMAIL_NOTIFICATIONS.NEWS')
      }
    }
  }

  const getConsentSwitchLabel = (type?: DocumentType) => {
    switch (type) {
      case 'ElectronicContact': {
        return `${t(
          'MY_SETTINGS.MARKETING_COMMUNICATION_CONSENTS.ELECTRONIC_CONTACT'
        )} (${t('MY_SETTINGS.MARKETING_COMMUNICATION_CONSENTS.OPTIONAL')})`
      }
      default: {
        return `${t(
          'MY_SETTINGS.MARKETING_COMMUNICATION_CONSENTS.PHONE_CONTACT'
        )} (${t('MY_SETTINGS.MARKETING_COMMUNICATION_CONSENTS.OPTIONAL')})`
      }
    }
  }

  const notificationSwitches: ISwitch[] = notifications
    .filter(n => n?.notificationMethod === 'Email')
    .map(n => ({
      label: getNotificationSwitchLabel(n?.notificationType),
      onChange: () => {
        return dispatch(
          updateUserNotification({
            id: n!.id!,
            isActive: !n?.isActive,
          })
        )
      },
      checked: !!n?.isActive,
    }))

  const allowedConsents: DocumentType[] = ['ElectronicContact', 'PhoneContact']
  const marketingSwitches: ISwitch[] = consents
    .filter(
      consent =>
        consent?.documentType && allowedConsents.includes(consent?.documentType)
    )
    .map(consent => ({
      label: getConsentSwitchLabel(consent?.documentType),
      onChange: () => {
        return dispatch(
          updateConsent({
            id: consent!.id,
            isAccepted: !(consent?.status === 'Accepted'),
          })
        )
      },
      checked: consent?.status === 'Accepted',
      mandatory: !!consent?.mandatory,
      documentType: consent?.documentType,
    }))

  const documentTypesToFetch: DocumentType[] = [
    'Terms',
    'PrivacyPolicy',
    'CookiesPolicy',
    'Faq',
  ]

  const loadData = useCallback(() => {
    dispatch(userNotificationsGetAll())
    Promise.all(documentTypesToFetch.map(d => dispatch(getPolicyDocument(d))))
  }, [dispatch, documentTypesToFetch])

  useEffect(() => {
    Promise.all(
      consents
        .filter(consent => !consent?.pdfDocumentAvailable)
        .map(consent =>
          executeGetDocuments({
            url: '/Documents/' + consent?.documentType,
          })
        )
    ).then(documents => {

      const contents = documents.reduce((acc, { data }) => {
        const type = data.type?.toString()
        if (!type) return acc

        return {
          ...acc,
          [type]: data.content,
        }
      }, {})
      setDocumentsContent(contents)
    })
  }, [consents, executeGetDocuments])

  useDispatchOnCustomerNumberChange(loadData)
  return {
    consents,
    account,
    notificationSwitches,
    marketingSwitches,
    marketingDocuments: documentsContent,
    measurementPoints: {
      data: measurementPoints,
      selectedMeasurementPoints,
      setSelectedMeasurementPoints,
    },
    activationPoint: {
      selectedActivationPointOption,
      setSelectedActivationPointOption,
    },
    correspondenceEmail: {
      correspondenceEmail,
      setCorrespondenceEmail,
    },
    policyDocuments,
    isErrorModalOpen,
    alreadySavedFormFields,
    agreements: {
      data: agreements,
      historicalAgreements,
      activeAgreements,
      eInvoiceAgreements,
      loading: agreementsLoading,
    },
    modals: {
      openModalKey,
      setOpenModalKey,
    },
    api: {
      executeUpdateEInvoiceEmail
    },
    address: {
      isAddressInDatabase,
      setIsAddressInDatabase
    }
  }
}
