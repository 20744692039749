import React from 'react'
import { Grid } from '@material-ui/core'
import { Formik, Form } from 'formik'
import Button from '../Button'
import FormikTextArea from '../FormikTextArea'
import FormikField from '../FormikField'
import Text from '../Text'
import {
  REFUSE,
  SEND,
  REPORT_DESCRIPTION,
  REPORT_TITLE,
  SEND_ERROR_REPORT,
  SEND_ERROR_REPORT_DESCRIPTION,
} from './strings'
import { validate } from './ReportForm.utils'
import useStyles from './ReportForm.style'

export interface IReportFormValues {
  title: string
  description: string
}

export interface IReportFormErrors {
  title?: string
  description?: string
}

export interface IReportForm {
  onSubmit: (values: IReportFormValues) => void
  onCancel: () => void
  callPending: boolean
  err?: string
}

const ReportForm: React.FC<IReportForm> = ({
  onSubmit,
  onCancel,
  callPending,
  err,
}) => {
  const styles = useStyles()

  return (
    <Grid container direction="column">
      <Formik
        initialValues={{ description: '', title: err ?? '' }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {() => {
          return (
            <Form className={styles.form}>
              <Text title className={styles.title}>
                {SEND_ERROR_REPORT}
              </Text>
              <Text small className={styles.subtitle}>
                {SEND_ERROR_REPORT_DESCRIPTION}
              </Text>
              <FormikField
                name="title"
                className={styles.errorTitle}
                placeholder={REPORT_TITLE}
                value={err}
                disabled={Boolean(err)}
              />
              <FormikTextArea
                name="description"
                placeholder={REPORT_DESCRIPTION}
                className={styles.textArea}
              />
              <Grid container alignItems="center" justify="center">
                <Button onClick={onCancel}>{REFUSE}</Button>
                <Button type="save" loading={callPending} submit>
                  {SEND}
                </Button>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}

export default ReportForm
