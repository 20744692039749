import { MfaContactType } from '@shared/contracts/mfaContactType'

export interface ModifyMFAFormProps {
  mfaContactType: MfaContactType | undefined
  mainEmail: string | undefined
  mainPhoneNumber: string | undefined
  mainPhonePrefix: string | undefined
  onCancel: () => void
  showUnexpectedErrorModal: () => void
  openEditMainPhoneForm: () => void
}

export enum MFAModalView {
  INTRODUCTION = 'INTRODUCTION',
  PHONE_INPUT = 'PHONE_INPUT',
  EMAIL_INPUT = 'EMAIL_INPUT',
  MFA_CODE = 'MFA_CODE',
  PHONE_WARNING = 'PHONE_WARNING',
}

export interface InitialFieldsType {
  MFAContactType: MfaContactType
  Email: string
  Phone: string
  PhonePrefix: string
  MfaCode: string
}
