import { createSlice } from '@reduxjs/toolkit'
import {
  initialLoadingState,
  LoadingState,
} from '../entity/helpers/entityReducerHelpers'
import { StoreReducers } from '../StoreReducers'
import { getTrustedMfaDevicesExtraReducers } from './trustedMfaDevicesThunk'
import { Device } from '@shared/contracts/trustedMfaDevice'

export interface TrustedMfaDevicesState extends LoadingState {
  devices: Device[]
}

const initialState: TrustedMfaDevicesState = {
  ...initialLoadingState,
  devices: [],
}

export const trustedMfaDevicesSlice = createSlice({
  name: StoreReducers.trustedMfaDevices,
  initialState,
  reducers: {
    resetState: state => Object.assign(state, initialState),
  },
  extraReducers: builder => {
    getTrustedMfaDevicesExtraReducers(builder)
  },
})

export const trustedMfaDevicesActions = trustedMfaDevicesSlice.actions
