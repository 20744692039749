import { InMemoryWebStorage, Oidc } from '@axa-fr/react-oidc-redux'
import ReturnPage from '@mbok/features/Return'

import React from 'react'
import { AppStore } from '../store/store'

export interface IOidcProviderProps {
  store: AppStore
}

// NOTE: when server side rendering will be required please omit usage of window
const getConfiguration = () => ({
  config: {
    client_id: process.env.REACT_APP_IS_CLIENT,
    redirect_uri: window.location.origin + '/authentication/callback',
    response_type: 'code',
    scope: 'openid mbok_api',
    authority: process.env.REACT_APP_IS_URL,
    silent_redirect_uri:
      window.location.origin + '/authentication/silent_callback',
    automaticSilentRenew: true,
    loadUserInfo: true,
    post_logout_redirect_uri: window.location.origin,
    monitorSession: process.env.NODE_ENV !== 'development',
  },
})

export const OidcProvider: React.FC<IOidcProviderProps> = ({
  children,
  store,
}) => {
  const configuration = getConfiguration()
  return (
    <Oidc
      store={store}
      configuration={configuration.config}
      isEnabled={true}
      UserStore={InMemoryWebStorage}
      callbackComponentOverride={() => <div></div>}
      notAuthenticated={ReturnPage}
      notAuthorized={ReturnPage}
    >
      {children}
    </Oidc>
  )
}
