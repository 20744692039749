import { createAsyncThunk } from '@reduxjs/toolkit'
import { useThunkApi } from '@core/apiClient/apiClient'
import { tokenLinkReducers } from './../../core/store/entity/tokenLinkReducer'

export const generateUserToken = createAsyncThunk(
  `${[tokenLinkReducers.generatedTokenLink]}/getTokenLink`,
  async (type, thunkApi) => {
    const [execute] = useThunkApi(
      {
        config: {
          method: 'get',
          url: process.env.REACT_APP_API_URL + 'Offers',
        },
      },
      thunkApi
    )
    const response = await execute()
    return response.data
  }
)
