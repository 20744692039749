import { GetDocumentsPayload } from '@core/store/entity/documents/documentsThunks'
import { ReactComponent as PaidSvg } from '@images/icons/myInvoices/Paid.svg'
import { ReactComponent as UnpaidSvg } from '@images/icons/myInvoices/Unpaid.svg'
import { ReactComponent as DownloadPDFSvg } from '@images/icons/myInvoices/DownloadPDF.svg'
import {
  CircularProgress,
  Fade,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { Button } from '@shared/components/Button'
import { StyledCheckbox } from '@shared/components/Checkbox'
import { NumberChangeAnimation } from '@shared/components/NumberChangeAnimation'
import { TableCellContent } from '@shared/components/TableCell'
import { useCreateTransaction } from '@shared/hooks/createTransaction'
import {
  AccountingDocumentListItem,
  AccountingDocumentStatus,
  AccountingDocumentStatusFilter,
  AccountingDocumentType,
  SecuredOrderItem,
  TranslatedAccountingDocumentStatus,
  TranslatedAccountingDocumentType,
} from '@shared/contracts/models'
import { useSavePdf } from '@shared/hooks/pdfSaver'
import { differenceInDays, format, isPast, parseISO } from 'date-fns'
import { equals, isEmpty } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import { animated, Transition } from 'react-spring'
import { FilterType, SortType } from '../../invoicesDataSource'
import { Sort } from '../FilterComponents'
import { SizeMe } from 'react-sizeme'
import { useLoadingState } from '@shared/hooks/loadingState'
import { CustomPagination } from '@shared/components/Pagination'
import CustomScrollBar from '@shared/components/ScrollBar'
import { ReactComponent as CheckedSvg } from '@images/icons/checkbox/Checked.svg'
import { ReactComponent as UncheckedSvg } from '@images/icons/checkbox/Unchecked.svg'
import { AddressParser } from '@shared/components/AddressParser'
import { amountParser } from '@shared/utils/balanceParser'
import useStyles, { LAST_BUTTON_WIDTH } from './InvoicesList.style'
import { useTranslation } from 'react-i18next'
import { SCROLL_SIZE } from '@shared/components/ScrollBar/ScrollBar.style'
import HintPopover from '@shared/components/HintPopover'

export const NotesAndOther = [
  AccountingDocumentType.InterestNote,
  AccountingDocumentType.DebitNote,
  AccountingDocumentType.CreditNote,
  AccountingDocumentType.NoteCorrection,
  AccountingDocumentType.Other,
]

type loadInvoicesType = (
  customerNumberChanged?: boolean,
  args?: GetDocumentsPayload
) => void

export interface DocsListProps {
  invoices?: AccountingDocumentListItem[]
  loading?: boolean
  paymentLoading?: boolean
  statusType?: AccountingDocumentStatusFilter
  pageSize: number
  pagination: { numberOfPages: number; docsPageStart: number }
  sort: SortType
  setSort: React.Dispatch<React.SetStateAction<SortType>>
  loadInvoices: loadInvoicesType
  filter: FilterType
  defaultFilter?: FilterType
}

const TABLE_HEIGHT = 100

export const DocsList: React.FC<DocsListProps> = ({
  invoices,
  loading,
  statusType,
  pageSize,
  pagination,
  sort,
  setSort,
  loadInvoices,
  filter,
  defaultFilter,
}) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const isXS = useMediaQuery(theme.breakpoints.down('xs'))

  const { t } = useTranslation()

  const isLoading = useLoadingState(loading ?? true, 800)

  const [page, setPage] = useState(1)
  const [invoicesState, setInvoicesState] = useState<
    AccountingDocumentListItem[]
  >(invoices || [])
  const [checkedInvoices, setCheckInvoices] = useState<string[]>([])
  const [checkedAmount, setCheckedAmount] = useState(0)
  const [tableHeight, setTableHeight] = useState(TABLE_HEIGHT)
  const savePdf = useSavePdf()
  const createTransaction = useCreateTransaction()
  const { Paid, Unpaid } = AccountingDocumentStatusFilter

  const getAllTransactions = useCallback((): SecuredOrderItem[] => {
    const transactions = invoices
      ?.filter(
        i =>
          i.paymentToken &&
          i.balance &&
          i.accountingDocumentId &&
          checkedInvoices.includes(i.accountingDocumentId)
      )
      .map(i => ({ paymentToken: i.paymentToken, amount: i.balance }))
    return transactions || []
  }, [invoices, checkedInvoices])

  const checked = useCallback(
    (accountingDocumentId: string | undefined) => {
      if (accountingDocumentId) {
        return checkedInvoices.includes(accountingDocumentId)
      } else {
        return false
      }
    },
    [checkedInvoices]
  )

  const updateAmount = useCallback((amount: number) => {
    setCheckedAmount(prev => Math.round((prev + amount) * 100) / 100)
  }, [])

  const handleInvoicesOnClick = useCallback(
    (accountingDocumentId: string | undefined, amount: number | undefined) => {
      if (checked(accountingDocumentId)) {
        if (accountingDocumentId) {
          const checkedOnes = [...checkedInvoices]
          const index = checkedOnes.indexOf(accountingDocumentId)
          checkedOnes.splice(index, 1)
          setCheckInvoices(checkedOnes)
        }
        if (amount) {
          updateAmount(-amount)
        }
      } else {
        if (accountingDocumentId) {
          setCheckInvoices(prev => [...prev, accountingDocumentId])
        }
        if (amount) {
          updateAmount(amount)
        }
      }
    },
    [checkedInvoices, checked, setCheckInvoices, updateAmount]
  )

  const checkPastUnpaidInvoices = useCallback(
    (invoices: AccountingDocumentListItem[]) => {
      const ids: Array<string> = []
      let amount = 0
      if (statusType === Unpaid) {
        invoices.forEach(doc => {
          const { accountingDocumentId, balance, dueDate, status } = doc
          if (
            accountingDocumentId &&
            balance &&
            status === AccountingDocumentStatus.Unpaid
          ) {
            if (dueDate && isPast(parseISO(dueDate))) {
              ids.push(accountingDocumentId)
              amount += balance
            }
          }
        })
      }
      setCheckInvoices(ids)
      setCheckedAmount(amount)
    },
    [Unpaid, statusType]
  )

  useEffect(() => {
    if (invoices) {
      if (statusType === Unpaid) {
        if (equals(invoicesState, invoices)) {
        } else {
          checkPastUnpaidInvoices(invoices)
        }
      }
      setInvoicesState(invoices)
    }
  }, [
    invoices,
    Unpaid,
    checkPastUnpaidInvoices,
    setInvoicesState,
    invoicesState,
    statusType,
  ])

  useEffect(() => {
    setPage(pagination.docsPageStart / 10 + 1)
  }, [pagination, setPage])

  const _loadInvoices = useCallback(
    (...params: Parameters<loadInvoicesType>) => {
      setCheckedAmount(0)
      setCheckInvoices([])
      loadInvoices(...params)
    },
    [setCheckedAmount, setCheckInvoices, loadInvoices]
  )

  const renderLoader = useCallback(
    (tableHeight: number) => (
      <div
        className={styles.loader}
        style={{
          height: tableHeight,
          maxHeight: tableHeight,
        }}
      >
        <CircularProgress size={32} disableShrink />
      </div>
    ),
    [styles.loader]
  )

  const renderInvoicesNotFound = useCallback(
    (
      statusType: AccountingDocumentStatusFilter,
      filter: FilterType,
      isLoading: boolean,
      defaultFilter?: FilterType
    ) => {
      const defaultFilters = defaultFilter?.typeOfDoc === filter.typeOfDoc
      const msg =
        statusType === Unpaid && defaultFilters
          ? t('MY_INVOICES.ALL_INVOICES_PAID')
          : t('MY_INVOICES.INVOICES_NOT_FOUND')
      return (
        <div className={styles.invoicesNotFound}>
          <Fade in={!isLoading}>
            <span>{msg}</span>
          </Fade>
        </div>
      )
    },
    [Unpaid, styles.invoicesNotFound, t]
  )

  const renderResults = useCallback(
    (
      statusType: AccountingDocumentStatusFilter,
      invoices?: AccountingDocumentListItem[]
    ) => (
      <div>
        <Table className={styles.table} stickyHeader aria-label="paymentsTable">
          <TableBody>
            {invoices?.map((doc, i) => {
              const {
                accountingDocumentId,
                documentNumber,
                consumptionValue,
                consumptionUnit,
                balance,
                totalAmount,
                dueDate,
                measurementPointName,
                measurementPointNumber,
                type,
                measurementPointAddressLine1,
                measurementPointAddressLine2,
                paymentToken,
                status,
                pdfAvailable,
              } = doc

              const invoiceType = type
                ? TranslatedAccountingDocumentType[type]
                : t('MY_INVOICES.INVOICE')

              const singlePoint =
                measurementPointName ??
                (measurementPointAddressLine1 ||
                measurementPointAddressLine2 ? (
                  <HintPopover
                    title={`Adres:`}
                    customHintTitle={
                      <div style={{ fontSize: 16 }}>
                        <AddressParser
                          a1={measurementPointAddressLine1}
                          a2={measurementPointAddressLine2}
                        />
                      </div>
                    }
                    content={
                      <div style={{ fontSize: 18 }}>
                        <AddressParser
                          a1={measurementPointAddressLine1}
                          a2={measurementPointAddressLine2}
                        />
                      </div>
                    }
                  />
                ) : (
                  measurementPointNumber ?? ''
                ))

              const invoiceMeasurementPoint = () => {
                if (
                  type === AccountingDocumentType.CollectiveInvoice ||
                  (type === AccountingDocumentType.InvoiceCorrection &&
                    !singlePoint)
                ) {
                  return t('MY_INVOICES.MANY_MEASUREMENT_POINTS')
                } else if (!type || NotesAndOther.includes(type)) {
                  return t('MY_INVOICES.DOES_NOT_CONCERN')
                } else {
                  return singlePoint
                }
              }

              const customBtnWidth =
                statusType === Paid
                  ? { width: LAST_BUTTON_WIDTH, marginLeft: 0 }
                  : {}

              const consumption =
                (type && NotesAndOther.includes(type)) ||
                consumptionValue === null
                  ? t('MY_INVOICES.DOES_NOT_CONCERN')
                  : consumptionValue !== undefined &&
                    consumptionValue !== null &&
                    consumptionUnit
                  ? consumptionValue + ' ' + consumptionUnit
                  : ''

              const payBtnText =
                type && NotesAndOther.includes(type)
                  ? t('MY_INVOICES.PAY')
                  : t('MY_INVOICES.PAY_THE_INVOICE')

              const unpaidAmountStyle =
                statusType === Unpaid && dueDate
                  ? isPast(parseISO(dueDate))
                    ? {
                        color: theme.palette.error.main,
                        border: '3px solid #E86F74',
                      }
                    : differenceInDays(parseISO(dueDate), new Date()) <= 5
                    ? {
                        color: theme.palette.secondary.main,
                        border: `3px solid ${theme.palette.secondary.main}`,
                      }
                    : {
                        color: theme.palette.primary.main,
                        border: `3px solid ${theme.palette.primary.main}`,
                      }
                  : {}

              return (
                <TableRow
                  key={accountingDocumentId}
                  style={i % 2 === 1 ? { backgroundColor: '#F6F9F8' } : {}}
                  tabIndex={-1}
                  className={styles.tableRow}
                >
                  {(!isXS || (isXS && statusType === Unpaid)) && (
                    <TableCell
                      className={`${styles.bodyCell} ${styles.checkboxStyles}`}
                    >
                      {statusType === Unpaid &&
                        status === AccountingDocumentStatus.Unpaid && (
                          <StyledCheckbox
                            icon={<UncheckedSvg />}
                            checkedIcon={<CheckedSvg />}
                            onClick={() =>
                              handleInvoicesOnClick(
                                accountingDocumentId,
                                balance
                              )
                            }
                            checked={checked(accountingDocumentId)}
                            disableRipple
                          />
                        )}
                    </TableCell>
                  )}
                  <TableCell
                    className={`${styles.bodyCell} ${
                      checked(accountingDocumentId) && styles.checkedCell
                    }`}
                    style={
                      statusType === Unpaid ? { width: 200 } : { minWidth: 220 }
                    }
                  >
                    <TableCellContent
                      header={invoiceType}
                      content={documentNumber || ''}
                      bold
                    />
                  </TableCell>
                  <TableCell
                    className={styles.bodyCell}
                    style={
                      statusType === Unpaid ? { width: 172 } : { minWidth: 140 }
                    }
                  >
                    {statusType === Unpaid ? (
                      <div
                        className={styles.amountCell}
                        style={unpaidAmountStyle}
                      >
                        {amountParser(balance) + ' zł'}
                      </div>
                    ) : (
                      <TableCellContent
                        header={t('MY_INVOICES.PAID')}
                        content={amountParser(totalAmount) + ' zł'}
                        opacity
                      />
                    )}
                  </TableCell>
                  {statusType === Unpaid && (
                    <TableCell
                      className={styles.bodyCell}
                      style={{
                        minWidth: 120,
                      }}
                    >
                      <TableCellContent
                        header={t('MY_INVOICES.STATUS')}
                        content={
                          status
                            ? TranslatedAccountingDocumentStatus[status]
                            : '-'
                        }
                        opacity
                        small
                      />
                    </TableCell>
                  )}
                  <TableCell
                    className={styles.bodyCell}
                    style={{
                      minWidth: statusType === Unpaid ? 100 : 124,
                    }}
                  >
                    <TableCellContent
                      header={t('MY_INVOICES.CONSUMPTION')}
                      content={consumption}
                      opacity
                    />
                  </TableCell>
                  <TableCell
                    className={styles.bodyCell}
                    style={{
                      minWidth: statusType === Unpaid ? 132 : 172,
                    }}
                  >
                    <TableCellContent
                      header={t('MY_INVOICES.PAYMENT_DUE_DATE')}
                      content={
                        dueDate ? format(parseISO(dueDate), 'dd.MM.yyyy') : ''
                      }
                      opacity
                    />
                  </TableCell>
                  <TableCell
                    className={styles.bodyCell}
                    style={{
                      maxWidth: statusType === Unpaid ? 232 : 240,
                    }}
                  >
                    <TableCellContent
                      header={t('MY_INVOICES.MEASUREMENT_POINT')}
                      content={invoiceMeasurementPoint()}
                      opacity
                    />
                  </TableCell>
                  <TableCell
                    className={`${styles.bodyCell} ${styles.lastCell}`}
                  >
                    <div className={styles.buttonsCell}>
                      <div className={styles.buttonsWrapper}>
                        <div className={styles.pdfBtn} style={customBtnWidth}>
                          {pdfAvailable &&
                            accountingDocumentId &&
                            savePdf(
                              `AccountingDocuments/${accountingDocumentId}/pdf`,
                              (loading, saveFn) =>
                                loading ? (
                                  <Button variant="outlined" disabled>
                                    <CircularProgress size={20} />
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      saveFn()
                                    }}
                                  >
                                    <DownloadPDFSvg />
                                    <span>PDF</span>
                                  </Button>
                                )
                            )}
                        </div>
                        {statusType === Unpaid && (
                          <div className={styles.payInvoiceBtn}>
                            {balance &&
                            status === AccountingDocumentStatus.Unpaid
                              ? createTransaction(
                                  [{ paymentToken, amount: balance }],
                                  (loading, saveFn) => (
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => saveFn()}
                                    >
                                      {loading ? (
                                        <CircularProgress size={20} />
                                      ) : (
                                        <>{payBtnText}</>
                                      )}
                                    </Button>
                                  )
                                )
                              : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    ),
    [
      checked,
      savePdf,
      isXS,
      handleInvoicesOnClick,
      Paid,
      Unpaid,
      styles,
      theme,
      createTransaction,
      t,
    ]
  )

  const HintContent = (
    <span>
      <ul style={{ listStyleType: 'none', marginBottom: 0, marginTop: 0 }}>
        <li>
          System płatności umożliwia dokonanie jednorazowej transakcji w
          wysokości nieprzekraczającej równowartości 1000 euro (przy
          zastosowaniu średniego kursu NBP obowiązującego w dniu dokonywania
          transakcji).
        </li>
      </ul>
    </span>
  )

  const renderPaymentSummary = useCallback(
    (isLoading: boolean, checkedAmount: number) => (
      <Collapse in={!isLoading} {...{ timeout: 400 }}>
        <div className={styles.sumAmount}>
          <div>
            <h3>
              <HintPopover title="Płatność" content={HintContent} topCard />
            </h3>
            <Transition
              items={checkedAmount > 0}
              from={{ opacity: 0.2 }}
              enter={{ opacity: 1 }}
            >
              {(values, visible) => {
                return (
                  visible && (
                    <animated.p style={values}>
                      <NumberChangeAnimation
                        value={checkedAmount}
                        precision={2}
                      />{' '}
                      zł
                    </animated.p>
                  )
                )
              }}
            </Transition>
            <Transition
              items={!Boolean(checkedAmount)}
              from={{ opacity: 0.2 }}
              enter={{ opacity: 1 }}
            >
              {(values, visible) => {
                return (
                  visible && <animated.p style={values}>Nie wybrano</animated.p>
                )
              }}
            </Transition>
          </div>
          <Collapse in={Boolean(checkedAmount)} {...{ timeout: 400 }}>
            <>
              {createTransaction(getAllTransactions(), (loading, saveFn) => (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={styles.payAllBtn}
                  onClick={() => saveFn()}
                >
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <>Zapłać faktury</>
                  )}
                </Button>
              ))}
            </>
          </Collapse>
        </div>
      </Collapse>
    ),
    [styles, createTransaction, getAllTransactions, HintContent]
  )

  return (
    <div>
      <div className={styles.tableHeader}>
        <div>
          {statusType === Paid ? <PaidSvg /> : <UnpaidSvg />}
          <h2>Dokumenty {statusType === Paid ? 'zapłacone' : 'do zapłaty'} </h2>
        </div>
        <Sort sort={sort} setSort={setSort} loadInvoices={_loadInvoices} />
      </div>

      <TableContainer
        className={styles.resizableTableContainer}
        style={{ height: tableHeight }}
      >
        <CustomScrollBar minHeight={tableHeight}>
          <SizeMe monitorHeight>
            {({ size }) => {
              setTimeout(() => {
                size.height && setTableHeight(size.height + SCROLL_SIZE * 1.25)
              }, 0)
              return (
                <>
                  {/* Loader */}
                  {isLoading && renderLoader(size.height ?? TABLE_HEIGHT)}

                  {/* No results */}
                  {isEmpty(invoices) &&
                    renderInvoicesNotFound(
                      statusType as 'Unpaid' | 'Paid',
                      filter,
                      isLoading,
                      defaultFilter
                    )}

                  {/* Results table */}
                  {!isLoading &&
                    renderResults(statusType as 'Paid' | 'Unpaid', invoices)}
                </>
              )
            }}
          </SizeMe>
        </CustomScrollBar>
      </TableContainer>

      {/* Unpaid invoices amount summary */}
      {statusType === Unpaid &&
        !isEmpty(invoices) &&
        renderPaymentSummary(isLoading, checkedAmount)}

      {/* Pagination */}
      <div style={{ marginTop: 36 }}>
        <CustomPagination
          page={page}
          numberOfPages={pagination.numberOfPages}
          onChange={(e: React.ChangeEvent<unknown>, val: number) => {
            if (val !== page) {
              _loadInvoices(false, { start: (val - 1) * pageSize })
            }
          }}
          loading={isLoading}
        />
      </div>
    </div>
  )
}
