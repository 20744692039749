import React from 'react'
import { Grid, Typography, GridProps } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EditSvg } from '@images/icons/other/EditPencil.svg'
import useStyles from './Setting.style'

export interface ISettingProps extends GridProps {
  label: string | JSX.Element
  id?: string
  value?: string | JSX.Element
  border?: boolean
  wrapTitle?: boolean
  onEditClick?: () => void
}

export const Setting: React.FC<ISettingProps> = ({
  label,
  value,
  id,
  border,
  wrapTitle,
  onEditClick,
  ...props
}) => {
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <Grid
      className={`${styles.wrapper} ${border ? '' : styles.noBorder}`}
      container
      direction="column"
      id={id}
      item
      {...props}
    >
      <Grid container alignItems="center">
        <Typography variant="inherit" className={styles.label}>
          {label}
        </Typography>
      </Grid>
      <Grid container alignItems="center">
        <div className={`${styles.title} ${wrapTitle ? styles.breakWord : ''}`}>
          {value || t('MY_SETTINGS.NOT_PROVIDED')}
          {onEditClick && (
            <EditSvg className={styles.icon} onClick={onEditClick} />
          )}
        </div>
      </Grid>
    </Grid>
  )
}
