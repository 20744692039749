import React, { useState, useEffect } from 'react'
import { Grid, Typography, GridProps, Collapse } from '@material-ui/core'
import { duration } from '@material-ui/core/styles/transitions'
import useStyles from './ErrorMessage.style'

export interface IErrorMessageProps extends GridProps {
  title?: string
  className?: string
}

const Button: React.FC<IErrorMessageProps> = ({
  className = '',
  title,
  ...props
}) => {
  const [message, setMessage] = useState(title)
  const styles = useStyles()

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (title) return setMessage(title)
    if (!title) {
      timer = setTimeout(() => {
        setMessage(title)
      }, duration.standard)
    }
    return () => clearTimeout(timer)
  }, [title])
  return (
    <Grid {...props} className={`${styles.wrapper} ${className}`}>
      <Collapse in={Boolean(title)} timeout={duration.standard}>
        <Typography className={styles.message}>{message}</Typography>
      </Collapse>
    </Grid>
  )
}

export default Button
