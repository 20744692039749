import { makeStyles } from '@material-ui/core'

const WIDTH = 300
const HEIGHT = 215

export default makeStyles(theme => ({
  icon: {
    width: WIDTH,
    height: HEIGHT,
    borderRadius: 24,
    [theme.breakpoints.down('md')]: {
      width: WIDTH * 0.9,
      height: HEIGHT * 0.9,
    },
    [theme.breakpoints.down('sm')]: {
      width: WIDTH * 0.8,
      height: HEIGHT * 0.8,
    },
    [theme.breakpoints.down('xs')]: {
      width: WIDTH * 0.7,
      height: HEIGHT * 0.7,
    },
  },
}))
