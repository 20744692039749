import {
  UseAutocompleteProps as MaterialAutocompleteProps,
  Autocomplete as MaterialAutocomplete,
} from '@material-ui/lab'
import React, { useEffect } from 'react'
import StyledCheckbox from '../Checkbox'
import { ReactComponent as CheckedSvg } from '@images/icons/checkbox/Checked.svg'
import { ReactComponent as UncheckedSvg } from '@images/icons/checkbox/Unchecked.svg'
import {
  Box,
  CircularProgress,
  InputAdornment,
  Paper,
  Popper,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Carousel from '../Carousel'
import useStyles from './Autocomplete.style'
import { ExpandMore, Search, Close } from '@material-ui/icons'
import FormikField from '@shared/components/modals/Modal/FormikField/FormikField'
import { ErrorPosition } from '@shared/components/modals/Modal/TextField'

export interface Classes {
  root?: string
  carouselOuterWrapper?: string
  carouselInnerWrapper?: string
  popper?: string
  inputRoot?: string
}

interface AutocompleteProps<T> {
  options: T[]
  selectedOptions?: T[]
  stringify: (item: T) => string
  children?: JSX.Element[] | JSX.Element
  disabled?: boolean
  name?: string
  error?: string
  classes?: Classes
  carousel?: boolean
  initialValue?: string
  errorPosition?: ErrorPosition
  label?: string
  loading?: boolean
  customNoOptionText?: string
}

export const Autocomplete = <T extends object | string>({
  multiple = false,
  options,
  filterOptions,
  onChange,
  value,
  stringify,
  selectedOptions,
  disabled = false,
  children,
  name,
  error,
  classes = {},
  carousel = true,
  initialValue = '',
  getOptionSelected,
  freeSolo,
  onInputChange,
  errorPosition,
  customNoOptionText,
  label,
  loading,
}: AutocompleteProps<T> &
  MaterialAutocompleteProps<
    T,
    boolean | undefined,
    undefined,
    boolean | undefined
  >) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const [inputValue, setInputValue] = React.useState<string>(initialValue)
  const [autocompleteValue, setAutocompleteValue] = React.useState<T | null>(
    value as T
  )

  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])
  return (
    <div className={classes.root}>
      <MaterialAutocomplete
        multiple={multiple}
        options={options}
        disableCloseOnSelect={!!multiple}
        getOptionLabel={stringify}
        getOptionSelected={getOptionSelected}
        freeSolo={freeSolo}
        onChange={(event, newValue, reason, details) => {
          if (reason !== 'remove-option') {
            onChange && onChange(event, newValue, reason)
            if (!multiple) {
              setAutocompleteValue(newValue as T)
              setInputValue(newValue ? stringify(newValue as T) : '')
            }
          } else if (event.type === 'click') {
            onChange && onChange(event, newValue, reason)
            if (!multiple) {
              setAutocompleteValue(null)
            }
          }
        }}
        renderTags={() => null}
        value={multiple ? value : autocompleteValue}
        inputValue={inputValue}
        onInputChange={(event, value, reason) => {
          if (reason !== 'reset' || !multiple) {
            setInputValue(value)
            onInputChange && onInputChange(event, value, reason)
          }
        }}
        disabled={disabled}
        clearOnBlur={false}
        disableListWrap
        disablePortal
        renderOption={
          multiple
            ? (option, { selected }) => (
                <React.Fragment>
                  <StyledCheckbox
                    icon={<UncheckedSvg />}
                    checkedIcon={<CheckedSvg />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {stringify(option)}
                </React.Fragment>
              )
            : undefined
        }
        renderInput={params => {
          return (
            <FormikField
              {...params}
              name={name ?? ''}
              value={inputValue}
              custom={true}
              autocomplete={true}
              label={
                <>
                  {label}{' '}
                  {loading && (
                    <CircularProgress size={14} className={styles.loading} />
                  )}
                </>
              }
              errorPosition={errorPosition}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: inputValue ? (
                  <InputAdornment position="end">
                    <Close
                      onClick={event => {
                        setInputValue('')
                        if (!multiple) {
                          setAutocompleteValue(null)
                          onInputChange && onInputChange(event, '', 'reset')
                        }
                      }}
                      className={styles.close}
                    />
                    {
                      (params.InputProps.endAdornment as JSX.Element).props
                        .children[1]
                    }
                  </InputAdornment>
                ) : multiple ? (
                  params.InputProps.endAdornment
                ) : (
                  (params.InputProps.endAdornment as JSX.Element).props
                    .children[1]
                ),
              }}
            />
          )
        }}
        noOptionsText={
          customNoOptionText === undefined
            ? t('NO_OPTIONS')
            : customNoOptionText
        }
        classes={{
          root: styles.root,
          inputRoot: `${styles.inputRoot} ${classes.inputRoot ?? ''}`,
          popper: `${styles.popper} ${classes.popper ?? ''}`,
          option: styles.option,
          noOptions: styles.noOptions,
        }}
        popupIcon={<ExpandMore />}
        PopperComponent={props => <Popper {...props} placement="bottom" />}
      />
      {error && <Paper className={styles.error}>{error}</Paper>}
      {carousel && (
        <Box
          className={`${classes.carouselOuterWrapper} ${
            selectedOptions?.length === 0 && styles.noItemsWrapper
          }`}
        >
          <Carousel
            length={selectedOptions ? selectedOptions.length : 0}
            className={classes.carouselInnerWrapper}
          >
            {children}
          </Carousel>
        </Box>
      )}
    </div>
  )
}
