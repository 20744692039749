import { makeStyles, Theme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import { duration } from '@material-ui/core/styles/transitions'
import { tableTextColor } from '@mbok/styles/constants'

const TEXTAREA_HEIGHT = 207

const getTextAreaStyles = (theme: Theme): CreateCSSProperties<{}> => ({
  width: '100%',
  border: '1.8px solid',
  borderColor: '#CECECE',
  backgroundColor: '#F9F9F9',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  boxSizing: 'border-box',
  borderRadius: 4,
  height: `${TEXTAREA_HEIGHT}px !important`,
  transition: `border-color ${duration.standard / 1000}s`,
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  letterSpacing: 0.2,
  resize: 'none',
  outline: 'none',
  color: tableTextColor,
  fontSize: 15,
  '&::placeholder': {
    color: tableTextColor,
    letterSpacing: 0.2,
    opacity: 0.55,
  },
})

export default makeStyles(theme => ({
  textarea: {
    ...getTextAreaStyles(theme),
  },
  textareaError: {
    ...getTextAreaStyles(theme),
    borderColor: theme.palette.error.main,
  },
}))
