import { makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

export default makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      padding: '28px 20px',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    margin: '0 auto',
    padding: theme.spacing(2, 0, 4),
    '& h1': {
      margin: 0,
      fontSize: 24,
      textAlign: 'center',
      fontWeight: 700,
    },
    '& p': {
      margin: theme.spacing(2, 0, 0),
      fontSize: 18,
      opacity: 0.75,
      textAlign: 'center',
      color: tableTextColor,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  content: {
    padding: theme.spacing(2, 0),
  },
  measurementPointsWrapper: {
    margin: theme.spacing(0, 2),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))
