export enum StoreReducers {
  userAccount = 'userAccount',
  userConsents = 'userConsents',
  ui = 'ui',
  payments = 'payments',
  persistedSettings = 'persistedSettings',
  policies = 'policies',
  offers = 'offers',
  administration = 'administration',
  trustedMfaDevices = 'trustedMfaDevices',
}
