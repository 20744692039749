import { useDispatchOnCustomerNumberChange } from '@core/store/customerNumberDispatcher'
import { getNoPdfDocuments } from '@core/store/policies/policiesSelectors'
import { getPolicyDocument } from '@core/store/policies/policiesThunks'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import { DocumentType } from '@shared/contracts/documentType'
import { useCallback } from 'react'

export type ReducedConsent = {
  type: DocumentType
  content: string
  pdf?: string
}

const useConfirmActivationPointFormDataSource = () => {
  const dispatch = useAppDispatch()

  const policyDocuments = useAppSelector(getNoPdfDocuments)

  const documentTypesToFetch: DocumentType[] = [
    'EInvoice',
    'EInvoiceTermsAcceptance',
  ]

  const consents = policyDocuments.map(
    ({ type, content }): ReducedConsent => {
      if (!type) {
        return {} as ReducedConsent
      }
      const pdf =
        type === DocumentType.EInvoiceTermsAcceptance
          ? `Documents/${DocumentType.EInvoiceTerms}/pdf`
          : null
      return {
        type,
        content,
        pdf,
      } as ReducedConsent
    }
  )

  const loadData = useCallback(() => {
    Promise.all(documentTypesToFetch.map(d => dispatch(getPolicyDocument(d))))
  }, [dispatch, documentTypesToFetch])


  useDispatchOnCustomerNumberChange(loadData)

  return {
    consents,
  }
}

export default useConfirmActivationPointFormDataSource
