import { useApi } from '@core/apiClient/apiClient'
import { useLogout } from '@core/auth/useLogout'
import {
  hasNotAcceptedRequiredOrSetConsentsSelector,
  notAcceptedRequiredConsentsSelector,
  notSetOptionalConsentsSelector,
} from '@core/store/entity/userConsents/userConsentsSelectors'
import { userConsentsGetAll } from '@core/store/entity/userConsents/userConsentsThunks'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Theme,
} from '@material-ui/core'
import StyledButton, { Button } from '@shared/components/Button'
import { Consent } from '@shared/contracts/models'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Consents } from '../Consents'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
  })
)

export const AcceptRequiredConsentsModal: React.FC = () => {
  const styles = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const requiredConsents = useAppSelector(notAcceptedRequiredConsentsSelector)
  const optionalConsents = useAppSelector(notSetOptionalConsentsSelector)
  const hasNotAcceptedConsents = useAppSelector(
    hasNotAcceptedRequiredOrSetConsentsSelector
  )

  const [executeSetConsent] = useApi({
    omitConsents: true,
    config: {
      method: 'put',
    },
  })

  const dispatch = useAppDispatch()
  const logout = useLogout()

  const hasAllRequiredConsentsSelected = (
    requiredConsents: (Consent | undefined)[],
    values: { [id: string]: boolean }
  ): boolean => {
    if (!requiredConsents) {
      return true
    } else {
      return requiredConsents.every(
        consent => consent !== undefined && values[consent.id] === true
      )
    }
  }

  const onSubmit = (
    values: { [id: string]: boolean },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true)
    Promise.all(
      optionalConsents.concat(requiredConsents).map(consent => {
        return executeSetConsent({
          url: 'Accounts/consents/' + consent?.id,
          data: {
            isAccepted: consent?.id && values[consent.id],
          },
        })
      })
    )
      .then(() => {
        dispatch(userConsentsGetAll())
      })
      .catch(() => {
        enqueueSnackbar(
          'Zapisywanie zgód nie powiodło się, spróbuj ponownie lub skontaktuj się z biurem obsługi jeśli sytuacja powtarza się.',
          { variant: 'error' }
        )
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      {({
        isValid,
        submitForm,
        submitCount,
        setSubmitting,
        isSubmitting,
        values,
      }) => (
        <Form>
          <Dialog
            aria-describedby="form-modal"
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open={hasNotAcceptedConsents}
          >
            <DialogContent classes={{ root: styles.dialogContent }}>
              <div>
                {requiredConsents.length > 0 && (
                  <>
                    <h1>Wymagane zgody</h1>
                    <Consents consents={requiredConsents as Consent[]} />
                  </>
                )}
                {optionalConsents.length > 0 && (
                  <>
                    <h1>Opcjonalne zgody</h1>
                    <Consents consents={optionalConsents as Consent[]} />
                  </>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ marginRight: 4 }}
                variant="text"
                onClick={() => {
                  logout()
                }}
              >
                Wyloguj się
              </Button>
              <StyledButton
                color="primary"
                variant="contained"
                disabled={
                  (submitCount > 0 && !isValid) ||
                  !hasAllRequiredConsentsSelected(requiredConsents, values)
                }
                type="submit"
                onClick={() => {
                  if (!isSubmitting) {
                    submitForm().then(() => {
                      onSubmit(values, setSubmitting)
                    })
                  }
                }}
                loading={isSubmitting}
              >
                Zapisz
              </StyledButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  )
}
