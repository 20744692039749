import { useDispatchOnCustomerNumberChange } from '@core/store/customerNumberDispatcher'
import {
  getAllUnpaidDocumentsSelector,
  getDocumentsByIdsSelector,
  getDocumentsLoadingSelector,
} from '@core/store/entity/documents/documentsSelector'
import { documentsGetAll } from '@core/store/entity/documents/documentsThunks'
import {
  getMeasurementPointsByIdSelector,
  measurementPointsLoadingSelector,
} from '@core/store/entity/measurementPoints/measurementPointsSelectors'
import { measurementPointsGetAll } from '@core/store/entity/measurementPoints/measurementPointsThunks'
import { getNewsLoadingSelector } from '@core/store/entity/news/newsSelector'
import { newsGetAll } from '@core/store/entity/news/newsThunks'
import {
  getActiveAgreementsSelector,
  getEInvoiceAgreementsSelector,
  getHistoricalAgreementsSelector,
  getNoEInvoiceAgreementsSelector,
  userAgreementsDictionarySelector,
  userAgreementsLoadingSelector,
} from '@core/store/entity/userAgreements/userAgreementsSelectors'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import { getAlreadySavedInvoiceTypeRequests } from '@core/store/ui/uiSelectors'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  AccountingDocumentListItem,
  AccountingDocumentsSortType,
  MeasurementPointListItem,
  News,
} from '@shared/contracts/models'
import { useCallback, useState } from 'react'
import ActivationPoint from '@shared/components/forms/eInvoice/ActivationPoint'
import { Modals } from './MyCenter'

export const useDataSource = () => {
  const dispatch = useAppDispatch()
  const account = useAppSelector(state => ({
    data: state.userAccount.account,
    loading: state.userAccount.loadingPending > 0,
  }))

  const newsLoading = useAppSelector(getNewsLoadingSelector)
  const [latestNewsState, setLatestNewsState] = useState<News[]>([])

  const historicalAgreements = useAppSelector(getHistoricalAgreementsSelector)
  const activeAgreements = useAppSelector(getActiveAgreementsSelector)
  const agreements = useAppSelector(userAgreementsDictionarySelector)
  const noEInvoiceAgreements = useAppSelector(getNoEInvoiceAgreementsSelector)
  const eInvoiceAgreements = useAppSelector(getEInvoiceAgreementsSelector)

  const agreementsLoading = useAppSelector(userAgreementsLoadingSelector)
  const [latestDocuments, setLatestDocuments] = useState<string[]>([])
  const documentsLoading = useAppSelector(getDocumentsLoadingSelector)
  const documentsData: AccountingDocumentListItem[] = useAppSelector(
    getDocumentsByIdsSelector(latestDocuments)
  )
  const unpaidDocuments: AccountingDocumentListItem[] = useAppSelector(
    getAllUnpaidDocumentsSelector
  )

  const alreadySavedInvoiceTypeRequests = useAppSelector(
    getAlreadySavedInvoiceTypeRequests
  )

  const [measurementsPointsIds, setMeasurementsPointsIds] = useState<string[]>(
    []
  )
  const measurements: MeasurementPointListItem[] = useAppSelector(
    getMeasurementPointsByIdSelector(measurementsPointsIds)
  )
  const measurementsLoading = useAppSelector(measurementPointsLoadingSelector)

  const [selectedIndex, setSelectedIndex] = useState(0)

  const [correspondenceEmail, setCorrespondenceEmail] = useState<
    string | undefined
  >(undefined)

  const [selectedMeasurementPoints, setSelectedMeasurementPoints] = useState<
    MeasurementPointListItem[]
  >([])

  const [
    selectedActivationPointOption,
    setSelectedActivationPointOption,
  ] = useState<ActivationPoint | undefined>(undefined)

  const [openModalKey, setOpenModalKey] = useState<Modals | undefined>(undefined)

  const loadMeasurementPoints = useCallback(
    (customerNumberChanged: boolean, selectedIndex?: number) => {
      dispatch(measurementPointsGetAll())
        .then(unwrapResult)
        .then(([payload]) => {
          setMeasurementsPointsIds(
            payload.results?.map(result => result.measurementPointId ?? '') ??
              []
          )
          setSelectedIndex(selectedIndex ?? 0)
        })
    },
    [dispatch]
  )

  const loadData = useCallback(
    (customerNumberChanged: boolean) => {
      dispatch(
        newsGetAll({
          keepPrevious: !customerNumberChanged,
          type: 'All',
          limit: 5,
        })
      )
        .then(unwrapResult)
        .then(([payload]) => {
          const [newsFirst, newsSecond] = payload.results ?? []
          if (newsFirst || newsSecond)
            setLatestNewsState([newsFirst, newsSecond])
        })
      dispatch(
        documentsGetAll({
          keepPrevious: !customerNumberChanged,
          limit: 6,
          sortBy: AccountingDocumentsSortType.DueDate,
          sortDesc: true,
        })
      )
        .then(unwrapResult)
        .then(([payload]) => {
          setLatestDocuments(
            payload.results?.map(result => result.accountingDocumentId ?? '') ??
              []
          )
        })
    },
    [dispatch]
  )

  useDispatchOnCustomerNumberChange(loadData)
  useDispatchOnCustomerNumberChange(loadMeasurementPoints)

  return {
    account,
    agreements: {
      data: agreements,
      historicalAgreements,
      activeAgreements,
      noEInvoiceAgreements,
      eInvoiceAgreements,
      loading: agreementsLoading,
    },
    documents: {
      lastData: documentsData,
      unpaid: unpaidDocuments,
      loading: documentsLoading,
    },
    measurementPoints: {
      data: measurements,
      loading: measurementsLoading,
      selectedIndex,
      setSelectedIndex,
      reloadData: loadMeasurementPoints,
      selectedMeasurementPoints,
      setSelectedMeasurementPoints,
    },
    news: {
      data: latestNewsState,
      loading: newsLoading,
    },
    alreadySavedInvoiceTypeRequests,
    correspondenceEmail: {
      correspondenceEmail,
      setCorrespondenceEmail,
    },
    activationPoint: {
      selectedActivationPointOption,
      setSelectedActivationPointOption,
    },
    modals: {
      openModalKey,
      setOpenModalKey
    }
  }
}
