import { EntityReducers } from '../../EntityReducers'
import { apiAction, handleLoadingAction } from './entityThunksFactory'
import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  EntityAdapter,
  AsyncThunk,
} from '@reduxjs/toolkit'
import { BaseState } from '../entityReducerHelpers'

export interface BaseGetAllPayload {
  keepPrevious?: boolean
}

export const getAllThunk = <
  EntityType,
  PayloadType extends BaseGetAllPayload | undefined = undefined,
  ResponseType = EntityType[]
>(
  name: EntityReducers | undefined,
  getAction: apiAction<PayloadType, ResponseType>
) => {
  return createAsyncThunk<[ResponseType, boolean], PayloadType, {}>(
    `${name}/fetchAll`,
    async (payload: PayloadType, thunkApi) => {
      const [execute] = getAction({ payload, thunkApi })
      const response = await execute()
      return [response.data, !!payload?.keepPrevious]
    }
  )
}

export const getAllExtraReducers = <
  EntityType,
  PayloadType = undefined,
  ApiResponseType = EntityType[]
>(
  thunk: AsyncThunk<[ApiResponseType, boolean], PayloadType, {}>,
  builder: ActionReducerMapBuilder<BaseState<EntityType>>,
  adapter: EntityAdapter<any>,
  extractArrayFromResponse?: (response: ApiResponseType) => EntityType[]
) => {
  if (thunk) {
    builder.addCase(
      thunk.fulfilled,
      (state, { payload: [data, keepPrevious] }) => {
        state.loadingPending > 0 && state.loadingPending--
        const saveAction = keepPrevious ? adapter.upsertMany : adapter.setAll

        if (extractArrayFromResponse) {
          saveAction(state, extractArrayFromResponse(data))
        } else {
          saveAction(state, data)
        }
      }
    )
    handleLoadingAction(thunk, builder)
  }
}
