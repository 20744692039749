import {
  Action,
  configureStore,
  getDefaultMiddleware,
  SerializableStateInvariantMiddlewareOptions,
  ThunkAction,
} from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { USER_FOUND } from 'redux-oidc'
import { showModal } from '@core/store/ui/actions'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import { actionsInterceptorMiddleware } from './middleware/actionsInterceptorMiddleware'
import rootReducer, { RootState } from './rootReducer'

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: [
        showModal.type,
        USER_FOUND,
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
      ],
      ignoredPaths: ['oidc.user', 'ui'],
    } as SerializableStateInvariantMiddlewareOptions,
  }).concat([actionsInterceptorMiddleware.middleWareFn]),
  devTools: process.env.NODE_ENV === 'development',
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types

export const useAppSelector = <State = RootState, PartState = unknown>(
  selector: (state: State) => PartState,
  equalityFn: (left: PartState, right: PartState) => boolean = (
    left: PartState,
    right: PartState
  ) => left === right
): ReturnType<typeof selector> =>
  useSelector<State, ReturnType<typeof selector>>(selector, equalityFn) // Export a hook that can be reused to resolve types

export default store

export type AppStore = typeof store

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
