import { makeStyles, Theme } from '@material-ui/core'

const WIDTH = 1000
const HEIGHT = 667.5

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    maxHeight: '100%',
  },
  animation: {
    height: HEIGHT * 0.7,
    width: WIDTH * 0.7,
    [theme.breakpoints.down('xl')]: {
      height: HEIGHT * 0.7,
      width: WIDTH * 0.7,
    },
    [theme.breakpoints.down('lg')]: {
      height: HEIGHT * 0.65,
      width: WIDTH * 0.65,
    },
    [theme.breakpoints.down('md')]: {
      height: HEIGHT * 0.55,
      width: WIDTH * 0.55,
    },
    [theme.breakpoints.down('sm')]: {
      height: HEIGHT * 0.45,
      width: WIDTH * 0.45,
    },
    [theme.breakpoints.down('xs')]: {
      height: HEIGHT * 0.28,
      width: WIDTH * 0.28,
    },
    borderRadius: 12,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.common.white,
    fontWeight: 800,
    '& h2': {
      margin: 0,
      fontSize: 44,
      textAlign: 'center',
      textShadow: `-1px 1px 4px ${theme.palette.common.black}`,
    },
    [theme.breakpoints.down('md')]: {
      '& h2': {
        fontSize: 36,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& h2': {
        fontSize: 32,
      },
    },
  },
}))
