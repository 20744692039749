import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'

export const uiSelector = (state: RootState) => state.ui

export const uiModalSelector = createSelector(uiSelector, state => state.modal)

export const isErrorModalOpen = createSelector(
  uiModalSelector,
  modal => modal.isOpen && modal.type === 'error'
)

export const getAlreadySavedFormFields = createSelector(
  uiSelector,
  ui => ui.alreadySavedFormFields
)

export const getAlreadySavedInvoiceTypeRequests = createSelector(
  uiSelector,
  ui => ui.alreadySavedInvoiceTypeRequests
)

export const getIsChangePhoneAndModifyMfaPath = createSelector(
  uiSelector,
  state => state.isChangePhoneAndModifyMfaPath
)
