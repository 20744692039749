import { IPath, SiderPaths } from '@core/routes/routesPaths'
import logo from '@images/logo.png'
import {
  Fade,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/KeyboardArrowLeft'
import { backdropBg } from '@mbok/styles/constants'
import { valuesIn } from 'ramda'
import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    fill: theme.palette.text.secondary,
    fontWeight: 600,
    textDecoration: 'none',
    fontSize: 17,
    position: 'relative',
    margin: 0,
    padding: theme.spacing(3, 6),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.palette.primary.main,
      '& svg': {
        fill: theme.palette.primary.main,
      },
      '&::before': {
        transform: 'translateX(-4px)',
        opacity: 1,
      },
    },
    '&::before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: 0,
      transform: 'translateX(-25px)',
      opacity: 0,
      width: 4,
      height: '100%',
      background: theme.palette.primary.main,
      boxShadow: `-20px 0px 0px 20px ${theme.palette.primary.light}`,
      transition: 'opacity 0.3s, transform 0.3s',
    },
  },
  activeLink: {
    color: theme.palette.primary.main,
    pointerEvents: 'none',
    '&::before': {
      transform: 'translateX(-4px)',
      opacity: 1,
    },
  },
  iconActive: {
    '& svg path': {
      fill: theme.palette.primary.main,
    },
  },
  icon: {
    height: 20,
    width: 'auto',
    padding: theme.spacing(0, 2.5),
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
  sider: {
    width: 230,
    height: '100vh',
    boxShadow:
      '0 7px 9px 3px rgba(105,105,105,0.03), 0 2px 3px 0 rgba(66,65,65,0.05)',
    background: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      zIndex: 11,
      width: 300,
    },
    [theme.breakpoints.down('xs')]: {
      width: '75vw',
    },
  },
  siderTransitions: {
    transition: 'width 0.3s, opacity 0.3s',
  },
  hidden: {
    width: 0,
    opacity: 0,
    overflow: 'hidden',
  },
  logoContainer: {
    display: 'flex',
    padding: theme.spacing(10, 7.5, 7.5),
  },
  logo: {
    width: '100%',
    alignSelf: 'flex-start',
    maxWidth: 175,
    cursor: 'pointer',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
    background: backdropBg,
  },
  closeIcon: {
    marginRight: theme.spacing(2.5),
    height: 32,
    fontSize: 32,
    cursor: 'pointer',
  },
}))

const Links: React.FC<{ closeSider: () => void }> = ({ closeSider }) => {
  const styles = useStyles()
  const [isHovered, setIsHovered] = useState(false)

  return (
    <>
      {valuesIn<IPath>(SiderPaths).map(
        ({ path, routeLinkTitle, RouteLinkIcon }) => {
          return (
            <NavLink
              onClick={closeSider}
              to={path}
              className={styles.link}
              activeClassName={`${!isHovered ? styles.activeLink : ''} ${
                styles.iconActive
              }`}
              key={path}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {RouteLinkIcon && <RouteLinkIcon className={styles.icon} />}
              {routeLinkTitle}
            </NavLink>
          )
        }
      )}
    </>
  )
}

export const Sider: React.FC<{
  isOpen?: boolean
  close: () => void
}> = ({ isOpen, close }) => {
  const styles = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()

  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setRendered(true)
    }, 0)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  const navbar = (
    <div
      className={`${styles.sider} ${mobile && !isOpen && styles.hidden} ${
        rendered && styles.siderTransitions
      }`}
    >
      <div className={styles.logoContainer}>
        {mobile && <CloseIcon className={styles.closeIcon} onClick={close} />}
        <img
          src={logo}
          alt="Logo"
          className={styles.logo}
          onClick={() => {
            history.push(SiderPaths.home.path)
            close()
          }}
        />
      </div>
      {<Links closeSider={close} />}
    </div>
  )

  return (
    <>
      {navbar}
      <Fade in={mobile && !!isOpen}>
        <div className={styles.overlay} onClick={close} />
      </Fade>
    </>
  )
}
