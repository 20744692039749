import { makeStyles, Theme } from '@material-ui/core'
import {
  tableTextColor,
} from '@mbok/styles/constants'
import { eInvoiceModalWidth } from '@shared/components/forms/eInvoice/style'


export default makeStyles((theme: Theme) => ({
  header: {
    fontSize: 20,
  },
  emptyContracts: {
    height: 180,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 19,
    color: tableTextColor,
    opacity: 0.55,
  },
  modalWrapper: {
    width: eInvoiceModalWidth,
    height: '80%',
  },
}))
