import React from 'react'
import Slider from 'react-slick'
import { Settings } from 'react-slick'
import useStyles from './Carousel.style'
import { Toggler } from '@shared/components/Toggler/Toggler'

interface CarouselProps {
  settings?: Settings
  length: number
  className?: string
  showToggler?: boolean
}

export const Carousel: React.FC<CarouselProps> = ({
  settings,
  children,
  length,
  className,
  showToggler = true
}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const defaultSettings: Settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    adaptiveHeight: true,
    slidesToShow: 1,
    variableWidth: true,
    initialSlide: currentSlide,
  }
  
  const styles = useStyles()
  const slider = React.useRef<Slider>(null)

  const setNext = () => {
    if (slider.current) {
      slider.current.slickNext()
    }
  }

  const setPrev = () => {
    if (slider.current) {
      slider.current.slickPrev()
    }
  }

  return (
    <>
      <Slider
        {...(settings === undefined ? defaultSettings : settings)}
        className={`${styles.wrapper} ${className ?? ''}`}
        ref={slider}
        afterChange={(currentSlide: number) => setCurrentSlide(currentSlide)}
      >
        {children}
      </Slider>
      {showToggler && length > 0 && (
        <div>
          <Toggler
            setNext={setNext}
            setPrev={setPrev}
            index={currentSlide}
            total={length}
            className={styles.toggler}
          />
        </div>
      )}
      {!showToggler && (
        <div className={styles.emptyToggler}>
        </div>
      )}
    </>
  )
}
