import React from 'react'
import {
  Grid,
  TextareaAutosize,
  TextareaAutosizeProps,
} from '@material-ui/core'
import ErrorMessage from '../ErrorMessage'
import useStyles from './TextArea.style'

export interface ITextAreaProps extends TextareaAutosizeProps {
  name: string
  formError?: string
}

const TextArea: React.FC<ITextAreaProps> = ({
  className = '',
  formError,
  ...props
}) => {
  const styles = useStyles()
  return (
    <Grid container>
      <ErrorMessage title={formError} />
      <TextareaAutosize
        className={`${
          formError ? styles.textareaError : styles.textarea
        } ${className}`}
        {...props}
      />
    </Grid>
  )
}

export default TextArea
