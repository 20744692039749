import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { ICustomerNumber } from '@shared/contracts/CustomerNumbers'
import { EntityReducers } from '../EntityReducers'
import {
  getBaseState,
  getEntityAdapterReducers,
  ExtendedBaseState,
} from '../helpers/entityReducerHelpers'
import { getAllExtraReducers } from '../helpers/thunks/getAllThunk'
import { customerNumbersGetAll } from './customerNumbersThunks'
import { persistReducer, PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { addCustomerNumbersSideEffects } from './customerNumbersSideEffects'

export interface additionalState {
  selectedCustomerNumber?: string
}

const entityAdapter = createEntityAdapter<ICustomerNumber>({
  selectId: el => el as string,
})

export const customerNumbersSlice = createSlice({
  name: EntityReducers.customerNumbers,
  initialState: {
    ...getBaseState<ICustomerNumber, additionalState>(entityAdapter, {
      selectedCustomerNumber: undefined,
    }),
  },
  reducers: {
    ...getEntityAdapterReducers<ICustomerNumber>(entityAdapter),
    setCustomerNumber: (state, { payload }) => {
      if (state.ids.indexOf(payload) < 0) {
        state.selectedCustomerNumber = state.ids[0] as string
        return
      }

      state.selectedCustomerNumber = payload
    },
  },
  extraReducers: builder => {
    getAllExtraReducers<ICustomerNumber>(
      customerNumbersGetAll,
      builder,
      entityAdapter
    )
  },
})

addCustomerNumbersSideEffects()

export const customerNumberActions = customerNumbersSlice.actions

const persistConfig: PersistConfig<ExtendedBaseState<
  ICustomerNumber,
  additionalState
>> = {
  key: EntityReducers.customerNumbers,
  storage: storage,
  whitelist: ['selectedCustomerNumber'],
}

export const persisteCustomerNumbersReducer = persistReducer(
  persistConfig,
  customerNumbersSlice.reducer
)
