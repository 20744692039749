import { makeStyles } from '@material-ui/core'

const ERROR_MESSAGE_HEIGHT = 25

export default makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    minHeight: ERROR_MESSAGE_HEIGHT,
    '& *': {
      width: '100%',
    },
  },
  message: {
    color: theme.palette.error.main,
    minHeight: ERROR_MESSAGE_HEIGHT,
    width: '100%',
    fontSize: 12,
    lineHeight: `${ERROR_MESSAGE_HEIGHT}px`,
  },
}))
