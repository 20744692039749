import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { tableTextColor } from '@mbok/styles/constants'

const PAPER_MAX_WIDTH = 544

export default makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      maxWidth: PAPER_MAX_WIDTH,
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        maxWidth: PAPER_MAX_WIDTH - 44,
      },
      fontSize: 14,
      color: tableTextColor,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        margin: 0,
      },
      '& svg': {
        marginLeft: theme.spacing(1),
      },
    },
  })
)
