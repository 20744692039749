import { AppPaths, IPath, SiderPaths } from '@core/routes/routesPaths'
import { Collapse, Paper, useMediaQuery, useTheme } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { useDataSource } from '@shared/components/summaryCards/dataSource'
import { Sider } from '@shared/components/Sider'
import { compose, find, valuesIn } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { positionValues, Scrollbars } from 'react-custom-scrollbars'
import { useHistory, useLocation } from 'react-router'
import { AcceptRequiredConsentsModal } from '../modals/AcceptRequiredConsentsModal'
import CustomerServiceOfficeFab from '../poppers/CustomerServiceOffice'
import CookiesFloatingButton from '../CookiesFloatingButton'
import { BalanceCard } from '../summaryCards/MyBalanceCard'
import { MyAccountCard } from '../summaryCards/MyAccountCard'
import { MyDocumentsCard } from '../summaryCards/MyDocumentsCard'
import { PaymentsCard } from '../summaryCards/MyPaymentsCard'
import { TextLoader } from '../TextLoader'
import { UserMenu } from '../UserMenu'
import { ReactComponent as NotificationOffSvg } from '@images/icons/notification/Notification_Off.svg'
import { ReactComponent as NotificationSvg } from '@images/icons/notification/Notification.svg'
import useStyles from './LoggedAppLayout.style'
import { CustomerSelector } from '../customersSelector/CustomersSelector'
import CookiesConsent from '../cookiesConsent'
import DownloadMobileApp from '../DownloadMobileApp'
import { Button } from '../Button'
import { useAppSelector } from '@core/store/store'
import { getDesktopOffersByTypeSelector } from '@core/store/offers/offersSelectors'
import { ImageType } from '@core/store/offers/types'
import { SummaryCardWrapper } from '../summaryCards/SummaryCardWrapper'

export interface ILoggedAppLayoutProps {
  ref?: React.Ref<typeof LoggedAppLayout>
  topHeaderContent?: string | React.ReactNode
  topHeaderLoading?: boolean
  additionalTopElements?: string | React.ReactNode
}

export const LoggedAppLayout: React.FC<ILoggedAppLayoutProps> = ({
  children,
  topHeaderContent,
  additionalTopElements,
  topHeaderLoading,
}) => {
  const styles = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const onlySMMobile = useMediaQuery(theme.breakpoints.only('sm'))
  const xsMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const location = useLocation()
  const history = useHistory()
  const offers = useAppSelector(getDesktopOffersByTypeSelector(ImageType.top))

  const [scrollTop, setScrollTop] = useState(0)
  const [activePageName, setactivePageName] = useState('')
  const [menuOpen, setMenuOpen] = useState(false)
  const [showCards, setShowCards] = useState(false)
  const [dueDate, setDueDate] = useState<Date | false | null>(null)
  const [showAllCards, setShowAllCards] = useState(true)

  const {
    account,
    agreements,
    balance,
    payments,
    documents,
    unreadNews,
  } = useDataSource()

  useEffect(() => {
    const activePath = compose(
      find<IPath>(path => path.path === location.pathname),
      (paths: typeof AppPaths) => valuesIn<IPath>(paths)
    )(AppPaths)

    activePath?.routeLinkTitle && setactivePageName(activePath?.routeLinkTitle)
  }, [location.pathname])

  const handleUpdate = ({ scrollTop }: positionValues) => {
    setScrollTop(scrollTop)
  }

  useEffect(() => {
    if (offers && dueDate !== null) {
      setShowAllCards(Boolean(dueDate || offers))
    }
  }, [dueDate, offers])

  const topCardsContainer = useMemo(() => {
    return [
      <BalanceCard
        balanceData={balance.data}
        documents={documents.unpaid}
        loading={balance.loading || documents.loading || payments.loading}
      />,
      <PaymentsCard
        documents={documents.unpaid}
        loading={documents.loading}
        dueDateState={dueDate}
        setDueDateState={setDueDate}
        offersLoaded={Boolean(offers)}
      />,
      <MyAccountCard account={account.data} loading={account.loading} />,
      <MyDocumentsCard
        activeDocs={agreements.activeAgreements.length}
        oldDocs={agreements.historicalAgreements.length}
        loading={agreements.loading}
      />,
      <SummaryCardWrapper displayAsEmpty />,
    ]
  }, [
    balance,
    documents,
    payments,
    dueDate,
    offers,
    setDueDate,
    account,
    agreements,
  ])

  const cardsSplitter = useMemo(() => {
    const cardsToDisplay: JSX.Element[] = []
    if (showAllCards) {
      topCardsContainer.forEach((card, i) => {
        if (i < 4) cardsToDisplay.push(card)
      })
    } else {
      topCardsContainer.forEach((card, i) => {
        if (i !== 1) cardsToDisplay.push(card)
      })
    }
    return cardsToDisplay
  }, [topCardsContainer, showAllCards])

  return (
    <div className={styles.container}>
      <Sider isOpen={menuOpen} close={() => setMenuOpen(false)} />
      <div className={styles.contentWrapper}>
        <DownloadMobileApp />
        <div className={`${styles.nav} ${scrollTop > 0 && styles.scrolled}`}>
          {mobile && (
            <MenuIcon
              className={styles.menuIcon}
              onClick={() => {
                setMenuOpen(!menuOpen)
              }}
            />
          )}
          {!mobile && activePageName}
          <div className={styles.navRight}>
            <CustomerSelector />
            <div
              className={styles.notification}
              onClick={() => history.push(SiderPaths.news.path)}
            >
              {unreadNews ? <NotificationSvg /> : <NotificationOffSvg />}
            </div>
            <UserMenu />
          </div>
        </div>
        <Scrollbars
          // This will activate auto hide
          autoHide
          // Hide delay in ms
          autoHideTimeout={1000}
          // Duration for hide animation in ms.
          autoHideDuration={200}
          onUpdate={handleUpdate}
        >
          <div className={styles.topContainer}>
            {(topHeaderContent || topHeaderLoading) && (
              <Paper className={styles.pageHeader}>
                <TextLoader loading={!!topHeaderLoading}>
                  {topHeaderContent}
                </TextLoader>
              </Paper>
            )}
            <div className={styles.additionalTopElements}>
              <div className={styles.statusCards}>
                <div className={styles.statusCardsRow}>
                  {cardsSplitter[0]}
                  {xsMobile && (
                    <Button
                      className={styles.toggleBtn}
                      variant="contained"
                      color="primary"
                      onClick={() => setShowCards(prev => !prev)}
                    >
                      {showCards ? 'Ukryj' : 'Pokaż'} karty
                    </Button>
                  )}

                  {xsMobile ? (
                    <div>
                      <Collapse in={showCards}>{cardsSplitter[1]}</Collapse>
                    </div>
                  ) : (
                    <>{cardsSplitter[1]}</>
                  )}
                </div>
                {onlySMMobile && (
                  <Button
                    className={styles.toggleBtn}
                    variant="contained"
                    color="primary"
                    onClick={() => setShowCards(prev => !prev)}
                  >
                    {showCards ? 'Ukryj' : 'Pokaż'} karty
                  </Button>
                )}
                <div className={styles.statusCardsRow}>
                  <Collapse
                    in={showCards || !mobile}
                    classes={{
                      container: styles.wrapperContainer,
                      wrapper: styles.wrapperContainer,
                      wrapperInner: styles.wrapperInner,
                    }}
                  >
                    {cardsSplitter[2]}
                    {cardsSplitter[3]}
                  </Collapse>
                </div>
              </div>

              {additionalTopElements}
            </div>
          </div>
          <div className={styles.mainElements}>{children}</div>
        </Scrollbars>
      </div>
      <AcceptRequiredConsentsModal />
      <CustomerServiceOfficeFab />
      <CookiesConsent />
      <CookiesFloatingButton />
    </div>
  )
}
