import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'

export default makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: 0,
      maxWidth: '100%',
      borderRadius: 8,
    },
    gridElement: {
      alignItems: 'center',
      padding: theme.spacing(4, 2),
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: 0.61,
      color: tableTextColor + colorOpacityParser(75),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 0),
      },
    },
    flexEndAlignment: {
      alignSelf: 'flex-end',
    },
  })
)
