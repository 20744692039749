import {
  CircularProgress,
  Fade,
  makeStyles,
  Paper,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import {
  StatusColor,
  subHeadColor,
  tableTextColor,
} from '@mbok/styles/constants'
import StyledButton from '@shared/components/Button'
import { useLoadingState } from '@shared/hooks/loadingState'
import React, { useMemo } from 'react'

export interface ISummaryCardRow {
  header: string | React.ReactNode
  content?: string | React.ReactNode
  buttonHandler?: () => void
  buttonText?: string
  customButton?: React.ReactNode
}

export interface ISummaryCardWrapperProps {
  title?: string | JSX.Element
  Icon?: React.FC<
    React.SVGProps<SVGSVGElement> & { title?: string | undefined }
  >
  customIconPosition?: boolean
  rows?: ISummaryCardRow[]
  statusColor?: StatusColor
  loading?: boolean
  displayAsEmpty?: boolean
}

export const CARD_WIDTH = 340
const CARD_MIN_WIDTH = CARD_WIDTH - 100
export const CARD_MIN_HEIGHT = 186

const ICON_SIZE = 20

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: CARD_WIDTH,
    minWidth: CARD_MIN_WIDTH,
    minHeight: CARD_MIN_HEIGHT,
    position: 'relative',
    marginRight: theme.spacing(4),
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: CARD_WIDTH,
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(4),
    },
  },
  headerWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 36,
    fontWeight: 700,
    padding: theme.spacing(1.25, 0.5),
    fontSize: 16,
    background: subHeadColor,
    borderRadius: 7,
    color: tableTextColor,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  title: {
    marginLeft: theme.spacing(3),
  },
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    marginLeft: theme.spacing(3),
  },
  customIcon: {
    width: ICON_SIZE * 2.1,
    height: ICON_SIZE * 2.1,
    minWidth: ICON_SIZE * 2.1,
    marginRight: theme.spacing(2),
  },
  rowsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    minHeight: 100,
    maxWidth: '100%',
    padding: theme.spacing(1, 0, 0),
  },
  row: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      '& button': {
        height: 32,
      },
    },
  },
  content: {
    fontSize: 18,
    fontWeight: 800,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
}))

export const SummaryCardWrapper: React.FC<ISummaryCardWrapperProps> = ({
  Icon,
  customIconPosition,
  title,
  rows,
  statusColor = 'default',
  loading,
  displayAsEmpty = false,
  children,
}) => {
  const styles = useStyles()
  const isLoading = useLoadingState(!!loading)
  const theme = useTheme()
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'))

  const iconContainer = useMemo(() => {
    return (
      <div className={styles.headerWrapper}>
        {Icon && title && (
          <>
            {customIconPosition && <Icon className={styles.customIcon} />}
            <div className={styles.header}>
              {!customIconPosition && <Icon className={styles.icon} />}
              <span className={styles.title}>{title}</span>
            </div>
          </>
        )}
      </div>
    )
  }, [Icon, title, customIconPosition, styles])

  return (
    <Paper
      style={
        displayAsEmpty
          ? {
              backgroundColor: 'transparent',
              border: 'none',
              display: mdScreen ? 'none' : 'flex',
            }
          : {}
      }
      variant="outlined"
      className={styles.card}
    >
      {!displayAsEmpty && (
        <>
          <Fade in={isLoading}>
            <div className={styles.loader}>
              {isLoading && <CircularProgress />}
            </div>
          </Fade>
          {iconContainer}
          <Fade in={!isLoading}>
            <div className={styles.rowsContainer}>
              {rows?.map(
                (
                  { header, buttonHandler, content, buttonText, customButton },
                  index
                ) => {
                  return (
                    <div key={index} className={styles.row}>
                      <div style={{ width: '100%' }}>
                        {header && <div>{header}</div>}
                        {content && (
                          <div className={styles.content}>{content}</div>
                        )}
                      </div>
                      {buttonHandler && buttonText && (
                        <StyledButton
                          variant="outlined"
                          color="primary"
                          onClick={() => buttonHandler()}
                          status={statusColor}
                          size="small"
                        >
                          {buttonText}
                        </StyledButton>
                      )}
                      {customButton}
                    </div>
                  )
                }
              )}
              {children}
            </div>
          </Fade>
        </>
      )}
    </Paper>
  )
}
