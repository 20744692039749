import i18n from '@i18n/index'
import { DocumentType } from '@shared/contracts/documentType'
import { FormValues } from './ConfirmActivationPointForm'

type ConfirmActivationPointFormErrors = {
  [type in DocumentType]?: string
}

export const validateConfirmActivationPointForm = (values: FormValues) => {
  const errors: ConfirmActivationPointFormErrors = {}
  if (!values[DocumentType.EInvoice]) {
    errors[DocumentType.EInvoice] = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  }
  if (!values[DocumentType.EInvoiceTermsAcceptance]) {
    errors[DocumentType.EInvoiceTermsAcceptance] = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  }
  return errors
}
