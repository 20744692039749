import React, { useCallback, useEffect, useState } from 'react'
import { Fade, makeStyles, Theme } from '@material-ui/core'
import { ReactComponent as EmailSvg } from '@images/icons/other/ConfirmMailAcc.svg'
import { BackgroundWrapper } from '../../shared/BackgroundWrapper'
import StyledButton from '../../shared/components/Button'
import { useApi } from '@core/apiClient/apiClient'
import { Link, useLocation } from 'react-router-dom'
import { isEmpty, unnest, values } from 'ramda'
import { ErrorMsg } from './static-data/RegisterData'
import { ConfirmEmailRequest, Contact } from '@shared/contracts/models'
import waves from '@images/polenergiaWaves.png'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '720px',
    padding: '68px 92px',
    margin: '32px',
    borderRadius: '7px',
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0 7px 9px 3px rgba(105,105,105,0.03), 0 2px 3px 0 rgba(66,65,65,0.5)',

    backgroundSize: '100% 35%',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("${waves}")`,

    '& h1': {
      margin: '0 0 16px',
      fontSize: 35,
      textAlign: 'center',
      letterSpacing: '0.29px',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& h3': {
      width: '100%',
      margin: '0 auto 52px',
      fontSize: 18,
      textAlign: 'center',
      letterSpacing: '0.05px',
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightLight,
      '& span ': {
        color: theme.palette.primary.main,
        fontWeight: 700,
      },
    },
    '& h4': {
      width: '100%',
      margin: '0 auto 12px',
      fontSize: 16,
      textAlign: 'center',
      letterSpacing: '0.05px',
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightBold,
    },

    [theme.breakpoints.down('sm')]: {
      padding: '36px 24px',
      margin: '24px 44px',
      '& h1': {
        fontSize: 31,
      },
    },
    [theme.breakpoints.down('xs')]: {
      margin: '24px',
      backgroundImage: 'none',
      '& h1': {
        fontSize: 28,
      },
      '& h3': {
        width: '100%',
        fontSize: 16,
        margin: '0 0 16px',
      },
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 36px',
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main + colorOpacityParser(13),
    '& svg': {
      width: '68px',
      height: '68px',
      color: theme.palette.primary.dark,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
      margin: '0 0 28px',
      '& svg': {
        width: '52px',
        height: '52px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '80px',
      height: '80px',
      margin: '0 0 20px',
      '& svg': {
        width: '48px',
        height: '48px',
      },
    },
  },
  errors: {
    marginBottom: '4px',
  },
  linkButton: {
    margin: '24px 0 0',
    width: '280px',
    '& button': {
      width: '100%',
      height: '48px',
      fontSize: 17,
      fontWeight: 700,
    },
    [theme.breakpoints.down('sm')]: {
      '& button': {
        fontSize: 15,
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        height: '42px',
        fontSize: 14,
      },
    },

    textDecoration: 'none',
    outline: 'none',
  },
}))

export const EmailConfirmationPage: React.FC = () => {
  const styles = useStyles()
  const search = useLocation().search
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [contact, setContact] = useState({ phone: '', mail: '' })

  const { t } = useTranslation()

  const [executeGetContactDetails] = useApi<Contact[]>({
    omitCustomerNumber: true,
    omitConsents: true,
    config: {
      method: 'get',
      url: 'Content/contactDetails',
    },
  })
  const getContactDetails = useCallback(async () => {
    const res = await executeGetContactDetails()
    const [contactDetails] = res.data
    setContact({
      phone: contactDetails.phoneNumber ?? '',
      mail: contactDetails.email ?? '',
    })
  }, [executeGetContactDetails])

  const [executeConfirmEmail, loading] = useApi({
    omitCustomerNumber: true,
    omitConsents: true,
    config: {
      method: 'put',
      url: 'Accounts/confirmEmail',
    },
  })

  const confirmEmail = useCallback(
    async (data: ConfirmEmailRequest) => {
      try {
        await executeConfirmEmail({ data: data })
      } catch (err) {
        getContactDetails()
        if (!err.status || err.status === 500) {
          setErrorMessages([err.response.message])
        } else {
          const errors = err.response
            ? values(err.response)
            : [ErrorMsg.noConnectionOrServerError]
          setErrorMessages(unnest(errors))
        }
      }
    },
    [executeConfirmEmail, getContactDetails]
  )

  useEffect(() => {
    const query = new URLSearchParams(search)
    const data = {
      userId: query.get('userId') || '',
      clientId: query.get('clientId') || '',
      token: query.get('token') || '',
    }
    confirmEmail(data)
  }, [confirmEmail, search])

  return (
    <BackgroundWrapper>
      <Fade in={!loading} {...(!loading ? { timeout: 500 } : {})}>
        <div className={styles.container}>
          <div className={styles.icon}>
            <EmailSvg />
          </div>
          {isEmpty(errorMessages) ? (
            <>
              <h1>{t('EMAIL_CONFIRMATION_PAGE.TITLE')}</h1>
              <h3>{t('EMAIL_CONFIRMATION_PAGE.SUBTITLE')}</h3>
            </>
          ) : (
            <>
              <h1>{t('EMAIL_CONFIRMATION_PAGE.NEW_ACC_ERROR_TITLE')}</h1>
              <div className={styles.errors}>
                {errorMessages.map((err, i) => {
                  return <h4 key={i}>{err}</h4>
                })}
              </div>
              <h3 style={{ marginBottom: 12 }}>
                {t('EMAIL_CONFIRMATION_PAGE.CONTACT_WITH_SERVICE')}:{' '}
                <span>{contact.phone}</span>
              </h3>
              <h3>
                {t('EMAIL_CONFIRMATION_PAGE.MAIL_CONTACT')}:{' '}
                <span>{contact.mail}</span>
              </h3>
            </>
          )}
          <Link to="/" className={styles.linkButton}>
            <StyledButton color="primary" variant="contained">
              {isEmpty(errorMessages)
                ? t('EMAIL_CONFIRMATION_PAGE.LOGIN')
                : t('EMAIL_CONFIRMATION_PAGE.BACK_TO_LOGIN')}
            </StyledButton>
          </Link>
        </div>
      </Fade>
    </BackgroundWrapper>
  )
}
