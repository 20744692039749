import { Grid, Typography } from '@material-ui/core'
import { Account } from '@shared/contracts/account'
import { MeasurementPointListItem } from '@shared/contracts/measurementPointListItem'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './Payer.styles'

interface PayerProps {
  account?: Account
  headingClass?: string
  className?: string
  correspondenceEmail?: string
  stringifyAddress: (point: MeasurementPointListItem) => string
}

export const Payer: React.FC<PayerProps> = ({
  account,
  headingClass = '',
  className = '',
  correspondenceEmail,
  stringifyAddress,
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <Typography className={headingClass && headingClass}>
        {t('MY_INVOICES.E_INVOICE.PAYER')}
      </Typography>
      <Grid container className={styles.row}>
        <Grid item xs={3} className={styles.item}>
          <Typography className={styles.subHeading}>
            {t('MY_INVOICES.E_INVOICE.PAYER_NUMBER')}
          </Typography>
        </Grid>
        <Grid item xs={4} className={styles.item}>
          <Typography className={styles.subHeading}>
            {t('MY_INVOICES.E_INVOICE.PAYER_NAME')}
          </Typography>
        </Grid>
        <Grid item xs={5} className={styles.item}>
          <Typography className={styles.subHeading}>
            {t('MY_INVOICES.E_INVOICE.MAIN_ADDRESS')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={styles.row}>
        <Grid item xs={3} className={styles.item}>
          <span className={styles.text}>{account?.customerNumber}</span>
        </Grid>
        <Grid item xs={4} className={styles.item}>
          <span className={styles.text}>{account?.name}</span>
        </Grid>
        <Grid item xs={5} className={styles.item}>
          <span className={styles.text}>
            { account?.mainAddressLine1 }
          </span>
          <span className={styles.text}>
            { account?.mainAddressLine2 }
          </span>
        </Grid>
      </Grid>
      {correspondenceEmail && (
        <Grid container className={styles.correspondenceEmail}>
          <Grid item xs={12} className={styles.item}>
            <Typography className={styles.subHeading}>
              {t('MY_INVOICES.E_INVOICE.E_INVOICE_EMAIL')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={styles.item}>
            <span className={styles.text}>{correspondenceEmail}</span>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
