export const REQUIRED = 'Pole jest wymagane'
export const TITLE_LENGTH = (amount: number) =>
  `Tytuł nie może mieć więcej niż ${amount} znaków`
export const DESCRIPTION_LENGTH = (amount: number) =>
  `Opis nie może mieć więcej niż ${amount} znaków`
export const REFUSE = 'Odrzuć'
export const SEND = 'Wyślij'
export const REPORT_TITLE = 'Tytuł zgłoszenia'
export const REPORT_DESCRIPTION = 'Treść zgłoszenia'
export const SEND_ERROR_REPORT = 'Wyślij zgłoszenie błędu'
export const SEND_ERROR_REPORT_DESCRIPTION =
  'Ups. Przepraszamy, coś poszło nie tak. Prosimy o wysłanie zgłoszenia za pomocą poniższego formularza. Dzięki temu szybciej usuniemy przyczynę problemu.'
