import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import useStyles from './HintModal.style'
import { Button } from '@shared/components/Button'

interface HintModalProps {
  title: string
  content: string | JSX.Element
  show: boolean
  close: () => void
}

export const HintModal: React.FC<HintModalProps> = ({
  title,
  content,
  show,
  close,
}) => {
  const styles = useStyles()
  return (
    <Dialog
      aria-describedby="form-modal"
      open={show}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      classes={{ paper: styles.dialog }}
      onClose={close}
    >
      <DialogContent classes={{ root: styles.container }}>
        <h1>{title}</h1>
        <div className={styles.content}>{content}</div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close} className={styles.btn}>
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  )
}
