import { makeStyles } from '@material-ui/core'

export const SWITCH_ON_HANDLE_COLOR = '#FFF'
export const SWITCH_OFF_HANDLE_COLOR = '#7C8A97'
export const SWITCH_ON_COLOR = '#12BEAB'
export const SWITCH_OFF_COLOR = '#EAECEB'

export const SWITCH_WIDTH = 40
export const SWITCH_HEIGHT = 22
export const SWITCH_HANDLE_DIAMETER = 18

export default makeStyles(() => ({}))
