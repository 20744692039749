import React, { useMemo } from 'react'
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'
import { Account } from '@shared/contracts/models'
import { TableCellContent } from '@shared/components/TableCell'
import { AddressParser } from '@shared/components/AddressParser'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: theme.spacing(0, 0, 2),
      maxWidth: '100%',
      borderRadius: '8px',
    },
    gridElement: {
      alignItems: 'center',
      padding: theme.spacing(4, 2),
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: 0.61,
      color: tableTextColor + colorOpacityParser(75),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 0),
      },
    },
  })
)

export interface MeasurementPointData {}

interface MyInformationProps {
  myInformation?: Account
}

export const MyInformation: React.FC<MyInformationProps> = ({
  myInformation,
}) => {
  const styles = useStyles()

  const {
    name,
    type,
    contactPhoneNumber,
    customerNumber,
    mainAddressLine1,
    mainAddressLine2,
    correspondenceAddressLine1,
    correspondenceAddressLine2,
    correspondenceEmail,
  } = myInformation ?? {}

  const mainAddress = useMemo(
    () => <AddressParser a1={mainAddressLine1} a2={mainAddressLine2} />,
    [mainAddressLine1, mainAddressLine2]
  )

  const correspondenceAddress = useMemo(
    () => (
      <AddressParser
        a1={correspondenceAddressLine1}
        a2={correspondenceAddressLine2}
      />
    ),
    [correspondenceAddressLine1, correspondenceAddressLine2]
  )

  return (
    <>
      <Grid container className={styles.table}>
        <Grid item xs={12} md={6} lg={4} className={styles.gridElement}>
          <TableCellContent
            header={
              type === 'Individual' ? 'Imię i Nazwisko' : 'Nazwa płatnika'
            }
            content={name || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={styles.gridElement}>
          <TableCellContent
            header="Telefon kontaktowy"
            content={contactPhoneNumber || 'Nie podano'}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4} className={styles.gridElement}>
          <TableCellContent
            header="Numer płatnika"
            content={customerNumber ?? ''}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} className={styles.gridElement}>
          <TableCellContent
            header="E-mail"
            content={correspondenceEmail ?? 'Nie podano'}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={styles.gridElement}>
          <TableCellContent header="Adres" content={mainAddress} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={styles.gridElement}>
          <TableCellContent
            header="Adres korespondencyjny"
            content={correspondenceAddress}
          />
        </Grid>
      </Grid>
    </>
  )
}
