import { useAnalytics } from '@core/analytics/useAnalytics'
import { useLogoutUserUnloaded } from '@core/auth/useUserUnloaded'
import { Routes } from '@core/routes/Routes'
import { actionsInterceptorMiddleware } from '@core/store/middleware/actionsInterceptorMiddleware'
import { useCacheReload } from '@shared/hooks/useCacheReload'
import { User } from 'oidc-client'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { USER_FOUND } from 'redux-oidc'

const App = () => {
  const history = useHistory()
  const cacheReload = useCacheReload()
  const [, , enableGa] = useAnalytics()

  useEffect(() => {
    actionsInterceptorMiddleware
      .addActionsPromise([USER_FOUND])
      .then(({ action }) => {
        const { state }: User = action.payload
        if (state) {
          history.replace(state.url)
        }
      })
  }, [history])

  useLogoutUserUnloaded()

  useEffect(() => {
    cacheReload()
  }, [cacheReload])

  useEffect(() => {
    enableGa()
  }, [enableGa])

  return <Routes />
}

export default App
