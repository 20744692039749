import { makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'

const ICON_SIZE = 24

export default makeStyles((theme: Theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 19,
    margin: theme.spacing(7, 0, 3),
    paddingTop: theme.spacing(7),
    borderTop: '1.8px solid #5F76FD1A',
    '& b': {
      marginRight: theme.spacing(2),
      fontWeight: 700,
      color: tableTextColor,
    },
  },
  icon: {
    height: ICON_SIZE,
    width: ICON_SIZE,
    minWidth: ICON_SIZE,
    minHeight: ICON_SIZE,
    marginRight: theme.spacing(4),
    fill: theme.palette.primary.main,
  },
  table: {
    margin: 0,
    minWidth: 920,
    borderRadius: '8px',
    '& .MuiTableCell-root': {
      border: 0,
    },
    '& .MuiTableCell-body': {
      alignItems: 'center',
      padding: theme.spacing(3, 1),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3, 1, 2, 1),
      },
    },
  },
  tableCell: {
    color: tableTextColor + colorOpacityParser(75),
    fontSize: 16,
    letterSpacing: '0.61px',
    lineHeight: '20px',
  },
  deleteButton: {
    cursor: 'pointer',
  },
  loader: {
    marginTop: theme.spacing(3),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
}))
