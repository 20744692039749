import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Theme,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Fade,
} from '@material-ui/core'
import DetailsCardWrapper from '@shared/components/DetailsCard'
import { ReactComponent as NewsSvg } from '@images/icons/myCenter/News.svg'
import { ToggleButtonGroup } from '@material-ui/lab'
import { ToggleButton } from '@shared/components/ToggleButton'
import { NewsTypeFilter } from '@shared/contracts/models'
import { CustomPagination } from '@shared/components/Pagination'
import { useLoadingState } from '@shared/hooks/loadingState'
import useStyles from './MyNewsPage.style'
import SingleNewsOnPage from './components/SingleNewsOnPage'
import { useNewsDataSource } from './newsDataSource'
import { isEmpty } from 'ramda'

const MIN_HEIGHT = 32
const NEWS_LOADING_HEIGHT = 200
const MY_NEWS = 'Moje wiadomości'

export const MyNewsPage: React.FC = () => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const mobileXS = useMediaQuery(theme.breakpoints.down('xs'))
  const [newsType, setNewsType] = useState(NewsTypeFilter.Unread)
  const [page, setPage] = useState(1)

  const {
    allNews,
    unreadNews,
    pagination,
    total,
    loading,
    loadAllNews,
    loadUnreadNews,
  } = useNewsDataSource()

  const { limit, numOfPages, pageStart } = pagination

  const isLoading = useLoadingState(loading)

  const handleNewsTypeChange = useCallback(
    (_: React.MouseEvent, newType: NewsTypeFilter) => {
      if (newType) {
        setPage(1)
        setNewsType(newType)
        if (newType === NewsTypeFilter.All) {
          loadAllNews(false, 0)
        } else {
          loadUnreadNews(false, 0)
        }
      }
    },
    [setPage, setNewsType, loadAllNews, loadUnreadNews]
  )

  useEffect(() => {
    if (newsType === NewsTypeFilter.All) {
      loadAllNews(false, 0)
    } else {
      loadUnreadNews(false, 0)
    }
  }, [loadAllNews, loadUnreadNews, newsType])

  useEffect(() => {
    setPage(pageStart / limit + 1)
  }, [pageStart, setPage, limit])

  const emptyNews = useMemo(() => {
    return <h2 className={styles.empty}>Brak wiadomości</h2>
  }, [styles.empty])

  const renderNews = useCallback(
    (type: NewsTypeFilter, isLoading: boolean): JSX.Element => {
      if (type === NewsTypeFilter.All) {
        return (
          <div>
            {!isLoading &&
              (isEmpty(allNews)
                ? emptyNews
                : !isLoading &&
                  allNews.map((news, i) => {
                    return <SingleNewsOnPage key={news.id ?? i} news={news} />
                  }))}
          </div>
        )
      } else {
        return (
          <div>
            {!isLoading &&
              (isEmpty(unreadNews)
                ? emptyNews
                : unreadNews.map((news, i) => {
                    return (
                      <SingleNewsOnPage
                        key={news.id ?? i}
                        news={news}
                        reloadNews={loadUnreadNews}
                      />
                    )
                  }))}
          </div>
        )
      }
    },
    [allNews, unreadNews, loadUnreadNews, emptyNews]
  )

  return (
    <>
      <div style={{ marginBottom: theme.spacing(10) }}>
        <DetailsCardWrapper
          title={MY_NEWS}
          Icon={NewsSvg}
          minHeight={MIN_HEIGHT}
          disableLoader
          customButtons={
            <div className={styles.headerContent}>
              <span style={{ marginRight: theme.spacing(4) }}>Wybierz:</span>
              <ToggleButtonGroup
                value={newsType}
                exclusive
                onChange={handleNewsTypeChange}
                className={styles.buttons}
              >
                <ToggleButton
                  value={NewsTypeFilter.Unread}
                  aria-label="left aligned"
                  style={{ marginRight: mobileXS ? 0 : theme.spacing(4) }}
                >
                  Nieprzeczytane ({total.unread})
                </ToggleButton>
                <ToggleButton
                  value={NewsTypeFilter.All}
                  aria-label="left aligned"
                >
                  Wszystkie ({total.all})
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          }
        >
          <CustomPagination
            page={page}
            numberOfPages={numOfPages}
            onChange={(e: React.ChangeEvent<unknown>, val: number) => {
              if (val !== page) {
                setPage(val)
                if (newsType === NewsTypeFilter.All) {
                  loadAllNews(false, (val - 1) * limit)
                } else {
                  loadUnreadNews(false, (val - 1) * limit)
                }
              }
            }}
          />
        </DetailsCardWrapper>
      </div>
      <div className={styles.resizableContainer}>
        <div>
          {isLoading && (
            <div
              className={styles.loader}
              style={{
                height: NEWS_LOADING_HEIGHT,
              }}
            >
              <CircularProgress />
            </div>
          )}
          <Fade in={!isLoading}>{renderNews(newsType, isLoading)}</Fade>
        </div>
      </div>
    </>
  )
}
