import { Theme, createStyles, makeStyles } from '@material-ui/core'

const BUTTON_WIDTH_CLOSED = 56
const BUTTON_WIDTH = 330
const BUTTON_HEIGHT = 56
const BUTTON_POSITION = 24
const BUTTON_ICON_SIZE = 42

const BUTTON_RADIUS = 56

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      width: BUTTON_WIDTH_CLOSED,
      height: BUTTON_HEIGHT,
      backgroundColor: theme.palette.common.white,
      borderRadius: BUTTON_RADIUS,
      boxShadow: '0 3px 7px 1px rgba(0,0,0,0.15)',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      zIndex: 1900,
      position: 'fixed',
      left: BUTTON_POSITION,
      bottom: BUTTON_POSITION,
      transition: 'width 200ms ease-in-out',
      '&:hover': {
        width: BUTTON_WIDTH,
        [theme.breakpoints.down('sm')]: {
          width: BUTTON_WIDTH_CLOSED - 20,
        },
        '& svg': {
          fill: theme.palette.primary.main,
        },
        '& span': {
          opacity: 1,
          width: '100%',
          transition: 'all 200ms ease-in',
          transitionDelay: '200ms',
          [theme.breakpoints.down('sm')]: {
            width: 0,
          },
        }
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '0 3px 7px 1px rgba(0,0,0,0.3)',
      },
      [theme.breakpoints.down('sm')]: {
        width: 56 - 20,
        height: BUTTON_HEIGHT - 20,
        right: BUTTON_POSITION - 12,
        bottom: BUTTON_POSITION - 12,
      },
    },
    icon: {
      display: 'flex',
      width: BUTTON_ICON_SIZE,
      height: BUTTON_ICON_SIZE,
      borderRadius: 8,
      marginTop: 6,
      marginLeft: 5,
      '& svg': {
        width: BUTTON_ICON_SIZE - 8,
        height: BUTTON_ICON_SIZE - 8,
        transition: 'fill 300ms ease-in-out',
        
      },
      [theme.breakpoints.down('sm')]: {
        width: BUTTON_ICON_SIZE - 18,
        height: BUTTON_ICON_SIZE - 18,
        marginLeft: 0,
        '& svg': {
          width: BUTTON_ICON_SIZE - 22,
          height: BUTTON_ICON_SIZE - 22,
        },
      },
    },
    text: {
      fontSize: 16,
      textTransform: 'uppercase',
      fontWeight: 500,
      opacity: 0,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    }
  })
)
