/**
 * Formats the specified string so that a separator is inserted every three characters.
 * Mainly intended for formatting phone numbers, but can be used for any strings of characters.
 *
 * @param input - The string of characters to be formatted. If not specified, the function will return an empty string.
 *                Examples: "123456789", "0123456789".
 * @param separator - A character that will be inserted every three characters in the specified string.
 *                    The default is set to a hyphen ('-'). Any character can be used, such as space (' '), underscore ('_'), etc.
 *                    Examples: "-", " ", "_".
 *
 * @returns Formatted string with inserted separators. If 'input' is not specified, returns an empty string.
 *          Examples of results: "123-456-789", "012-345-678-9", "123 456 789", "012_345_678_9".
 */
export const phoneFormatter = (
  input?: string,
  separator: string = '-'
): string => {
  const matched = input?.match(/.{1,3}/g)
  const formatted = matched ? matched.join(separator) : ''

  return formatted
}
