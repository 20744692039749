import React from 'react'
import { NewPassword } from '@mbok/features/NewPassword/NewPassword'
import { EmailConfirmationPage } from '@mbok/features/Register/EmailConfirmationPage'
import { RegisterPage } from '@mbok/features/Register/RegisterPage'
import { ReactComponent as FakturySvg } from '@images/icons/sider/FakturySider.svg'
import { ReactComponent as ZuzycieSvg } from '@images/icons/sider/ZuzycieSider.svg'
import { ReactComponent as CentrumSvg } from '@images/icons/sider/CentrumSider.svg'
import { ReactComponent as UstawieniaSvg } from '@images/icons/sider/UstawieniaSider.svg'
import { ReactComponent as WiadomosciSvg } from '@images/icons/sider/WiadomosciSider.svg'

import MyCenter from '@mbok/features/MyCenter'
import MyReadings from '@mbok/features/MyReadings'
import MyInvoices from '@mbok/features/MyInvoices'
import MyNewsPage from '@mbok/features/MyNews'
import ReturnPage from '@mbok/features/Return'
import MySettings from '@mbok/features/MySettings'

export interface IPath {
  path: string
  component: React.FC<{}>
  protectedRoute?: boolean
  routeLinkTitle?: string
  RouteLinkIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const pathFactory = (
  path: string,
  component: React.FC<{}>,
  protectedRoute?: boolean,
  routeLinkTitle?: string,
  RouteLinkIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
): IPath => ({
  path,
  component,
  protectedRoute,
  routeLinkTitle,
  RouteLinkIcon,
})

export const SiderPaths = {
  home: pathFactory('/centrum', MyCenter, true, 'Moje centrum', CentrumSvg),
  // punktyPoboru: pathFactory(
  //   '/punkty-poboru',
  //   Home,
  //   true,
  //   'Punkty poboru',
  //   BusinessCardSvg
  // ),
  myAccountingDocuments: pathFactory(
    '/moje-faktury',
    MyInvoices,
    true,
    'Moje faktury',
    FakturySvg
  ),
  myUsage: pathFactory(
    '/moje-zuzycie',
    MyReadings,
    true,
    'Moje zużycie',
    ZuzycieSvg
  ),
  news: pathFactory(
    '/wiadomosci',
    MyNewsPage,
    true,
    'Wiadomości',
    WiadomosciSvg
  ),
  settings: pathFactory(
    '/ustawienia',
    MySettings,
    true,
    'Ustawienia',
    UstawieniaSvg
  ),
}

export const AppPaths = {
  paymentProcessing: pathFactory('/przetwarzanie-platnosci', ReturnPage),
  register: pathFactory('/rejestracja', RegisterPage),
  emailConfirmation: pathFactory('/potwierdzenie-email', EmailConfirmationPage),
  newPassword: pathFactory('/nowe-haslo', NewPassword),
  ...SiderPaths,
}
