/**
 * mBok API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AccountingDocumentTypeFilter =
  | 'All'
  | 'CollectiveInvoice'
  | 'AllInvoices'
  | 'AllNotes'

export const AccountingDocumentTypeFilter = {
  All: 'All' as AccountingDocumentTypeFilter,
  CollectiveInvoice: 'CollectiveInvoice' as AccountingDocumentTypeFilter,
  AllInvoices: 'AllInvoices' as AccountingDocumentTypeFilter,
  AllNotes: 'AllNotes' as AccountingDocumentTypeFilter,
}
