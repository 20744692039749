import {
  Checkbox,
  Collapse,
  makeStyles,
  Theme,
  withStyles,
  withTheme,
} from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { ReactComponent as CheckedSvg } from '@images/icons/checkbox/Checked.svg'
import { ReactComponent as UncheckedSvg } from '@images/icons/checkbox/Unchecked.svg'
import { useSavePdf } from '@shared/hooks/pdfSaver'
import { DocumentType } from '@shared/contracts/models'
import { useTranslation } from 'react-i18next'
import { inputBackgroundColor } from '@mbok/styles/constants'

export const StyledCheckbox = withStyles((theme: Theme) => ({
  root: {
    height: '22px',
    width: '22px',
    padding: 0,
    borderRadius: '4px',
    backgroundColor: inputBackgroundColor,
  },
}))(withTheme(Checkbox))

export default StyledCheckbox

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(0, 0, 3),
    cursor: 'pointer',
  },
  consentContainer: {
    margin: theme.spacing(0, 3),
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0.1px',
    transition: 'color 0.2s ease-in-out',
    '& p': {
      margin: theme.spacing(2, 0, 4),
      opacity: 0.75,
      fontSize: 11,
    },
  },
  spanHover: {
    opacity: 1,
    cursor: 'pointer',
    fontWeight: 700,
    color: theme.palette.primary.main,
    transition: 'color 0.2s ease-in-out',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  optionalConsent: {
    opacity: 0.35,
    color: theme.palette.text.primary,
  },
  error: {
    color: theme.palette.error.main,
  },
}))

interface ConsentsCheckboxProps {
  field: {
    name: string
    value: boolean
  }
  showError: boolean
  mandatory?: boolean
  documentFile?: string
  documentContent?: string
  documentTitle?: string
  documentType?: DocumentType
}

export const ConsentsCheckbox: React.FC<ConsentsCheckboxProps> = ({
  field,
  showError,
  documentTitle,
  mandatory = false,
  documentFile,
  documentContent,
  documentType,
}) => {
  const styles = useStyles()
  const [show, setShow] = useState(false)

  const savePdf = useSavePdf()
  const { t } = useTranslation()

  const optionalConsentTitle = useMemo(() => {
    switch (documentType) {
      case 'ElectronicContact':
        return t(
          'MY_SETTINGS.MARKETING_COMMUNICATION_CONSENTS.ELECTRONIC_CONTACT'
        )
      case 'PhoneContact':
        return t('MY_SETTINGS.MARKETING_COMMUNICATION_CONSENTS.PHONE_CONTACT')
      default:
        return ''
    }
  }, [documentType, t])

  return (
    <label className={styles.container}>
      <StyledCheckbox
        icon={<UncheckedSvg />}
        checked={Boolean(field.value)}
        checkedIcon={<CheckedSvg />}
        disableRipple
        {...field}
      />
      <div
        className={`${styles.consentContainer} ${
          mandatory && showError ? styles.error : null
        }`}
      >
        {documentFile ? (
          <>
            Oświadczam, że zapoznałem się z treścią niniejszego{' '}
            {savePdf(
              documentFile,
              (_, saveFn) => (
                <span
                  className={styles.spanHover}
                  onClick={e => {
                    e.preventDefault()
                    saveFn()
                  }}
                >
                  Regulaminu{' '}
                </span>
              ),
              documentTitle || 'dokument'
            )}
            <span className={styles.optionalConsent}>(ZGODA OBOWIĄZKOWA)</span>
            {', '}
            akceptuję jego treść i zobowiązuję się do przestrzegania go.
          </>
        ) : (
          <>
            {optionalConsentTitle}{' '}
            <span className={styles.optionalConsent}>(Opcjonalne)</span>
            <div>
              <span
                className={styles.spanHover}
                onClick={e => {
                  e.preventDefault()
                  setShow(prev => !prev)
                }}
              >
                {show ? 'Zwiń' : 'Rozwiń'}
              </span>
            </div>
            <Collapse in={show}>
              <p>{documentContent}</p>
            </Collapse>
          </>
        )}
      </div>
    </label>
  )
}
