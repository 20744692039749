import React, { useCallback } from 'react'
import { createStyles, Divider, makeStyles, Theme } from '@material-ui/core'
import { News } from '@shared/contracts/models'
import { format, isYesterday, parseJSON } from 'date-fns'
import { tableTextColor } from '@mbok/styles/constants'
import { DETAILS_CARD_CONTENT_MIN_HEIGHT } from '@shared/components/DetailsCard/DetailsCard'
import { ReactComponent as ReadNewsSvg } from '@images/icons/myNews/ReadNews.svg'
import { ReactComponent as UnreadNewsSvg } from '@images/icons/myNews/UnreadNews.svg'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

const NEWS_CONTENT_HEIGHT = 64

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    singleNews: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      borderBottom: '1.8px solid #5F76FD1A',
    },
    newsHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    icon: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginRight: theme.spacing(3),
    },
    header: {
      color: tableTextColor,
      '& p': {
        margin: theme.spacing(0, 0, 0.5),
        opacity: 0.55,
        fontSize: 14,
        letterSpacing: '0.60px',
      },
      '& h1': {
        margin: 0,
        fontSize: 17,
        letterSpacing: '0.64px',
      },
    },
    newsContent: {
      height: NEWS_CONTENT_HEIGHT,
      fontSize: 14,
      letterSpacing: '0.61px',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 3,
      overflow: 'hidden',
    },
    empty: {
      height: DETAILS_CARD_CONTENT_MIN_HEIGHT,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 19,
      color: tableTextColor,
      opacity: 0.55,
    },
  })
)

export interface MyNewsProps {
  myNews: News[]
}

export const MyNews: React.FC<MyNewsProps> = ({ myNews }) => {
  const [firstNews, secondNews] = myNews
  return (
    <div>
      <div>
        {firstNews && <SingleNews singleNews={firstNews} />}
        {secondNews && (
          <>
            <Divider style={{ marginBottom: 20 }} />
            <SingleNews singleNews={secondNews} />
          </>
        )}
      </div>
    </div>
  )
}

export const SingleNews: React.FC<{ singleNews: News }> = ({ singleNews }) => {
  const styles = useStyles()
  const { date, title, message, markedAsRead } = singleNews

  const yesterday = useCallback(
    (date: Date) => isYesterday(parseJSON(date)),
    []
  )

  const formattedDate = useCallback(
    (date: Date) => format(parseJSON(date), 'dd.MM.yyyy'),
    []
  )

  return (
    <div
      className={styles.singleNews}
      style={{ borderBottom: 'none', marginBottom: '0' }}
    >
      <div className={styles.newsHeader}>
        <div className={styles.icon}>
          {markedAsRead ? <ReadNewsSvg /> : <UnreadNewsSvg />}
        </div>
        <div className={styles.header}>
          <p>{date && (yesterday(date) ? 'Wczoraj' : formattedDate(date))}</p>
          <h1>{title}</h1>
        </div>
      </div>
      <div className={styles.newsContent}><ReactQuill theme="bubble" readOnly={true} value={JSON.parse(message ?? "")} /></div>
    </div>
  )
}
