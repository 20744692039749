import { actionsInterceptorMiddleware } from '@core/store/middleware/actionsInterceptorMiddleware'
import { USER_FOUND, USER_SIGNED_OUT } from 'redux-oidc'
import { customerNumberActions } from '../entity/customerNumbers/customerNumbers'
import { getUserAccount } from './userAccountThunks'

export const addUserAccountSideEffects = () => {
  actionsInterceptorMiddleware.addManyActionsCallback([
    {
      actionTypes: [USER_FOUND, customerNumberActions.setCustomerNumber.type],
      callback: ({ dispatch, state }) => {
        if (!state.userAccount.loadingPending) {
          dispatch(getUserAccount())
        }
      },
    },
    {
      actionTypes: [USER_SIGNED_OUT],
      callback: () => {
        window.location.reload()
      },
    },
  ])
}
