import React from 'react'
import useStyles from './Toggler.style'
import RightPng from '@images/icons/toggler/Right @3x.png'
import LeftPng from '@images/icons/toggler/Left @3x.png'
import { useTranslation } from 'react-i18next'

export interface ITogglerProps {
  setIndex?: React.Dispatch<React.SetStateAction<number>>
  index: number
  total: number
  content?: JSX.Element
  className?: string
  setNext?: () => void
  setPrev?: () => void
}

export const Toggler: React.FC<ITogglerProps> = ({
  index,
  setIndex,
  total,
  content,
  className,
  setNext,
  setPrev,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <div className={`${styles.toggler} ${className}`}>
      <div
        onClick={() => {
          if (setPrev) {
            setPrev()
          } else {
            setIndex && setIndex((prev: number) => {
              return prev === 0 ? total - 1 : prev - 1
            })
          }
        }}
      >
        <img src={LeftPng} alt="" />
      </div>
      {content ?? (
        <p>
          <span>{index + 1}</span> {t('OF').toLowerCase()} {total}
        </p>
      )}
      <div
        onClick={() => {
          if (setNext) {
            setNext()
          } else {
            setIndex && setIndex(prev => {
              return prev === total - 1 ? 0 : prev + 1
            })
          }
        }}
      >
        <img src={RightPng} alt="" />
      </div>
    </div>
  )
}
