import { useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import { newsGetAll } from '@core/store/entity/news/newsThunks'
import { useDispatchOnCustomerNumberChange } from '@core/store/customerNumberDispatcher'
import { unwrapResult } from '@reduxjs/toolkit'
import { getNewsByIdsSelector } from '@core/store/entity/news/newsSelector'
import { News } from '@shared/contracts/models'

const NEWS_LIMIT = 10

export const useNewsDataSource = () => {
  const dispatch = useAppDispatch()

  const [allNewsIds, setAllNewsIds] = useState<string[]>([])
  const [unreadNewsIds, setUnreadNewsIds] = useState<string[]>([])
  const [numOfPages, setNumOfPages] = useState(0)
  const [pageStart, setPageStart] = useState(0)
  const [total, setTotal] = useState<{ all: number; unread: number }>({
    all: 0,
    unread: 0,
  })
  const [loading, setLoading] = useState(false)

  const allNewsData: News[] = useAppSelector(getNewsByIdsSelector(allNewsIds))
  const unreadNewsData: News[] = useAppSelector(
    getNewsByIdsSelector(unreadNewsIds)
  )

  const loadAllNews = useCallback(
    (customerNumberChanged?: boolean, start?: number) => {
      setLoading(true)
      dispatch(
        newsGetAll({
          keepPrevious: !customerNumberChanged,
          type: 'All',
          limit: NEWS_LIMIT,
          start: start ?? pageStart,
        })
      )
        .then(unwrapResult)
        .then(([payload]) => {
          setTotal(prev => ({
            all: payload.total ?? 0,
            unread: prev.unread,
          }))
          start && setPageStart(start)
          setNumOfPages(
            payload.total ? Math.ceil(payload.total / NEWS_LIMIT) : 0
          )
          setAllNewsIds(payload.results?.map(res => res.id ?? '') ?? [])
        })
        .finally(() => setLoading(false))
    },
    [dispatch, pageStart]
  )

  const loadUnreadNews = useCallback(
    (customerNumberChanged?: boolean, start?: number) => {
      setLoading(true)
      dispatch(
        newsGetAll({
          keepPrevious: !customerNumberChanged,
          type: 'Unread',
          limit: NEWS_LIMIT,
          start: start ?? pageStart,
        })
      )
        .then(unwrapResult)
        .then(([payload]) => {
          setTotal(prev => ({
            all: prev.all,
            unread: payload.total ?? 0,
          }))
          start && setPageStart(start)
          setNumOfPages(
            payload.total ? Math.ceil(payload.total / NEWS_LIMIT) : 0
          )
          setUnreadNewsIds(payload.results?.map(res => res.id ?? '') ?? [])
        })
        .finally(() => setLoading(false))
    },
    [dispatch, pageStart]
  )

  useDispatchOnCustomerNumberChange(loadAllNews)
  useDispatchOnCustomerNumberChange(loadUnreadNews)

  return {
    allNews: allNewsData,
    unreadNews: unreadNewsData,
    pagination: {
      limit: NEWS_LIMIT,
      numOfPages,
      pageStart,
    },
    total,
    loading,
    loadAllNews,
    loadUnreadNews,
  }
}
