import { useApi } from '@core/apiClient/apiClient'
import { Collapse } from '@material-ui/core'
import { Consent, Document } from '@shared/contracts/models'
import { Field, FieldProps } from 'formik'
import { useSnackbar } from 'notistack'
import { isEmpty } from 'ramda'
import React, { useEffect, useState } from 'react'
import { ConsentsCheckbox } from './Checkbox'

export const Consents: React.FC<{ consents: Consent[] }> = ({ consents }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [executeGetDocuments] = useApi<Document>({
    omitCustomerNumber: true,
    omitConsents: true,
    config: {
      method: 'get',
    },
  })

  const [documentsContent, setDocumentsContent] = useState(
    {} as {
      [consentType: string]: string
    }
  )

  useEffect(() => {
    Promise.all(
      consents
        .filter(consent => !consent.pdfDocumentAvailable)
        .map(consent =>
          executeGetDocuments({
            url: '/Documents/' + consent.documentType,
          })
        )
    )
      .then(documents => {
        const contents = documents.reduce((acc, { data }) => {
          const type = data.type?.toString()
          if (!type) return acc

          return {
            ...acc,
            [type]: data.content,
          }
        }, {})
        setDocumentsContent(contents)
      })
      .catch(() => {
        enqueueSnackbar(
          'Pobieranie treści zgód nie udało się, odśwież stronę lub skontaktuj się z biurem obsługi jeśli sytuacja powtarza się.',
          { variant: 'error' }
        )
      })
  }, [consents, enqueueSnackbar, executeGetDocuments])

  return (
    <Collapse
      in={!isEmpty(consents)}
      {...(!isEmpty(consents) ? { timeout: 500 } : {})}
    >
      {consents?.map(consent => {
        const { id, mandatory, documentType, pdfDocumentAvailable } = consent
        return (
          <Field
            key={id}
            name={id}
            validate={(value: boolean) => {
              if (mandatory && !Boolean(value)) {
                return true
              }
              return false
            }}
          >
            {({
              field,
              meta: { error, touched },
              form: { submitCount },
            }: FieldProps) => {
              const showError = Boolean(error) && (touched || submitCount > 0)
              return (
                <ConsentsCheckbox
                  field={field}
                  showError={showError}
                  mandatory={mandatory}
                  documentTitle={consent.documentTitle}
                  documentContent={
                    consent.documentType &&
                    documentsContent[consent.documentType]
                  }
                  documentFile={
                    pdfDocumentAvailable ? `Documents/${documentType}/pdf` : ''
                  }
                  documentType={documentType}
                />
              )
            }}
          </Field>
        )
      })}
    </Collapse>
  )
}
