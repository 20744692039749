import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'
import { StoreReducers } from '../StoreReducers'
import storage from 'redux-persist/lib/storage'

export type persistedSettingsType =
  | 'cookiesSeen'
  | 'analyticsEnabled'
  | 'mobileAppNavSeen'

export type PersistedSettingsState = {
  [key in persistedSettingsType]: boolean
}

const initialState: PersistedSettingsState = {
  cookiesSeen: false,
  analyticsEnabled: false,
  mobileAppNavSeen: false,
}

export interface ISetPersistedSettingsPayload {
  persistedSetting: persistedSettingsType
  value: boolean
}

export const persistedSettingsSlice = createSlice({
  name: StoreReducers.persistedSettings,
  initialState,
  reducers: {
    setPersistedSetting: (
      state,
      { payload }: PayloadAction<ISetPersistedSettingsPayload>
    ) => {
      state[payload.persistedSetting] = payload.value
    },
  },
})

export const persistedSettingsActions = persistedSettingsSlice.actions

const persistConfig: PersistConfig<PersistedSettingsState> = {
  key: StoreReducers.persistedSettings,
  storage: storage,
}

export const persistedSettingsReducer = persistReducer(
  persistConfig,
  persistedSettingsSlice.reducer
)
