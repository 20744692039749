import { MfaContactType } from '@shared/contracts/mfaContactType'

export interface EditMainPhoneFormProps {
  onCancel: () => void
  showUnexpectedErrorModal: () => void
  mainPhoneNumber: string | undefined
  mainPhonePrefix: string | undefined
  isMfaEnabled: boolean | undefined
  mfaContactType: MfaContactType | undefined
  openModifyMfaForm: () => void
}

export enum EditPhoneModalView {
  INTRODUCTION = 'INTRODUCTION',
  PHONE_INPUT = 'PHONE_INPUT',
  MFA_CODE = 'MFA_CODE',
}

export interface InitialFieldsType {
  Phone: string
  PhonePrefix: string
  MfaCode: string
}
