import { actionsInterceptorMiddleware } from '@core/store/middleware/actionsInterceptorMiddleware'
import { USER_FOUND } from 'redux-oidc'
import { customerNumberActions } from './customerNumbers'
import { customerNumbersGetAll } from './customerNumbersThunks'

export const addCustomerNumbersSideEffects = () => {
  actionsInterceptorMiddleware.addManyActionsCallback([
    {
      actionTypes: [USER_FOUND],
      callback: ({ dispatch, state }) => {
        if (!state.customerNumbers.loadingPending) {
          dispatch(customerNumbersGetAll())
        }
      },
    },
    {
      actionTypes: [customerNumbersGetAll.fulfilled.type],
      callback: ({ dispatch, state }) => {
        const selectdNumer = state.customerNumbers.selectedCustomerNumber
        const customerNumbers = state.customerNumbers.ids
        if (
          !selectdNumer ||
          (selectdNumer && customerNumbers.indexOf(selectdNumer) < 0)
        ) {
          dispatch(customerNumberActions.setCustomerNumber(customerNumbers[0]))
        }
      },
    },
  ])
}
