import {
  IChangePasswordErrors,
  IChangePasswordFormValues,
} from './ChangePasswordForm'
import {
  PasswordRequirements,
  passReqNames,
} from '../../../../Register/static-data/RegisterData'

import i18n from '@i18n/index'

const getPasswordMisssingRequirement = (value: string) => {
  const missingRequirements = [
    passReqNames.LETTERS,
    passReqNames.DIGIT,
    passReqNames.LENGTH,
  ].map(item =>
    !PasswordRequirements[item].regex.test(value)
      ? PasswordRequirements[item].title
      : ''
  )
  return missingRequirements.filter(i => i)
}

export const validate = (values: IChangePasswordFormValues) => {
  const errors: IChangePasswordErrors = {}
  if (!values.currentPassword) {
    errors.currentPassword = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  } else {
    const missingReqs = getPasswordMisssingRequirement(values.currentPassword)
    if (missingReqs.length)
      errors.currentPassword = i18n().t('FORM_ERRORS.MISSING_REQUIREMENTS', {
        reqs: missingReqs.join(', '),
      })
  }
  if (values.currentPassword === values.newPassword) {
    errors.newPassword = i18n().t(
      'FORM_ERRORS.NEW_PASSWORD_SHOULD_BE_DIFFERENT'
    )
  }
  if (!values.newPassword) {
    errors.newPassword = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  } else {
    const missingReqs = getPasswordMisssingRequirement(values.newPassword)
    if (missingReqs.length)
      errors.newPassword = i18n().t('FORM_ERRORS.MISSING_REQUIREMENTS', {
        reqs: missingReqs.join(', '),
      })
  }
  if (!values.confirmNewPassword) {
    errors.confirmNewPassword = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  } else if (values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = i18n().t('FORM_ERRORS.PASSWORDS_MUST_BE_SAME')
  }
  return errors
}
