import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { primaryColor } from '@mbok/styles/constants'
import {
  CircularProgress,
  Fade,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useAppSelector,useAppDispatch } from '@core/store/store'
import { ImageType } from '@core/store/offers/types'
import { getDesktopOffersByTypeSelector } from '@core/store/offers/offersSelectors'
import Slider from 'react-slick'
import useStyles from './OfferCarousel.style'
import { generateUserToken } from '@shared/utils/generateUserToken'
import store from '@core/store/store'
import { showModal } from '@core/store/ui/actions'
import i18n from '@i18n/index'
export interface IOfferCarousel {
  type: ImageType
}

const LOADING_CUSTOM_TIMEOUT = 800

const OfferCarousel: React.FC<IOfferCarousel> = ({ type, ...props }) => {
  const dispatch = useAppDispatch()
  const styles = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const offers = useAppSelector(
    getDesktopOffersByTypeSelector(smallScreen ? ImageType.top : type)
  )

  const initialIndex = useMemo(() => {
    return type === ImageType.card && offers && offers.length > 1 ? 1 : 0
  }, [type, offers])

  useEffect(() => {
    setActiveSlide(initialIndex)
  }, [initialIndex])

  const [activeSlide, setActiveSlide] = useState(initialIndex)
  const settings = {
    dots: true,
    infinite: true,
    adaptiveHeight: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialIndex,
    autoplay: true,
    autoplaySpeed: 8000,
    beforeChange: (old: number, next: number) => setActiveSlide(next),
    appendDots: (dots: any) => (
      <div
        style={{
          position: 'absolute',
          bottom: theme.spacing(2),
          [theme.breakpoints.down(350)]: {
            bottom: theme.spacing(3),
          },
          zIndex: 1,
          width: '100%',
          maxHeight: '20px !important',
          minHeight: '20px !important',
          padding: 'none',
        }}
      >
        {offers && offers.length && (
          <ul
            style={{
              margin: '0px',
              display: 'flex',
              padding: 0,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {dots}
          </ul>
        )}
      </div>
    ),
    customPaging: (i: number) => {
      const style = {
        width: 12,
        height: 12,
        borderRadius: 100,
        backgroundColor: 'white',
        transition: 'all 0.3s',
      }
      const activeStyle = {
        ...style,
        width: 16,
        height: 16,
        backgroundColor: primaryColor,
        transition: 'all 0.3s',
      }
      return <div style={i === activeSlide ? activeStyle : style} />
    },
  }
  const getBgUrl = (url: string) => {
    const newUrl = url.includes('http') ? url : `http://${url}`
    return newUrl.replace(/\\/g, '/')
  }

  const itemClassName = useCallback(
    (type: ImageType) => {
      switch (type) {
        case ImageType.top:
          return { itemStyle: styles.itemTopType, wrapper: '' }
        case ImageType.card:
          return {
            itemStyle: styles.itemCardType,
            wrapper: styles.wrapperCardType,
          }
        default:
          return { itemStyle: styles.itemBannerType, wrapper: '' }
      }
    },
    [
      styles.itemTopType,
      styles.itemCardType,
      styles.itemBannerType,
      styles.wrapperCardType,
    ]
  )
const handleRedirect =async ()=>{
  dispatch(generateUserToken()).then((response: any) => {
   window.open('https://nowaoferta.polenergia.pl/')
  })
  .catch(()=>{
    store.dispatch(
      showModal({
        title: i18n().t('MODAL_MESSAGES.SORRY'),
        subtitle: i18n().t('MODAL_MESSAGES.UNEXPECTED_ERROR_OCCURED'),
        type: 'error',
        withCancelButton: true,
        withSubmitButton: false,
      })
    )
  })
}

  return (
    <div className={`${styles.wrapper} ${itemClassName(type).wrapper}`}>
      {offers ? (
        <Slider {...settings}>
          {offers.map((item, index) => (
            <Fade
              {...{ timeout: LOADING_CUSTOM_TIMEOUT * 1.5 }}
              in={Boolean(offers)}
              key={`ad-${index}-${item.title}-${item.adType}`}
            >
              <div
                className={styles.adItem}
                onClick={handleRedirect}
                style={{ cursor: item.redirectUrl ? 'pointer' : 'default' }}
              >
                <div
                  aria-label={item.title}
                  className={`${styles.itemContent} ${
                    itemClassName(type).itemStyle
                  }`}
                  style={{
                    backgroundImage: `url('${getBgUrl(
                      item.images[0].imageUrl
                    )}')`,
                  }}
                ></div>
              </div>
            </Fade>
          ))}
        </Slider>
      ) : (
        <div className={styles.loader}>
          <CircularProgress size={28} />
        </div>
      )}
    </div>
  )
}

export default OfferCarousel
