import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { EntityReducers } from '../EntityReducers'
import {
  getBaseState,
  getEntityAdapterReducers,
} from '../helpers/entityReducerHelpers'
import { getAllExtraReducers } from '../helpers/thunks/getAllThunk'
import {
  userAgreementsGetAll,
  updateAgreementEmail,
  updateAgreementCorrespondenceAddress,
} from './userAgreementsThunks'
import { addUserAgreementsSideEffects } from './userAgreementsSideEffects'
import { handleLoadingAction } from '../helpers/thunks/entityThunksFactory'
import {
  AgreementListItem,
  AgreementListItemPagedResponse,
  InvoiceType,
  MeasurementPointListItem,
} from '@shared/contracts/models'
import { BaseAction } from '@core/store/types'
import { NewAddressPayload } from './types'
import { getAgreementsIds } from '@shared/utils/measurementPoints'

const entityAdapter = createEntityAdapter<AgreementListItem>({
  selectId: agreement => agreement.agreementId ?? '',
})

export interface UpdateAgreementsEmailsPayload {
  newEmailAddress: string
}

type RefreshAgreementsEmailsAction = BaseAction & {
  payload: {
    oldEmail?: string
    newEmail: string
    measurementPoints: MeasurementPointListItem[]
    agreements?: (AgreementListItem | undefined)[]
  }
}

type RefreshCorrespondenceAddressAction = BaseAction & {
  payload: {
    agreementId: string
    newAddress: NewAddressPayload
  }
}

type RefreshAgreementsAfterActivateAction = BaseAction & {
  payload: {
    measurementPoints: MeasurementPointListItem[]
    agreements?: (AgreementListItem | undefined)[]
    newEmail: string
    oldEmail?: string
  }
}

export const userAgreementsSlice = createSlice({
  name: EntityReducers.userAgreements,
  initialState: {
    ...getBaseState(entityAdapter),
  },
  reducers: {
    refreshAgreementsEmails: (state, action: RefreshAgreementsEmailsAction) => {
      if (!state || !state.entities || !action.payload) {
        return
      }
      const ids = getAgreementsIds(action.payload.measurementPoints)
      const otherAgreementsIds = getAgreementsIds(
        action.payload.agreements ?? []
      )
      const allIds =
        otherAgreementsIds.length > 0 ? ids.concat(otherAgreementsIds) : ids
      allIds.forEach((agreementId?: string) => {
        if (!agreementId) {
          return
        }
        let value = state.entities[agreementId]
        if (!value || !value?.emailAddresses) {
          return
        }

        value.emailAddresses.forEach((email, index, emails) => {
          emails[index] = action.payload.newEmail
        })
      })
    },
    refreshAgreementCorrespondenceAddress: (
      state,
      action: RefreshCorrespondenceAddressAction
    ) => {
      if (!state || !state.entities || !action.payload) {
        return
      }
      let value = state.entities[action.payload.agreementId]
      if (value) {
        value.correspondenceAddressLine1 =
          action.payload.newAddress.newAddressLine1
        value.correspondenceAddressLine2 =
          action.payload.newAddress.newAddressLine2
      }
    },
    refreshAgreementEmailsAfterActivateEInvoice: (
      state,
      action: RefreshAgreementsAfterActivateAction
    ) => {
      if (!state || !state.entities || !action.payload) {
        return
      }
      const ids = getAgreementsIds(action.payload.measurementPoints)
      const otherAgreementsIds = getAgreementsIds(
        action.payload.agreements ?? []
      )
      const allIds =
        otherAgreementsIds.length > 0 ? ids.concat(otherAgreementsIds) : ids
      allIds.forEach((agreementId?: string) => {
        if (!agreementId) {
          return
        }
        let value = state.entities[agreementId]
        if (!value || !value?.emailAddresses) {
          return
        }
        value.invoiceType = InvoiceType.Email
        if (value.emailAddresses.length > 0) {
          value.emailAddresses.forEach((email, index, emails) => {
            emails[index] = action.payload.newEmail
          })
        } else {
          value.emailAddresses = Array(action.payload.newEmail)
        }
      })
    },
    ...getEntityAdapterReducers<AgreementListItem>(entityAdapter),
  },
  extraReducers: builder => {
    getAllExtraReducers<
      AgreementListItem,
      undefined,
      AgreementListItemPagedResponse
    >(
      userAgreementsGetAll,
      builder,
      entityAdapter,
      response => response.results ?? []
    )
    handleLoadingAction(updateAgreementCorrespondenceAddress, builder)
    builder.addCase(
      updateAgreementCorrespondenceAddress.fulfilled,
      (state, { payload }) => {
        state.loadingPending > 0 && state.loadingPending--
      }
    )
    handleLoadingAction(updateAgreementEmail, builder)
    builder.addCase(updateAgreementEmail.fulfilled, (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
    })
  },
})

addUserAgreementsSideEffects()

export const userAgreementsActions = userAgreementsSlice.actions
