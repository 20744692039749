import React, { useState, useCallback } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import { DocumentType } from '@shared/contracts/models'
import Switch from '../../Switch'
import useStyles from './SwitchForm.style'

export interface ISwitch {
  label: string | JSX.Element
  checked?: boolean
  onChange: () => Promise<unknown>
  mandatory?: boolean
  documentType?: DocumentType
}

export interface ISwitchFormProps {
  data: ISwitch[]
  name: string
}

export const SwitchForm: React.FC<ISwitchFormProps> = ({ data, name }) => {
  const styles = useStyles()
  const [loading, setLoading] = useState<number[]>([])
  const startLoading = (index: number) => {
    const newLoading = [...loading.filter(item => item !== index), index]
    setLoading(newLoading)
  }
  const stopLoading = (index: number) => {
    const newLoading = [...loading.filter(item => item !== index)]
    setLoading(newLoading)
  }

  const isItemLoading = useCallback(
    (index: number) => {
      return loading.includes(index)
    },
    [loading]
  )

  return (
    <Grid className={styles.wrapper} container direction="column">
      {data.map((item, index) => (
        <Grid
          container
          className={styles.row}
          key={`switch-${item.label}-${index}-${name}`}
        >
          {isItemLoading(index) ? (
            <div className={styles.loaderContainer}>
              <div>
                <CircularProgress size={22} />
              </div>
            </div>
          ) : (
            <Switch
              onChange={async () => {
                startLoading(index)
                await item.onChange()
                stopLoading(index)
              }}
              checked={!!item.checked}
              disabled={item.mandatory}
            />
          )}
          <div className={styles.title}>{item.label}</div>
        </Grid>
      ))}
    </Grid>
  )
}
