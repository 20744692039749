import React, { useState, useEffect, useCallback } from 'react'
import {
  TextField,
  withTheme,
  withStyles,
  Theme,
  makeStyles,
  Collapse,
} from '@material-ui/core'
import StyledButton from './Button'
import {
  accountTypes,
  InputType,
  TaxInputType,
  PasswordRequirements,
  fieldNames,
  passReqNames,
} from '../../features/Register/static-data/RegisterData'
import { FieldInputProps } from 'formik'
import { ReactComponent as HideSvg } from '@images/icons/input/HideInput.svg'
import { ReactComponent as ShowSvg } from '@images/icons/input/ShowInput.svg'
import { ReactComponent as CheckOnSvg } from '@images/icons/checkbox/Checked.svg'
import { ReactComponent as CheckOffSvg } from '@images/icons/checkbox/Unchecked.svg'
import HintPopover from './HintPopover'

export const Input = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& input': {
      padding: '2px 0',
      fontSize: 16,
      opacity: 0.85,
      color: theme.palette.text.secondary,
      letterSpacing: '0.11px',
      fontWeight: theme.typography.fontWeightBold,
      '&::placeholder': {
        letterSpacing: '1.25px',
        color: theme.palette.text.secondary,
        opacity: 0.35,
        fontWeight: theme.typography.fontWeightBold,
      },
      '&:disabled': {
        color: theme.palette.text.secondary,
        opacity: 0.35,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    '& label': {
      display: 'none',
    },
    '& div': {
      margin: 0,
    },
    '& div:after, div:before': {
      display: 'none',
    },
  },
}))(withTheme(TextField))

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    margin: '0 0 16px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '50px',
    '& p': {
      margin: 0,
      fontSize: 11,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0.08px',
      textAlign: 'center',
    },
    '& button': {
      fontSize: 16,
      width: '44%',
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        fontSize: 10,
      },
      '& button': {
        fontSize: 12,
        width: '44%',
      },
    },
  },
  titles: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'left',
    textAlign: 'left',
    minHeight: '17px',
    margin: '0 0 4px 20px',
    '& p': {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      gap: '3.5px',
      marginRight: '8px',
      fontSize: 12,
      fontWeight: 800,
      color: theme.palette.text.primary,
      letterSpacing: '0.08px',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
      '& span': {
        opacity: 0.25,
        [theme.breakpoints.down('xs')]: {
          fontSize: 10,
        },
      },
    },
    '& div > p': {
      margin: 0,
      fontSize: 12,
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightBold,

      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginLeft: 0,
    },
  },
  titlesAndButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  changeButton: {
    textDecoration: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    padding: 0,
    marginTop: '2px',
    fontSize: 13,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0.09px',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  input: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 16px 0 20px',
    height: '50px',
    border: '1.7px solid #D3CFCF',
    borderRadius: '7px',
    backgroundColor: '#f9f9f9',
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: ' 0.11px',
    lineHeight: '25px',
    overflow: 'hidden',

    transition: 'border 0.2s ease-in-out',
  },
  error: {
    border: `1.7px solid ${theme.palette.error.main}`,
  },
  icon: {
    height: 24,
    width: 24,
    '& svg': {
      opacity: 0.85,
      transition: 'opacity 0.2s ease-in-out',
    },
    '&:hover': {
      '& svg': {
        opacity: 1,
      },
    },
    cursor: 'pointer',
  },
  iconDisabled: {
    height: 24,
    width: 24,
    '& svg': {
      color: theme.palette.primary.main,
    },
    opacity: 0.2,
  },
  password: {
    display: 'flex',
    margin: '8px -16px 0',
    '& div': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '0 16px 0',
      '& svg': {
        width: '16px',
        height: '16px',
        marginRight: '4px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: '8px 0 0',
      '& div': {
        margin: 0,
      },
    },
  },
  validationContainer: {
    '& svg': {
      transition: 'color 0.2s ease-in-out',
    },
    '& p': {
      margin: 0,
      fontSize: 12,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0.08px',
      transition: 'color 0.2s ease-in-out',
    },
  },
  goodPasswordIcon: {
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
  wrongPasswordIcon: {
    '& svg': {
      color: 'lightGrey',
    },
  },
  wrongPasswordText: {
    color: theme.palette.error.main,
  },
}))

export interface StyledInputProps {
  value: string
  change: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  errorMsg?: string
  type?: string
  placeholder?: string
}

// reusable styled input

export const StyledInput: React.FC<StyledInputProps> = ({
  value,
  change,
  errorMsg,
  type,
  placeholder,
}) => {
  const styles = useStyles()

  return (
    <div className={styles.inputContainer}>
      <div className={styles.titles}>
        <Collapse in={Boolean(errorMsg)}>
          <p>{errorMsg}</p>
        </Collapse>
      </div>
      <div className={`${styles.input} ${Boolean(errorMsg) && styles.error}`}>
        <Input
          type={type ?? 'text'}
          value={value}
          onChange={change}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}

// input (form)

interface CustomInputProps {
  field: {
    name: string
    value: string
  }
  inputData: InputType
  showError?: boolean
  errMessage?: string
  optional?: boolean
}

export const CustomInput: React.FC<CustomInputProps> = ({
  field,
  inputData,
  showError,
  errMessage,
  optional,
}) => {
  const styles = useStyles()
  const { title, placeholder, errorMsg, helper, disable } = inputData
  const [error, setError] = useState('')

  useEffect(() => {
    if (showError) {
      if (errMessage) {
        setError(errMessage)
      } else {
        setError(errorMsg())
      }
    }
  }, [showError, setError, errorMsg, errMessage])

  return (
    <div className={styles.inputContainer}>
      <div className={styles.titles}>
        <p>
          {title} {optional && <span>(opcjonalne)</span>}{' '}
          {helper && <HintPopover content={helper.content} />}
        </p>
        <Collapse in={showError && Boolean(error)}>
          <p>{error}</p>
        </Collapse>
      </div>
      <div className={`${styles.input} ${showError && styles.error}`}>
        <Input
          type={field.name}
          {...field}
          placeholder={placeholder}
          disabled={disable}
        />
      </div>
    </div>
  )
}

// password

interface CustomPasswordInputProps extends CustomInputProps {
  showRequirements?: boolean
}

interface PasswordReqTypes {
  [key: string]: boolean
}

export const CustomPasswordInput: React.FC<CustomPasswordInputProps> = ({
  field,
  showError,
  inputData,
  showRequirements = true,
}) => {
  const styles = useStyles()
  const { title, placeholder, errorMsg } = inputData
  const { DIGIT, LETTERS, LENGTH } = passReqNames

  const [visible, setVisible] = useState(false)
  const [passwordReq, setPasswordReq] = useState<PasswordReqTypes>({
    [DIGIT]: false,
    [LETTERS]: false,
    [LENGTH]: false,
  })

  const check = useCallback((value: string, type: string): boolean => {
    return PasswordRequirements[type].regex.test(value)
  }, [])

  useEffect(() => {
    if (field.value) {
      setPasswordReq({
        digit: check(field.value, DIGIT),
        letters: check(field.value, LETTERS),
        length: check(field.value, LENGTH),
      })
    } else {
      setPasswordReq({
        digit: false,
        letters: false,
        length: false,
      })
    }
  }, [field.value, field.name, check, DIGIT, LETTERS, LENGTH])

  return (
    <div className={styles.inputContainer}>
      <div className={styles.titles}>
        <p>{title}</p>
        <Collapse in={showError}>
          <p>{errorMsg()}</p>
        </Collapse>
      </div>
      <div className={`${styles.input} ${showError && styles.error}`}>
        <Input
          type={visible ? 'text' : 'password'}
          autoComplete="off"
          {...field}
          placeholder={placeholder}
        />
        <div
          className={field.value ? styles.icon : styles.iconDisabled}
          onClick={() => {
            field.value && setVisible(prev => !prev)
          }}
        >
          {visible ? <ShowSvg /> : <HideSvg />}
        </div>
      </div>
      {showRequirements && (
        <div className={styles.password}>
          {Object.keys(PasswordRequirements).map(name => {
            const requirement = PasswordRequirements[name]
            return (
              <div key={name} className={styles.validationContainer}>
                {passwordReq[name] ? <CheckOnSvg /> : <CheckOffSvg />}
                <p
                  className={
                    !passwordReq[name] && showError
                      ? styles.wrongPasswordText
                      : ''
                  }
                >
                  {requirement.title}
                </p>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

// pesel/nip

interface PeselOrNipProps {
  setFieldValue: (field: string, value: any) => void
  fieldData: {
    field: FieldInputProps<any>
    input: TaxInputType
  }
  showError: boolean
  accType: string
  errMessage?: string
}

export const PeselOrNipInput: React.FC<PeselOrNipProps> = ({
  setFieldValue,
  fieldData,
  showError,
  accType,
  errMessage,
}) => {
  const styles = useStyles()
  const { COMMERCIAL, INDIVIDUAL, NOT_SELECTED } = accountTypes
  const { ACCOUNT_TYPE, TAX_NUMBER } = fieldNames

  const [error, setError] = useState('')
  const [accountType, setAccountType] = useState<string>(accType)

  const { field, input } = fieldData
  const title = input.title(accountType)
  const placeholder = input.placeholder(accountType)

  useEffect(() => {
    setError(input.errorMsg(accountType))
    if (showError && field.value) {
      setError(input.errorMsg(accountType))
    }
  }, [accountType, field.value, showError, setError, input])

  useEffect(() => {
    if (
      showError &&
      errMessage &&
      errMessage !== input.errorMsg(INDIVIDUAL) &&
      errMessage !== input.errorMsg(COMMERCIAL) &&
      errMessage !== input.errorMsg(NOT_SELECTED)
    ) {
      setError(errMessage)
    }
  }, [showError, errMessage, input, COMMERCIAL, INDIVIDUAL, NOT_SELECTED])

  useEffect(() => {
    setAccountType(accType)
  }, [accType])

  return (
    <div className={styles.inputContainer}>
      <div className={styles.titlesAndButton}>
        <div className={styles.titles}>
          <p>{title}</p>
          <Collapse in={showError}>
            <p>{error}</p>
          </Collapse>
        </div>
        {accountType !== NOT_SELECTED && (
          <button
            className={styles.changeButton}
            onClick={() => {
              setFieldValue(ACCOUNT_TYPE, NOT_SELECTED)
              setAccountType(NOT_SELECTED)
              setFieldValue(TAX_NUMBER, '')
            }}
          >
            ZMIEŃ
          </button>
        )}
      </div>

      <Collapse in={accountType === NOT_SELECTED}>
        <div className={styles.buttonsContainer}>
          <StyledButton
            color="secondary"
            variant="contained"
            onClick={() => {
              setFieldValue(ACCOUNT_TYPE, INDIVIDUAL)
              setAccountType(INDIVIDUAL)
            }}
          >
            Prywatne
          </StyledButton>
          <p>LUB</p>
          <StyledButton
            color="secondary"
            variant="contained"
            onClick={() => {
              setFieldValue(ACCOUNT_TYPE, COMMERCIAL)
              setAccountType(COMMERCIAL)
            }}
          >
            Firmowe
          </StyledButton>
        </div>
      </Collapse>

      <Collapse in={accountType !== NOT_SELECTED}>
        <div className={`${styles.input} ${showError && styles.error}`}>
          <Input {...field} placeholder={placeholder} />
        </div>
      </Collapse>
    </div>
  )
}
