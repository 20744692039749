import { createSlice } from '@reduxjs/toolkit'
import {
  initialLoadingState,
  LoadingState,
} from '../entity/helpers/entityReducerHelpers'
import { StoreReducers } from '../StoreReducers'
import { Offer } from './types'
import { getOffersExtraReducers } from './offersThunks'

export interface OffersState extends LoadingState {
  offers?: Offer[] | null
}

const initialState: OffersState = {
  ...initialLoadingState,
  offers: null,
}

export const offersSlice = createSlice({
  name: StoreReducers.offers,
  initialState,
  reducers: {
    resetState: () => {
      return initialState
    },
  },
  extraReducers: builder => {
    getOffersExtraReducers(builder)
  },
})

export const offerActions = offersSlice.actions
