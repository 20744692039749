import { makeStyles, Theme } from '@material-ui/core'
import { secondaryColor } from '@mbok/styles/constants'

export default makeStyles((theme: Theme) => ({
  cardHeader: {
    padding: 0,
  },
  cardContent: {
    padding: 0,
    marginTop: theme.spacing(2),
    '&:last-child': {
      paddingBottom: 0,
    },
    fontSize: 18,
  },
  emptyCard: {
    padding: '0px !important',
    fontSize: 18,
    height: 80,
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 35,
    height: 35,
    backgroundColor: '#F2F3F4',
    padding: theme.spacing(2),
    borderRadius: 4,
  },
  emptyCardAvatar: {
    width: 60,
    height: 60,
    marginRight: theme.spacing(8),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  numberHeading: {
    marginBottom: theme.spacing(1),
    fontSize: 14,
  },
  number: {
    fontWeight: 800,
    fontSize: 18,
  },
  delete: {
    position: 'absolute',
    top: 12,
    right: 8,
    width: 20,
    height: 20,
    color: secondaryColor,
  },
}))
