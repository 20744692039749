import { makeStyles, Theme } from '@material-ui/core'
import {
  tableTextColor,
  textFieldBorderColor,
  autocompleteBackgroundColor,
  secondaryColor,
  borderColor,
  errorRedColor
} from '@mbok/styles/constants'

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    '& .MuiInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiFormControl-root': {
      alignSelf: 'center',
    },
    '& .MuiSvgIcon-root': {
      color: secondaryColor,
      fontSize: '1.2rem',
    },
  },
  inputRoot: {
    '&[class*="MuiInput-root"]': {
      border: '1.8px solid',
      backgroundColor: autocompleteBackgroundColor,
      borderColor: textFieldBorderColor,
      borderRadius: 4,
      height: 40,
      padding: `${theme.spacing(0, 3)} !important`,
      '&  .MuiAutocomplete-input': {
        padding: theme.spacing(0, 3),
        fontSize: 15,
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'inherit',
      marginRight: theme.spacing(3),
    },
    '&.MuiInput-adornedEnd': {
      paddingRight: theme.spacing(3) + 'px !important',
    },
    letterSpacing: '0.2px',
    fontWeight: theme.typography.fontWeightBold,
    color: tableTextColor,
  },
  inputError: {
    '& .MuiInput-root': {
      borderColor: errorRedColor,
    },
  },
  option: {
    display: 'flex',
    minHeight: 40,
    padding: theme.spacing(2, 3),
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    '&[aria-selected=true]': {
      backgroundColor: autocompleteBackgroundColor,
    },
    '&[aria-selected=false][data-focus=true]': {
      backgroundColor: 'white',
    },
    '& .MuiIconButton-root': {
      height: 20,
      width: 20,
      backgroundColor: 'transparent',
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(3),
    },
  },
  highlightedOption: {
    backgroundColor: `${autocompleteBackgroundColor} !important`,
  },
  error: {
    height: 40,
    border: '1.7px solid #FF4067',
    borderRadius: 4,
    backgroundColor: '#FAD1DA',
    boxShadow: 'none',
    color: '#D2183E',
    fontSize: 14,
    letterSpacing: 0.1,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(2, 4),
    marginTop: theme.spacing(2),
  },
  errorInput: {
    paddingLeft: 0,
  },
  noOptions: {
    borderRadius: 4,
  },
  noItemsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    cursor: 'pointer',
  },
  popper: {
    '& .MuiAutocomplete-paper': {
      padding: 0,
      marginTop: -1.8,
    },
    '& .MuiAutocomplete-listbox': {
      maxHeight: 136,
      border: `1.7px solid ${borderColor}`,
      borderRadius: 4,
    },
    '& .MuiPaper-rounded': {
      borderRadius: 4,
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
      border: 'none',
    },
  },
  loading: {
    marginLeft: theme.spacing(1),
  },
}))
