import Cookies from 'universal-cookie'

const analyticsCookiesNames = ['_ga', '_gid', '_gat']
const defaultCookiesPath = '/'

export const deactivateGa = () => {
  const cookies = new Cookies()
  analyticsCookiesNames.forEach(cookieName => {
    cookies.remove(cookieName, { path: defaultCookiesPath })
  });
}
