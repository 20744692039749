import React, { useCallback, useEffect, useState } from 'react'
import {
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Theme,
} from '@material-ui/core'
import StyledButton from '@shared/components/Button'
import { ErrorCodes, ErrorCodeTypes } from '../../static-data/RegisterData'
import { useApi } from '@core/apiClient/apiClient'
import { Contact } from '@shared/contracts/models'
import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { Animation } from '@shared/components/Animation'
import { AnimationSluchawkiSrc } from '@shared/static-data/staticData'

const WIDTH = 84
const HEIGHT = 84
const BTN_HEIGHT = 48

const COPY_ICON_SIZE = 18

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      padding: 0,
    },
    icon: {
      width: WIDTH,
      height: HEIGHT,
      marginBottom: theme.spacing(1),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: theme.spacing(5, 0, 0),
      '& h1': {
        margin: theme.spacing(0, 0, 4),
        fontSize: 28,
        textAlign: 'center',
        letterSpacing: '0.29px',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& h3': {
        margin: theme.spacing(0, 0, 4),
        fontSize: 18,
        textAlign: 'center',
        letterSpacing: '0.05px',
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightLight,
      },
      '& h5': {
        margin: theme.spacing(0, 0, 5),
        fontSize: 12,
        color: theme.palette.common.black,
      },
      [theme.breakpoints.down('xs')]: {
        '& h1': {
          fontSize: 24,
        },
        '& h3': {
          fontSize: 15,
        },
      },
    },
    info: {
      margin: theme.spacing(0, 0, 5),
      fontSize: 15,
      '& p': {
        margin: theme.spacing(0, 0, 1),
        '&:last-child': {
          fontSize: 18,
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      '& a': {
        cursor: 'pointer',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        transition: 'color 0.2s ease-in-out',
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
      '& svg': {
        marginLeft: theme.spacing(1),
        fill: theme.palette.primary.main,
        height: COPY_ICON_SIZE,
        width: COPY_ICON_SIZE,
        cursor: 'pointer',
        transition: 'fill 0.2s ease-in-out, transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1)',
          fill: theme.palette.primary.dark,
        },
      },
      '& div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    data: {
      fontSize: 18,
      fontWeight: theme.typography.fontWeightBold,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    button: {
      margin: 0,
      width: '44%',
      height: BTN_HEIGHT,
      fontSize: 17,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('xs')]: {
        height: BTN_HEIGHT - 10,
        fontSize: 14,
        width: '100%',
      },
    },
  })
)

interface UpdateDataModalProps {
  show: boolean
  close: () => void
  errCode: ErrorCodeTypes
}

export const ValidationErrorModal: React.FC<UpdateDataModalProps> = ({
  show,
  close,
  errCode,
}) => {
  const styles = useStyles()
  const [contact, setContact] = useState({ phone: '', mail: '' })

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const copyDataToClipboard = useCallback(
    (name: string, dataToCopy: string) => {
      copy(dataToCopy) &&
        enqueueSnackbar(`${name} ${t('BOK_FAB.HAS_BEEN_COPIED')}`, {
          variant: 'default',
          preventDuplicate: true,
          autoHideDuration: 2000,
        })
    },
    [enqueueSnackbar, t]
  )

  const [executeGetContactDetails] = useApi<Contact[]>({
    omitCustomerNumber: true,
    omitConsents: true,
    config: {
      method: 'get',
      url: 'Content/contactDetails',
    },
  })

  const getContactDetails = useCallback(async () => {
    const res = await executeGetContactDetails()
    const [contactDetails] = res.data
    setContact({
      phone: contactDetails.phoneNumber ?? '',
      mail: contactDetails.email ?? '',
    })
  }, [executeGetContactDetails])

  useEffect(() => {
    getContactDetails()
  }, [getContactDetails])

  return (
    <Dialog
      aria-describedby="form-modal"
      open={show}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      className={styles.contentWrapper}
      onClose={close}
    >
      <DialogContent className={styles.content}>
        <Animation src={AnimationSluchawkiSrc} customClass={styles.icon} />
        {errCode === ErrorCodes.FakeTaxNumber && (
          <h1>Dane wymagają aktualizacji</h1>
        )}
        {errCode === ErrorCodes.TaxNumberUsed && <h1>Konto już istnieje</h1>}
        <h3>
          {errCode === ErrorCodes.SegmentNotActive
            ? 'Aby utworzyć konto w portalu klienta, skontaktuj się z nami'
            : 'Prosimy, skontaktuj się z nami w celu wyjaśnienia sprawy'}
        </h3>
        <div className={styles.info}>
          <p>
            Telefonicznie <span>(8:00-18:00, pon-pt.)</span>
          </p>
          <Collapse in={Boolean(contact.phone)}>
            {isMobile ? (
              <a className={styles.data} href={`tel:${contact.phone}`}>
                {contact.phone}
              </a>
            ) : (
              <div>
                <span className={styles.data}>{contact.phone}</span>
                {contact.phone && (
                  <FileCopyIcon
                    onClick={() => {
                      copyDataToClipboard('Telefon', contact.phone)
                    }}
                  />
                )}
              </div>
            )}
          </Collapse>
        </div>
        <div className={styles.info}>
          <p>
            Za pomocą E-mail <span>(24h na dobę, 7 dni w tygodniu)</span>
          </p>
          <Collapse in={Boolean(contact.mail)}>
            <div>
              <a
                className={styles.data}
                href={`mailto:${contact.mail}?subject=Moja Polenergia - rejestracja - kod błędu: ${errCode}`}
              >
                {contact.mail}
              </a>
              {!isMobile && contact.mail && (
                <FileCopyIcon
                  onClick={() => {
                    copyDataToClipboard('E-mail', contact.mail)
                  }}
                />
              )}
            </div>
          </Collapse>
        </div>
        <h5>{errCode !== ErrorCodes.empty && `Kod błędu: ${errCode}`}</h5>
      </DialogContent>
      <DialogActions className={styles.buttonContainer}>
        <StyledButton
          color="primary"
          variant="contained"
          className={styles.button}
          onClick={close}
        >
          Wróć do formularza
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}
