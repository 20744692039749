import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  MeasurementPointListItem,
  MeasurementPointListItemPagedResponse
} from '@shared/contracts/models'
import { useThunkApi } from '../../../apiClient/apiClient'
import { EntityReducers } from '../EntityReducers'
import { getAllThunk } from '../helpers/thunks/getAllThunk'
import { ActivationEInvoiceRequest, UpdateEInvoiceEmailRequest } from './types'

export const measurementPointsGetAll = getAllThunk<
  MeasurementPointListItem,
  undefined,
  MeasurementPointListItemPagedResponse
>(EntityReducers.measurementPoints, ({ thunkApi }) =>
  useThunkApi(
    {
      config: {
        method: 'get',
        url: `MeasurementPoints?agreementStatusFilter=Active`,
      },
    },
    thunkApi
  )
)

export const updateMeasurementPointsEmail = createAsyncThunk<
  UpdateEInvoiceEmailRequest,
  UpdateEInvoiceEmailRequest
>(
  `${[EntityReducers.measurementPoints]}/updateEInvoiceEmail`,
  async ({ ...data }, thunkApi) => {
    const [execute] = useThunkApi<UpdateEInvoiceEmailRequest>(
      {
        config: {
          method: 'put',
          url: `MeasurementPoints/updateEInvoiceEmail`,
          data,
        },
      },
      thunkApi
    )
    await execute()
    return { ...data }
  }
)

export const activationEInvoices = createAsyncThunk<
  ActivationEInvoiceRequest,
  ActivationEInvoiceRequest
>(
  `${[EntityReducers.measurementPoints]}/EInvoice`,
  async ({ ...data }, thunkApi) => {
    const [execute] = useThunkApi<ActivationEInvoiceRequest>(
      {
        config: {
          method: 'post',
          url: `MeasurementPoints/EInvoice`,
          data,
        },
      },
      thunkApi
    )
    await execute()
    return { ...data }
  }
)
