import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useModalFormStyles from '@mbok/styles/modalForm'
import { Account } from '@shared/contracts/account'
import { MeasurementPointListItem } from '@shared/contracts/measurementPointListItem'
import { Form, Formik } from 'formik'
import RadioGroup from '@shared/components/RadioGroup'
import { createFilterOptions } from '@material-ui/lab'
import Autocomplete from '@shared/components/Autocomplete'
import Label from '@shared/components/Label'
import Payer from '../Payer'
import Actions from '@shared/components/Actions'
import ActivationPoint from '../ActivationPoint'
import { AgreementListItem } from '@shared/contracts/agreementListItem'
import SelectedMeasurementPoint from '../SelectedMeasurementPoint'
import useStyles from './SelectActivationPointForm.style'
import FormikField from '@shared/components/modals/Modal/FormikField'
import { ErrorPosition } from '@shared/components/modals/Modal/TextField/TextField'
import { Option } from '@shared/components/RadioGroup/RadioGroup'
import { getMeasurementPointsFilteredByAgreements } from '@shared/utils/measurementPoints'
import SingleMeasurementPoint from '../SingleMeasurementPoint'
import { validateActivationPointForm } from './SelectActivationPointForm.utils'
import { Scrollbars } from 'react-custom-scrollbars'
import { isEmpty } from 'ramda'
import EInvoiceSettings from '../EInvoiceSettings'
import MissingEInvoices from '../MissingEInvoices'
import useEInvoiceStyles from '@shared/components/forms/eInvoice/style'
import ActivatedEInvoices from '../ActivatedEInvoices'
import { capitalize } from '@shared/utils/stringParser'
import { getUniqueEmails } from '@shared/utils/agreements'

interface Classes {
  selectedMeasurementPoint?: string
  selectedMeasurementPointWide?: string
  headingClass?: string
}

interface EInvoiceOptions {
  all: Option
  selected: Option
  many: Option
}

interface SelectActivationPointFormProps {
  account?: Account
  measurementPoints: {
    data: MeasurementPointListItem[]
    currentMeasurementPoint: MeasurementPointListItem | undefined
  }
  onCancel: () => void
  onSubmit: () => void
  correspondenceEmail: string
  setCorrespondenceEmail: (email: string) => void
  selectedMeasurementPoints: MeasurementPointListItem[]
  setSelectedMeasurementPoints: (points: MeasurementPointListItem[]) => void
  selectedActivationPointOption: ActivationPoint | undefined
  setSelectedActivationPointOption: (option: ActivationPoint) => void
  noEInvoiceAgreements?: (AgreementListItem | undefined)[]
  eInvoiceAgreements?: (AgreementListItem | undefined)[]
  stringify: (point: MeasurementPointListItem) => string
  settings: EInvoiceSettings
  update?: boolean
}

export interface FormValues {
  activationPoint: string
  email: string
  selectedMeasurementPoints: MeasurementPointListItem[]
}

export const SelectActivationPointForm: React.FC<SelectActivationPointFormProps> = ({
  account,
  measurementPoints,
  onCancel,
  onSubmit,
  correspondenceEmail,
  setCorrespondenceEmail,
  selectedMeasurementPoints,
  setSelectedMeasurementPoints,
  selectedActivationPointOption,
  setSelectedActivationPointOption,
  noEInvoiceAgreements,
  eInvoiceAgreements,
  stringify,
  settings,
  update = false,
}) => {
  const formStyles = useModalFormStyles()
  const { t } = useTranslation()
  const styles = useStyles()
  const eInvoiceStyles = useEInvoiceStyles()

  const [
    availableMeasurementPoints,
    setAvailableMeasurementPoints,
  ] = React.useState<MeasurementPointListItem[]>([])

  React.useEffect(() => {
    if (update) {
      setAvailableMeasurementPoints(
        getMeasurementPointsFilteredByAgreements(
          measurementPoints.data,
          eInvoiceAgreements ?? []
        )
      )
      return
    }
    setAvailableMeasurementPoints(
      getMeasurementPointsFilteredByAgreements(
        measurementPoints.data,
        noEInvoiceAgreements ?? []
      )
    )
  }, [measurementPoints.data, noEInvoiceAgreements, update, eInvoiceAgreements])

  const filterOptions = createFilterOptions({
    stringify: stringify,
  })

  // Use callback instead of memo to avoid manual deep cloning of the returned object.
  const getPreOptions = React.useCallback((): EInvoiceOptions => {
    return {
      all: {
        value: ActivationPoint.All.toString(),
        label: t('MY_INVOICES.E_INVOICE.ACTIVATION_POINTS.ALL'),
      },
      selected: {
        value: ActivationPoint.One.toString(),
        label: <></>,
      },
      many: {
        value: ActivationPoint.Many.toString(),
        label: t('MY_INVOICES.E_INVOICE.ACTIVATION_POINTS.MANY'),
      },
    }
  }, [t])

  const updateOptions = React.useMemo((): Option[] | undefined => {
    if (!update) {
      return undefined
    }
    const newOptions = getPreOptions()
    const currentPoint = measurementPoints.currentMeasurementPoint
    newOptions.selected.label = (
      <SingleMeasurementPoint
        heading={t('MY_INVOICES.E_INVOICE.ACTIVATION_POINTS.SELECTED')}
        measurementPoint={currentPoint ? `(${stringify(currentPoint)})` : ''}
        classes={{
          heading: styles.singleMeasurementPointHeading,
          text: styles.singleMeasurementPointText,
        }}
      />
    )
    newOptions.all.label = capitalize(
      `${newOptions.all.label} ${t(
        'MY_INVOICES.E_INVOICE.WITH_ACTIVATED_E_INVOICE'
      )}`.toLowerCase()
    )
    newOptions.many.label = capitalize(
      `${newOptions.many.label} ${t(
        'MY_INVOICES.E_INVOICE.WITH_ACTIVATED_E_INVOICE'
      )}:`.toLowerCase()
    )

    if (
      settings.hasManyAgreements &&
      eInvoiceAgreements &&
      getUniqueEmails(eInvoiceAgreements).some(
        (emailAddress: string) => emailAddress !== account?.correspondenceEmail
      )
    ) {
      return [newOptions.all]
    } else if (settings.hasManyAgreements) {
      switch (settings.activatedEInvoices) {
        case ActivatedEInvoices.All:
          return [newOptions.all, newOptions.selected, newOptions.many]
        case ActivatedEInvoices.Some:
          return [newOptions.all, newOptions.selected, newOptions.many]
      }
    }
    if (settings.activatedEInvoices === ActivatedEInvoices.All) {
      newOptions.selected.label = (
        <SelectedMeasurementPoint
          number={currentPoint?.number}
          address={stringify({
            addressLine1: currentPoint?.addressLine1,
            addressLine2: currentPoint?.addressLine2,
          } as MeasurementPointListItem)}
          isDelete={false}
          className={eInvoiceStyles.selectedMeasurementPointWide}
        />
      )
      return [newOptions.selected]
    }

    return undefined
  }, [
    measurementPoints.currentMeasurementPoint,
    getPreOptions,
    settings,
    stringify,
    t,
    update,
    styles.singleMeasurementPointHeading,
    styles.singleMeasurementPointText,
    eInvoiceStyles.selectedMeasurementPointWide,
    account,
    eInvoiceAgreements,
  ])

  const activateOptions = React.useMemo((): Option[] | undefined => {
    if (update) {
      return undefined
    }
    const newOptions = getPreOptions()
    const currentPoint = measurementPoints.currentMeasurementPoint
    const labelSelected = (
      <SingleMeasurementPoint
        heading={t('MY_INVOICES.E_INVOICE.ACTIVATION_POINTS.SELECTED')}
        measurementPoint={currentPoint ? `(${stringify(currentPoint)})` : ''}
        classes={{
          heading: styles.singleMeasurementPointHeading,
          text: styles.singleMeasurementPointText,
        }}
      />
    )
    const noEInvoicePoint = getMeasurementPointsFilteredByAgreements(
      measurementPoints.data,
      noEInvoiceAgreements ?? []
    )[0]
    const labelSingle = (
      <SelectedMeasurementPoint
        number={noEInvoicePoint.number}
        address={stringify({
          addressLine1: noEInvoicePoint?.addressLine1,
          addressLine2: noEInvoicePoint?.addressLine2,
        } as MeasurementPointListItem)}
        isDelete={false}
        className={eInvoiceStyles.selectedMeasurementPointWide}
      />
    )
    newOptions.many.label += ':'

    if (settings.hasSelectedAgreementEInvoice) {
      switch (settings.missingEInvoices) {
        case MissingEInvoices.Many:
          return [newOptions.all, newOptions.many]
        case MissingEInvoices.Single:
          newOptions.selected.label = labelSingle
          return [newOptions.selected]
        case MissingEInvoices.None:
          return []
      }
    }
    switch (settings.missingEInvoices) {
      case MissingEInvoices.Many:
        newOptions.selected.label = labelSelected
        return [newOptions.all, newOptions.selected, newOptions.many]
      case MissingEInvoices.Single:
        newOptions.selected.label = labelSingle
        return [newOptions.selected]
      case MissingEInvoices.None:
        return []
    }

    return undefined
  }, [
    getPreOptions,
    settings,
    measurementPoints.data,
    measurementPoints.currentMeasurementPoint,
    t,
    stringify,
    noEInvoiceAgreements,
    styles.singleMeasurementPointHeading,
    styles.singleMeasurementPointText,
    eInvoiceStyles.selectedMeasurementPointWide,
    update,
  ])

  const options = update ? updateOptions : activateOptions

  const initialActivationPointOption = React.useMemo(() => {
    if (!options) {
      return undefined
    }
    if (
      options.length === 1 &&
      update &&
      eInvoiceAgreements &&
      getUniqueEmails(eInvoiceAgreements).some(
        (email: string) => email !== account?.correspondenceEmail
      )
    ) {
      return ActivationPoint.All
    } else if (options.length === 1) {
      return 'selected' in options ? ActivationPoint.One : ActivationPoint.All
    }
    if (options.length > 1) {
      return ActivationPoint.All
    }
    return undefined
  }, [options, account, eInvoiceAgreements, update])

  const parsedSelectedActivationPointOption = React.useMemo(() => {
    if (selectedActivationPointOption === undefined) {
      if (initialActivationPointOption === undefined) {
        return ''
      }
      return initialActivationPointOption.toString()
    }
    return selectedActivationPointOption.toString()
  }, [initialActivationPointOption, selectedActivationPointOption])

  const initialValuesRefs = {
    activationPoint: React.useRef(parsedSelectedActivationPointOption),
    email: React.useRef(correspondenceEmail),
    selectedMeasurementPoints: React.useRef(selectedMeasurementPoints),
  }

  const initialValues: FormValues = React.useMemo(() => {
    return {
      activationPoint: initialValuesRefs.activationPoint.current,
      email: initialValuesRefs.email.current,
      selectedMeasurementPoints:
        initialValuesRefs.selectedMeasurementPoints.current,
    }
  }, [initialValuesRefs])

  const removeMeasurementPoint = (
    measurementPoint: MeasurementPointListItem
  ) => {
    const selected = selectedMeasurementPoints.filter(
      (point: MeasurementPointListItem) =>
        point.measurementPointId !== measurementPoint.measurementPointId
    )
    setSelectedMeasurementPoints(selected)
    return selected
  }

  const isManyActivationPointsSelected = (value?: string) => {
    return value && value === ActivationPoint.Many.toString()
  }

  const onFormSubmit = (values: FormValues) => {
    const currentPoint = measurementPoints.currentMeasurementPoint
    switch (Number(values.activationPoint)) {
      case ActivationPoint.All:
        setSelectedMeasurementPoints(availableMeasurementPoints)
        break
      case ActivationPoint.One:
        if (update) {
          currentPoint && setSelectedMeasurementPoints([currentPoint])
          break
        } else if (settings.hasSelectedAgreementEInvoice) {
          setSelectedMeasurementPoints(availableMeasurementPoints)
          break
        } else {
          currentPoint && setSelectedMeasurementPoints([currentPoint])
        }
        break
      case ActivationPoint.Many:
        setSelectedMeasurementPoints(values.selectedMeasurementPoints)
        break
    }
    setSelectedActivationPointOption(Number(values.activationPoint))
    setCorrespondenceEmail(values.email)
    onSubmit()
  }

  const singleMeasurementPointHeading = React.useMemo(() => {
    const activateFor = update
      ? t('MY_INVOICES.E_INVOICE.UPDATE_FOR')
      : t('MY_INVOICES.E_INVOICE.ACTIVATE_FOR')
    const prefix = 'MY_INVOICES.E_INVOICE.ACTIVATION_POINTS.'
    if (settings.hasSelectedAgreementEInvoice) {
      return `${activateFor} ${t(prefix + 'SINGLE')}:`
    }
    return `${activateFor} ${t(prefix + 'ALL')}:`
  }, [t, settings.hasSelectedAgreementEInvoice, update])

  let message: String
  if (
    update &&
    eInvoiceAgreements &&
    getUniqueEmails(eInvoiceAgreements).some(
      (email: string) => email !== account?.correspondenceEmail
    )
  ) {
    message = t('MY_INVOICES.E_INVOICE.LIMIT_EXCEEDED_MESSAGE_1')
  } else if (
    !update &&
    eInvoiceAgreements &&
    (getUniqueEmails(eInvoiceAgreements).length === 2 ||
      (getUniqueEmails(eInvoiceAgreements).length === 1 &&
        !getUniqueEmails(eInvoiceAgreements).includes(
          account?.correspondenceEmail ?? ''
        )))
  ) {
    message = t('MY_INVOICES.E_INVOICE.LIMIT_EXCEEDED_MESSAGE_2')
  }

  return (
    <Scrollbars style={{ height: '100%' }}>
      <Grid container className={formStyles.wrapper} direction="column">
        <Typography className={formStyles.title}>
          {update
            ? t('MY_INVOICES.E_INVOICE.UPDATE')
            : t('MY_INVOICES.E_INVOICE.ACTIVATE')}
        </Typography>
        <Payer
          account={account}
          headingClass={eInvoiceStyles.modalHeading}
          stringifyAddress={stringify}
        />
        <Grid className={styles.wrapper}>
          <Formik
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validate={validateActivationPointForm}
            validateOnChange
          >
            {({ values, setFieldValue, touched, errors }) => (
              <Form>
                {options && options.length > 1 ? (
                  <>
                    <RadioGroup
                      name={'activationPoint'}
                      value={values.activationPoint}
                      label={
                        (update
                          ? t('MY_INVOICES.E_INVOICE.UPDATE_FOR')
                          : t('MY_INVOICES.E_INVOICE.ACTIVATE_FOR', {
                              activationPoint: '',
                            }).trim()) + ':'
                      }
                      onChange={event => {
                        setFieldValue(
                          'activationPoint',
                          event.currentTarget.value
                        )
                        setSelectedActivationPointOption(
                          Number(event.currentTarget.value)
                        )
                      }}
                      options={options}
                      headingClass={eInvoiceStyles.modalHeading}
                    />
                    <Autocomplete
                      multiple
                      name="selectedMeasurementPoints"
                      options={availableMeasurementPoints}
                      disableCloseOnSelect
                      filterOptions={filterOptions}
                      onChange={(event, newValue, reason) => {
                        setSelectedMeasurementPoints(
                          newValue as MeasurementPointListItem[]
                        )
                        setFieldValue('selectedMeasurementPoints', newValue)
                      }}
                      value={values.selectedMeasurementPoints}
                      stringify={stringify}
                      selectedOptions={selectedMeasurementPoints}
                      disabled={
                        !isManyActivationPointsSelected(values.activationPoint)
                      }
                      carousel={
                        !!isManyActivationPointsSelected(values.activationPoint)
                      }
                      error={
                        touched.selectedMeasurementPoints &&
                        errors.selectedMeasurementPoints
                          ? (errors.selectedMeasurementPoints as string)
                          : undefined
                      }
                      classes={{
                        root: eInvoiceStyles.autocompleteWrapper,
                        carouselOuterWrapper:
                          eInvoiceStyles.carouselOuterWrapper,
                        carouselInnerWrapper:
                          eInvoiceStyles.carouselInnerWrapper,
                        popper: eInvoiceStyles.autocompletePopper,
                      }}
                      errorPosition={ErrorPosition.Disable}
                    >
                      {selectedMeasurementPoints.length > 0 ? (
                        selectedMeasurementPoints.map(
                          (option: MeasurementPointListItem) => (
                            <SelectedMeasurementPoint
                              number={option?.number}
                              onDelete={() => {
                                setFieldValue(
                                  'selectedMeasurementPoints',
                                  removeMeasurementPoint(option)
                                )
                              }}
                              disabled={
                                !isManyActivationPointsSelected(
                                  values.activationPoint
                                )
                              }
                              address={stringify(option)}
                              className={
                                eInvoiceStyles.selectedMeasurementPoint
                              }
                            />
                          )
                        )
                      ) : (
                        <SelectedMeasurementPoint
                          empty
                          className={eInvoiceStyles.selectedMeasurementPoint}
                        />
                      )}
                    </Autocomplete>
                  </>
                ) : (
                  <SingleMeasurementPoint
                    heading={singleMeasurementPointHeading}
                    measurementPoint={
                      options && !isEmpty(options) ? options[0].label : ''
                    }
                    classes={{
                      heading: `${eInvoiceStyles.modalHeading} ${styles.measurementPointsHeading}`,
                    }}
                  />
                )}

                <Label
                  control={
                    <FormikField
                      name="email"
                      placeholder={t('MY_SETTINGS.MY_ACCOUNT_DATA.EMAIL')}
                      errorPosition={ErrorPosition.Bottom}
                      onChange={event => {
                        setFieldValue('email', event.currentTarget.value)
                      }}
                    />
                  }
                  label={
                    (update
                      ? t('MY_INVOICES.E_INVOICE.UPDATE_EMAIL_LABEL')
                      : t('MY_INVOICES.E_INVOICE.SELECTED_EMAIL_LABEL')) + ':'
                  }
                  labelPlacement="top"
                  classes={{
                    root: styles.emailWrapper,
                    label: eInvoiceStyles.modalHeading,
                  }}
                />
                {!!message && <div className={styles.messages}>{message}</div>}
                <Actions
                  onCancel={onCancel}
                  cancelText={t('CANCEL')}
                  submitText={t('NEXT')}
                />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Scrollbars>
  )
}
