import { pushToEventsQueue, sendAllEventsFromQueue } from '../utils/eventsQueue'

type useEventsQueueReturnType = [
  typeof pushToEventsQueue,
  typeof sendAllEventsFromQueue
]

export const useEventsQueue = (): useEventsQueueReturnType => {
  return [pushToEventsQueue, sendAllEventsFromQueue]
}
