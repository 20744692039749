import React, { useCallback } from 'react'
import { useAppSelector, useAppDispatch } from '@core/store/store'
import { useCookiesSeen } from './hooks/useCookiesSeen'
import { useAnalytics } from '@core/analytics/useAnalytics'
import { ReactComponent as CookieSvg } from '@images/icons/other/Cookies.svg'
import useStyles from './CookiesFloatingButton.style'
import { CookiesSettings } from '../cookiesConsent/components/CookiesSettings'
import { switchCookiesDialog } from '@core/store/ui/actions'

export const CookiesFloatingButton: React.FC = () => {
  const styles = useStyles()
  const dispatch = useAppDispatch()

  const { isOpen } = useAppSelector(state => state.ui.cookiesSettingsDialog)

  const [, googleAnalytics, toggleGA] = useAnalytics()
  // eslint-disable-next-line
  const [cookieAccepted, setCookieAccepted] = useCookiesSeen()

  const handleCookieAccept = useCallback(
    (analytics: boolean) => {
      toggleGA(analytics)
      setCookieAccepted(true)
      dispatch(switchCookiesDialog(false))
    },
    [setCookieAccepted, toggleGA, dispatch]
  )

  return (
    <div>
      <button
        className={styles.button}
        onClick={() => dispatch(switchCookiesDialog(!isOpen))}
      >
        <div className={styles.icon}>
          <CookieSvg />
        </div>
        <span className={styles.text}>Ustawienia plików cookies</span>
      </button>

      <CookiesSettings
        close={() => dispatch(switchCookiesDialog(false))}
        analytics={googleAnalytics}
        handleCookieAccept={handleCookieAccept}
      />
    </div>
  )
}
