import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { MeasurementPointListItem } from '@shared/contracts/models'
import { values, props } from 'ramda'

export const measurementPointsSelector = (state: RootState) =>
  state.measurementPoints

export const measurementPointsLoadingSelector = createSelector(
  measurementPointsSelector,
  state => state.loadingPending > 0
)

export const measurementPointsDataSelector = createSelector(
  measurementPointsSelector,
  state =>
    values(state.entities).filter(
      el => el !== undefined
    ) as MeasurementPointListItem[]
)

export const measurementPointsDictionarySelector = createSelector(
  measurementPointsSelector,
  state => state.entities
)

export const getMeasurementPointsByIdSelector = (ids: string[]) =>
  createSelector(
    measurementPointsSelector,
    state => props(ids, state.entities) as MeasurementPointListItem[]
  )
