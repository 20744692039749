import { 
    FormControlLabel,
    FormControlLabelProps
} from '@material-ui/core'
import React from 'react'

const Label: React.FC<FormControlLabelProps> = ({
    label,
    control,
    ...props
}) => {
  return (
    <FormControlLabel
      control={control}
      label={label}
      {...props}
    />
  )
}

export default Label
