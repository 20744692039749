import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Formik, Form } from 'formik'
import FormikField from '@shared/components/modals/Modal/FormikField'
import { useTranslation } from 'react-i18next'
import { AddressErrors, isPostalCodeValid, postalCodeLength } from './EditAddressForm.utils'
import Button from '@shared/components/modals/Modal/Button'
import useStyles from './EditAddressForm.style'
import {
  Address,
  implodeAddress,
  removeDelimiterFromAddressLine1,
} from '@shared/utils/addressParser'
import { capitalize } from '@shared/utils/stringParser'
import { ErrorPosition } from '@shared/components/modals/Modal/TextField/TextField'
import Autocomplete from '@shared/components/Autocomplete'
import { useEditAddressFormDataSource } from './dataSource'
import { equals } from 'ramda'

export interface IEditAddressFormProps {
  title: string
  field1: string
  field2: string
  onSubmit: (values: { field1: string; field2?: string }) => void
  onCancel: () => void
  submitBtnText?: string
  cancelBtnText?: string
  compInfo?: string
  validate: (values: Address) => AddressErrors
  setIsAddressInDatabase: (isAddressInDatabase: boolean) => void
}

export const EditAddressForm: React.FC<IEditAddressFormProps> = ({
  title,
  onSubmit,
  onCancel,
  submitBtnText,
  cancelBtnText,
  compInfo,
  validate,
  field1,
  field2,
  setIsAddressInDatabase
}) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const initialValues =
  {
    streetName: '',
    streetNumber: '',
    houseNumber: '',
    postalCode: '',
    cityName: '',
  }

  const {
    address: { getAddresses, getStreets },
    loading,
    cities,
    streets,
    results,
  } = useEditAddressFormDataSource(setIsAddressInDatabase)

  React.useEffect(() => {
    getAddresses(initialValues.postalCode ?? '', initialValues.cityName ?? '')
  }, [initialValues.postalCode, initialValues.cityName, getAddresses])

  const isFieldChanged = (address: Address) => {
    const newAddress = implodeAddress({
      cityName: address.cityName?.trim(),
      houseNumber: address.houseNumber?.trim(),
      postalCode: address.postalCode?.trim(),
      streetName: address.streetName?.trim(),
      streetNumber: address.streetNumber?.trim(),
    })
    return !equals({ field1, field2 }, { field1: removeDelimiterFromAddressLine1(newAddress.field1), field2 })
  }

  const onFormSubmit = (address: Address) => {
    if(cities?.data?.includes(address.cityName ?? '') 
      && streets?.data?.includes(address.streetName ?? ''))
    {
      setIsAddressInDatabase(true)
    }
    else{
      setIsAddressInDatabase(false)
    }
    
    onSubmit(implodeAddress({
      cityName: address.cityName?.trim(),
      houseNumber: address.houseNumber?.trim(),
      postalCode: address.postalCode?.trim(),
      streetName: address.streetName?.trim(),
      streetNumber: address.streetNumber?.trim()

    }))
  }
  
  return (
    <Grid className={styles.wrapper} container direction="column">
      {title && (
        <Typography className={styles.title}>
          {capitalize(title.toLowerCase())}
        </Typography>
      )}
      <Grid>
        <Formik
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          validate={validate}
          validateOnChange
        >
          {({ values, setFieldValue }) => {
            return (
              <Form className={styles.form}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormikField
                      name="postalCode"
                      label={t('MY_SETTINGS.ADDRESS.POSTAL_CODE')}
                      errorPosition={ErrorPosition.Bottom}
                      inputProps={{ maxLength: postalCodeLength }}
                      onChange={event => {
                        setFieldValue('postalCode', event.currentTarget.value)
                        if (isPostalCodeValid(event.currentTarget.value)) {
                          getAddresses(
                            event.currentTarget.value,
                            values.postalCode ?? ''
                          )
                        }
                        ['cityName', 'streetName', 'streetNumber', 'houseNumber'].every((field: string) => setFieldValue(field, '', true))
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={cities.data ?? []}
                      stringify={item => {
                        return item ?? ''
                      }}
                      name="cityName"
                      carousel={false}
                      initialValue={values.cityName}
                      value={values.cityName}
                      getOptionSelected={(option, value) => {
                        return option === value
                      }}
                      freeSolo={true}
                      onInputChange={(event, value, reason) => {
                        setFieldValue('cityName', value)
                        streets.set(getStreets(value, results))
                        setFieldValue('streetName', '', true)
                        setFieldValue('streetNumber', '', true)
                        setFieldValue('houseNumber', '')
                        setIsAddressInDatabase(cities?.data?.includes(value) ?? false)
                      }}
                      label={t('MY_SETTINGS.ADDRESS.CITY')}
                      errorPosition={ErrorPosition.Bottom}
                      classes={{
                        popper: `${styles.popper} ${styles.popperNarrow}`,
                      }}
                      disabled={loading.data}
                      loading={loading.data}

                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={streets.data ?? []}
                      stringify={item => {
                        return item ?? ''
                      }}
                      name="streetName"
                      onInputChange={(event, value, reason) => {
                        setFieldValue('streetName', value)
                        setFieldValue('streetNumber', '', true)
                        setFieldValue('houseNumber', '')
                        setIsAddressInDatabase(streets?.data?.includes(value) ?? false)
                      }}
                      getOptionSelected={(option, value) => {
                        return option === value
                      }}
                      carousel={false}
                      freeSolo={true}
                      initialValue={values.streetName}
                      value={values.streetName}
                      label={t('MY_SETTINGS.ADDRESS.STREET')}
                      errorPosition={ErrorPosition.Bottom}
                      classes={{
                        popper: styles.popper,
                      }}
                      disabled={loading.data}
                      loading={loading.data}

                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikField
                      name="streetNumber"
                      label={t('MY_SETTINGS.ADDRESS.STREET_NUMBER')}
                      errorPosition={ErrorPosition.Bottom}
                      onChange={event => {
                        setFieldValue('streetNumber', event.currentTarget.value)
                        setFieldValue('houseNumber', '')
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikField
                      name="houseNumber"
                      label={t('MY_SETTINGS.ADDRESS.HOUSE_NUMBER')}
                      errorPosition={ErrorPosition.Bottom}
                      onChange={event => {
                        setFieldValue('houseNumber', event.currentTarget.value)
                      }}
                    />
                  </Grid>
                </Grid>
                
                {compInfo !== undefined ? (
                  <div className={styles.infoWrapper}>
                    <span>{compInfo}</span>
                  </div>
                ) : null}

                <div className={styles.buttonsWrapper}>
                  <Button onClick={onCancel}>
                    {cancelBtnText ?? t('CLOSE')}
                  </Button>
                  <Button type="save" submit disabled={!isFieldChanged(values)}>
                    {submitBtnText ?? t('SEND')}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}
