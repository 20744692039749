import React from 'react'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { preRegister } from '../static-data/RegisterData'
import { useMediaQuery } from '@material-ui/core'
import { SubmitButton } from './SubmitButton'
import { bgColor } from '@mbok/styles/constants'
import { AnimationPreRegisterSrc } from '@shared/static-data/staticData'
import { Animation } from '@shared/components/Animation'

const ANIMATION_WIDTH = 300
const ANIMATION_HEIGHT = 240

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& h1': {
      margin: '0 0 32px',
      fontSize: 18,
      textAlign: 'left',
      lineHeight: '20px',
      letterSpacing: '0.13px',
      fontWeight: theme.typography.fontWeightBold,
    },
    '& button': {
      margin: '32px 0 0',
      [theme.breakpoints.down('xs')]: {
        margin: '16px 0',
      },
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 5,
    backgroundColor: '#e6f2f2',
    margin: theme.spacing(0, 0, 10),
  },
  animation: {
    height: ANIMATION_HEIGHT,
    width: ANIMATION_WIDTH,
    [theme.breakpoints.down('xs')]: {
      height: ANIMATION_HEIGHT * 0.8,
      width: ANIMATION_WIDTH * 0.8,
    },
  },
  icon: {
    marginRight: '12px',
    height: '24px',
    minWidth: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      height: '20px',
      minWidth: '20px',
      lineHeight: '20px',
    },
  },
  row: {
    display: 'flex',
    paddingBottom: '20px',
    marginBottom: '20px',
  },
  rowContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  rightRowContent: {
    marginLeft: '28px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '16px',
    },
  },
  rowTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& div': {
      '& h3': {
        margin: theme.spacing(1, 0),
        fontSize: 12,
        fontWeight: 800,
        letterSpacing: '0.08px',
        '& span': {
          opacity: 0.25,
          [theme.breakpoints.down('xs')]: {
            fontSize: 10,
          },
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: 11.5,
        },
      },
      '& p': {
        margin: theme.spacing(4, 0, 0),
        maxWidth: '236px',
        minWidth: '160px',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.1px',
        fontWeight: theme.typography.fontWeightBold,
        '& span': {
          fontWeight: 800,
        },
        [theme.breakpoints.down('xs')]: {
          maxWidth: '100%',
          margin: theme.spacing(1, 0, 0),
        },
      },
    },
  },
  rowDocSmall: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  document: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: bgColor,
    textAlign: 'center',
    margin: theme.spacing(0, 2),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 2, 0),
      '& svg': {
        height: 160,
      },
    },
  },
  rowBorder: {
    borderBottom: '1.8px solid #E2E8ED',
  },
}))

interface PreRegisterProps {
  buttonVisible?: boolean
  nextPage?: () => void
}

export const PreRegister: React.FC<PreRegisterProps> = ({
  nextPage,
  buttonVisible,
}) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const XSScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const { subtitle, rows } = preRegister

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Animation
          src={AnimationPreRegisterSrc}
          customClass={styles.animation}
        />
      </div>
      <h1>{subtitle}</h1>
      {rows.map(({ left, right }, i) => (
        <div
          className={`${styles.row} ${styles.rowBorder}  ${
            right?.doc && styles.rowDocSmall
          }`}
          key={i}
        >
          <div className={styles.rowContent}>
            <div className={styles.icon}>{left.icon}</div>
            <div className={styles.rowTitle}>
              <div>{left.title}</div>
            </div>
          </div>
          {right && (
            <div
              className={`${styles.rowContent} ${
                XSScreen && right.doc ? null : styles.rightRowContent
              }`}
            >
              {right.doc ? (
                <div className={styles.document}>{right.doc}</div>
              ) : (
                <>
                  <div className={styles.icon}>{right.icon}</div>
                  <div className={styles.rowTitle}>
                    <div>{right.title}</div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      ))}
      {!buttonVisible && (
        <SubmitButton
          variant="contained"
          color="primary"
          onClick={() => nextPage && nextPage()}
        >
          Dalej
        </SubmitButton>
      )}
    </div>
  )
}
