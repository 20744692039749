import {
  darken,
  LinearProgress as MuiLinear,
  withStyles,
} from '@material-ui/core'
import { bgColor } from '@mbok/styles/constants'

export const LinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5,
    backgroundColor: darken(bgColor, 0.04),
  },
}))(MuiLinear)
