import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { EntityReducers } from '../EntityReducers'
import {
  getBaseState,
  getEntityAdapterReducers,
} from '../helpers/entityReducerHelpers'
import { getAllExtraReducers } from '../helpers/thunks/getAllThunk'
import { userConsentsGetAll, updateConsent } from './userConsentsThunks'
import { handleLoadingAction } from '../helpers/thunks/entityThunksFactory'
import { addUserConsentsSideEffects } from './userConsentsSideEffects'
import { Consent } from '@shared/contracts/models'

const entityAdapter = createEntityAdapter<Consent>()

export const userConsentsSlice = createSlice({
  name: EntityReducers.userConsents,
  initialState: {
    ...getBaseState(entityAdapter),
  },
  reducers: {
    ...getEntityAdapterReducers<Consent>(entityAdapter),
  },
  extraReducers: builder => {
    getAllExtraReducers<Consent>(userConsentsGetAll, builder, entityAdapter)
    handleLoadingAction(updateConsent, builder)
    builder.addCase(updateConsent.fulfilled, (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
      const entity = state.entities[payload.id]
      state.entities = {
        ...state.entities,
        [payload.id]: {
          ...(entity as Consent),
          status: payload.isAccepted ? 'Accepted' : 'Declined',
        },
      }
    })
  },
})

addUserConsentsSideEffects()

export const userConsentsActions = userConsentsSlice.actions
