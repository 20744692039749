import { makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

const BTN_WIDTH = 160
const BTN_MIN_HEIGHT = 34

const ICON_SIZE = 24

export default makeStyles((theme: Theme) => ({
  card: {
    width: '100%',
    height: '100%',
    marginRight: theme.spacing(5),
    position: 'relative',
    minWidth: 270,
    '&:last-child': {
      marginRight: 0,
    },
    padding: theme.spacing(5, 7.5),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 19,
    padding: theme.spacing(1, 0),
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    whiteSpace: 'nowrap',
    '& b': {
      marginRight: theme.spacing(2),
      fontWeight: 700,
      color: tableTextColor,
    },
    '& div': {
      display: 'flex',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: ICON_SIZE,
    width: ICON_SIZE,
    minWidth: ICON_SIZE,
    minHeight: ICON_SIZE,
    marginRight: theme.spacing(4),
    fill: theme.palette.primary.main,
  },
  row: {
    paddingTop: theme.spacing(5),
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  content: {
    fontSize: 18,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  loader: {
    marginTop: theme.spacing(3),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  btn: {
    width: BTN_WIDTH,
    minHeight: BTN_MIN_HEIGHT,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    whiteSpace: 'normal',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
  },
  secondaryBtn: {
    marginLeft: theme.spacing(3),
  }
}))
