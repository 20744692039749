/**
 * mBok API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AgreementType =
  | 'Distribution'
  | 'Sales'
  | 'SelfComprehensive'
  | 'ExternalComprehensive'
  | 'DistributionSales'
  | 'SelfComprehensivePackage'
  | 'ExternalComprehensivePackage'
  | 'SalesPackage'

export const AgreementType = {
  Distribution: 'Distribution' as AgreementType,
  Sales: 'Sales' as AgreementType,
  SelfComprehensive: 'SelfComprehensive' as AgreementType,
  ExternalComprehensive: 'ExternalComprehensive' as AgreementType,
  DistributionSales: 'DistributionSales' as AgreementType,
  SelfComprehensivePackage: 'SelfComprehensivePackage' as AgreementType,
  ExternalComprehensivePackage: 'ExternalComprehensivePackage' as AgreementType,
  SalesPackage: 'SalesPackage' as AgreementType,
}

export const TranslatedAgreementType = {
  [AgreementType.Distribution]: 'Umowa dystrybucyjna',
  [AgreementType.Sales]: 'Umowa Sprzedaży',
  [AgreementType.SelfComprehensive]: 'Umowa kompleksowa',
  [AgreementType.ExternalComprehensive]: 'Umowa kompleksowa',
  [AgreementType.DistributionSales]: 'Umowa Kompleksowa Sprzedawcy Zewnętrznego',
  [AgreementType.SelfComprehensivePackage]: 'Umowa kompleksowa produktowa',
  [AgreementType.ExternalComprehensivePackage]: 'Umowa kompleksowa produktowa',
  [AgreementType.SalesPackage]: 'Umowa sprzedażowa produktowa',
}
