import i18n from '@i18n/index'
import ActivationPoint from '../ActivationPoint'
import { FormValues } from './SelectActivationPointForm'
import { emailRegex } from '@mbok/features/MySettings/components/forms/EditFieldForm/EditFieldForm.utils'

interface ActivationPointFormErrors {
  activationPoint?: string
  email?: string
  selectedMeasurementPoints?: string
}

export const validateActivationPointForm = (values: FormValues) => {
  const errors: ActivationPointFormErrors = {}

  if (
    Number(values.activationPoint) === ActivationPoint.Many &&
    values.selectedMeasurementPoints.length === 0
  ) {
    errors.selectedMeasurementPoints = i18n().t(
      'FORM_ERRORS.NO_MEASUREMENT_POINTS_SELECTED'
    )
  }
  if (!values.email) {
    errors.email = i18n().t('FORM_ERRORS.FIELD_REQUIRED')
  } else if (!emailRegex.test(values.email)) {
    errors.email = i18n().t('FORM_ERRORS.EMAIL')
  }

  return errors
}
