import { makeStyles, Theme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import { tableTextColor } from '@mbok/styles/constants'

const BUTTON_HEIGHT = 38

const getButtonStyles = (theme: Theme): CreateCSSProperties<{}> => ({
  fontSize: 14,
  height: BUTTON_HEIGHT,
  boxSizing: 'border-box',
  padding: theme.spacing(1.25, 9.25),
  boxShadow: 'none',
})

export default makeStyles(theme => ({
  closeButton: {
    '&:first-child:not(:last-child)': {
      marginRight: theme.spacing(7),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    color: tableTextColor,
    border: '2.7px solid #E1E2E2',
    ...getButtonStyles(theme),
    '&:hover': {
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  saveButton: {
    ...getButtonStyles(theme),
  },
}))
