import ReactGA from 'react-ga4'
import { pushToEventsQueue } from './eventsQueue'

export const trackCustomEvent = (
  eventArgs: any,
  cookieValue: boolean
) => {
  if (!cookieValue) {
    pushToEventsQueue(eventArgs)
    return
  }

  ReactGA.event(eventArgs)
}
