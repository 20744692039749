import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  MeasurementPointListItem,
  MeasurementPointListItemPagedResponse,
} from '@shared/contracts/models'
import { EntityReducers } from '../EntityReducers'
import {
  getBaseState,
  getEntityAdapterReducers,
} from '../helpers/entityReducerHelpers'
import { getAllExtraReducers } from '../helpers/thunks/getAllThunk'
import { addMeasurementPointsSideEffects } from './measurementPointsSideEffects'
import { measurementPointsGetAll } from './measurementPointsThunks'

const entityAdapter = createEntityAdapter<MeasurementPointListItem>({
  selectId: agreement => agreement.measurementPointId ?? '',
})

export const measurementPointsSlice = createSlice({
  name: EntityReducers.measurementPoints,
  initialState: {
    ...getBaseState(entityAdapter),
  },
  reducers: {
    ...getEntityAdapterReducers<MeasurementPointListItem>(entityAdapter),
  },
  extraReducers: builder => {
    getAllExtraReducers<
      MeasurementPointListItem,
      undefined,
      MeasurementPointListItemPagedResponse
    >(
      measurementPointsGetAll,
      builder,
      entityAdapter,
      response => response.results ?? []
    )
  },
})

addMeasurementPointsSideEffects()

export const measurementPointsActions = measurementPointsSlice.actions
