import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import {
  AccountingDocumentListItem,
  AccountingDocumentStatus,
} from '@shared/contracts/models'
import { props, values } from 'ramda'
import { isFuture, parseISO } from 'date-fns'

export const documentsSelector = (state: RootState) => state.documents

export const getDocumentsLoadingSelector = createSelector(
  documentsSelector,
  state => state.loadingPending > 0
)

export const getAllDocumentsSelector = createSelector(
  documentsSelector,
  state => values(state.entities) as AccountingDocumentListItem[]
)

export const getAllUnpaidDocumentsSelector = createSelector(
  getAllDocumentsSelector,
  documents =>
    documents.filter(
      document =>
        document.status === AccountingDocumentStatus.Unpaid &&
        document.dueDate &&
        isFuture(parseISO(document.dueDate))
    )
)

export const getDocumentsByIdsSelector = (ids: string[]) =>
  createSelector(
    documentsSelector,
    state =>
      props(ids, state.entities).filter(
        document => document
      ) as AccountingDocumentListItem[]
  )
