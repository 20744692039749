import { Theme, createStyles, makeStyles, darken } from '@material-ui/core'
import { borderColor, tableTextColor } from '@mbok/styles/constants'

const BUTTON_SIZE = 64
const BUTTON_POSITION = 24
const BUTTON_ICON_SIZE = 56

const CONTAINER_WIDTH = 340
const ICON_SIZE = 20
const BTN_HEIGHT = 38

const COPY_ICON_SIZE = 15

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: BUTTON_SIZE,
      height: BUTTON_SIZE,
      backgroundColor: theme.palette.common.white,
      borderRadius: 15,
      boxShadow: '0 3px 7px 1px rgba(0,0,0,0.15)',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      zIndex: 1900,
      position: 'fixed',
      right: BUTTON_POSITION,
      bottom: BUTTON_POSITION,
      '&:hover': {
        '& svg': {
          color: theme.palette.primary.dark,
        },
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '0 3px 7px 1px rgba(0,0,0,0.3)',
      },
      [theme.breakpoints.down('sm')]: {
        width: BUTTON_SIZE - 20,
        height: BUTTON_SIZE - 20,
        right: BUTTON_POSITION - 12,
        bottom: BUTTON_POSITION - 12,
      },
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: BUTTON_ICON_SIZE,
      height: BUTTON_ICON_SIZE,
      borderRadius: 8,
      '& svg': {
        width: BUTTON_ICON_SIZE - 8,
        height: BUTTON_ICON_SIZE - 8,
        color: theme.palette.primary.main,
        transition: 'color 0.2s ease-in-out',
      },
      [theme.breakpoints.down('sm')]: {
        width: BUTTON_ICON_SIZE - 16,
        height: BUTTON_ICON_SIZE - 16,
        '& svg': {
          width: BUTTON_ICON_SIZE - 20,
          height: BUTTON_ICON_SIZE - 20,
        },
      },
    },
    paper: {
      background: 'transparent',
      border: 'none',
      boxShadow: 'none',
      zIndex: 2000,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(5, 6, 7),
      marginBottom: theme.spacing(4),
      backgroundColor: theme.palette.common.white,
      borderRadius: 7,
      color: tableTextColor,
      border: `2.5px solid ${borderColor}`,
      width: CONTAINER_WIDTH,
      [theme.breakpoints.down('xs')]: {
        width: CONTAINER_WIDTH - 24,
      },
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 15,
      '& h1': {
        fontSize: 15,
        fontWeight: 700,
        letterSpacing: 0.13,
        textAlign: 'center',
        margin: theme.spacing(0, 4),
      },
    },
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      fill: theme.palette.primary.dark,
      cursor: 'pointer',
      transition: 'fill 0.2s ease-in-out',
      '&:hover': {
        fill: theme.palette.primary.main,
      },
    },
    divider: {
      margin: theme.spacing(4, 0),
    },
    addressContent: {
      '& p, span': {
        fontSize: 13,
        opacity: 0.65,
        letterSpacing: 0.57,
      },
      '& p': {
        margin: theme.spacing(0, 0, 1),
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
      '& svg': {
        height: ICON_SIZE,
        width: ICON_SIZE,
        marginRight: theme.spacing(2.5),
      },
    },
    officeName: {
      '& h2': {
        margin: 0,
        fontSize: 13,
        opacity: 0.55,
        letterSpacing: 0.57,
      },
      '& h3': {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        fontSize: 15,
        letterSpacing: 0.25,
      },
      '& svg': {
        marginLeft: theme.spacing(1),
        fill: theme.palette.primary.main,
        height: COPY_ICON_SIZE,
        width: COPY_ICON_SIZE,
        cursor: 'pointer',
        transition: 'fill 0.2s ease-in-out, transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1)',
          fill: theme.palette.primary.dark,
        },
      },
      '& a': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        transition: 'color 0.2s ease-in-out',
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
    },
    contactContent: {
      '& div': {
        marginBottom: theme.spacing(2),
      },
    },
    btn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(6),
      height: BTN_HEIGHT,
      width: '100%',
      border: `2.7px solid ${theme.palette.primary.main}`,
      borderRadius: 19,
      fontSize: 14,
      letterSpacing: 0.55,
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fontWeight: 700,
      transition: 'background-color 0.2s ease-in-out',
      '&:hover': {
        backgroundColor: darken(theme.palette.common.white, 0.05),
      },
    },
  })
)
