import { getAllThunk } from '../helpers/thunks/getAllThunk'
import { EntityReducers } from '../EntityReducers'
import { useThunkApi } from '@core/apiClient/apiClient'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationSetting } from '@shared/contracts/models'

export type UpdateUserNotificationPayload = {
  id: string
  isActive: boolean
}

export const userNotificationsGetAll = getAllThunk<NotificationSetting>(
  EntityReducers.userNotifications,
  ({ thunkApi }) =>
    useThunkApi(
      {
        config: {
          method: 'get',
          url: `accounts/settings/notifications`,
          params: {
            method: 'All',
          },
        },
        omitConsents: true,
      },
      thunkApi
    )
)

export const updateUserNotification = createAsyncThunk<
  UpdateUserNotificationPayload,
  UpdateUserNotificationPayload
>(
  `${[EntityReducers.userNotifications]}/updateNotification`,
  async (payload, thunkApi) => {
    const [execute] = useThunkApi<unknown>(
      {
        config: {
          method: 'put',
          url: `accounts/settings/notifications/${payload.id}`,
          data: {
            isActive: payload.isActive,
          },
        },
      },
      thunkApi
    )
    await execute()
    return {
      id: payload.id,
      isActive: payload.isActive,
    }
  }
)
