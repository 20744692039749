import { InitialFieldsType } from '../components/Register'
import { toPairs } from 'ramda'
import { AdditionalValidationType, fieldNames } from '../static-data/RegisterData'
const { PASSWORD, INVOICE_VALUE } = fieldNames

export const extractFormValues = (
  values: InitialFieldsType
): InitialFieldsType => {
  const AcceptedConsentIds: Array<string> = []
  let restValues: InitialFieldsType = {}

  toPairs(values).forEach(([fieldName, value]) => {
    if (typeof value === 'boolean' && value) {
      AcceptedConsentIds.push(fieldName)
    } else {
      restValues[fieldName] = value
    }
  })

  return {
    ...restValues,
    AcceptedConsentIds,
    ClientId: 'mBok_web',
    ConfirmedPassword: values[PASSWORD],
    AdditionalValidationType: values[INVOICE_VALUE] === "" ? AdditionalValidationType.SEND_TOKEN.toString() : AdditionalValidationType.SEND_INVOICE.toString()
  }
}
