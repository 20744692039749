import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiPaper-root': {
        maxWidth: '700px',
        padding: theme.spacing(7, 10),
        [theme.breakpoints.down('sm')]: {
          paddingBottom: theme.spacing(6),
        },
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(5, 8),
        },
      },
      color: tableTextColor,
    },
    mainContent: {
      fontSize: 18,
      '& a': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        transition: 'color 0.2s ease-in-out',
        '&:hover': {
          color: theme.palette.primary.dark,
        },
        textDecoration: 'none',
      },
    },
    dialogTitle: {
      margin: theme.spacing(0, 0, 3),
      padding: 0,
      fontSize: 28,
      fontWeight: theme.typography.fontWeightBold,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      '& h2, h3, h4': {
        margin: 0,
      },
    },
    cookieCard: {
      padding: theme.spacing(4),
      margin: theme.spacing(0, 0, 5),
      boxShadow:
        '0 7px 9px 3px rgba(105,105,105,0.02), 0 2px 5px 0 rgba(66,65,65,0.05)',
      borderRadius: 4,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(6, 2, 8),
      },
    },
    cookiesTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(0, 0, 3),
      alignItems: 'baseline',
      '& h3': {
        fontSize: 20,
        letterSpacing: 0.7,
        fontWeight: theme.typography.fontWeightBold,
      },
      '& span': {
        color: theme.palette.primary.main,
        paddingLeft: theme.spacing(1),
        minWidth: 112,
        textAlign: 'right',
        fontSize: 12,
        letterSpacing: 0.64,
        fontWeight: theme.typography.fontWeightBold,
      },
      [theme.breakpoints.down('sm')]: {
        '& h3': {
          fontSize: 16,
        },
      },
    },
    cookieContainer: {
      display: 'flex',
      flexDirection: 'column',
      '& p': {
        margin: 0,
        fontSize: 12,
        opacity: 0.8,
        color: tableTextColor,
        maxWidth: 240,
        [theme.breakpoints.down('xs')]: {
          maxWidth: '74%',
        },
      },
    },
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& h4': {
        margin: 0,
        color: '#4E4F4F',
        fontSize: 16,
        lineHeight: '20px',
        letterSpacing: 0.64,
        fontWeight: theme.typography.fontWeightBold,
      },
      [theme.breakpoints.down('sm')]: {
        '& h4': {
          fontSize: 12,
        },
      },
    },
    buttonsContainer: {
      display: 'flex',
      margin: theme.spacing(4, 0, 2),
      justifyContent: 'space-evenly',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-around',
        margin: 0,
      },
      '& button': {
        height: 48,
        width: 176,
        fontSize: 16,
        [theme.breakpoints.down('xs')]: {
          height: 40,
          fontSize: 14,
          width: '40%',
          margin: theme.spacing(0, 0, 5),
        },
      },
    },
    switchStateTitle: {
      textTransform: 'uppercase',
    },
  })
)
