import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { Reading } from '@shared/contracts/models'
import { getMonth, parseISO, isThisYear } from 'date-fns'
import { groupBy, mapObjIndexed, reduce, values } from 'ramda'

export const readingsSelector = (state: RootState) => state.readings

export const allReadingsSelector = createSelector(readingsSelector, state =>
  values(state.entities)
)

export const readingsLoadingSelector = createSelector(
  readingsSelector,
  readings => {
    return readings.loadingPending > 0
  }
)

const calculateUsagePerMonth = (readings: (Reading | undefined)[]) => {
  return reduce<
    Reading,
    {
      [month: number]: {
        past: number
        current: number
      }
    }
  >((acc, reading) => {
    const date = parseISO(reading.date)
    const month = getMonth(date)
    const currentYear = isThisYear(date)

    const current =
      (acc[month]?.current ?? 0) + (currentYear ? reading.amount : 0)
    const past = (acc[month]?.past ?? 0) + (!currentYear ? reading.amount : 0)
    acc[month] = {
      past,
      current,
    }

    return acc
  }, {})(readings as Reading[])
}

export const getUsageByAllMeasurementPoints = createSelector(
  allReadingsSelector,
  calculateUsagePerMonth
)

export const getUsageByMeasurementPoints = createSelector(
  allReadingsSelector,
  readings => {
    const groupedByMeasurementPoint = groupBy(
      reading => reading?.measurementPointId ?? '',
      readings
    )

    return mapObjIndexed((values: (Reading | undefined)[]) =>
      calculateUsagePerMonth(values)
    )(groupedByMeasurementPoint)
  }
)
