import { makeStyles, Theme } from '@material-ui/core'

const ICON_SIZE = 24

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '98%',
    maxWidth: '98%',
    margin: '0 auto 24px',
    padding: '20px',
    borderRadius: '7px',
    boxShadow:
      '0px 5px 9px 3px rgba(105, 105, 105, 0.03), 0px 1px 6px 3px rgba(74, 73, 73, 0.03), 0px 3px 3px rgba(66, 65, 65, 0.05)',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: ICON_SIZE,
      height: ICON_SIZE,
    },
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
    borderRadius: 4,
    backgroundColor: '#F2F3F41A',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.05)',
    [theme.breakpoints.down('xs')]: {
      '& svg': {
        width: ICON_SIZE - 4,
        height: ICON_SIZE - 4,
      },
      marginRight: theme.spacing(2),
    },
  },
  collectionPoint: {
    '& h5': {
      margin: 0,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '1.03px',
    },
    '& h2': {
      margin: 0,
      fontSize: 18,
      fontWeight: 800,
      letterSpacing: '1.32px',
    },
    [theme.breakpoints.down('md')]: {
      '& h2': {
        fontSize: 16,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& h5': {
        fontSize: 12,
      },
      '& h2': {
        fontSize: 12,
      },
    },
  },
  address: {
    margin: 0,
    fontSize: 18,
    fontWeight: theme.typography.fontWeightLight,
    letterSpacing: '0.89px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}))
