import { makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

export default makeStyles((theme: Theme) => ({
  wrapper: {
    width: 'auto',
    boxSizing: 'border-box',
    '&:not(:first-child)': {
      paddingLeft: theme.spacing(15),
    },
    '&:not(:last-child)': {
      paddingRight: theme.spacing(15),
      borderRight: '1.8px solid #5F76FD1A',
    },
    paddingTop: theme.spacing(10.5),
    paddingBottom: theme.spacing(12),
  },
  contentWrapper: {
    width: 'auto',
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    paddingTop: theme.spacing(10.5),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  input: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 16px 0 20px',
    height: '50px',
    border: '1.7px solid #D3CFCF',
    borderRadius: '7px',
    backgroundColor: '#f9f9f9',
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: ' 0.11px',
    lineHeight: '25px',
    overflow: 'hidden',
    transition: 'border 0.2s ease-in-out',
  },
  error: {
    border: `1.7px solid ${theme.palette.error.main}`,
  },
  titlesAndButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  title: {
    fontSize: 25,
    lineHeight: '31px',
    letterSpacing: 0.2,
    tableTextColor: tableTextColor,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  paragraph: {
    marginBottom: theme.spacing(6),
  },
  paragraphBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonsWrapper: {
    marginTop: theme.spacing(12),
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: 'column-reverse',
      '& button': {
        width: '100%',
      },
    },
  },
  spacer: {
    width: '100%',
    height: 1.8,
    backgroundColor: '#5F76FD1A',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  numericInput: {
    '& > div > input[type="number"]': {
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '-moz-appearance': 'textfield',
    },
  },
}))
