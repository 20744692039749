import { LayoutWrapperContext } from '@core/routes/Routes'
import { SiderPaths } from '@core/routes/routesPaths'
import { activationEInvoices } from '@core/store/entity/measurementPoints/measurementPointsThunks'
import { userAgreementsActions } from '@core/store/entity/userAgreements/userAgreements'
import { getDesktopOffersByTypeSelector } from '@core/store/offers/offersSelectors'
import { ImageType } from '@core/store/offers/types'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import { showModal } from '@core/store/ui/actions'
import { ReactComponent as ContractSvg } from '@images/icons/myCenter/Contract.svg'
import { ReactComponent as NewsSvg } from '@images/icons/myCenter/News.svg'
import { ReactComponent as ProfileSvg } from '@images/icons/myCenter/Profile.svg'
import { ReactComponent as WalletSvg } from '@images/icons/myCenter/Wallet.svg'
import { Grid, Typography } from '@material-ui/core'
import DetailsCardWrapper from '@shared/components/DetailsCard'
import { DETAILS_CARD_CONTENT_MIN_HEIGHT } from '@shared/components/DetailsCard/DetailsCard'
import ConfirmActivationPointForm from '@shared/components/forms/eInvoice/ConfirmActivationPointForm'
import EInvoiceSettings from '@shared/components/forms/eInvoice/EInvoiceSettings'
import MissingEInvoices from '@shared/components/forms/eInvoice/MissingEInvoices'
import SelectActivationPointForm from '@shared/components/forms/eInvoice/SelectActivationPointForm'
import useEInvoiceStyles from '@shared/components/forms/eInvoice/style'
import Modal from '@shared/components/modals/Modal'
import OfferCarousel from '@shared/components/OfferCarousel'
import {
  getAgreementsAssignedToOtherEmailThan,
  isMeasurementPointAssignedToAgreement,
} from '@shared/utils/agreements'
import {
  getMeasurementPointsNumbers,
  stringifyMeasurementPoint,
} from '@shared/utils/measurementPoints'
import { isEmpty } from 'ramda'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import MyDocumentsTable from './components/MyDocumentsTable'
import { MyInformation } from './components/MyInformation'
import { MyNews } from './components/MyNews'
import { MyPaymentsTable } from './components/MyPaymentsTable'
import { useDataSource } from './dataSource'
import useStyles from './MyCenter.style'
import { usePaymentsStatus } from './MyCenter.utils'
import {
  CUSTOMER_DATA,
  HIDE_DETAILS,
  MORE_INFO,
  MY_DOCS,
  SHOW_DETAILS,
} from './strings'

export enum Modals {
  SelectActivationPoint,
  ConfirmActivationPoint,
}

export const MyCenter: React.FC = () => {
  const styles = useStyles()
  const history = useHistory()
  const [showDocDetails, setShowDocDetails] = useState(false)
  const dispatch = useAppDispatch()
  const eInvoiceStyles = useEInvoiceStyles()

  const { t } = useTranslation()
  usePaymentsStatus()

  const offers = !isEmpty(
    useAppSelector(getDesktopOffersByTypeSelector(ImageType.card))
  )

  const {
    account,
    agreements,
    documents,
    measurementPoints,
    news,
    alreadySavedInvoiceTypeRequests,
    correspondenceEmail,
    activationPoint,
    modals,
  } = useDataSource()

  const myDocsTitle = useCallback(
    (showDocDetails: boolean) =>
      showDocDetails
        ? measurementPoints.data[measurementPoints.selectedIndex]?.name ??
          measurementPoints.data[measurementPoints.selectedIndex]?.number ??
          ''
        : MY_DOCS,
    [measurementPoints]
  )

  const setLayoutProps = useContext(LayoutWrapperContext)

  useEffect(() => {
    setLayoutProps({
      topHeaderContent: (
        <Typography className={styles.header}>
          <b>{account.data?.name},</b> {`${t('MY_CENTER.WELCOME_BACK')}!`}
        </Typography>
      ),
      topHeaderLoading: account.loading,
    })

    return () => {
      setLayoutProps({})
    }
  }, [account.loading, account.data, setLayoutProps, styles.header, t])

  const clearEInvoiceState = React.useCallback(() => {
    modals.setOpenModalKey(undefined)
    measurementPoints.setSelectedMeasurementPoints([])
    correspondenceEmail.setCorrespondenceEmail(
      account.data?.correspondenceEmail ?? ''
    )
  }, [modals, measurementPoints, account.data, correspondenceEmail])

  const getAccountEmail = React.useCallback((): string => {
    return account.data?.email ?? ''
  }, [account.data])

  const getCorrespondenceEmail = React.useCallback((): string => {
    return (
      correspondenceEmail.correspondenceEmail ??
      account.data?.correspondenceEmail ??
      ''
    )
  }, [account.data, correspondenceEmail])

  const missingEInvoices = React.useMemo((): MissingEInvoices => {
    if (agreements.noEInvoiceAgreements.length === 0) {
      return MissingEInvoices.None
    } else {
      if (agreements.noEInvoiceAgreements.length === 1) {
        return MissingEInvoices.Single
      } else {
        return MissingEInvoices.Many
      }
    }
  }, [agreements.noEInvoiceAgreements])

  const hasSelectedAgreementEInvoice = React.useMemo(() => {
    const selectedMeasurementPoint =
      measurementPoints.data[measurementPoints.selectedIndex]
    return isMeasurementPointAssignedToAgreement(
      selectedMeasurementPoint,
      agreements.eInvoiceAgreements
    )
  }, [
    agreements.eInvoiceAgreements,
    measurementPoints.data,
    measurementPoints.selectedIndex,
  ])

  const eInvoiceSettings: EInvoiceSettings = {
    missingEInvoices: missingEInvoices,
    hasSelectedAgreementEInvoice: hasSelectedAgreementEInvoice,
  }

  const isEInvoiceButtonVisible = () => {
    return eInvoiceSettings.missingEInvoices !== MissingEInvoices.None
  }

  const showSuccessModal = useCallback(() => {
    modals.setOpenModalKey(undefined)
    dispatch(
      showModal({
        type: 'success',
        title: t('MY_CONTRACTS.MODAL.THANKS_FOR_CHANGE'),
        subtitle: t('MODAL_MESSAGES.SERVICE_ACTIVATED'),
        text: (
          <Trans i18nKey="MODAL_MESSAGES.CONFIRMATION_SENT_TO_EMAIL">
            Activation confirmation has been sent to
            <br />
            {{ email: getAccountEmail() }}, with the terms of use.
          </Trans>
        ),
        onCancel: () => clearEInvoiceState(),
      })
    )
  }, [t, dispatch, clearEInvoiceState, modals, getAccountEmail])

  const showErrorModal = () => {
    dispatch(
      showModal({
        type: 'error',
        title: t('MODAL_MESSAGES.SORRY'),
        subtitle: t('MODAL_MESSAGES.UNEXPECTED_ERROR_OCCURED'),
        withCancelButton: true,
        withSubmitButton: true,
        onSubmit: () => {
          dispatch(
            showModal({
              type: 'report',
            })
          )
        },
      })
    )
  }

  const submitConfirmActivationPointForm = () => {
    dispatch(
      activationEInvoices({
        eInvoiceEmailAddress: correspondenceEmail.correspondenceEmail ?? '',
        measurementPointNumbers: getMeasurementPointsNumbers(
          measurementPoints.selectedMeasurementPoints
        ),
        isEInvoiceAccepted: true,
        isEInvoiceTremsAccepted: true,
      })
    )
      .then(() => {
        dispatch(
          userAgreementsActions.refreshAgreementEmailsAfterActivateEInvoice({
            agreements:
              correspondenceEmail.correspondenceEmail ===
              account.data?.correspondenceEmail
                ? []
                : getAgreementsAssignedToOtherEmailThan(
                    account.data?.correspondenceEmail ?? '',
                    agreements.eInvoiceAgreements
                  ),
            newEmail: correspondenceEmail.correspondenceEmail ?? '',
            measurementPoints: measurementPoints.selectedMeasurementPoints,
          })
        )
        showSuccessModal()
      })
      .catch(() => {
        showErrorModal()
      })
  }

  const modalsToOpen = {
    [Modals.SelectActivationPoint]: (
      <SelectActivationPointForm
        account={account.data}
        measurementPoints={{
          data: measurementPoints.data,
          currentMeasurementPoint:
            measurementPoints.data[measurementPoints.selectedIndex],
        }}
        eInvoiceAgreements={agreements.eInvoiceAgreements}
        noEInvoiceAgreements={agreements.noEInvoiceAgreements}
        onCancel={clearEInvoiceState}
        onSubmit={() => modals.setOpenModalKey(Modals.ConfirmActivationPoint)}
        correspondenceEmail={getCorrespondenceEmail()}
        setCorrespondenceEmail={correspondenceEmail.setCorrespondenceEmail}
        selectedMeasurementPoints={measurementPoints.selectedMeasurementPoints}
        setSelectedMeasurementPoints={
          measurementPoints.setSelectedMeasurementPoints
        }
        selectedActivationPointOption={
          activationPoint.selectedActivationPointOption
        }
        setSelectedActivationPointOption={
          activationPoint.setSelectedActivationPointOption
        }
        stringify={stringifyMeasurementPoint}
        settings={eInvoiceSettings}
      />
    ),
    [Modals.ConfirmActivationPoint]: (
      <ConfirmActivationPointForm
        account={account.data}
        correspondenceEmail={getCorrespondenceEmail()}
        selectedMeasurementPoints={measurementPoints.selectedMeasurementPoints}
        onCancel={() => modals.setOpenModalKey(Modals.SelectActivationPoint)}
        onSubmit={submitConfirmActivationPointForm}
        eInvoiceAgreements={agreements.eInvoiceAgreements}
        selectedActivationPointOption={
          activationPoint.selectedActivationPointOption
        }
        setSelectedMeasurementPoints={
          measurementPoints.setSelectedMeasurementPoints
        }
        stringify={stringifyMeasurementPoint}
        settings={eInvoiceSettings}
      />
    ),
  }

  return (
    <>
      <Grid container spacing={6}>
        <Grid item lg={offers ? 8 : 12} xs={12}>
          <DetailsCardWrapper
            title={t('MY_PAYMENTS')}
            Icon={WalletSvg}
            buttonHandler={() => {
              history.push(SiderPaths.myAccountingDocuments.path)
            }}
            buttonText={t('MY_CENTER.SHOW_ALL')}
            loading={documents.loading}
          >
            {documents.lastData && !isEmpty(documents.lastData) ? (
              <MyPaymentsTable myPayments={documents.lastData} />
            ) : (
              <div className={styles.emptyContracts}>Brak płatności</div>
            )}
          </DetailsCardWrapper>
        </Grid>
        {offers && (
          <Grid
            item
            lg={4}
            sm={12}
            xs={12}
            style={{ width: offers ? '100%' : 0 }}
          >
            <OfferCarousel type={ImageType.card} />
          </Grid>
        )}

        <Grid item lg={5} sm={12} style={{ width: '100%' }}>
          <DetailsCardWrapper
            title={t('MY_MESSAGES.title')}
            Icon={NewsSvg}
            buttonHandler={() => {
              history.push(SiderPaths.news.path)
            }}
            buttonText={t('MY_CENTER.SHOW_ALL')}
            loading={news.loading}
            minHeight={
              isEmpty(news.data)
                ? DETAILS_CARD_CONTENT_MIN_HEIGHT / 2
                : DETAILS_CARD_CONTENT_MIN_HEIGHT
            }
          >
            {news.data && !isEmpty(news.data) ? (
              <MyNews myNews={news.data} />
            ) : (
              <div className={styles.emptyContracts}>Brak wiadomości</div>
            )}
          </DetailsCardWrapper>
        </Grid>
        <Grid item lg={7} xs={12}>
          <DetailsCardWrapper
            title={myDocsTitle(showDocDetails)}
            Icon={ContractSvg}
            buttonHandler={() => {
              setShowDocDetails(prev => !prev)
            }}
            buttonText={
              isEmpty(measurementPoints.data)
                ? undefined
                : showDocDetails
                ? HIDE_DETAILS
                : SHOW_DETAILS
            }
            secondaryButtonHandler={
              isEInvoiceButtonVisible()
                ? () => modals.setOpenModalKey(Modals.SelectActivationPoint)
                : undefined
            }
            secondaryButtonText={
              isEInvoiceButtonVisible()
                ? t('MY_INVOICES.E_INVOICE.ACTIVATE')
                : undefined
            }
            loading={agreements.loading || measurementPoints.loading}
          >
            {measurementPoints.data && !isEmpty(measurementPoints.data) ? (
              <MyDocumentsTable
                measurementPoints={measurementPoints}
                account={account.data}
                agreements={agreements?.data}
                details={showDocDetails}
                alreadySavedInvoiceTypeRequests={
                  alreadySavedInvoiceTypeRequests
                }
                activateEInvoiceHandler={
                  isEInvoiceButtonVisible()
                    ? () => modals.setOpenModalKey(Modals.SelectActivationPoint)
                    : undefined
                }
              />
            ) : (
              <div className={styles.emptyContracts}>Brak aktywnych umów</div>
            )}

            <Modal
              isOpen={modals.openModalKey !== undefined}
              className={eInvoiceStyles.modalWrapper}
            >
              {modals.openModalKey !== undefined &&
                modalsToOpen[modals.openModalKey]}
            </Modal>
          </DetailsCardWrapper>
        </Grid>

        <Grid item xs={12}>
          <DetailsCardWrapper
            title={CUSTOMER_DATA}
            Icon={ProfileSvg}
            buttonHandler={() => history.push(SiderPaths.settings.path)}
            buttonText={MORE_INFO}
            loading={account.loading}
          >
            <MyInformation myInformation={account.data} />
          </DetailsCardWrapper>
        </Grid>
      </Grid>
    </>
  )
}
