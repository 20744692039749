import React from 'react'
import icons, { ModalIconTypes } from './icons'
import useStyles from './Icon.style'
import { Animation } from '@shared/components/Animation'

export interface IIcon {
  type: ModalIconTypes
}

const Icon: React.FC<IIcon> = ({ type }) => {
  const styles = useStyles()

  if (type === 'info') {
    return (
      <img
        style={{ marginBottom: 12 }}
        src={icons[type]}
        alt={`icon-${type}`}
      />
    )
  }

  return <Animation customClass={styles.icon} src={icons[type]} />
}

export default Icon
