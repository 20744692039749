import React from 'react'
import { Collapse, Grid, Typography } from '@material-ui/core'
import { Formik, Form } from 'formik'
import FormikField from '@shared/components/modals/Modal/FormikField'
import { useTranslation } from 'react-i18next'
import {
  IDoubleFieldErrors,
  IDoubleFieldValues,
  ISingleFieldErrors,
  ISingleFieldValues,
} from './EditFieldForm.utils'
import Button from '@shared/components/modals/Modal/Button'
import ErrorMessage from '@shared/components/modals/Modal/ErrorMessage'
import { capitalize } from './EditFieldForm.utils'
import useStyles from './EditFieldForm.style'
import { ErrorPosition } from '@shared/components/modals/Modal/TextField'

export type Field = {
  label: string
  value: string
  name: string
}

export interface IEditFieldFormProps {
  title: string
  formError?: string
  field1: Field
  field2?: Field
  onSubmit: (values: { field1: string; field2?: string }) => void
  onCancel: () => void
  submitBtnText?: string
  cancelBtnText?: string
  validate: (
    values: ISingleFieldValues | IDoubleFieldValues
  ) => IDoubleFieldErrors | ISingleFieldErrors
  saveBtnVisible?: boolean
  emailToInform?: JSX.Element
}

export const EditFieldForm: React.FC<IEditFieldFormProps> = ({
  title,
  field1,
  field2,
  onSubmit,
  onCancel,
  submitBtnText,
  cancelBtnText,
  validate,
  formError,
  saveBtnVisible,
  emailToInform,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const initialValues = field2
    ? { field1: field1.value, field2: field2.value }
    : { field1: field1.value }

  return (
    <Grid className={styles.wrapper} container direction="column">
      {title && (
        <Typography className={styles.title}>
          {capitalize(title.toLowerCase())}
        </Typography>
      )}
      <Grid>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          validateOnChange
        >
          {({ values }) => {
            return (
              <Form className={styles.form}>
                <FormikField errorPosition={ErrorPosition.Bottom} name="field1" label={field1.label} />
                {field2 && <FormikField errorPosition={ErrorPosition.Bottom} name="field2" label={field2.label} />}
                <Collapse in={Boolean(formError)}>
                  <div className={styles.messageWrapper}>
                    <ErrorMessage title={formError} />
                  </div>
                </Collapse>
                {emailToInform && (
                  <div className={styles.emailWrapper}>{emailToInform}</div>
                )}
                <div className={styles.buttonsWrapper}>
                  <Button onClick={onCancel}>
                    {cancelBtnText ?? t('CLOSE')}
                  </Button>
                  <Button
                    type="save"
                    submit
                    disabled={
                      !(
                        saveBtnVisible ||
                        values.field1 !== initialValues.field1 ||
                        (field2 && values.field2 !== initialValues.field2)
                      )
                    }
                  >
                    {submitBtnText ?? t('SEND')}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}
