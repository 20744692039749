import React, { FunctionComponent } from 'react'
import { Field, useFormikContext } from 'formik'
// import {
//   Input,
// } from "@shared/components/Input";
import TextField from '../TextField'
import { ITextFieldProps } from '../TextField/TextField'

interface FormikFieldProps extends Omit<ITextFieldProps, 'formError'> {}

const FormikField: FunctionComponent<FormikFieldProps> = ({
  name,
  label,
  type = 'text',
  required = false,
  disabled = false,
  autocomplete,
  ...props
}) => {
  const formikContext = useFormikContext()
  const formikErrors: {
    [key: string]: string
  } = formikContext.errors
  const formikTouchedFields: {
    [key: string]: string
  } = formikContext.touched
  const fieldError = formikTouchedFields[name] ? formikErrors[name] : undefined
  
  const autocompleteField = 
    <Field
      required={required}
      label={label}
      name={name}
      type={type}
      component={TextField}
      disabled={disabled}
      formError={fieldError}
      autocomplete={true}
      {...props}
    />
  
  return (
    autocomplete? 
    autocompleteField :
    <Field
      required={required}
      label={label}
      name={name}
      type={type}
      as={TextField}
      disabled={disabled}
      formError={fieldError}
      {...props}
    />  
  )
}

export default FormikField
