import {
  Button as MaterialButton,
  ButtonProps,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  withTheme,
} from '@material-ui/core'
import {
  borderColor,
  getStatusColor,
  StatusColor,
  textSecondaryColor,
} from '@mbok/styles/constants'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'
import React from 'react'

export const Button = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'unset',
    borderRadius: '24px',
    boxShadow:
      '-1px -2px 3px rgba(53, 160, 148, 0.15), 2px 3px 4px rgba(54, 54, 54, 0.24)',
    fontWeight: 700,
    padding: '5px 25px',
    fontSize: 17,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      padding: '0 16px',
    },
  },
  sizeSmall: {
    padding: '2.5px 25px',
    fontSize: 14,
    height: 'fit-content',
  },
  containedPrimary: {
    border: `2.5px solid transparent`,
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
  },
  outlined: {
    border: `2.5px solid ${borderColor}`,
    color: textSecondaryColor,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
  },
  outlinedPrimary: {
    border: `2.5px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&:hover': {
      border: `2.5px solid ${theme.palette.primary.main}`,
    },
  },
  containedSecondary: {
    height: '50px',
    border: 'transparent',
    borderRadius: '7px',
    backgroundColor: '#f5f5f5',
    boxShadow: 'none',
    color: theme.palette.text.secondary + colorOpacityParser(75),
    fontWeight: 700,
    letterSpacing: ' 0.11px',
    lineHeight: '16px',
    overflow: 'hidden',
    transition: 'color 0.2s, background-color 0.2s',
    '&:hover': {
      opacity: 1,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      border: 'transparent',
      boxShadow: 'none',
    },
  },
  text: {
    padding: 0,
    border: 'transparent',
    boxShadow: 'none',
    color: theme.palette.primary.main,
    transition: 'color 0.2s ease-in-out',
    '&:hover': {
      boxShadow: 'none',
      border: 'transparent',
      backgroundColor: 'transparent',
      color: theme.palette.primary.dark,
    },
  },
}))(withTheme(MaterialButton))

export interface IStyledButtonProps extends ButtonProps {
  status?: StatusColor
  loading?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spinner: {
      marginLeft: '0',
      width: '0 !important',
      height: '20px',
      transition: 'margin 0.3s, width 0.3s',
      color: theme.palette.common.white,
      right: '-10px',
      position: 'relative',
    },
    visible: {
      marginLeft: '10px',
      width: '20px !important',
    },
  })
)

export const StyledButton: React.FC<IStyledButtonProps> = ({
  status,
  loading,
  children,
  ...props
}) => {
  const styles = useStyles()
  const statusColorValue = status && getStatusColor(status)
  const additionalStyles =
    props.variant === 'outlined' && statusColorValue
      ? {
          border: `2.5px solid ${statusColorValue}`,
          color: statusColorValue,
        }
      : {}

  return (
    <Button
      style={{
        ...additionalStyles,
        pointerEvents: loading ? 'none' : 'auto',
      }}
      {...props}
    >
      {children}{' '}
      {loading && (
        <CircularProgress size={20} className={`${styles.spinner}`} />
      )}
    </Button>
  )
}

export default StyledButton
