import { useEffect, useState } from 'react'
import { useAppSelector } from './store'

export const useDispatchOnCustomerNumberChange = (
  loadDataFn: (numberChanged: boolean) => void
) => {
  const { selectedCustomerNumber } = useAppSelector(
    store => store.customerNumbers
  )

  const [previousCustomerNumber, setPreviousCustomerNumber] = useState<string>()

  useEffect(() => {
    if (previousCustomerNumber === selectedCustomerNumber) {
      return
    }

    loadDataFn(
      !!(
        previousCustomerNumber &&
        previousCustomerNumber !== selectedCustomerNumber
      )
    )

    setPreviousCustomerNumber(selectedCustomerNumber)
  }, [selectedCustomerNumber, previousCustomerNumber, loadDataFn])
}
