import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { lightGrey, tableTextColor } from '@mbok/styles/constants'

const EDIT_ICON_SIZE = 16

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(3, 6),
      backgroundColor: lightGrey,
      color: tableTextColor + '8C',
      fontSize: 14,
      letterSpacing: 0.61,
      lineHeight: '14px',
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
        flexDirection: 'column',
      },
    },
    measurementPoint: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginRight: theme.spacing(3),
      fontSize: 17,
      letterSpacing: 0.64,
      '& p': {
        display: 'flex',
        flexWrap: 'wrap',
        margin: 0,
        marginRight: theme.spacing(1),
        fontSize: 17,
        letterSpacing: 0.64,
        lineHeight: '18px',
      },
      '& span': {
        color: tableTextColor,
        marginRight: theme.spacing(2),
      },
      '& div': {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(3),
        cursor: 'pointer',
        color: theme.palette.primary.main,
        transition: 'color 0.2s ease-in-out',
        '&:hover': {
          color: theme.palette.primary.dark,
        },
        '& svg': {
          width: EDIT_ICON_SIZE,
          height: EDIT_ICON_SIZE,
          marginRight: theme.spacing(0.5),
        },
        '& p': {
          margin: 0,
          fontSize: 14,
          letterSpacing: '0.55px',
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& span': {
          margin: theme.spacing(1, 0, 2),
        },
      },
      [theme.breakpoints.down('xs')]: {
        alignSelf: 'flex-start',
        '& span': {
          fontSize: 15,
        },
      },
    },
    toggler: {
      justifyContent: 'flex-end',
    },
    togglerContainer: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(0.5),
        alignSelf: 'flex-end',
      },
    },
    tableWrapper: {
      display: 'flex',
      minHeight: 250,
      width: '40%',
      border: '1px solid black',
    },
    invoiceTypeContent: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& span': {
        fontSize: 17,
        fontWeight: 700,
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2),
      },
      '& div': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
        '& svg': {
          margin: theme.spacing(0, 0.5, 0, 0),
          width: EDIT_ICON_SIZE,
          minWidth: EDIT_ICON_SIZE,
          height: EDIT_ICON_SIZE,
          minHeight: EDIT_ICON_SIZE,
          fill: theme.palette.primary.main,
          transition: 'fill 0.2s ease-in-out',
        },
        '& span': {
          fontSize: 14,
          transition: 'color 0.2s ease-in-out',
        },
        '&:hover': {
          '& svg': {
            fill: theme.palette.primary.dark,
          },
          '& span': {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
    modal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(6, 0, 8),
      '& h1, h2, h3': {
        textAlign: 'center',
        margin: theme.spacing(0, 0, 3),
      },
      '& h1': {
        fontSize: 25,
        color: tableTextColor,
      },
      '& h2': {
        fontSize: 22,
        color: tableTextColor,
      },
      '& h3': {
        fontSize: 17,
        color: '#7c8a97',
        margin: 0,
      },
      '& div': {
        paddingBottom: 0,
      },
      '& grid': {
        width: '100%',
      },
    },
    modalButtonsWrapper: {
      paddingTop: theme.spacing(6),
      display: 'flex',
      alignSelf: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        flexDirection: 'column-reverse',
        '& button': {
          width: '100%',
        },
      },
    },
  })
)
