import { makeStyles, Theme } from '@material-ui/core'
import {
  borderColor,
  textPrimaryColor,
} from '@mbok/styles/constants'
import { autocompleteWrapperPadding } from '@shared/components/forms/eInvoice/SelectedMeasurementPoint'

export const eInvoiceModalWidth = 720
const measurementPointBoxShadow =
  '0 5px 9px 3px rgba(105,105,105,0.03), 0 1px 6px 3px rgba(74,73,73,0.03), 0 3px 3px 0 rgba(66,65,65,0.05)'

export const eInvoiceModalHeight = 840

export default makeStyles((theme: Theme) => ({
  modalWrapper: {
    width: `${eInvoiceModalWidth}px !important`,
	height: '90%',
    maxHeight: `${eInvoiceModalHeight}px`,
  },
  autocompleteWrapper: {
    paddingLeft: autocompleteWrapperPadding,
  },
  carouselOuterWrapper: {
    width:
      eInvoiceModalWidth - theme.spacing(2 * 16) - autocompleteWrapperPadding,
    marginTop: theme.spacing(3),
    minHeight: 200,
  },
  carouselOuterWrapperWide: {
    width: eInvoiceModalWidth - theme.spacing(2 * 16),
  },
  carouselInnerWrapper: {
    borderRadius: 7,
    boxShadow: measurementPointBoxShadow,
  },
  autocompletePopper: {
    // Dirty hack - popper width and position got calculated incorrectly
    width: `${
      eInvoiceModalWidth - theme.spacing(2 * 16) - autocompleteWrapperPadding
    }px !important`,
    '& .MuiAutocomplete-paper': {
      padding: 0,
      marginTop: -1.8,
    },
    '& .MuiAutocomplete-listbox': {
      maxHeight: 136,
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
      border: `1.7px solid ${borderColor}`,
    },
  },
  modalHeading: {
    color: textPrimaryColor,
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 800,
    lineHeight: '25px',
    letterSpacing: 0.08,
  },
  selectedMeasurementPoint: {
    '&:not(.MuiPaper-elevation1)': {
      boxShadow: 'none',
    },
    width:
      eInvoiceModalWidth - theme.spacing(2 * 16) - autocompleteWrapperPadding,
    borderRadius: 7,
    boxShadow: measurementPointBoxShadow,
    padding: theme.spacing(6),
    position: 'relative',
  },
  selectedMeasurementPointWide: {
    width: eInvoiceModalWidth - theme.spacing(2 * 16),
    boxShadow: measurementPointBoxShadow,
    padding: theme.spacing(6),
    position: 'relative',
  },
}))
