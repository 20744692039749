import { ReactComponent as CheckedSvg } from '@images/icons/checkbox/Checked.svg'
import { ReactComponent as UncheckedSvg } from '@images/icons/checkbox/Unchecked.svg'
import { Box, Grid, Typography } from '@material-ui/core'
import useModalFormStyles from '@mbok/styles/modalForm'
import Actions from '@shared/components/Actions'
import Carousel from '@shared/components/Carousel'
import StyledCheckbox from '@shared/components/Checkbox'
import useEInvoiceStyles from '@shared/components/forms/eInvoice/style'
import Label from '@shared/components/Label'
import { Account } from '@shared/contracts/account'
import { AgreementListItem } from '@shared/contracts/agreementListItem'
import { DocumentType } from '@shared/contracts/documentType'
import { MeasurementPointListItem } from '@shared/contracts/measurementPointListItem'
import { useSavePdf } from '@shared/hooks/pdfSaver'
import { Form, Formik } from 'formik'
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import ActivationPoint from '../ActivationPoint'
import EInvoiceSettings from '../EInvoiceSettings'
import Payer from '../Payer'
import SelectedMeasurementPoint from '../SelectedMeasurementPoint'
import useStyles from './ConfirmActivationPointForm.style'
import { validateConfirmActivationPointForm } from './ConfirmActivationPointForm.utils'
import useConfirmActivationPointFormDataSource, {
  ReducedConsent,
} from './dataSource'

export type FormValues = {
  [type in DocumentType]?: boolean
}

interface ConfirmActivationPointFormProps {
  account?: Account
  selectedMeasurementPoints: MeasurementPointListItem[]
  correspondenceEmail: string
  onCancel: () => void
  onSubmit: () => void
  selectedActivationPointOption: ActivationPoint | undefined
  stringify: (point: MeasurementPointListItem) => string
  settings: EInvoiceSettings
  setSelectedMeasurementPoints: (points: MeasurementPointListItem[]) => void
  update?: boolean
  eInvoiceAgreements?: (AgreementListItem | undefined)[]
}

export const ConfirmActivationPointForm: React.FC<ConfirmActivationPointFormProps> = ({
  account,
  selectedMeasurementPoints,
  correspondenceEmail,
  onCancel,
  onSubmit,
  selectedActivationPointOption,
  stringify,
  settings,
  setSelectedMeasurementPoints,
  update = false,
  eInvoiceAgreements,
}) => {
  const formStyles = useModalFormStyles()
  const { t } = useTranslation()
  const styles = useStyles()
  const { consents } = useConfirmActivationPointFormDataSource()
  const eInvoiceStyles = useEInvoiceStyles()
  const savePdf = useSavePdf()

  const initialValues: FormValues = {
    [DocumentType.EInvoice]: false,
    [DocumentType.EInvoiceTermsAcceptance]: false,
  }

  const changeFor = React.useMemo((): string => {
    const prefix = 'MY_INVOICES.E_INVOICE.ACTIVATION_POINTS.'
    const count = ` (${selectedMeasurementPoints.length})`

    if (selectedActivationPointOption === ActivationPoint.Many) {
      return t(`${prefix}MANY`) + count
    }
    if (selectedActivationPointOption === ActivationPoint.All) {
      return t(`${prefix}ALL`) + count
    }
    if (settings.hasSelectedAgreementEInvoice && !update) {
      return t(`${prefix}SINGLE`)
    }
    return t(`${prefix}SELECTED`)
  }, [
    t,
    selectedActivationPointOption,
    settings,
    selectedMeasurementPoints.length,
    update,
  ])

  const onFormCancel = React.useCallback(() => {
    if (selectedActivationPointOption !== ActivationPoint.Many) {
      setSelectedMeasurementPoints([])
    }
    onCancel()
  }, [onCancel, selectedActivationPointOption, setSelectedMeasurementPoints])

  const replaceConsentLink = (consent: ReducedConsent) => {
    const linkPattern = /\*.*\*/
    const matched = consent.content.match(linkPattern)
    if (matched !== null && consent.pdf) {
      const exploded = consent.content.split(matched[0])
      const noMarks = matched[0].replaceAll('*', '')
      const pdf = savePdf(
        consent.pdf ?? '',
        (_, saveFn) => <span onClick={() => saveFn()}>{noMarks}</span>,
        noMarks
      )

      return [exploded[0], pdf, exploded[1]]
    }
    return [consent.content]
  }

  return (
    <Scrollbars style={{ height: '100%' }}>
      <Grid container className={formStyles.wrapper} direction="column">
        <Typography className={formStyles.title}>
          {t(
            update
              ? 'MY_INVOICES.E_INVOICE.CONFIRM_UPDATE'
              : 'MY_INVOICES.E_INVOICE.CONFIRM_ACTIVATION'
          )}
        </Typography>
        <Payer
          account={account}
          correspondenceEmail={correspondenceEmail}
          headingClass={eInvoiceStyles.modalHeading}
          stringifyAddress={stringify}
        />
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            onSubmit()
          }}
          validate={update ? undefined : validateConfirmActivationPointForm}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box className={styles.measurementPoints}>
                <Typography
                  className={`${eInvoiceStyles.modalHeading} ${styles.measurementPointsHeading}`}
                >{`${t(
                  update
                    ? 'MY_INVOICES.E_INVOICE.UPDATE_FOR'
                    : 'MY_INVOICES.E_INVOICE.ACTIVATE_FOR'
                )} ${changeFor}:`}</Typography>
                <Box className={eInvoiceStyles.carouselOuterWrapperWide}>
                  <Carousel
                    length={selectedMeasurementPoints.length}
                    showToggler={
                      Number(selectedActivationPointOption) !==
                        ActivationPoint.One &&
                      selectedMeasurementPoints.length > 1
                    }
                    className={eInvoiceStyles.carouselInnerWrapper}
                  >
                    {selectedMeasurementPoints.map(
                      (option: MeasurementPointListItem) => (
                        <SelectedMeasurementPoint
                          address={stringify(option)}
                          number={option?.number}
                          isDelete={false}
                          className={
                            eInvoiceStyles.selectedMeasurementPointWide
                          }
                          key={option?.number}
                        />
                      )
                    )}
                  </Carousel>
                </Box>
              </Box>
              {!update && (
                <Box className={styles.consents}>
                  {consents?.map((consent: ReducedConsent) => (
                    <Label
                      label={replaceConsentLink(consent).map(
                        (part: any) => part
                      )}
                      control={
                        <StyledCheckbox
                          icon={<UncheckedSvg />}
                          checkedIcon={<CheckedSvg />}
                          style={{ marginRight: 8 }}
                          checked={values[consent.type]}
                          onChange={() =>
                            setFieldValue(consent.type, !values[consent.type])
                          }
                        />
                      }
                      className={styles.consent}
                    />
                  ))}
                </Box>
              )}

              <Actions
                cancelText={t('EDIT')}
                submitText={update ? t('CHANGE') : t('ACTIVATE')}
                onCancel={onFormCancel}
                disabled={
                  update
                    ? false
                    : values[DocumentType.EInvoice] === false ||
                      values[DocumentType.EInvoiceTermsAcceptance] === false
                }
              />
            </Form>
          )}
        </Formik>
      </Grid>
    </Scrollbars>
  )
}
