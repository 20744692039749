import { useApi } from '@core/apiClient/apiClient'
import { customerNumberActions } from '@core/store/entity/customerNumbers/customerNumbers'
import {
  getAllCustomerNumbersSelector,
  getSelectedCustomerNumberSelector,
} from '@core/store/entity/customerNumbers/customerNumbersSelectors'
import { userConsentsSlice } from '@core/store/entity/userConsents/userConsents'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import {
  Account,
  AccountBalance,
  MeasurementPointListItem,
  MeasurementPointListItemPagedResponse,
} from '@shared/contracts/models'
import { useCacheReload } from '@shared/hooks/useCacheReload'
import { equals } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const useDataSource = () => {
  const dispatch = useAppDispatch()
  const customerNumbers = useAppSelector(
    getAllCustomerNumbersSelector,
    (left, right) => equals(left, right)
  )
  const selectedCustomerNumber = useAppSelector(
    getSelectedCustomerNumberSelector
  )
  const cacheReload = useCacheReload()

  const [dataLoading, setDataLoading] = useState(false)
  const [customersData, setCustomersData] = useState<Account[]>([])
  const [balanceData, setBalanceData] = useState<{
    [customerId: string]: number
  }>({})

  const [getCustomerData] = useApi<Account>({
    config: {
      url: 'accounts',
      method: 'get',
    },
  })

  const pointsDataMapper = useCallback(
    apiResponse => apiResponse.results ?? [],
    []
  )
  const [getPoints, pointsLoading, pointsData] = useApi<
    MeasurementPointListItem[],
    MeasurementPointListItemPagedResponse
  >(
    {
      config: {
        url: 'MeasurementPoints',
        method: 'get',
        params: {
          agreementStatusFilter: 'Active',
        },
      },
    },
    pointsDataMapper
  )

  const [getBalance] = useApi<AccountBalance>({
    config: {
      method: 'get',
      url: 'Accounts/balance',
    },
  })

  const setNewCustomerNumber = useCallback(
    (customerNumber: string) => {
      dispatch(userConsentsSlice.actions.removeAll())
      dispatch(customerNumberActions.setCustomerNumber(customerNumber))
      cacheReload()
    },
    [dispatch, cacheReload]
  )

  useEffect(() => {
    setDataLoading(true)

    Promise.all([
      Promise.all(
        customerNumbers.map(customerNumber =>
          getCustomerData({
            params: {
              customerNumber,
            },
          })
        )
      ).then(responses => {
        const customersData = responses.map(responses => {
          return responses.data
        })

        setCustomersData(customersData)
      }),

      Promise.all(
        customerNumbers.map(customerNumber =>
          getBalance({
            params: { customerNumber },
          })
        )
      ).then(responses => {
        const balance = customerNumbers.reduce((acc, number, index) => {
          return {
            ...acc,
            [number as string]: responses[index].data.balance,
          }
        }, {})
        setBalanceData(balance)
      }),
    ]).then(() => {
      setDataLoading(false)
    })
  }, [customerNumbers, getCustomerData, getPoints, getBalance])

  const orderedCustomers = useMemo(() => {
    return customersData.sort((x, y) => {
      return x.customerNumber === selectedCustomerNumber
        ? -1
        : y.customerNumber === selectedCustomerNumber
        ? 1
        : 0
    })
  }, [customersData, selectedCustomerNumber])

  return {
    customerNumbers: orderedCustomers,
    selectedCustomerNumber,
    customers: customersData,
    balance: balanceData,
    loading: dataLoading,
    measurementPoints: {
      loading: pointsLoading,
      data: pointsData,
      get: (customerNumber: string) =>
        getPoints({
          params: { customerNumber },
        }),
    },
    setNewCustomerNumber,
  }
}
