/**
 * mBok API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ConsentStatus = 'Accepted' | 'NotSet' | 'Declined'

export const ConsentStatus = {
  Accepted: 'Accepted' as ConsentStatus,
  NotSet: 'NotSet' as ConsentStatus,
  Declined: 'Declined' as ConsentStatus,
}
