import React from 'react'
import useModalFormStyles from '@mbok/styles/modalForm'
import { Grid, Typography } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import { MeasurementPointListItem } from '@shared/contracts/measurementPointListItem'
import Carousel from '@shared/components/Carousel'
import useEInvoiceStyles from '@shared/components/forms/eInvoice/style'
import SelectedMeasurementPoint from '@shared/components/forms/eInvoice/SelectedMeasurementPoint'
import Actions from '@shared/components/Actions'

interface Classes {
  carouselOuterWrapper?: string
  carouselInnerWrapper?: string
}

interface UpdateLinkedInvoicesProps {
  measurementPoints: MeasurementPointListItem[]
  oldEmail: string
  classes?: Classes
  stringify: (point: MeasurementPointListItem) => string
  onSubmit: () => void
  onCancel: () => void
}

export const UpdateLinkedInvoices: React.FC<UpdateLinkedInvoicesProps> = ({
  measurementPoints,
  oldEmail,
  classes = {},
  stringify,
  onSubmit,
  onCancel,
}) => {
  const formStyles = useModalFormStyles()
  const { t } = useTranslation()
  const eInvoiceStyles = useEInvoiceStyles()

  return (
    <Grid container className={formStyles.wrapper} direction="column">
      <Typography className={formStyles.title}>
        {t('MY_SETTINGS.MY_PAYER_DATA.NEW_CONTACT_EMAIL')}
      </Typography>
      <div>
        <div>
          <Trans
            i18nKey={'MY_SETTINGS.MY_PAYER_DATA.OLD_CONTACT_EMAIL_ALREADY_USED'}
          >
            The previous e-mail address
            {{ oldEmail: oldEmail }} is also used to send e-invoices for one or
            more of your contracts.
          </Trans>
        </div>
        <div className={eInvoiceStyles.carouselOuterWrapper}>
          <Carousel
            length={measurementPoints.length}
            className={eInvoiceStyles.carouselInnerWrapper}
          >
            {measurementPoints.map((option: MeasurementPointListItem) => (
              <SelectedMeasurementPoint
                number={option?.number}
                address={stringify(option)}
                className={eInvoiceStyles.selectedMeasurementPoint}
                isDelete={false}
              />
            ))}
          </Carousel>
        </div>
        <div>
          <Trans i18nKey="MY_SETTINGS.MY_PAYER_DATA.CONFIRM_NEW_EMAIL">
            If you only want to change your contact e-mail address and still
            receive e-invoices to {{ oldEmail }}, use the "
            {{ doNotChangeLabel: t('DO_NOT_CHANGE') }}" button.
          </Trans>
        </div>
        <Actions
          cancelText={t('DO_NOT_CHANGE')}
          submitText={t('CONTINUE')}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </div>
    </Grid>
  )
}
