import React, { useState } from 'react'
import { Collapse, Grid, Typography, DialogContent } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'ramda'

import useStyles from './EnableMFAForm.style'
import { useAppDispatch } from '@core/store/store'
import {
  EnableMFAFormProps,
  InitialFieldsType,
  MFAModalView,
} from './EnableMFAForm.types'
import {
  mfaChannels,
  mfaHelper,
  validateFormFields,
} from './EnableMFAForm.utils'
import Button from '@shared/components/modals/Modal/Button'
import { SelectiveButtons } from '@shared/components/SelectiveButtons/SelectiveButtons'
import { Input } from '@shared/components/Input'
import PhoneNumberInput from '@shared/components/PhoneNumberInput/PhoneNumberInput'
import {
  enableMfa,
  enableMfaVerification,
  getUserAccount,
} from '@core/store/user/userAccountThunks'
import LabeledField from '@shared/components/InputWrapper/LabeledField'
import { showModal } from '@core/store/ui/actions'
import { ResponseWithError } from '@shared/contracts/responseWithError'

export const EnableMFAForm: React.FC<EnableMFAFormProps> = ({
  onCancel,
  mainEmail,
  mainPhoneNumber,
  mainPhonePrefix,
  showErrorModal,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [mfaModalView, setMfaModalView] = useState<MFAModalView>(
    MFAModalView.INTRODUCTION
  )

  const initialValues = {
    MFAContactType: '',
    Email: mainEmail ?? '',
    Phone: mainPhoneNumber ?? '',
    PhonePrefix: mainPhonePrefix ?? '+48',
    MfaCode: '',
  }

  const handleOnCancel = () => {
    onCancel()
    setMfaModalView(MFAModalView.INTRODUCTION)
  }

  const handleSelectContactType = (option: string) => {
    option === mfaChannels[0].value
      ? setMfaModalView(MFAModalView.PHONE_INPUT)
      : setMfaModalView(MFAModalView.EMAIL_INPUT)
  }

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLDivElement>,
    values: InitialFieldsType,
    validateForm: (values: {}, mfaModalView: MFAModalView) => {},
    submitForm: (() => Promise<void>) & (() => Promise<any>),
    setFieldError: (field: string, message: string) => void
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleMfaActivation(values, validateForm, submitForm, setFieldError)
    }
  }

  const verifyAndSendToken = async (
    values: InitialFieldsType,
    setFieldError: (field: string, message: string) => void
  ) => {
    const res = await dispatch(
      enableMfaVerification({
        mfaContactType: String(values.MFAContactType),
        phoneNumber:
          values.MFAContactType === mfaChannels[0].value ||
          String(values.Phone).length
            ? String(values.Phone)
            : null,
        phonePrefix:
          values.MFAContactType === mfaChannels[0].value ||
          String(values.Phone).length
            ? String(values.PhonePrefix)
            : null,
      })
    )
    if ((res as ResponseWithError).error?.message) {
      mfaModalView === MFAModalView.EMAIL_INPUT &&
        setFieldError('Email', (res as ResponseWithError).error!.message)
      mfaModalView === MFAModalView.PHONE_INPUT &&
        setFieldError('Phone', (res as ResponseWithError).error!.message)
    }
    if (res.type.includes('rejected')) {
      showErrorModal()
    } else {
      setMfaModalView(MFAModalView.ACIVATION_CODE)
    }
  }

  const handleMfaActivation = async (
    values: InitialFieldsType,
    validateForm: (values: {}, mfaModalView: MFAModalView) => {},
    submitForm: (() => Promise<void>) & (() => Promise<any>),
    setFieldError: (field: string, message: string) => void
  ) => {
    const validate = validateForm(values, mfaModalView)
    switch (mfaModalView) {
      case MFAModalView.INTRODUCTION:
        setMfaModalView(MFAModalView.MFA_CHANNEL)
        break
      case MFAModalView.MFA_CHANNEL:
        submitForm()
        break
      case MFAModalView.EMAIL_INPUT:
      case MFAModalView.PHONE_INPUT:
        submitForm()
        if (isEmpty(validate)) {
          await verifyAndSendToken(values, setFieldError)
        }
        break
      case MFAModalView.ACIVATION_CODE:
        submitForm()
        if (isEmpty(validate)) {
          const res = await dispatch(
            enableMfa({
              mfaContactType: String(values.MFAContactType),
              mfaCode: String(values.MfaCode),
              phoneNumber:
                values.MFAContactType === mfaChannels[0].value ||
                String(values.Phone).length
                  ? String(values.Phone)
                  : null,
              phonePrefix:
                values.MFAContactType === mfaChannels[0].value ||
                String(values.Phone).length
                  ? String(values.PhonePrefix)
                  : null,
            })
          )
          if ((res as ResponseWithError).error?.message) {
            setFieldError('MfaCode', (res as ResponseWithError).error!.message)
          } else {
            dispatch(getUserAccount())
            handleOnCancel()
            dispatch(
              showModal({
                type: 'success',
                title: t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_ACTIVATION_SUCCESS'),
              })
            )
          }
        }
        break
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={values => validateFormFields(values, mfaModalView)}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        submitForm,
        resetForm,
        setFieldError,
      }) => {
        return (
          <Form>
            <Grid container className={styles.wrapper} direction="column">
              <Typography className={styles.title}>
                {mfaModalView === MFAModalView.ACIVATION_CODE
                  ? t('MY_SETTINGS.MY_ACCOUNT_DATA.ENTER_MFA_ACTIVATION_CODE')
                  : t('MY_SETTINGS.MY_ACCOUNT_DATA.SELECT_MFA_PATH')}
              </Typography>
              <DialogContent className={styles.contentWrapper}>
                <Collapse in={mfaModalView === MFAModalView.INTRODUCTION}>
                  <Typography
                    className={`${styles.paragraph} ${styles.paragraphBold}`}
                  >
                    {t('MY_SETTINGS.MY_ACCOUNT_DATA.WHAT_IS_MFA')}
                  </Typography>
                  <Typography className={styles.paragraph}>
                    {t('MY_SETTINGS.MY_ACCOUNT_DATA.WHAT_IS_MFA_CONTENT')}
                  </Typography>
                  <Typography
                    className={`${styles.paragraph} ${styles.paragraphBold}`}
                  >
                    {t('MY_SETTINGS.MY_ACCOUNT_DATA.HOW_MFA_WORKS')}
                  </Typography>
                  <Typography className={styles.paragraph}>
                    {t('MY_SETTINGS.MY_ACCOUNT_DATA.HOW_MFA_WORKS_CONTENT')}
                  </Typography>
                </Collapse>

                <Collapse
                  in={mfaModalView === MFAModalView.MFA_CHANNEL}
                  timeout={{ enter: 650, exit: 650 }}
                >
                  <SelectiveButtons
                    name="MFAContactType"
                    label={t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_BUTTONS_LABEL')}
                    error={errors?.MFAContactType}
                    options={mfaChannels}
                    onClick={option => {
                      resetForm()
                      handleSelectContactType(option)
                    }}
                    helper={{
                      content: mfaHelper,
                    }}
                  />
                </Collapse>

                <Collapse
                  in={mfaModalView === MFAModalView.PHONE_INPUT}
                  timeout={{ enter: 650, exit: 350 }}
                >
                  <LabeledField
                    title={t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_PHONE_LABEL')}
                    handleBackButton={() => {
                      setFieldValue('MFAContactType', '')
                      setMfaModalView(MFAModalView.MFA_CHANNEL)
                    }}
                    errorMsg={errors?.Phone}
                    touched={touched['Phone']}
                  >
                    <PhoneNumberInput
                      name="Phone"
                      phonePrefixName="PhonePrefix"
                      optional={false}
                      persistedPrefix={String(values.PhonePrefix)}
                      persistedPhoneNumber={String(values.Phone)}
                      disabled={!isNil(mainPhoneNumber)}
                    />
                  </LabeledField>
                </Collapse>
                <Collapse
                  in={mfaModalView === MFAModalView.EMAIL_INPUT}
                  timeout={{ enter: 650, exit: 350 }}
                >
                  <LabeledField
                    title={t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_EMAIL_LABEL')}
                    handleBackButton={() => {
                      setFieldValue('MFAContactType', '')
                      setMfaModalView(MFAModalView.MFA_CHANNEL)
                    }}
                    errorMsg={errors?.Email}
                    touched={touched['Email']}
                  >
                    <div
                      className={`${styles.input} ${
                        errors.Email && touched['Email'] && styles.error
                      }`}
                    >
                      <Input
                        name="Email"
                        value={String(values.Email)}
                        placeholder={t(
                          'MY_SETTINGS.MY_ACCOUNT_DATA.EMAIL_PLACEHOLDER'
                        )}
                        onChange={e => setFieldValue('Email', e.target.value)}
                        onBlur={() => setTouched({ Email: true })}
                        disabled={!isNil(mainEmail)}
                      />
                    </div>
                  </LabeledField>
                </Collapse>
                <Collapse
                  in={mfaModalView === MFAModalView.ACIVATION_CODE}
                  timeout={{ enter: 650, exit: 350 }}
                >
                  <LabeledField
                    title={t('MY_SETTINGS.MY_ACCOUNT_DATA.ENTER_MFA_CODE')}
                    errorMsg={errors.MfaCode}
                    touched={touched['MfaCode']}
                  >
                    <div
                      className={`${styles.input} ${
                        errors.MfaCode && touched['MfaCode'] && styles.error
                      }`}
                    >
                      <Input
                        name="MfaCode"
                        value={String(values.MfaCode)}
                        type="number"
                        placeholder={t(
                          'MY_SETTINGS.MY_ACCOUNT_DATA.ENTER_MFA_CODE'
                        )}
                        onChange={e => setFieldValue('MfaCode', e.target.value)}
                        onBlur={() => setTouched({ MfaCode: true })}
                        onKeyPress={e =>
                          handleKeyPress(
                            e,
                            values,
                            validateFormFields,
                            submitForm,
                            setFieldError
                          )
                        }
                        className={styles.numericInput}
                      />
                    </div>
                  </LabeledField>
                </Collapse>
                <div className={styles.spacer} />
                <Typography
                  className={`${styles.paragraphBold} ${styles.textDanger}`}
                >
                  {t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_CAUTION')}
                </Typography>
              </DialogContent>

              <div className={styles.buttonsWrapper}>
                <Button onClick={handleOnCancel}>{t('CLOSE')}</Button>
                {mfaModalView === MFAModalView.ACIVATION_CODE && (
                  <Button
                    style={{ marginRight: '28px' }}
                    onClick={() => {
                      setFieldValue('MfaCode', '')
                      setFieldError('MfaCode', '')
                      verifyAndSendToken(values, setFieldError)
                    }}
                  >
                    {t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_RESEND_TOKEN')}
                  </Button>
                )}
                <Button
                  type="save"
                  onClick={() => {
                    handleMfaActivation(
                      values,
                      validateFormFields,
                      submitForm,
                      setFieldError
                    )
                  }}
                >
                  {t('MY_SETTINGS.MY_ACCOUNT_DATA.ACTIVATE_MFA')}
                </Button>
              </div>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
