import { makeStyles } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

export default makeStyles(theme => ({
  wrapper: {
    width: 'auto',
    boxSizing: 'border-box',
    '&:not(:first-child)': {
      paddingLeft: theme.spacing(15),
    },
    '&:not(:last-child)': {
      paddingRight: theme.spacing(15),
      borderRight: '1.8px solid #5F76FD1A',
    },
    padding: theme.spacing(12, 16)
  },
  title: {
    fontSize: 25,
    lineHeight: '31px',
    letterSpacing: 0.2,
    tableTextColor: tableTextColor,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(6),
  },
  buttonsWrapper: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: 'column-reverse',
      '& button': {
        width: '100%',
      },
    },
  },
}))
