import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { EntityReducers } from './EntityReducers'
import {
  getBaseState,
  getEntityAdapterReducers,
} from './helpers/entityReducerHelpers'
import {
  getByIdThunk,
  getByIdExtraReducers,
} from './helpers/thunks/getByIdThunk'
import { getAllThunk, getAllExtraReducers } from './helpers/thunks/getAllThunk'
import { useThunkApi } from '@core/apiClient/apiClient'

export interface additionalState {
  count: number
}

export interface ITest {
  id: string
  test: string
}

const entityAdapter = createEntityAdapter<ITest>({})

export const testGetById = getByIdThunk<ITest>(
  EntityReducers.test,
  ({ payload, thunkApi }) =>
    useThunkApi(
      {
        config: {
          method: 'get',
          url: `https://jsonplaceholder.typicode.com/todos/${payload}`,
        },
      },
      thunkApi
    )
)

export const testGetAll = getAllThunk<ITest>(
  EntityReducers.test,
  ({ thunkApi }) =>
    useThunkApi(
      {
        config: {
          method: 'get',
          url: `https://jsonplaceholder.typicode.com/todos`,
        },
      },
      thunkApi
    )
)

export const testSlice = createSlice({
  name: EntityReducers.test,
  initialState: {
    ...getBaseState<ITest, additionalState>(entityAdapter, {
      count: 1,
    }),
  },
  reducers: {
    ...getEntityAdapterReducers<ITest>(entityAdapter),
    increment: state => {
      state.count++
    },
  },
  extraReducers: builder => {
    getByIdExtraReducers<ITest>(testGetById, builder, entityAdapter)
    getAllExtraReducers<ITest>(testGetAll, builder, entityAdapter)
  },
})
