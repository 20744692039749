import { Theme, createStyles, makeStyles } from '@material-ui/core'

export const phoneFieldStyles = (theme: Theme) =>
  createStyles({
    root: {
      '& .react-tel-input': {
        margin: '0 0 16px',
      },
      '& .react-tel-input .form-control': {
        backgroundColor: '#f9f9f9',
        border: '1.7px solid #D3CFCF',
        height: '50px',
        width: 'calc(100% - 90px)',
        fontSize: '16px',
        marginLeft: '90px',
        borderRadius: '7px',
        paddingLeft: '20px',
        letterSpacing: '0.11px',
        color: '#3A3A3A',
        opacity: '0.85',
        fontWeight: 600,
        fontFamily: '"Mulish", "Helvetica", "Arial", sans-serif',
      },
      '& .react-tel-input .flag-dropdown': {
        borderRadius: '7px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .react-tel-input .flag-dropdown:hover': {
        backgroundColor: '#f9f9f9',
      },
      '&.error .selected-flag': {
        border: '1.7px solid #D22454 !important',
      },
      '&.disabled .selected-flag': {
        color: 'rgba(58, 58, 58, 0.35)',
        cursor: 'default',
      },
      '& .react-tel-input .selected-flag': {
        padding: '0 50px 0 28px',
        borderRadius: '7px',
        backgroundColor: '#f9f9f9',
      },
      '& .react-tel-input .selected-flag:hover': {
        backgroundColor: '#f9f9f9',
      },
      '& .react-tel-input .country-list': {
        top: '-11px',
        left: 0,
        boxShadow: 'none',
        overflowX: 'hidden',
        borderRadius: '3px',
        border: '1px solid #D3CFCF',
      },
      '&.error .form-control': {
        border: '1.7px solid #D22454 !important',
      },
      '&.disabled .form-control': {
        color: 'rgba(58, 58, 58, 0.35)',
        cursor: 'default',
      },
      '& .react-tel-input .search-emoji': {
        textIndent: '-9999px',
        fontSize: 0,
      },
      '& .react-tel-input .search-box': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        border: 'none',
        height: '50px',
        paddingLeft: '45px',
        borderRadius: '3px',
        borderBottom: '1px solid #D3CFCF',
        fontSize: '15px',
        color: '#3A3A3A',
      },
      '& input[type="search"].search-box::-webkit-search-cancel-button': {
        WebkitAppearance: 'none',
        height: '19px',
        width: '19px',
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='rgb(72,169,161)' d='M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z'/%3E%3C/svg%3E") no-repeat`,
        backgroundSize: 'contain',
        opacity: 1,
        cursor: 'pointer',
      },
      '& input[type="search"].search-box::-ms-clear': {
        WebkitAppearance: 'none',
        height: '19px',
        width: '19px',
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='rgb(72,169,161)' d='M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z'/%3E%3C/svg%3E") no-repeat`,
        backgroundSize: 'contain',
        opacity: 1,
        cursor: 'pointer',
      },
      '& .react-tel-input .search-emoji::before': {
        content:
          'url("svg/Search.svg") !important' /* ścieżka do nowej ikony */,
        position: 'absolute !important',
        background: 'url("svg/Search.svg") !important',
        top: '15px',
        left: '5px',
        height: '19px',
        width: '19px',
        zIndex: 99999,
        marginLeft: '15px',
      },
      '& .react-tel-input li:nth-child(1)': {
        paddingTop: '25px',
      },
      '& .react-tel-input .country-list .country': {
        paddingLeft: '20px',
      },
      '& .react-tel-input .country-list .no-entries-message': {
        paddingLeft: '20px',
        color: '#3A3A3A',
        fontFamily: 'inherit',
      },
    },
  })

export default makeStyles((theme: Theme) =>
  createStyles({
    label: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'left',
      textAlign: 'left',
      minHeight: '17px',
      margin: '0 0 4px 20px',
      '& p': {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        gap: '3.5px',
        marginRight: '8px',
        fontSize: 12,
        fontWeight: 800,
        color: theme.palette.text.primary,
        letterSpacing: '0.08px',
        [theme.breakpoints.down('xs')]: {
          margin: 0,
        },
        '& span': {
          opacity: 0.25,
          [theme.breakpoints.down('xs')]: {
            fontSize: 10,
          },
        },
      },
      '& div > p': {
        margin: 0,
        fontSize: 12,
        color: theme.palette.error.main,
        fontWeight: theme.typography.fontWeightBold,

        [theme.breakpoints.down('xs')]: {
          margin: 0,
        },
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        marginLeft: 0,
      },
    },
  })
)
