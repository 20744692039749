import React, { useCallback, useState } from 'react'
import { Paper, Divider } from '@material-ui/core'
import { isYesterday, format, parseJSON } from 'date-fns'
import { News } from '@shared/contracts/models'
import { Button } from '@shared/components/Button'
import { useApi } from '@core/apiClient/apiClient'
import useStyles from './SingleNewsOnPage.style'
import SingleNewsModal from '../modals/SingleNewsModal'
import { ReactComponent as ReadNewsSvg } from '@images/icons/myNews/ReadNews.svg'
import { ReactComponent as UnreadNewsSvg } from '@images/icons/myNews/UnreadNews.svg'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

export const SingleNewsOnPage: React.FC<{
  news: News
  reloadNews?: (
    customerNumberChanged?: boolean | undefined,
    start?: number | undefined
  ) => void
}> = ({ news, reloadNews }) => {
  const styles = useStyles()
  const [showModal, setShowModal] = useState(false)

  const { id, title, date, message, markedAsRead } = news

  const [executeMarkAsRead] = useApi({
    config: {
      method: 'put',
    },
  })

  const handleShowMessageClick = useCallback(
    (id: string) => {
      setShowModal(true)
      executeMarkAsRead({ url: `News/${id}/markAsRead` })
    },
    [setShowModal, executeMarkAsRead]
  )

  const handleModalClose = useCallback(() => {
    setShowModal(false)
    reloadNews && reloadNews(false, 0)
  }, [setShowModal, reloadNews])

  const yesterday = useCallback(
    (date: Date) => isYesterday(parseJSON(date)),
    []
  )

  const formattedDate = useCallback(
    (date: Date) => format(parseJSON(date), 'dd.MM.yyyy'),
    []
  )

  return (
    <>
      <Paper variant="outlined" className={styles.paper}>
        <div className={styles.upperSection}>
          <div className={styles.header}>
            <div className={styles.icon}>
              {markedAsRead ? <ReadNewsSvg /> : <UnreadNewsSvg />}
            </div>
            <div className={styles.left}>
              <p>
                {date && (yesterday(date) ? 'Wczoraj' : formattedDate(date))}
              </p>
              <h1>{title}</h1>
            </div>
          </div>
          <Button
            variant="outlined"
            onClick={() => id && handleShowMessageClick(id)}
          >
            Zobacz wiadomość
          </Button>
        </div>
        <Divider />
        <div className={styles.content}>
          <ReactQuill theme="bubble" readOnly={true} value={JSON.parse(message ?? "")} />
        </div>
      </Paper>
      <SingleNewsModal
        show={showModal}
        close={() => handleModalClose()}
        news={news}
      />
    </>
  )
}
