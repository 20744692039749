import { EntityReducers } from '../../EntityReducers'
import { apiAction, handleLoadingAction } from './entityThunksFactory'
import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  EntityAdapter,
  AsyncThunk,
} from '@reduxjs/toolkit'
import { RootState } from '../../../rootReducer'
import { BaseState } from '../entityReducerHelpers'

export interface IFetchByIdPayload {
  id: string
  force?: boolean
}

export const getByIdThunk = <T>(
  name: EntityReducers | undefined,
  getAction: apiAction<string, T>
) => {
  return createAsyncThunk(
    `${name}/fetchById`,
    async ({ id }: IFetchByIdPayload, thunkApi) => {
      const [execute] = getAction({ payload: id, thunkApi })
      const response = await execute()
      return response.data
    },
    {
      condition: ({ id, force }: IFetchByIdPayload, { getState }) => {
        const state = (getState() as RootState)[name as EntityReducers]

        const entity = state.entities[id]
        if (!force && !!entity) {
          // Already fetched or in progress, don't need to re-fetch
          return false
        }
      },
    }
  )
}

export const getByIdExtraReducers = <T>(
  thunk: AsyncThunk<T, IFetchByIdPayload, {}>,
  builder: ActionReducerMapBuilder<BaseState<T>>,
  adapter: EntityAdapter<any>
) => {
  if (thunk) {
    builder.addCase(thunk.fulfilled, (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
      adapter.upsertOne(state, payload as T)
    })

    handleLoadingAction(thunk, builder)
  }
}
