import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Collapse, withStyles } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import { parsePhoneNumber } from 'react-phone-number-input'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import pl from 'react-phone-input-2/lang/pl.json'
import 'react-phone-input-2/lib/style.css'

import { HintPopover } from '../HintPopover/HintPopover'
import useStyles, { phoneFieldStyles } from './PhoneNumberInput.style'
import {
  IPhoneNumberFormValues,
  PhoneNumberInputProps,
} from './PhoneNumberInput.types'

const PhoneNumberInput: React.FC<PropsWithChildren<PhoneNumberInputProps>> = ({
  classes,
  ...props
}) => {
  const {
    name,
    phonePrefixName,
    persistedPhoneNumber,
    persistedPrefix,
    label,
    helper,
    placeholder,
    localization,
    optional,
  } = props
  const styles = useStyles()
  const {
    setFieldValue,
    values,
    errors,
    touched,
    setTouched,
  } = useFormikContext<IPhoneNumberFormValues>()
  const [field] = useField(name)
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('')

  const isError = Boolean(errors[field.name] && touched[field.name])
  const parsedPhone = parsePhoneNumber(
    `${persistedPrefix}${persistedPhoneNumber}`
  )

  const handleOnMount = (phone: string, data: CountryData) => {
    phonePrefixName &&
      setFieldValue(
        phonePrefixName,
        `${
          persistedPrefix && persistedPrefix.length > 0
            ? persistedPrefix
            : `+${data.dialCode}`
        }`
      )
  }

  const handleOnChange = (phone: string, data: CountryData) => {
    phonePrefixName && setFieldValue(phonePrefixName, `+${data.dialCode}`)
    setFieldValue(name, phone.slice(data.dialCode.length))
  }

  const validateOnBlur = (value: string | number | boolean) => {
    setTouched({ ...touched, [field.name]: true })
  }

  useEffect(() => {
    if (parsedPhone) {
      setPhoneNumber(parsedPhone?.number)
    }
  }, [parsedPhone])

  return (
    <>
      {label && (
        <div className={styles.label}>
          <p>
            {label} {optional && <span>(opcjonalne)</span>}{' '}
            {helper && <HintPopover content={helper.content} />}
          </p>
          <Collapse in={isError}>
            <p>{errors.Phone}</p>
          </Collapse>
        </div>
      )}
      <div
        className={
          classes.root +
          (isError ? ' error' : props.disabled ? ' disabled' : '')
        }
      >
        <PhoneInput
          {...props}
          country={props.disabled ? undefined : 'pl'}
          value={phoneNumber}
          onMount={(phone, data: CountryData) => handleOnMount(phone, data)}
          onChange={(phone, data: CountryData) => handleOnChange(phone, data)}
          onBlur={() => validateOnBlur(values.Phone)}
          placeholder={placeholder ? placeholder : 'Wpisz numer telefonu'}
          localization={localization ? localization : pl}
          enableSearch
          countryCodeEditable={false}
          searchPlaceholder=""
          searchNotFound="Nie znaleziono kraju"
        />
      </div>
    </>
  )
}

export default withStyles(phoneFieldStyles)(PhoneNumberInput)
