export interface EnableMFAFormProps {
  mainEmail: string | undefined
  mainPhoneNumber: string | undefined
  mainPhonePrefix: string | undefined
  onCancel: () => void
  showErrorModal: () => void
}

export enum MFAModalView {
  INTRODUCTION = 'INTRODUCTION',
  MFA_CHANNEL = 'MFA_CHANNEL',
  PHONE_INPUT = 'PHONE_INPUT',
  EMAIL_INPUT = 'EMAIL_INPUT',
  ACIVATION_CODE = 'ACIVATION_CODE',
}

export interface InitialFieldsType {
  [key: string]: string | boolean | string[]
}
