import { useCallback, useState } from 'react'
import { useDispatchOnCustomerNumberChange } from '@core/store/customerNumberDispatcher'
import { getDocumentsByIdsSelector } from '@core/store/entity/documents/documentsSelector'
import {
  documentsGetAll,
  GetDocumentsPayload,
} from '@core/store/entity/documents/documentsThunks'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  AccountingDocumentListItem,
  AccountingDocumentsSortType,
  AccountingDocumentTypeFilter,
} from '@shared/contracts/models'
import {
  measurementPointsDataSelector,
  measurementPointsLoadingSelector,
} from '@core/store/entity/measurementPoints/measurementPointsSelectors'
import { paymentsLoadingSelector } from '@core/store/payments/paymentsSelectors'

export interface FilterType {
  measurementPoint: string | undefined
  typeOfDoc: AccountingDocumentTypeFilter
}

export interface SortType {
  sortBy: AccountingDocumentsSortType
  sortDesc: boolean
}

export const useInvoicesDataSource = () => {
  const dispatch = useAppDispatch()
  const account = useAppSelector(state => ({
    data: state.userAccount.account,
    loading: state.userAccount.loadingPending > 0,
  }))

  const measurements = useAppSelector(measurementPointsDataSelector)
  const measurementsLoading = useAppSelector(measurementPointsLoadingSelector)

  const paymentsLoading = useAppSelector(paymentsLoadingSelector)

  const [paidDocs, setPaidDocs] = useState<string[]>([])
  const [unpaidDocs, setUnpaidDocs] = useState<string[]>([])

  const defaultFilterValues = {
    measurementPoint: '',
    typeOfDoc: 'All' as AccountingDocumentTypeFilter,
  }
  const [paginationPaid, setPaginationPaid] = useState({
    numberOfPages: 0,
    docsPageStart: 0,
  })
  const [paginationUnpaid, setPaginationUnpaid] = useState({
    numberOfPages: 0,
    docsPageStart: 0,
  })

  const [filterBy, setFilterBy] = useState<FilterType>({
    measurementPoint: defaultFilterValues.measurementPoint,
    typeOfDoc: defaultFilterValues.typeOfDoc,
  })

  const [sortTypePaid, setSortTypePaid] = useState<SortType>({
    sortBy: 'DueDate',
    sortDesc: true,
  })

  const [sortTypeUnpaid, setSortTypeUnpaid] = useState<SortType>({
    sortBy: 'DueDate',
    sortDesc: false,
  })

  const [paidLoading, setPaidLoading] = useState(false)
  const [unpaidLoading, setUnpaidLoading] = useState(false)

  const unpaidDocuments: AccountingDocumentListItem[] = useAppSelector(
    getDocumentsByIdsSelector(unpaidDocs)
  )
  const paidDocuments: AccountingDocumentListItem[] = useAppSelector(
    getDocumentsByIdsSelector(paidDocs)
  )

  const PAGE_SIZE = 10

  const loadPaidInvoices = useCallback(
    (customerNumberChanged?: boolean, args?: GetDocumentsPayload) => {
      setPaidLoading(true)
      dispatch(
        documentsGetAll({
          limit: PAGE_SIZE,
          keepPrevious: !customerNumberChanged,
          status: 'Paid',
          start: args?.start ?? paginationPaid.docsPageStart,
          sortBy: args?.sortBy ?? sortTypePaid.sortBy,
          sortDesc: args?.sortDesc ?? sortTypePaid.sortDesc,
          type: args?.type ?? filterBy.typeOfDoc,
          measurementPointId:
            args?.measurementPointId ?? filterBy.measurementPoint,
        })
      )
        .then(unwrapResult)
        .then(([payload]) => {
          setPaginationPaid(prev => ({
            docsPageStart: args?.start ?? prev.docsPageStart,
            numberOfPages: payload.total
              ? Math.ceil(payload.total / PAGE_SIZE)
              : 0,
          }))
          setPaidDocs(
            payload.results?.map(result => result.accountingDocumentId ?? '') ??
              []
          )
        })
        .finally(() => setPaidLoading(false))
    },
    [dispatch, paginationPaid, sortTypePaid, filterBy]
  )

  const loadUnpaidInvoices = useCallback(
    (customerNumberChanged?: boolean, args?: GetDocumentsPayload) => {
      setUnpaidLoading(true)
      dispatch(
        documentsGetAll({
          limit: PAGE_SIZE,
          keepPrevious: !customerNumberChanged,
          status: 'Unpaid',
          start: args?.start ?? paginationUnpaid.docsPageStart,
          sortBy: args?.sortBy ?? sortTypeUnpaid.sortBy,
          sortDesc: args?.sortDesc ?? sortTypeUnpaid.sortDesc,
          type: args?.type ?? filterBy.typeOfDoc,
          measurementPointId:
            args?.measurementPointId ?? filterBy.measurementPoint,
        })
      )
        .then(unwrapResult)
        .then(([payload]) => {
          setPaginationUnpaid(prev => ({
            docsPageStart: args?.start ?? prev.docsPageStart,
            numberOfPages: payload.total
              ? Math.ceil(payload.total / PAGE_SIZE)
              : 0,
          }))
          setUnpaidDocs(
            payload.results?.map(result => result.accountingDocumentId ?? '') ??
              []
          )
        })
        .finally(() => setUnpaidLoading(false))
    },
    [dispatch, paginationUnpaid, sortTypeUnpaid, filterBy]
  )

  const loadInitialValuesAndData = useCallback(() => {
    setPaginationPaid({
      docsPageStart: 0,
      numberOfPages: 0,
    })
    setPaginationUnpaid({
      docsPageStart: 0,
      numberOfPages: 0,
    })
    setFilterBy({
      measurementPoint: '',
      typeOfDoc: AccountingDocumentTypeFilter.All,
    })
    setSortTypePaid({
      sortBy: AccountingDocumentsSortType.DueDate,
      sortDesc: true,
    })
    setSortTypeUnpaid({
      sortBy: AccountingDocumentsSortType.DueDate,
      sortDesc: false,
    })
    loadPaidInvoices(false, {
      start: 0,
      sortBy: AccountingDocumentsSortType.DueDate,
      sortDesc: true,
      type: AccountingDocumentTypeFilter.All,
      measurementPointId: '',
    })
    loadUnpaidInvoices(false, {
      start: 0,
      sortBy: AccountingDocumentsSortType.DueDate,
      sortDesc: false,
      type: AccountingDocumentTypeFilter.All,
      measurementPointId: '',
    })
  }, [loadPaidInvoices, loadUnpaidInvoices])

  useDispatchOnCustomerNumberChange(loadInitialValuesAndData)

  return {
    account,
    payments: {
      loading: paymentsLoading,
    },
    invoices: {
      unpaid: unpaidDocuments,
      paid: paidDocuments,
      paidLoading,
      unpaidLoading,
    },
    loadInvoices: {
      loadPaidInvoices,
      loadUnpaidInvoices,
    },
    filter: {
      filterBy,
      setFilterBy,
      defaultFilterValues,
    },
    sort: {
      sortTypePaid,
      setSortTypePaid,
      sortTypeUnpaid,
      setSortTypeUnpaid,
    },
    paginationState: {
      pageSize: PAGE_SIZE,
      paginationPaid,
      setPaginationPaid,
      paginationUnpaid,
      setPaginationUnpaid,
    },
    measurementPoints: {
      data: measurements,
      loading: measurementsLoading,
    },
  }
}
