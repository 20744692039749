import React, { useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
  Fade,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { format, parseISO } from 'date-fns'

import useStyles from './DevicesList.style'
import { ReactComponent as DocumentsSvg } from '@images/icons/mySettings/Documents.svg'
import { ReactComponent as DeleteSvg } from '@images/icons/mySettings/Delete.svg'
import { Button } from '@shared/components/Button'
import { TableCellContent } from '@shared/components/TableCell'
import { useAppDispatch, useAppSelector } from '@core/store/store'
import {
  getTrustedMfaDevices,
  removeTrustedMfaDevice,
} from '@core/store/trustedMfaDevices/trustedMfaDevicesThunk'
import {
  selectTrustedMfaDevices,
  trustedMfaDevicesLoadingSelector,
} from '@core/store/trustedMfaDevices/trustedMfaDevicesSelectors'

const DevicesList = () => {
  const styles = useStyles()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const devices = useAppSelector(selectTrustedMfaDevices)
  const loadingDevices = useAppSelector(trustedMfaDevicesLoadingSelector)

  useEffect(() => {
    dispatch(getTrustedMfaDevices())
  }, [dispatch])

  const handleRemoveDevices = (id: number) => {
    dispatch(removeTrustedMfaDevice({ id }))
  }

  return (
    <>
      <div className={styles.title}>
        <DocumentsSvg className={styles.icon} />
        <b>{t('MY_SETTINGS.MY_ACCOUNT_DATA.LIST_OF_TRUSTED_MFA_DEVICES')}</b>
      </div>
      <TableContainer>
        {loadingDevices ? (
          <Fade in={loadingDevices}>
            <div className={styles.loader}>
              {loadingDevices && <CircularProgress />}
            </div>
          </Fade>
        ) : devices.length < 1 ? (
          <Typography>
            {t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_DEVICES_LIST_EMPTY')}
          </Typography>
        ) : (
          <Table
            className={styles.table}
            stickyHeader
            aria-label="list of trusted mfa devices"
          >
            <TableBody>
              {devices.map((device, index) => (
                <TableRow
                  key={device.id}
                  style={index % 2 === 1 ? { backgroundColor: '#F6F9F8' } : {}}
                  tabIndex={-1}
                >
                  <TableCell className={styles.tableCell}>
                    <TableCellContent
                      header={t('MY_SETTINGS.MY_ACCOUNT_DATA.DEVICE_NAME')}
                      content={device.friendlyName}
                      opacity
                    />
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <TableCellContent
                      header={t(
                        'MY_SETTINGS.MY_ACCOUNT_DATA.DATE_OF_DEVICE_ADDITION'
                      )}
                      content={format(
                        parseISO(device.createdAtUtc),
                        'dd.MM.yyyy'
                      )}
                      opacity
                    />
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <TableCellContent
                      header={t('MY_SETTINGS.MY_ACCOUNT_DATA.VALID_UNTIL')}
                      content={format(
                        parseISO(device.expirationDateUtc),
                        'dd.MM.yyyy'
                      )}
                      opacity
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant={'text'}
                      onClick={() => handleRemoveDevices(device.id)}
                    >
                      <DeleteSvg className={styles.icon} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  )
}

export default DevicesList
