import React from 'react'
import useStyles from './MeasurementPoint.style'
import { AddressParser } from '@shared/components/AddressParser'
import { ReactComponent as MeasurementPointSvg } from '@images/icons/other/CollectionPoint.svg'

interface MeasurementPointProps {
  number?: string
  name?: string
  addressLine1?: string
  addressLine2?: string
}

export const MeasurementPoint: React.FC<MeasurementPointProps> = ({
  number,
  name,
  addressLine1,
  addressLine2,
}) => {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.icon}>
          <MeasurementPointSvg />
        </div>
        <div className={styles.collectionPoint}>
          <h5>{name ? 'Nazwa punktu poboru' : 'Numer punktu poboru'}</h5>
          <h2>{name ?? number}</h2>
        </div>
      </div>
      <p className={styles.address}>
        <AddressParser a1={addressLine1} a2={addressLine2} />
      </p>
    </div>
  )
}
