import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  titlesAndButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'left',
    textAlign: 'left',
    minHeight: '17px',
    margin: '0 0 4px 20px',
    '& p': {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      gap: '3.5px',
      marginRight: '8px',
      fontSize: 12,
      fontWeight: 800,
      color: theme.palette.text.primary,
      letterSpacing: '0.08px',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
      '& span': {
        opacity: 0.25,
        [theme.breakpoints.down('xs')]: {
          fontSize: 10,
        },
      },
    },
    '& div > p': {
      margin: 0,
      fontSize: 12,
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightBold,

      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginLeft: 0,
    },
  },
  changeButton: {
    textDecoration: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    padding: 0,
    marginTop: '2px',
    fontSize: 13,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0.09px',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}))
