import { makeStyles } from '@material-ui/core'
import {
  tableTextColor,
  borderColor,
  modalIconBackgroundColor,
} from '@mbok/styles/constants'

export const WRAPPER_WIDTH = 552

export default makeStyles(theme => ({
  wrapper: {
    width: WRAPPER_WIDTH,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.common.white,
    border: `1.7px solid ${borderColor}`,
    outline: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '92%',
      padding: theme.spacing(0, 10),
    },
  },
  backdrop: {
    backgroundColor: `${tableTextColor} !important`,
    opacity: 0.75,
  },
  content: {
    padding: theme.spacing(10, 0, 8),
    textAlign: 'center',
  },
  buttonsWrapper: {
    width: '100%',
    marginTop: theme.spacing(12),
    display: 'flex',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    '& button': {
      height: '100%',
      padding: theme.spacing(1.25, 4),
    },
  },
  noMargin: {
    margin: '0px !important',
  },
  iconWrapper: {
    backgroundColor: modalIconBackgroundColor,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(-10),
    marginBottom: theme.spacing(6),
    width: '100%',
    fontSize: 0,
  },
  textWrapper: {
    padding: theme.spacing(0, 16),
    whiteSpace: 'pre-wrap'
  },
}))
