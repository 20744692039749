import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Dialog, DialogContent } from '@material-ui/core'
import StyledButton from '../../../../shared/components/Button'
import waves from '@images/polenergiaWaves.png'
import { Link } from 'react-router-dom'
import { Animation } from '@shared/components/Animation'
import { AnimationWyslanieSrc } from '@shared/static-data/staticData'

const WIDTH = 300
const HEIGHT = 150

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      backgroundSize: '100% 35%',
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url("${waves}")`,
      [theme.breakpoints.down('xs')]: {
        backgroundImage: 'none',
      },
    },
    animation: {
      width: WIDTH,
      height: HEIGHT,
      [theme.breakpoints.down('xs')]: {
        width: WIDTH * 0.7,
        height: HEIGHT * 0.7,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: '20px 0 40px',

      '& h1': {
        margin: '0 0 16px',
        fontSize: 35,
        textAlign: 'center',
        letterSpacing: '0.29px',
        fontWeight: theme.typography.fontWeightRegular,
      },
      '& h3': {
        margin: '0 0 32px',
        fontSize: 18,
        textAlign: 'center',
        letterSpacing: '0.05px',
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightLight,
      },
      '& h2': {
        margin: '0 0 56px',
        fontSize: 25,
        textAlign: 'center',
        letterSpacing: '0.17px',
        color: theme.palette.text.secondary,
        fontWeight: 700,
        opacity: 0.55,
      },
      [theme.breakpoints.down('sm')]: {
        padding: '20px 0',
      },
      [theme.breakpoints.down('xs')]: {
        '& h1': {
          fontSize: 28,
        },
        '& h3': {
          fontSize: 16,
        },
        '& h2': {
          margin: '0 0 24px',
          fontSize: 16,
        },
      },
    },
    linkButton: {
      margin: '24px 0 0',
      width: '280px',
      '& button': {
        width: '100%',
        height: '48px',
        fontSize: 17,
        fontWeight: 700,
      },
      [theme.breakpoints.down('sm')]: {
        '& button': {
          fontSize: 15,
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '12px 0 0',
        '& button': {
          height: '38px',
          fontSize: 14,
        },
      },

      textDecoration: 'none',
      outline: 'none',
    },
  })
)

interface RegisterConfModalProps {
  show: boolean
  email: string
}

export const RegisterConfirmationModal: React.FC<RegisterConfModalProps> = ({
  show,
  email,
}) => {
  const styles = useStyles()
  const [isOpen, setIsOpen] = useState(show)

  return (
    <Dialog
      aria-describedby="form-"
      open={isOpen}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      classes={{ paper: styles.contentWrapper }}
    >
      <DialogContent className={styles.content}>
        <Animation src={AnimationWyslanieSrc} customClass={styles.animation} />
        <h1>Rejestracja zakończona!</h1>
        <h3>Wysłaliśmy Ci email z linkiem aktywacyjnym na adres:</h3>
        <h2>{email}</h2>
        <Link to="/" className={styles.linkButton}>
          <StyledButton
            color="primary"
            variant="contained"
            onClick={() => setIsOpen(false)}
          >
            Przejdź do logowania
          </StyledButton>
        </Link>
      </DialogContent>
    </Dialog>
  )
}

export default RegisterConfirmationModal
