import React, { useCallback, useEffect, useState } from 'react'
import { Popover } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useApi } from '@core/apiClient/apiClient'
import { ReactComponent as BtnSvg } from '@images/icons/fabBOK/Btn.svg'
import useStyles from './CustomerServiceOfficeFab.style'
import { CustomerServiceOffice } from '@shared/contracts/customerServiceOffice'
import { CONTACT_PHONE, SERVICE_PHONE, EMAIL } from './strings'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'
import { isMobile } from 'react-device-detect'

export const CustomerServiceOfficeFab: React.FC = () => {
  const { t } = useTranslation()

  const styles = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [showData, setShowData] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(showData ? null : event.currentTarget)
  }

  // eslint-disable-next-line
  const [index, setIndex] = useState(0)
  const [contactDetails, setContactDetails] = useState<
    CustomerServiceOffice[]
  >()
  const [executeGetContactDetails] = useApi<CustomerServiceOffice[]>({
    config: {
      method: 'get',
      url: 'Content/customerServiceOffice',
    },
  })

  const getContactDetails = useCallback(async () => {
    try {
      const response = await executeGetContactDetails()
      const data = response.data
      setContactDetails(data)
    } catch {}
  }, [executeGetContactDetails])

  const copyDataToClipboard = useCallback(
    (name: string, dataToCopy: string) => {
      copy(dataToCopy) &&
        enqueueSnackbar(`${name} ${t('BOK_FAB.HAS_BEEN_COPIED')}`, {
          variant: 'default',
          preventDuplicate: true,
          autoHideDuration: 2000,
        })
    },
    [enqueueSnackbar, t]
  )

  useEffect(() => {
    if (!contactDetails) {
      getContactDetails()
    }
  }, [showData, getContactDetails, contactDetails])

  const { email, phone, servicePhone } =
    (contactDetails && contactDetails[index]) ?? {}

  return (
    <div>
      <button
        className={styles.button}
        onClick={e => {
          handleClick(e)
          setShowData(prev => !prev)
        }}
      >
        <div className={styles.icon}>
          <BtnSvg />
        </div>
      </button>
      <Popover
        classes={{ paper: styles.paper }}
        anchorEl={anchorEl}
        onClose={() => {
          setShowData(false)
        }}
        open={showData && !!contactDetails}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <>
          {contactDetails && (
            <div className={styles.container}>
              <div className={styles.title}>
                <CloseIcon
                  className={styles.closeIcon}
                  onClick={() => setShowData(false)}
                />
              </div>
              <div className={styles.contactContent}>
                <div className={styles.officeName}>
                  <h2>{CONTACT_PHONE}</h2>
                  <h3>
                    {isMobile ? (
                      <a href={`tel:${phone}`}>{phone}</a>
                    ) : (
                      <>
                        <span>{phone}</span>
                        {phone && (
                          <FileCopyIcon
                            onClick={() => {
                              copyDataToClipboard(CONTACT_PHONE, phone)
                            }}
                          />
                        )}
                      </>
                    )}
                  </h3>
                </div>
                <div className={styles.officeName}>
                  <h2>{SERVICE_PHONE}</h2>
                  <h3>
                    {isMobile ? (
                      <a href={`tel:${servicePhone}`}>{servicePhone}</a>
                    ) : (
                      <>
                        <span>{servicePhone}</span>
                        {servicePhone && (
                          <FileCopyIcon
                            onClick={() => {
                              copyDataToClipboard(SERVICE_PHONE, servicePhone)
                            }}
                          />
                        )}
                      </>
                    )}
                  </h3>
                </div>
                <div className={styles.officeName}>
                  <h2>{EMAIL}</h2>
                  <h3>
                    <a href={`mailto:${email}`}>{email}</a>
                    {!isMobile && email && (
                      <FileCopyIcon
                        onClick={() => {
                          copyDataToClipboard(EMAIL, email)
                        }}
                      />
                    )}
                  </h3>
                </div>
              </div>
            </div>
          )}
        </>
      </Popover>
    </div>
  )
}
