export const isRejected = (value: string) => value.includes('rejected')

export enum fields {
  EMAIL = 'Email',
  CORRESPONDENCE_EMAIL = 'CorrespondenceEmail',
  CONTACT_PHONE = 'ContactPhone',
  MAIN_ADDRESS_LINE_1 = 'MainAddressLine1',
  MAIN_ADDRESS_LINE_2 = 'MainAddressLine2',
  CORRESPONDENCE_ADDRESS_LINE_1 = 'CorrespondenceAddressLine1',
  CORRESPONDENCE_ADDRESS_LINE_2 = 'CorrespondenceAddressLine2',
  AGREEMENT_ADDRESS_LINE_1 = 'AgreementAddressLine1',
  AGREEMENT_ADDRESS_LINE_2 = 'AgreementAddressLine2',
  AGREEMENT_EMAIL = 'AgreementEmail',
}

export const trimString = (value: string) => value.replace(/ /g, '')
