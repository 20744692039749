import React from 'react'
import { useLogout } from '@core/auth/useLogout'
import { makeStyles, Theme } from '@material-ui/core'
import { NavExpandMenu } from './NavExpandMenu'
import { tableTextColor } from '@mbok/styles/constants'
import { ReactComponent as LogoutSvg } from '@images/icons/Logout.svg'

const useStyles = makeStyles((theme: Theme) => ({
  logout: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(6, 0),
  },
  clickLogout: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    '& svg': {
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
    },
    '& span': {
      opacity: 0.8,
      fontSize: 17,
      color: tableTextColor,
      letterSpacing: 0.27,
      fontWeight: theme.typography.fontWeightBold,
      transition: 'opacity 0.2s ease-in-out',
    },
    '&:hover': {
      '& span': {
        opacity: 1,
      },
    },
  },
  button: {
    cursor: 'pointer',
    height: 24,
    '& svg': {
      fill: theme.palette.primary.dark,
      '&:hover': {
        fill: theme.palette.primary.main,
      },
    },
  },
}))

export const UserMenu = () => {
  const styles = useStyles()
  const logout = useLogout()

  return (
    <NavExpandMenu
      clickElementRender={(expandIcon, clickHandler) => {
        return (
          <div className={styles.button} onClick={clickHandler}>
            {expandIcon}
          </div>
        )
      }}
    >
      <div className={styles.logout}>
        <div
          className={styles.clickLogout}
          onClick={() => {
            logout()
          }}
        >
          <LogoutSvg />
          <span>Wyloguj</span>
        </div>
      </div>
    </NavExpandMenu>
  )
}
