import { makeStyles, Theme } from '@material-ui/core'

export const SCROLL_SIZE = 8

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  track: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 1,
  },
  trackHorizontal: {
    minWidth: '100%',
    bottom: 0,
  },
  thumb: {
    borderRadius: 8,
    background: 'rgba(0, 0, 0, 0.2)',
  },
  trackVertical: {
    right: 0,
  },
}))
