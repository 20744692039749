import React from 'react'
import { ReactComponent as ProfileSvg } from '@images/icons/myCenter/Profile.svg'
import { SummaryCardWrapper } from './SummaryCardWrapper'
import { Account, AccountType } from '@shared/contracts/models'

interface MyAccountCardProps {
  account?: Account
  loading?: boolean
}

export const MyAccountCard: React.FC<MyAccountCardProps> = ({
  account,
  loading,
}) => {
  const { Commercial } = AccountType
  return (
    <SummaryCardWrapper
      title="Moje konto"
      Icon={ProfileSvg}
      rows={[
        {
          header: 'Numer płatnika',
          content: account?.customerNumber ?? '',
        },
        {
          header: account?.type === Commercial ? 'Firma' : 'Pan / Pani',
          content: account?.name,
        },
      ]}
      loading={loading}
    ></SummaryCardWrapper>
  )
}
