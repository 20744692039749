export const primaryColor = '#12BEAB'
export const lightColor = '#12BEAB40'
export const warningColor = '#E9A711'
export const errorColor = '#FFABAE'
export const subHeadColor = '#F2F6F5'
export const bgColor = '#FBFBFB'
export const borderColor = '#E2E4E3'
export const textPrimaryColor = '#1D4874'
export const textSecondaryColor = '#3A3A3A'
export const errorRedColor = '#D22454'
export const headerBg = '#F6F9F8'
export const sidePadding = '28px'
export const backdropBg = 'rgba(17, 41, 65, 0.75)'
export const tableTextColor = '#112941'
export const autocompleteInputColor = 'rgba(17,41,65,0.55)'
export const lightGrey = '#F2F6F5'
export const tableAmountColor = '#07617B'
export const modalIconBackgroundColor = 'rgba(71, 169, 160, 0.2)'
export const textFieldBorderColor = '#D3CFCF'
export const autocompleteBackgroundColor = '#F9F9F9'
export const secondaryColor = '#48A9A1'
export const navHeight = '60px'
export const inputBackgroundColor = '#F7F7F7'

export const contentSidePadding = (top: number, bottom?: number) => ({
  padding: `${top}px ${sidePadding} ${bottom ?? top}px`,
})

export type StatusColor = 'default' | 'warning' | 'error'

export const getStatusColor = (status: StatusColor) => {
  switch (status) {
    case 'default':
      return primaryColor
    case 'warning':
      return warningColor
    case 'error':
      return errorColor
  }
}
