import { RootState } from '@core/store/rootReducer'
import { createSelector } from '@reduxjs/toolkit'

export const trustedMfaDevicesSelector = (state: RootState) =>
  state.trustedMfaDevices

export const trustedMfaDevicesLoadingSelector = createSelector(
  trustedMfaDevicesSelector,
  state => state.loadingPending > 0
)

export const selectTrustedMfaDevices = createSelector(
  trustedMfaDevicesSelector,
  state => state.devices
)
