import { ModalType } from '@core/store/ui/ui'
import {
  AnimationNadplataSrc,
  AnimationBladSrc,
  AnimationSukcesSrc,
  AnimationOczekiwaniePlatnoscSrc,
  AnimationDokonanaPlatnoscSrc,
  AnimationWyslanieSrc,
} from '@shared/static-data/staticData'
import info from './Info.svg'

export type ModalIconTypes = Extract<
  ModalType,
  | 'error'
  | 'info'
  | 'overpay'
  | 'environment'
  | 'success'
  | 'paymentWait'
  | 'paymentDone'
>

const images: Record<ModalIconTypes, string> = {
  environment: AnimationWyslanieSrc, // tutaj będzie e-faktura z animacją
  error: AnimationBladSrc,
  info: info,
  overpay: AnimationNadplataSrc,
  success: AnimationSukcesSrc,
  paymentWait: AnimationOczekiwaniePlatnoscSrc,
  paymentDone: AnimationDokonanaPlatnoscSrc,
}

export default images
