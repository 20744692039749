import {
  ThunkDispatch,
  AnyAction,
  AsyncThunk,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit'
import { ApiClientReturnType } from '@core/apiClient/apiClient'
import { LoadingState } from '../entityReducerHelpers'

export interface IThunkApi {
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
  getState: () => unknown
}

export interface IApiActionProps<P> {
  payload?: P
  thunkApi: IThunkApi
}

export type apiAction<P, T> = ({
  payload,
  thunkApi,
}: IApiActionProps<P>) => ApiClientReturnType<T>

export const handleLoadingAction = (
  thunk: AsyncThunk<any, any, any>,
  builder: ActionReducerMapBuilder<LoadingState>
) => {
  builder.addCase(thunk.pending, state => {
    state.loadingPending++
  })

  builder.addCase(thunk.rejected, state => {
    state.loadingPending > 0 && state.loadingPending--
  })
}
