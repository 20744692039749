import { OidcSecure } from '@axa-fr/react-oidc-redux'
import { useAppSelector } from '@core/store/store'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import { Animation } from '@shared/components/Animation'
import { AnimationScreenSrc } from '@shared/static-data/staticData'
import React, { useEffect, useState } from 'react'
import { Route } from 'react-router'
import { CustomAuthenticatingComponent } from './CustomAuthenticationgComponent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    animation: {
      width: '100vw',
      height: '100vh',
    },
  })
)

export interface IProtectedRouteProps {
  path: string
  component: React.FC<{}>
}

export const getProtectedComponent = (Component: React.FC<{}>) => () => {
  const styles = useStyles()
  const { user } = useAppSelector(state => state.oidc)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const timeout = setTimeout(
      () => {
        setLoading(false)
      },
      process.env.NODE_ENV === 'development' ? 0 : 3000
    )

    return () => {
      clearTimeout(timeout)
    }
  }, [user])

  return (
    <>
      {!loading || user ? (
        <div style={{ display: !user ? 'none' : 'visible' }}>
          <OidcSecure authenticating={CustomAuthenticatingComponent}>
            <Component />
          </OidcSecure>
        </div>
      ) : null}
      {!user ? (
        <Animation src={AnimationScreenSrc} customClass={styles.animation} />
      ) : null}
    </>
  )
}

export const ProtectedRoute: React.FC<IProtectedRouteProps> = ({
  component,
  path,
}) => {
  return (
    <>
      <Route component={getProtectedComponent(component)} path={path} />
    </>
  )
}
