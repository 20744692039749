import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { News, NewsPagedResponse } from '@shared/contracts/models'
import { EntityReducers } from '../EntityReducers'
import {
  getBaseState,
  getEntityAdapterReducers,
} from '../helpers/entityReducerHelpers'
import { getAllExtraReducers } from '../helpers/thunks/getAllThunk'
import { GetNewsPayload, newsGetAll } from './newsThunks'

const entityAdapter = createEntityAdapter<News>()

export const newsSlice = createSlice({
  name: EntityReducers.news,
  initialState: {
    ...getBaseState(entityAdapter),
  },
  reducers: {
    ...getEntityAdapterReducers<News>(entityAdapter),
  },
  extraReducers: builder => {
    getAllExtraReducers<News, GetNewsPayload, NewsPagedResponse>(
      newsGetAll,
      builder,
      entityAdapter,
      response => response.results ?? []
    )
  },
})
