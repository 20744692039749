import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

const BTN_WIDTH = 200
const BTN_HEIGHT = 40
const ICON_SIZE = 20
const NEWS_CONTENT_HEIGHT = 64

export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(8),
    },
    upperSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(4),
      '& button': {
        width: BTN_WIDTH,
        height: BTN_HEIGHT,
        fontSize: 14,
        border: '2.7px solid #DAD9D9',
        color: tableTextColor,
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& button': {
          width: '100%',
        },
      },
    },
    icon: {
      marginRight: theme.spacing(5),
      width: ICON_SIZE,
      height: ICON_SIZE,
      [theme.breakpoints.down('xs')]: {
        marginRight: theme.spacing(3),
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      color: tableTextColor,
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    left: {
      '& p': {
        margin: 0,
        opacity: 0.55,
        fontSize: 14,
        letterSpacing: '0.60px',
      },
      '& h1': {
        margin: 0,
        fontSize: 17,
        letterSpacing: '0.64px',
      },
    },
    content: {      
      height: NEWS_CONTENT_HEIGHT,
      fontSize: 14,
      letterSpacing: '0.61px',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 3,
      overflow: 'hidden',
    },
  })
)
