import React from 'react'
import { Animation } from '@shared/components/Animation'
import { AnimationScreenSrc } from '@shared/static-data/staticData'
import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    animation: {
      width: '100vw',
      height: '100vh',
    },
  })
)

export const CustomAuthenticatingComponent = () => {
  const styles = useStyles()
  return <Animation src={AnimationScreenSrc} customClass={styles.animation} />
}
