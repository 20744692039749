import { useApi } from '@core/apiClient/apiClient'
import { getFileName } from '@shared/utils/fileNameFromContentDisposition'

import { saveAs } from 'file-saver'
import { useCallback, useState } from 'react'

export const useSavePdf = () => {
  const [get] = useApi<Blob>({
    omitCustomerNumber: true,
    omitConsents: true,
    config: {
      responseType: 'blob',
    },
  })

  const [downloadingUrls, setDownloadingUrls] = useState<{
    [url: string]: Boolean
  }>({})

  const setDownloadingState = useCallback(
    (url: string, state: boolean) => {
      setDownloadingUrls({
        ...downloadingUrls,
        [url]: state,
      })
    },
    [downloadingUrls]
  )

  return useCallback(
    (
      url: string,
      downloadBtnRenderer: (
        loading: boolean,
        saveFunction: () => void
      ) => React.ReactNode,
      documentName?: string
    ) => {
      const saveFn = () => {
        setDownloadingState(url, true)
        get({
          url,
        }).then(response => {
          let fileName = documentName
          if (!documentName) {
            fileName = getFileName(response)
          }
          saveAs(response.data, fileName + '.pdf')
          setDownloadingState(url, false)
        })
      }

      return downloadBtnRenderer(!!downloadingUrls[url], saveFn)
    },
    [downloadingUrls, get, setDownloadingState]
  )
}
