import { makeStyles } from '@material-ui/core'

const WRAPPER_HEIGHT = 42

export default makeStyles(theme => ({
  wrapper: {
    height: WRAPPER_HEIGHT,
    width: 'auto',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 17,
    lineHeight: '21px',
    letterSpacing: 0.6,
    fontWeight: 600,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    transition: 'color 0.2s ease-in-out',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}))
