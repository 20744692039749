import { forEachObjIndexed } from 'ramda'
import { AnyAction, Middleware } from 'redux'
import { RootState } from '../rootReducer'
import { AppDispatch } from '../store'

export interface IActionsHandlerCallback {
  action: AnyAction
  dispatch: AppDispatch
  state: RootState
}

export interface IActionsHandler {
  actionTypes: string[]
  callback: (response: IActionsHandlerCallback) => void
}

export class actionsInterceptorMiddleware {
  static actionHandlers: { [id: string]: IActionsHandler } = {}

  static addManyActionsCallback(actionsHandlers: IActionsHandler[]) {
    actionsHandlers.forEach(actionsHandler => {
      actionsInterceptorMiddleware.addActionsCallback(actionsHandler)
    })
  }

  static addActionsCallback(actionsHandler: IActionsHandler) {
    const handlerId = '_' + Math.random().toString(36).substr(2, 9)

    this.actionHandlers[handlerId] = actionsHandler

    return () => actionsInterceptorMiddleware.removeListener(handlerId)
  }

  static addActionsPromise(actionTypes: string[]) {
    let cleanAction: ReturnType<typeof actionsInterceptorMiddleware.addActionsCallback>
    return new Promise(resolve => {
      cleanAction = this.addActionsCallback({
        actionTypes,
        callback: resolve,
      })
    }).then(result => {
      cleanAction()
      return result as IActionsHandlerCallback
    })
  }

  static removeListener(key: string) {
    delete actionsInterceptorMiddleware.actionHandlers[key]
  }

  static middleWareFn: Middleware = store => next => action => {
    next(action)
    forEachObjIndexed(actionHandler => {
      if (actionHandler.actionTypes.includes(action.type)) {
        actionHandler.callback({
          action,
          dispatch: store.dispatch,
          state: store.getState(),
        })
      }
    }, actionsInterceptorMiddleware.actionHandlers)

    return
  }
}
