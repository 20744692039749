import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import DetailsCardWrapper from '@shared/components/DetailsCard'
import { ReactComponent as ContractSvg } from '@images/icons/myCenter/Contract.svg'
import { ReactComponent as ProfileSvg } from '@images/icons/myCenter/Profile.svg'
import { ReactComponent as CreditCardSvg } from '@images/icons/mySettings/CreditCard.svg'
import { ReactComponent as DocumentsSvg } from '@images/icons/mySettings/Documents.svg'
import { ReactComponent as EmailSvg } from '@images/icons/mySettings/Email.svg'
import { ReactComponent as MarketingSvg } from '@images/icons/mySettings/Marketing.svg'
import { Button } from '@shared/components/Button'
import Modal from '@shared/components/modals/Modal'
import useStyles from './MySettingsPage.style'
import { Trans, useTranslation } from 'react-i18next'
import { useMySettingsDataSource } from './mySettingsDataSource'
import { Setting, DocumentLink } from './components'
import { fields, isRejected, trimString } from './MySettingsPage.utils'
import { Field } from './components/forms/EditFieldForm/EditFieldForm'
import EditFieldForm from './components/forms/EditFieldForm'
import {
  validateEmailForm,
  validateAddressForm,
  validatePhoneForm,
  IDoubleFieldErrors,
  IDoubleFieldValues,
  ISingleFieldErrors,
  ISingleFieldValues,
} from './components/forms/EditFieldForm/EditFieldForm.utils'
import SwitchForm from './components/forms/SwitchForm'
import ChangePasswordForm from './components/forms/ChangePasswordForm'
import { useAppDispatch } from '@core/store/store'
import { useSavePdf } from '@shared/hooks/pdfSaver'
import {
  showModal,
  addSavedFormField,
  removeSavedFormField,
} from '@core/store/ui/actions'
import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit'
import Toggler from '@shared/components/Toggler'
import ids from './ids'
import {
  updateCorrespondenceAddress,
  updateCorrespondenceEmail,
  updateMainAddress,
  updatePhoneNumber,
  updatePassword,
} from '@core/store/user/userAccountThunks'
import { updateAgreementCorrespondenceAddress } from '@core/store/entity/userAgreements/userAgreementsThunks'
import HintPopover from '@shared/components/HintPopover'
import { isEmpty } from 'ramda'
import { AddressParser } from '@shared/components/AddressParser'
import EditAddressForm from './components/forms/EditAddressForm'
import { validateAddressForm as validateAddress } from './components/forms/EditAddressForm/EditAddressForm.utils'

import {
  getUniqueEmails,
  isEmailAssignedToAgreements,
} from '@shared/utils/agreements'
import SelectActivationPointForm from '@shared/components/forms/eInvoice/SelectActivationPointForm'
import {
  getMeasurementPointsFilteredByAgreements,
  stringifyMeasurementPoint,
  getMeasurementPointsFilteredByEmail,
  getMeasurementPointsNumbers,
} from '@shared/utils/measurementPoints'
import EInvoiceSettings from '@shared/components/forms/eInvoice/EInvoiceSettings'
import ActivatedEInvoices from '@shared/components/forms/eInvoice/ActivatedEInvoices'
import useEInvoiceStyles from '@shared/components/forms/eInvoice/style'
import useMFAFormStyles from './components/forms/EnableMFAForm/EnableMFAForm.style'
import useUpdatedInvoicesStyles from './components/forms/UpdateLinkedInvoices/UpdateLinkedInvoices.style'
import ConfirmActivationPointForm from '@shared/components/forms/eInvoice/ConfirmActivationPointForm'
import { InvoiceType } from '@shared/contracts/invoiceType'
import {
  explodeAddress,
  removeDelimiterFromAddressLine1,
} from '@shared/utils/addressParser'
import {
  refreshContactPhoneNumber,
  refreshMainAddress,
  refreshCorrespondenceAddress,
  refreshCorrespondenceEmail,
} from '@core/store/user/actions'
import { getTrustedMfaDevices } from '@core/store/trustedMfaDevices/trustedMfaDevicesThunk'
import { userAgreementsActions } from '@core/store/entity/userAgreements/userAgreements'
import UpdateLinkedInvoices from './components/forms/UpdateLinkedInvoices'
import { updateMeasurementPointsEmail } from '@core/store/entity/measurementPoints/measurementPointsThunks'
import DevicesList from './components/DevicesList/DevicesList'
import EnableMFAForm from './components/forms/EnableMFAForm'
import ModifyMFAForm from './components/forms/ModifyMFAForm'
import EditMainPhoneForm from './components/forms/EditMainPhoneForm'

const MY_DATA_MIN_HEIGHT = 166
const PAYER_MIN_HEIGHT = 230
const INVOICE_MIN_HEIGHT = 166
const NOTIFICATIONS_MIN_HEIGHT = 260
const DOCUMENTS_MIN_HEIGHT = 166

interface ResponseWithError {
  payload: unknown
  type: string
  error?: {
    message: string
  }
}

export enum Modals {
  SelectActivationPointUpdate,
  ConfirmActivationPointUpdate,
  NewContactEmail,
}

export interface NewFieldsValue {
  field1?: string
  field2?: string
}

export const MySettingsPage: React.FC = () => {
  const initialFieldState: Omit<Field, 'value' | 'onChange'> = {
    label: '',
    name: '',
  }
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isChangePasswordFormOpen, setIsChangePasswordFormOpen] = useState(
    false
  )
  const [isEnableMfaFormOpen, setIsEnableMfaFormOpen] = useState<boolean>(false)
  const [isModifyMfaFormOpen, setIsModifyMfaFormOpen] = useState<boolean>(false)
  const [isEditMainPhoneFormOpen, setIsEditMainPhoneFormOpen] = useState<
    boolean
  >(false)
  const [field1, setField1] = useState<Omit<Field, 'value' | 'onChange'>>(
    initialFieldState
  )
  const [field2, setField2] = useState<
    Omit<Field, 'value' | 'onChange'> | undefined
  >(undefined)
  const [field1Value, setField1Value] = useState('')
  const [field2Value, setField2Value] = useState('')
  const [agreementIndex, setAgreementIndex] = useState(0)
  // eslint-disable-next-line
  const [agreementEmailIndex, setAgreementEmailIndex] = useState(-1)
  const [emailToEdit, setEmailToEdit] = useState<string | undefined>(undefined)
  const [newEmail, setNewEmail] = useState<string | undefined>(undefined)
  const [title, setTitle] = useState('')
  const [companyInfo, setCompanyInfo] = useState<string | undefined>(undefined)
  const [currentFieldsError, setCurrentFieldsError] = useState<
    string | undefined
  >(undefined)
  const [currentPasswordError, setCurrentPasswordError] = useState<
    string | undefined
  >(undefined)
  const [isAddressFormOpen, setIsAddressFormOpen] = useState(false)

  const styles = useStyles()
  const eInvoiceStyles = useEInvoiceStyles()
  const updateLinkedInvoicesStyles = useUpdatedInvoicesStyles()
  const mfaFormStyles = useMFAFormStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const {
    account: { data },
    agreements: { activeAgreements, eInvoiceAgreements, loading },
    notificationSwitches,
    marketingSwitches,
    measurementPoints,
    policyDocuments,
    isErrorModalOpen,
    alreadySavedFormFields,
    marketingDocuments,
    modals,
    correspondenceEmail,
    activationPoint,
    api: { executeUpdateEInvoiceEmail },
    address: { isAddressInDatabase, setIsAddressInDatabase },
  } = useMySettingsDataSource()
  const savePdf = useSavePdf()

  const currentAgreement = activeAgreements[agreementIndex]
  const currAgreementPaperInvoice = currentAgreement?.invoiceType === 'Paper'

  const closeForm = () => {
    setIsFormOpen(false)
    setIsAddressFormOpen(false)
  }

  const clearEInvoiceState = React.useCallback(() => {
    modals.setOpenModalKey(undefined)
    measurementPoints.setSelectedMeasurementPoints([])
    correspondenceEmail.setCorrespondenceEmail(undefined)
  }, [modals, measurementPoints, correspondenceEmail])

  const getCorrespondenceEmail = React.useCallback((): string => {
    return (
      correspondenceEmail.correspondenceEmail ??
      currentAgreement?.emailAddresses[0] ??
      ''
    )
  }, [correspondenceEmail.correspondenceEmail, currentAgreement])

  const getModalSubtitle = useCallback(
    (id?: string, newFieldsValue?: NewFieldsValue) => {
      const modalSubtitleKey = 'MODAL_MESSAGES.CONFIRMATION.'
      switch (id) {
        case ids.USER_CONTACT_EMAIL:
          return (
            <Trans i18nKey={modalSubtitleKey + 'CONTACT_EMAIL'}>
              We changed your contact e-mail to:
              <br />
              {{ email: newFieldsValue?.field1 }}
            </Trans>
          )
        case ids.USER_CONTACT_PHONE:
          return (
            <Trans i18nKey={modalSubtitleKey + 'CONTACT_PHONE'}>
              We changed your contact phone number to:
              <br />
              {{ number: newFieldsValue?.field1 }}
            </Trans>
          )
        case ids.USER_CORRESPONDENCE_ADDRESS:
          return (
            <Trans i18nKey={modalSubtitleKey + 'CORRESPONDENCE_ADDRESS'}>
              We changed your correspondence address to:
              <br />
              {{
                line1: removeDelimiterFromAddressLine1(newFieldsValue?.field1),
              }}
              <br />
              {{ line2: newFieldsValue?.field2 }}
            </Trans>
          )
        case ids.USER_MAIN_ADDRESS:
          return (
            <Trans i18nKey={modalSubtitleKey + 'MAIN_ADDRESS'}>
              We changed your main address to:
              <br />
              {{
                line1: removeDelimiterFromAddressLine1(newFieldsValue?.field1),
              }}
              <br />
              {{ line2: newFieldsValue?.field2 }}
            </Trans>
          )
        case ids.SETTINGS_INVOICE_EMAIL:
          return (
            <Trans i18nKey={modalSubtitleKey + 'INVOICE_EMAIL'}>
              We changed your e-invoice e-mail address to:
              <br />
              {{ email: newFieldsValue?.field1 }}
            </Trans>
          )
        default:
          return id && !!id.match(ids.SETTINGS_INVOICE_ADDRESS) ? (
            <Trans i18nKey={modalSubtitleKey + 'INVOICE_ADDRESS'}>
              We changed your invoice address to:
              <br />
              {{
                line1: removeDelimiterFromAddressLine1(newFieldsValue?.field1),
              }}
              <br />
              {{ line2: newFieldsValue?.field2 }}
            </Trans>
          ) : (
            t('MODAL_MESSAGES.REQUEST_ACCEPTED')
          )
      }
    },
    [t]
  )

  const isConfirmationNeeded = useMemo(() => {
    if (isAddressInDatabase !== undefined) {
      return !isAddressInDatabase
    }
    return false
  }, [isAddressInDatabase])

  const successModalTitle = useMemo(
    (id?: string) => {
      if (id === ids.SETTINGS_INVOICE_EMAIL) {
        return t('MY_CONTRACTS.MODAL.THANKS_FOR_CHANGE')
      }
      return t('MODAL_MESSAGES.SUCCESS')
    },
    [t]
  )

  const getModalText = useCallback(
    (linkInvoices?: boolean, confirmationStillPendng?: boolean) => {
      if (linkInvoices !== undefined) {
        return linkInvoices
          ? t('MODAL_MESSAGES.LINKED_E_INVOICES_UPDATED')
          : t('MODAL_MESSAGES.LINKED_E_INVOICES_NOT_UPDATED')
      }
      return t(
        isConfirmationNeeded || confirmationStillPendng === true
          ? 'MODAL_MESSAGES.IN_THREE_DAYS_WILL_CHANGE'
          : ''
      )
    },
    [isConfirmationNeeded, t]
  )

  const showSuccessModal = useCallback(
    (
      id?: string,
      newFieldsValue?: NewFieldsValue,
      linkInvoices?: boolean,
      confirmationStillPendng?: boolean
    ) => {
      if (!isConfirmationNeeded && confirmationStillPendng !== true) {
        dispatch(removeSavedFormField({ id: id }))
      }
      dispatch(
        showModal({
          type: 'success',
          title: successModalTitle,
          subtitle:
            isConfirmationNeeded || confirmationStillPendng
              ? t('MODAL_MESSAGES.REQUEST_ACCEPTED')
              : getModalSubtitle(id, newFieldsValue),
          text: getModalText(linkInvoices, confirmationStillPendng),
          onCancel: () => setAgreementEmailIndex(-1),
        })
      )
      setIsAddressInDatabase(undefined)
    },
    [
      dispatch,
      successModalTitle,
      getModalText,
      t,
      getModalSubtitle,
      isConfirmationNeeded,
      setIsAddressInDatabase,
    ]
  )

  const isAddressId = useCallback((id: string) => {
    const addresses = [ids.USER_CORRESPONDENCE_ADDRESS, ids.USER_MAIN_ADDRESS]
    if (addresses.includes(id)) {
      return true
    }

    return !!id.match(ids.SETTINGS_INVOICE_ADDRESS)
  }, [])

  const openForm = useCallback(
    (id: string) => {
      const field = alreadySavedFormFields.find(f => f.id === id)
      if (field) {
        return showSuccessModal(id, undefined, undefined, true)
      }
      if (isAddressId(id)) {
        return setIsAddressFormOpen(true)
      }
      setIsFormOpen(true)
    },
    [alreadySavedFormFields, showSuccessModal, isAddressId]
  )

  const onFieldSet = ({
    name,
    initialValue = '',
    isSecond = false,
  }: {
    name: string
    initialValue?: string
    isSecond?: boolean
  }) => {
    const val = !!initialValue ? initialValue : ''
    if (isSecond) {
      setField2Value(val)
    } else {
      setField1Value(val)
    }
    setField2(undefined)
    switch (name) {
      case fields.CONTACT_PHONE:
        setTitle(
          t('MY_SETTINGS.CHANGE_SETTING', {
            setting: t('MY_SETTINGS.MY_PAYER_DATA.CONTACT_PHONE_NUMBER'),
          })
        )
        return setField1({
          label: t('MY_SETTINGS.MY_ACCOUNT_DATA.PHONE'),
          name: fields.CONTACT_PHONE,
        })
      case fields.CORRESPONDENCE_EMAIL:
        setTitle(
          t('MY_SETTINGS.CHANGE_SETTING', {
            setting: t('MY_SETTINGS.MY_PAYER_DATA.CONTACT_EMAIL_ADDRESS'),
          })
        )
        return setField1({
          label: t('MY_SETTINGS.MY_ACCOUNT_DATA.EMAIL'),
          name: fields.CORRESPONDENCE_EMAIL,
        })
      case fields.CORRESPONDENCE_ADDRESS_LINE_1:
        setTitle(
          t('MY_SETTINGS.CHANGE_SETTING', {
            setting: t('MY_SETTINGS.MY_PAYER_DATA.CORRESPONDENCE_ADDRESS'),
          })
        )
        setCompanyInfo(undefined)
        return setField1({
          label: t('MY_SETTINGS.MY_PAYER_DATA.ADDRESS_LINE_1'),
          name: fields.CORRESPONDENCE_ADDRESS_LINE_1,
        })
      case fields.CORRESPONDENCE_ADDRESS_LINE_2:
        return setField2({
          label: t('MY_SETTINGS.MY_PAYER_DATA.ADDRESS_LINE_2'),
          name: fields.CORRESPONDENCE_ADDRESS_LINE_2,
        })
      case fields.MAIN_ADDRESS_LINE_1:
        setTitle(
          t('MY_SETTINGS.CHANGE_SETTING', {
            setting: t('MY_SETTINGS.MY_PAYER_DATA.BASE_ADDRESS'),
          })
        )
        setCompanyInfo(
          data?.type === 'Individual'
            ? undefined
            : t('MY_SETTINGS.MY_PAYER_DATA.BASE_ADDRESS_INFO_COMP')
        )
        return setField1({
          label: t('MY_SETTINGS.MY_PAYER_DATA.ADDRESS_LINE_1'),
          name: fields.MAIN_ADDRESS_LINE_1,
        })
      case fields.MAIN_ADDRESS_LINE_2:
        return setField2({
          label: t('MY_SETTINGS.MY_PAYER_DATA.ADDRESS_LINE_2'),
          name: fields.MAIN_ADDRESS_LINE_2,
        })
      case fields.AGREEMENT_ADDRESS_LINE_1:
        setTitle(
          t('MY_SETTINGS.CHANGE_SETTING', {
            setting: t('MY_SETTINGS.CONTRACT_DATA.INVOICE_ADDRESS'),
          })
        )
        setCompanyInfo(undefined)
        return setField1({
          label: t('MY_SETTINGS.MY_PAYER_DATA.ADDRESS_LINE_1'),
          name: fields.AGREEMENT_ADDRESS_LINE_1,
        })
      case fields.AGREEMENT_ADDRESS_LINE_2:
        return setField2({
          label: t('MY_SETTINGS.MY_PAYER_DATA.ADDRESS_LINE_2'),
          name: fields.AGREEMENT_ADDRESS_LINE_2,
        })

      case fields.AGREEMENT_EMAIL:
        setTitle(
          t('MY_SETTINGS.CHANGE_SETTING', {
            setting: t('MY_SETTINGS.CONTRACT_DATA.INVOICE_EMAIL_ADDRESS'),
          })
        )
        return setField1({
          label: t('MY_SETTINGS.CONTRACT_DATA.INVOICE_EMAIL_ADDRESS'),
          name: fields.AGREEMENT_EMAIL,
        })
      default:
        return setField1({
          label: '',
          name: '',
        })
    }
  }

  const currentMeasurementPoint = useMemo(() => {
    return measurementPoints.data.find(
      item => item?.agreementId === currentAgreement?.agreementId
    )
  }, [measurementPoints, currentAgreement])

  const measurementPointAddress = useMemo(() => {
    return (
      <AddressParser
        a1={currentMeasurementPoint?.addressLine1}
        a2={currentMeasurementPoint?.addressLine2}
      />
    )
  }, [currentMeasurementPoint])

  useEffect(() => {
    setAgreementIndex(0)
  }, [activeAgreements.length, loading, setAgreementIndex])

  const showErrorModal = useCallback(() => {
    dispatch(
      showModal({
        type: 'error',
        title: t('MODAL_MESSAGES.SORRY'),
        subtitle: t('MODAL_MESSAGES.UNEXPECTED_ERROR_OCCURED'),
        withCancelButton: true,
        withSubmitButton: true,
        onSubmit: () => {
          dispatch(
            showModal({
              type: 'report',
            })
          )
        },
      })
    )
  }, [dispatch, t])

  const onFormCancel = () => {
    closeForm()
    setField1(initialFieldState)
    setField2(undefined)
    setField1Value('')
    setField2Value('')
    setCurrentFieldsError(undefined)
    if (isUpdateContactEmailFormOpen()) {
      dispatch(removeSavedFormField({ id: ids.USER_CONTACT_EMAIL }))
    }
  }

  const showFinishModal = (
    response: { type: string },
    fieldId: string,
    newFieldsValue?: NewFieldsValue,
    linkInvoices?: boolean
  ) => {
    if (isRejected(response.type) && !isErrorModalOpen) {
      showErrorModal()
    } else {
      showSuccessModal(fieldId, newFieldsValue, linkInvoices)
    }
  }

  const getValidationFunc = () => {
    switch (field1.name) {
      case fields.CONTACT_PHONE:
        return validatePhoneForm
      case fields.AGREEMENT_EMAIL:
        return validateEmailForm
      case fields.CORRESPONDENCE_EMAIL:
        return validateEmailForm
      default:
        return validateAddressForm
    }
  }

  const onFormSubmit = async (
    { field1: value1, field2: value2 }: NewFieldsValue,
    index: number,
    linkInvoices?: boolean
  ) => {
    let action: Promise<PayloadAction<unknown>> | undefined = undefined
    let updateAction:
      | ActionCreatorWithPayload<any, string>
      | undefined = undefined
    let successAction: (() => void) | undefined = undefined

    const field1Value: string = value1?.trim() ?? ''
    const field2Value: string | undefined = value2?.trim()
    let fieldId = ''

    switch (field1.name) {
      case fields.CONTACT_PHONE:
        action = dispatch(
          updatePhoneNumber({ newPhoneNumber: trimString(field1Value) })
        )
        fieldId = ids.USER_CONTACT_PHONE
        successAction = () => dispatch(addSavedFormField({ id: fieldId }))
        updateAction = refreshContactPhoneNumber
        break
      case fields.CORRESPONDENCE_EMAIL:
        fieldId = ids.USER_CONTACT_EMAIL
        successAction = () =>
          dispatch(addSavedFormField({ id: ids.USER_CONTACT_EMAIL }))
        action = dispatch(
          updateCorrespondenceEmail({ newEmailAddress: field1Value })
        )
        updateAction = refreshCorrespondenceEmail
        break
      case fields.CORRESPONDENCE_ADDRESS_LINE_1:
        fieldId = ids.USER_CORRESPONDENCE_ADDRESS
        successAction = () => dispatch(addSavedFormField({ id: fieldId }))
        action = dispatch(
          updateCorrespondenceAddress(
            explodeAddress({ line1: field1Value, line2: field2Value ?? '' })
          )
        )
        if (!isConfirmationNeeded) {
          updateAction = refreshCorrespondenceAddress
        }
        break
      case fields.MAIN_ADDRESS_LINE_1:
        fieldId = ids.USER_MAIN_ADDRESS
        successAction = () => dispatch(addSavedFormField({ id: fieldId }))
        action = dispatch(
          updateMainAddress(
            explodeAddress({ line1: field1Value, line2: field2Value ?? '' })
          )
        )
        if (!isConfirmationNeeded) {
          updateAction = refreshMainAddress
        }
        break
      case fields.AGREEMENT_ADDRESS_LINE_1:
        fieldId = ids.getAgreementAddressId(
          currentMeasurementPoint?.agreementId || ''
        )
        successAction = () =>
          dispatch(
            addSavedFormField({
              id: fieldId,
            })
          )
        action = dispatch(
          updateAgreementCorrespondenceAddress({
            ...explodeAddress({ line1: field1Value, line2: field2Value ?? '' }),
            id: currentMeasurementPoint?.agreementId || '',
          })
        )
        if (!isConfirmationNeeded) {
          updateAction =
            userAgreementsActions.refreshAgreementCorrespondenceAddress
        }
        break
    }

    if (!action) return
    const response = await action
    if (!isRejected(response.type) && successAction) successAction()
    if ((response as ResponseWithError).error?.message) {
      return setCurrentFieldsError(
        (response as ResponseWithError).error!.message
      )
    }
    if (linkInvoices !== undefined) {
      if (linkInvoices) {
        dispatch(
          updateMeasurementPointsEmail({
            newEInvoiceEmailAddress: newEmail ?? '',
            measurementPointNumbers:
              getMeasurementPointsNumbers(
                measurementPoints.selectedMeasurementPoints
              ) ?? [],
          })
        )
        dispatch(
          userAgreementsActions.refreshAgreementsEmails({
            measurementPoints: measurementPoints.selectedMeasurementPoints,
            newEmail: newEmail ?? '',
          })
        )
      } else {
        let oldEmail = data?.correspondenceEmail ?? ''
        dispatch(
          updateMeasurementPointsEmail({
            newEInvoiceEmailAddress: emailToEdit ?? '',
            measurementPointNumbers:
              getMeasurementPointsNumbers(
                measurementPoints.selectedMeasurementPoints
              ) ?? [],
          })
        )
        dispatch(
          userAgreementsActions.refreshAgreementsEmails({
            measurementPoints: measurementPoints.selectedMeasurementPoints,
            agreements: eInvoiceAgreements,
            newEmail: oldEmail ?? '',
          })
        )
      }
    }
    if (updateAction) {
      let payload
      if (
        [refreshMainAddress, refreshCorrespondenceAddress].includes(
          updateAction
        )
      ) {
        payload = {
          newAddressLine1: removeDelimiterFromAddressLine1(field1Value),
          newAddressLine2: field2Value,
        }
      } else if (
        updateAction ===
        userAgreementsActions.refreshAgreementCorrespondenceAddress
      ) {
        payload = {
          agreementId: currentMeasurementPoint?.agreementId || '',
          newAddress: {
            newAddressLine1: removeDelimiterFromAddressLine1(field1Value),
            newAddressLine2: field2Value,
          },
        }
      } else {
        payload = response.payload
      }
      dispatch(updateAction(payload))
    }

    modals.setOpenModalKey(undefined)
    showFinishModal(
      response,
      fieldId,
      {
        field1: field1Value,
        field2: field2Value,
      },
      linkInvoices
    )
    closeForm()
    setAgreementIndex(index)
    return setCurrentFieldsError(undefined)
  }

  const marketingSwitchesData = marketingSwitches.map(item => ({
    ...item,
    label: (
      <HintPopover
        clickable
        title={item.label as string}
        content={
          item.documentType
            ? marketingDocuments[item.documentType]
            : t(
                'MY_SETTINGS.MARKETING_COMMUNICATION_CONSENTS.DOCUMENT_UNAVAILABLE'
              )
        }
      />
    ),
  }))

  const isContactEmail = useCallback(
    (title: string) => {
      return (
        title ===
        t('MY_SETTINGS.CHANGE_SETTING', {
          setting: t('MY_SETTINGS.MY_PAYER_DATA.CONTACT_EMAIL_ADDRESS'),
        })
      )
    },
    [t]
  )

  const modalEmailInfo = useCallback(
    (title: string, email?: string) => {
      const isEmail = isContactEmail(title)
      const emailToInform =
        (isEmail ? data?.email : data?.correspondenceEmail ?? data?.email) ?? ''
      let message
      if (isEmail) {
        message = t('MODAL_MESSAGES.EMAIL_UPDATE_INFORM')
      } else {
        message = ''
      }

      return emailToInform ? (
        <>
          <span>{message}</span>
          {!data?.correspondenceEmail && !isEmail && (
            <HintPopover content="W sekcji Moje dane płatnika możesz dodać e-mail kontaktowy, na który zostanie wysłane potwierdzenie realizacji zmiany" />
          )}
        </>
      ) : undefined
    },
    [data, isContactEmail, t]
  )

  const activatedEInvoices = React.useMemo(() => {
    if (eInvoiceAgreements.length === 0) {
      return ActivatedEInvoices.None
    }
    return eInvoiceAgreements.length === activeAgreements.length
      ? ActivatedEInvoices.All
      : ActivatedEInvoices.Some
  }, [eInvoiceAgreements.length, activeAgreements.length])

  const eInvoiceSettings: EInvoiceSettings = {
    hasManyAgreements: activeAgreements.length > 1,
    activatedEInvoices: activatedEInvoices,
  }

  const updateEInvoiceEmail = useCallback(() => {
    if (correspondenceEmail.correspondenceEmail === undefined) {
      return
    }
    if (
      eInvoiceAgreements &&
      getUniqueEmails(eInvoiceAgreements).some(
        (email: string) => email !== data?.correspondenceEmail
      )
    ) {
      executeUpdateEInvoiceEmail({
        data: {
          newEInvoiceEmailAddress: correspondenceEmail.correspondenceEmail,
          measurementPointNumbers: getMeasurementPointsNumbers(
            getMeasurementPointsFilteredByAgreements(
              measurementPoints.data ?? [],
              eInvoiceAgreements
            )
          ),
        },
      })
        .then(() => {
          modals.setOpenModalKey(undefined)
          showSuccessModal(ids.SETTINGS_INVOICE_EMAIL, {
            field1: correspondenceEmail.correspondenceEmail,
          })
          dispatch(
            userAgreementsActions.refreshAgreementsEmails({
              oldEmail: data?.correspondenceEmail,
              measurementPoints: measurementPoints.selectedMeasurementPoints,
              agreements: eInvoiceAgreements ?? [],
              newEmail: correspondenceEmail.correspondenceEmail ?? '',
            })
          )
          clearEInvoiceState()
        })
        .catch(() => {
          modals.setOpenModalKey(undefined)
          showErrorModal()
          clearEInvoiceState()
        })
    } else {
      executeUpdateEInvoiceEmail({
        data: {
          newEInvoiceEmailAddress: correspondenceEmail.correspondenceEmail,
          measurementPointNumbers: getMeasurementPointsNumbers(
            measurementPoints.selectedMeasurementPoints
          ),
        },
      })
        .then(() => {
          modals.setOpenModalKey(undefined)
          showSuccessModal(ids.SETTINGS_INVOICE_EMAIL, {
            field1: correspondenceEmail.correspondenceEmail,
          })
          dispatch(
            userAgreementsActions.refreshAgreementsEmails({
              oldEmail: data?.correspondenceEmail,
              measurementPoints: measurementPoints.selectedMeasurementPoints,
              newEmail: correspondenceEmail.correspondenceEmail ?? '',
            })
          )
          clearEInvoiceState()
        })
        .catch(() => {
          modals.setOpenModalKey(undefined)
          showErrorModal()
          clearEInvoiceState()
        })
    }
  }, [
    correspondenceEmail.correspondenceEmail,
    executeUpdateEInvoiceEmail,
    measurementPoints.selectedMeasurementPoints,
    showSuccessModal,
    showErrorModal,
    eInvoiceAgreements,
    modals,
    clearEInvoiceState,
    dispatch,
    data,
    measurementPoints.data,
  ])

  const modalsToOpen = {
    [Modals.SelectActivationPointUpdate]: (
      <SelectActivationPointForm
        account={data}
        measurementPoints={{
          data: measurementPoints.data,
          currentMeasurementPoint: currentMeasurementPoint,
        }}
        eInvoiceAgreements={eInvoiceAgreements}
        onCancel={clearEInvoiceState}
        onSubmit={() =>
          modals.setOpenModalKey(Modals.ConfirmActivationPointUpdate)
        }
        correspondenceEmail={getCorrespondenceEmail()}
        setCorrespondenceEmail={correspondenceEmail.setCorrespondenceEmail}
        selectedMeasurementPoints={measurementPoints.selectedMeasurementPoints}
        setSelectedMeasurementPoints={
          measurementPoints.setSelectedMeasurementPoints
        }
        selectedActivationPointOption={
          activationPoint.selectedActivationPointOption
        }
        setSelectedActivationPointOption={
          activationPoint.setSelectedActivationPointOption
        }
        stringify={stringifyMeasurementPoint}
        settings={eInvoiceSettings}
        update={true}
      />
    ),
    [Modals.ConfirmActivationPointUpdate]: (
      <ConfirmActivationPointForm
        account={data}
        correspondenceEmail={getCorrespondenceEmail()}
        selectedMeasurementPoints={measurementPoints.selectedMeasurementPoints}
        onCancel={() =>
          modals.setOpenModalKey(Modals.SelectActivationPointUpdate)
        }
        onSubmit={updateEInvoiceEmail}
        selectedActivationPointOption={
          activationPoint.selectedActivationPointOption
        }
        setSelectedMeasurementPoints={
          measurementPoints.setSelectedMeasurementPoints
        }
        stringify={stringifyMeasurementPoint}
        settings={{ ...eInvoiceSettings, hasSelectedAgreementEInvoice: true }}
        update={true}
        eInvoiceAgreements={eInvoiceAgreements}
      />
    ),
    [Modals.NewContactEmail]: (
      <UpdateLinkedInvoices
        measurementPoints={measurementPoints.selectedMeasurementPoints}
        oldEmail={emailToEdit ?? ''}
        onSubmit={() => {
          onFormSubmit({ field1: newEmail }, agreementIndex, true)
        }}
        onCancel={() => {
          onFormSubmit({ field1: newEmail }, agreementIndex, false)
        }}
        stringify={stringifyMeasurementPoint}
      />
    ),
  }

  const isUpdateContactEmailFormOpen = () => {
    return !!alreadySavedFormFields.find(f => f.id === ids.USER_CONTACT_EMAIL)
  }

  const onUpdateContactEmailFormSubmit = (values: NewFieldsValue) => {
    setEmailToEdit(data?.correspondenceEmail)
    setNewEmail(values.field1)
    setIsFormOpen(false)
    if (
      isEmailAssignedToAgreements(
        data?.correspondenceEmail ?? '',
        activeAgreements
      )
    ) {
      measurementPoints.setSelectedMeasurementPoints(
        getMeasurementPointsFilteredByEmail(
          measurementPoints.data,
          activeAgreements,
          data?.correspondenceEmail ?? ''
        )
      )
      modals.setOpenModalKey(Modals.NewContactEmail)
      return
    }
    onFormSubmit(values, agreementIndex)
  }

  let modalStyle
  if (modals.openModalKey === Modals.NewContactEmail) {
    modalStyle = updateLinkedInvoicesStyles.modalWrapper
  } else if (modals.openModalKey !== undefined) {
    modalStyle = eInvoiceStyles.modalWrapper
  } else if (isEnableMfaFormOpen || isModifyMfaFormOpen) {
    modalStyle = mfaFormStyles.modalWrapper
  }

  return (
    <>
      <Modal
        isOpen={
          isFormOpen ||
          isChangePasswordFormOpen ||
          isAddressFormOpen ||
          isEnableMfaFormOpen ||
          isModifyMfaFormOpen ||
          isEditMainPhoneFormOpen ||
          modals.openModalKey !== undefined
        }
        className={modalStyle}
      >
        {modals.openModalKey !== undefined ? (
          modalsToOpen[modals.openModalKey]
        ) : isChangePasswordFormOpen ? (
          <ChangePasswordForm
            onCancel={() => {
              setIsChangePasswordFormOpen(false)
              setCurrentPasswordError(undefined)
            }}
            formError={currentPasswordError}
            onSubmit={async values => {
              const response = await dispatch(
                updatePassword({
                  confirmedNewPassword: values.confirmNewPassword,
                  currentPassword: values.currentPassword,
                  newPassword: values.newPassword,
                })
              )
              if ((response as ResponseWithError).error?.message) {
                return setCurrentPasswordError(
                  (response as ResponseWithError).error!.message
                )
              }
              if (isRejected(response.type)) {
                showErrorModal()
              } else {
                dispatch(
                  showModal({
                    type: 'success',
                    title: t('MODAL_MESSAGES.DATA_SAVED_SUCCESSFULLY'),
                  })
                )
                dispatch(getTrustedMfaDevices())
              }
              setIsChangePasswordFormOpen(false)
              return setCurrentPasswordError(undefined)
            }}
          />
        ) : isEnableMfaFormOpen ? (
          <EnableMFAForm
            onCancel={() => setIsEnableMfaFormOpen(false)}
            mainEmail={data?.email}
            mainPhoneNumber={data?.phoneNumber}
            mainPhonePrefix={data?.phonePrefix}
            showErrorModal={showErrorModal}
          />
        ) : isModifyMfaFormOpen ? (
          <ModifyMFAForm
            onCancel={() => setIsModifyMfaFormOpen(false)}
            mfaContactType={data?.mfaContactType}
            mainEmail={data?.email}
            mainPhoneNumber={data?.phoneNumber}
            mainPhonePrefix={data?.phonePrefix}
            showUnexpectedErrorModal={showErrorModal}
            openEditMainPhoneForm={() => setIsEditMainPhoneFormOpen(true)}
          />
        ) : isEditMainPhoneFormOpen ? (
          <EditMainPhoneForm
            onCancel={() => setIsEditMainPhoneFormOpen(false)}
            mainPhoneNumber={data?.phoneNumber}
            mainPhonePrefix={data?.phonePrefix}
            isMfaEnabled={data?.isMfaEnabled}
            showUnexpectedErrorModal={showErrorModal}
            mfaContactType={data?.mfaContactType}
            openModifyMfaForm={() => setIsModifyMfaFormOpen(true)}
          />
        ) : isAddressFormOpen ? (
          <EditAddressForm
            onCancel={onFormCancel}
            onSubmit={values => {
              onFormSubmit(values, agreementIndex)
            }}
            field1={field1Value}
            field2={field2Value ?? ''}
            submitBtnText={t('CHANGE')}
            validate={validateAddress}
            title={title}
            setIsAddressInDatabase={setIsAddressInDatabase}
            compInfo={companyInfo}
          />
        ) : (
          <EditFieldForm
            onCancel={onFormCancel}
            onSubmit={values => {
              isUpdateContactEmailFormOpen()
                ? onUpdateContactEmailFormSubmit(values)
                : onFormSubmit(values, agreementIndex)
            }}
            submitBtnText={t('CHANGE')}
            formError={currentFieldsError}
            validate={
              getValidationFunc() as (
                vals: IDoubleFieldValues | ISingleFieldValues
              ) => IDoubleFieldErrors | ISingleFieldErrors
            }
            title={title}
            field1={{
              ...field1,
              value: field1Value,
            }}
            field2={
              field2
                ? {
                    ...field2,
                    value: field2Value,
                  }
                : undefined
            }
            emailToInform={
              isContactEmail(title)
                ? modalEmailInfo(title, field1Value)
                : modalEmailInfo(title)
            }
          />
        )}
      </Modal>
      <div className={styles.sectionWrapper}>
        <DetailsCardWrapper
          title={t('MY_SETTINGS.MY_ACCOUNT_DATA.title')}
          Icon={ProfileSvg}
          wrapperMinHeight={MY_DATA_MIN_HEIGHT}
          minHeight={0}
          loading={loading}
          customButtons={
            <Grid container spacing={2}>
              <Grid item className={styles.changePasswordWrapper}>
                <Button
                  variant="outlined"
                  onClick={() => setIsChangePasswordFormOpen(true)}
                  className={styles.settingsActionButton}
                >
                  {t('MY_SETTINGS.MY_ACCOUNT_DATA.CHANGE_PASSWORD')}
                </Button>
              </Grid>
              {!data?.isMfaEnabled ? (
                <Grid item className={styles.changePasswordWrapper}>
                  <Button
                    variant="outlined"
                    onClick={() => setIsEnableMfaFormOpen(true)}
                    className={styles.settingsActionButton}
                  >
                    {t('MY_SETTINGS.MY_ACCOUNT_DATA.ACTIVATE_MFA')}
                  </Button>
                </Grid>
              ) : (
                <Grid item className={styles.changePasswordWrapper}>
                  <Button
                    variant="outlined"
                    onClick={() => setIsModifyMfaFormOpen(true)}
                    className={styles.settingsActionButton}
                  >
                    {t('MY_SETTINGS.MY_ACCOUNT_DATA.MODIFY_MFA')}
                  </Button>
                </Grid>
              )}
            </Grid>
          }
        >
          <Grid container wrap="wrap" className={styles.settingsWrapper}>
            <Setting
              label={
                <HintPopover
                  title={t('MY_SETTINGS.MY_ACCOUNT_DATA.EMAIL')}
                  content={t('MY_SETTINGS.MY_ACCOUNT_DATA.EMAIL_HINT')}
                />
              }
              value={data?.email ?? t('MY_SETTINGS.NOT_PROVIDED')}
              wrapTitle
              xs={12}
              sm={6}
              lg={5}
            />
            <Setting
              label={
                <HintPopover
                  title={t('MY_SETTINGS.MY_ACCOUNT_DATA.PHONE')}
                  content={t('MY_SETTINGS.MY_ACCOUNT_DATA.PHONE_HINT')}
                />
              }
              value={
                data?.phoneNumber
                  ? (data?.phonePrefix ? data.phonePrefix + ' ' : '') +
                    data.phoneNumber
                  : t('MY_SETTINGS.NOT_PROVIDED')
              }
              onEditClick={() => setIsEditMainPhoneFormOpen(true)}
              xs={12}
              sm={5}
              lg={6}
            />
            {data?.isMfaEnabled ? (
              <Grid container>
                <Setting label={''} xs={12} value={<DevicesList />} />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </DetailsCardWrapper>
      </div>
      <div className={styles.sectionWrapper}>
        <DetailsCardWrapper
          title={t('MY_SETTINGS.MY_PAYER_DATA.title')}
          Icon={CreditCardSvg}
          wrapperMinHeight={PAYER_MIN_HEIGHT}
          minHeight={0}
          loading={loading}
        >
          <Grid container direction="column" className={styles.settingsWrapper}>
            <Grid wrap="wrap" container>
              <Setting
                label={
                  <HintPopover
                    title={t('MY_SETTINGS.MY_PAYER_DATA.PAYER_NUMBER')}
                    content={t('MY_SETTINGS.MY_PAYER_DATA.PAYER_NUMBER_HINT')}
                  />
                }
                xs={12}
                sm={6}
                lg={5}
                value={data?.customerNumber ? data.customerNumber : undefined}
              />
              <Setting
                label={
                  <HintPopover
                    title={t(
                      data?.type === 'Individual'
                        ? 'MY_SETTINGS.MY_PAYER_DATA.NAME_AND_LASTNAME'
                        : 'MY_SETTINGS.MY_PAYER_DATA.COMPANY_NAME'
                    )}
                    content={t(
                      data?.type === 'Individual'
                        ? 'MY_SETTINGS.MY_PAYER_DATA.NAME_AND_LASTNAME_HINT'
                        : 'MY_SETTINGS.MY_PAYER_DATA.COMPANY_NAME_HINT'
                    )}
                  />
                }
                xs={12}
                sm={5}
                lg={6}
                value={data?.name}
              />
            </Grid>
            <Grid container wrap="wrap" className={styles.settingsWrapper}>
              <Setting
                label={
                  <HintPopover
                    title={t('MY_SETTINGS.MY_PAYER_DATA.CONTACT_EMAIL')}
                    content={t('MY_SETTINGS.MY_PAYER_DATA.CONTACT_EMAIL_HINT')}
                  />
                }
                wrapTitle
                xs={12}
                sm={6}
                lg={5}
                value={
                  data?.correspondenceEmail ?? t('MY_SETTINGS.NOT_PROVIDED')
                }
                onEditClick={() => {
                  openForm(ids.USER_CONTACT_EMAIL)
                  dispatch(addSavedFormField({ id: ids.USER_CONTACT_EMAIL }))
                  onFieldSet({
                    name: fields.CORRESPONDENCE_EMAIL,
                    initialValue: data?.correspondenceEmail,
                  })
                }}
              />
              <Setting
                label={
                  <HintPopover
                    title={t('MY_SETTINGS.MY_PAYER_DATA.CONTACT_PHONE')}
                    content={t('MY_SETTINGS.MY_PAYER_DATA.CONTACT_PHONE_HINT')}
                  />
                }
                xs={12}
                sm={5}
                lg={6}
                value={
                  data?.contactPhoneNumber || t('MY_SETTINGS.NOT_PROVIDED')
                }
                onEditClick={() => {
                  onFieldSet({
                    name: fields.CONTACT_PHONE,
                    initialValue: trimString(data?.contactPhoneNumber || ''),
                  })
                  openForm(ids.USER_CONTACT_PHONE)
                }}
              />
            </Grid>
            <Grid container wrap="wrap" className={styles.settingsWrapper}>
              <Setting
                label={
                  <HintPopover
                    title={t('MY_SETTINGS.MY_PAYER_DATA.BASE_ADDRESS')}
                    content={t(
                      data?.type === 'Individual'
                        ? 'MY_SETTINGS.MY_PAYER_DATA.BASE_ADDRESS_HINT_INDY'
                        : 'MY_SETTINGS.MY_PAYER_DATA.BASE_ADDRESS_HINT_COMP'
                    )}
                  />
                }
                xs={12}
                sm={6}
                lg={5}
                value={
                  <span className={styles.addressWrapper}>
                    <AddressParser
                      a1={data?.mainAddressLine1}
                      a2={data?.mainAddressLine2}
                    />
                  </span>
                }
                onEditClick={() => {
                  onFieldSet({
                    name: fields.MAIN_ADDRESS_LINE_1,
                    initialValue: data?.mainAddressLine1,
                  })
                  onFieldSet({
                    name: fields.MAIN_ADDRESS_LINE_2,
                    initialValue: data?.mainAddressLine2,
                    isSecond: true,
                  })
                  openForm(ids.USER_MAIN_ADDRESS)
                }}
              />
              <Setting
                label={
                  <HintPopover
                    title={t(
                      'MY_SETTINGS.MY_PAYER_DATA.CORRESPONDENCE_ADDRESS'
                    )}
                    content={t(
                      'MY_SETTINGS.MY_PAYER_DATA.CORRESPONDENCE_ADDRESS_HINT'
                    )}
                  />
                }
                xs={12}
                sm={5}
                lg={6}
                value={
                  <span className={styles.addressWrapper}>
                    <AddressParser
                      a1={data?.correspondenceAddressLine1}
                      a2={data?.correspondenceAddressLine2}
                    />
                  </span>
                }
                onEditClick={() => {
                  onFieldSet({
                    name: fields.CORRESPONDENCE_ADDRESS_LINE_1,
                    initialValue: data?.correspondenceAddressLine1,
                  })
                  onFieldSet({
                    name: fields.CORRESPONDENCE_ADDRESS_LINE_2,
                    initialValue: data?.correspondenceAddressLine2,
                    isSecond: true,
                  })
                  openForm(ids.USER_CORRESPONDENCE_ADDRESS)
                }}
              />
            </Grid>
          </Grid>
        </DetailsCardWrapper>
      </div>
      <div className={styles.sectionWrapper}>
        <DetailsCardWrapper
          title={t('MY_SETTINGS.CONTRACT_DATA.title')}
          Icon={ContractSvg}
          wrapperMinHeight={INVOICE_MIN_HEIGHT}
          minHeight={0}
          headerClass={styles.header}
          loading={loading}
          customButtons={
            measurementPoints &&
            !isEmpty(measurementPoints) && (
              <Grid
                container
                alignItems="center"
                className={styles.togglerWrapper}
              >
                {activeAgreements.length > 1 && (
                  <Toggler
                    setIndex={setAgreementIndex}
                    total={activeAgreements.length}
                    index={agreementIndex}
                    className={styles.toggler}
                  />
                )}
                <Typography className={styles.pointTitle}>
                  <span>{`${t(
                    'MY_SETTINGS.CONTRACT_DATA.MEASURMENT_POINT'
                  )}:`}</span>
                  <span>
                    {currentMeasurementPoint?.name ||
                      currentMeasurementPoint?.number ||
                      t('MY_SETTINGS.NAME_MISSING')}
                  </span>
                </Typography>
              </Grid>
            )
          }
        >
          {measurementPoints && !isEmpty(measurementPoints) ? (
            <Grid
              container
              direction="column"
              className={styles.settingsWrapper}
            >
              <Grid container>
                <Setting
                  label={
                    <HintPopover
                      title={t(
                        'MY_SETTINGS.CONTRACT_DATA.MEASURMENT_POINT_ADDRESS'
                      )}
                      content={t(
                        'MY_SETTINGS.CONTRACT_DATA.MEASURMENT_POINT_ADDRESS_HINT'
                      )}
                    />
                  }
                  xs={12}
                  sm={6}
                  lg={5}
                  value={
                    <span className={styles.addressWrapper}>
                      {measurementPointAddress}
                    </span>
                  }
                />
                {currAgreementPaperInvoice ? (
                  <Setting
                    label={
                      <HintPopover
                        title={t('MY_SETTINGS.CONTRACT_DATA.INVOICE_ADDRESS')}
                        content={t(
                          'MY_SETTINGS.CONTRACT_DATA.INVOICE_ADDRESS_HINT'
                        )}
                      />
                    }
                    xs={12}
                    sm={5}
                    lg={6}
                    value={
                      <span className={styles.addressWrapper}>
                        <AddressParser
                          a1={currentAgreement?.correspondenceAddressLine1}
                          a2={currentAgreement?.correspondenceAddressLine2}
                        />
                      </span>
                    }
                    onEditClick={() => {
                      onFieldSet({
                        name: fields.AGREEMENT_ADDRESS_LINE_1,
                        initialValue:
                          currentAgreement?.correspondenceAddressLine1,
                      })
                      onFieldSet({
                        name: fields.AGREEMENT_ADDRESS_LINE_2,
                        initialValue:
                          currentAgreement?.correspondenceAddressLine2,
                        isSecond: true,
                      })
                      openForm(
                        ids.getAgreementAddressId(
                          currentAgreement?.agreementId || ''
                        )
                      )
                    }}
                  />
                ) : (
                  <Setting
                    style={{ marginBottom: 8 }}
                    label={
                      <HintPopover
                        title={t(
                          'MY_SETTINGS.CONTRACT_DATA.INVOICE_EMAIL_ADDRESS'
                        )}
                        content={t(
                          'MY_SETTINGS.CONTRACT_DATA.INVOICE_EMAIL_ADDRESS_HINT'
                        )}
                      />
                    }
                    wrapTitle
                    xs={12}
                    md={6}
                    lg={5}
                    value={
                      currentAgreement?.emailAddresses[0] ??
                      t('MY_SETTINGS.NOT_PROVIDED')
                    }
                    onEditClick={
                      currentAgreement &&
                      currentAgreement.invoiceType === InvoiceType.Email
                        ? () => {
                            modals.setOpenModalKey(
                              Modals.SelectActivationPointUpdate
                            )
                          }
                        : undefined
                    }
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <div className={styles.emptyContracts}>Brak aktywnych umów</div>
          )}
        </DetailsCardWrapper>
      </div>
      <div className={styles.sectionWrapper}>
        <div className={styles.emailNotificationsContainer}>
          <DetailsCardWrapper
            title={t('MY_SETTINGS.EMAIL_NOTIFICATIONS.title')}
            Icon={EmailSvg}
            wrapperMinHeight={NOTIFICATIONS_MIN_HEIGHT}
            minHeight={0}
            loading={loading}
          >
            <SwitchForm data={notificationSwitches} name="notifications" />
          </DetailsCardWrapper>
        </div>
        <div className={styles.marketingConsentsContainer}>
          <DetailsCardWrapper
            title={t('MY_SETTINGS.MARKETING_COMMUNICATION_CONSENTS.title')}
            Icon={MarketingSvg}
            wrapperMinHeight={NOTIFICATIONS_MIN_HEIGHT}
            headerClass={styles.header}
            minHeight={0}
            loading={loading}
          >
            <SwitchForm data={marketingSwitchesData} name="marketing" />
          </DetailsCardWrapper>
        </div>
      </div>
      <div className={styles.sectionWrapper}>
        <DetailsCardWrapper
          title={t('MY_SETTINGS.DOCUMENTS.title')}
          Icon={DocumentsSvg}
          wrapperMinHeight={DOCUMENTS_MIN_HEIGHT}
          minHeight={0}
        >
          <Grid className={styles.linkWrapper} container>
            {policyDocuments.map((doc, index) =>
              savePdf(
                `Documents/${doc.type}/pdf`,
                (_, saveFn) => (
                  <DocumentLink
                    key={`document-${document}-${index}`}
                    title={doc?.title || ''}
                    onClick={() => saveFn()}
                  />
                ),
                doc?.title || 'dokument'
              )
            )}
          </Grid>
        </DetailsCardWrapper>
      </div>
    </>
  )
}
