import React from 'react'
import { Collapse, Grid, Typography } from '@material-ui/core'
import { Formik, Form } from 'formik'
import FormikField from '@shared/components/modals/Modal/FormikField'
import { useTranslation } from 'react-i18next'
import { validate } from './ChangePasswordForm.utils'
import ErrorMessage from '@shared/components/modals/Modal/ErrorMessage'
import Button from '@shared/components/modals/Modal/Button'
import useStyles from './ChangePasswordForm.style'

export interface IChangePasswordFormValues {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

export interface IChangePasswordErrors {
  currentPassword?: string
  newPassword?: string
  confirmNewPassword?: string
}

export interface IChangePasswordFormProps {
  onCancel: () => void
  onSubmit: (values: IChangePasswordFormValues) => void
  formError?: string
}

export const ChangePasswordForm: React.FC<IChangePasswordFormProps> = ({
  onSubmit,
  onCancel,
  formError,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <Grid className={styles.wrapper} container direction="column">
      <Typography className={styles.title}>
        {t('MY_SETTINGS.MY_ACCOUNT_DATA.CHANGE_PASSWORD')}
      </Typography>
      <Grid>
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          }}
          onSubmit={onSubmit}
          validate={validate}
          validateOnChange
        >
          {() => {
            return (
              <Form className={styles.form}>
                <FormikField
                  name="currentPassword"
                  placeholder={t(
                    'MY_SETTINGS.MY_ACCOUNT_DATA.CURRENT_PASSWORD'
                  )}
                  type="password"
                />
                <div className={styles.spacer} />
                <FormikField
                  name="newPassword"
                  placeholder={t('MY_SETTINGS.MY_ACCOUNT_DATA.NEW_PASSWORD')}
                  type="password"
                />
                <FormikField
                  name="confirmNewPassword"
                  placeholder={t(
                    'MY_SETTINGS.MY_ACCOUNT_DATA.CONFIRM_NEW_PASSWORD'
                  )}
                  type="password"
                />
                <Collapse in={Boolean(formError)}>
                  <div className={styles.messageWrapper}>
                    <ErrorMessage title={formError} />
                  </div>
                </Collapse>
                <div className={styles.buttonsWrapper}>
                  <Button onClick={onCancel}>{t('CLOSE')}</Button>
                  <Button type="save" submit>
                    {t('SAVE')}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}
