import React, { useMemo } from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Fade,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pagination: {
      '& li': {
        '& button': {
          '&.MuiPaginationItem-textPrimary.Mui-selected': {
            color: theme.palette.common.white,
          },
        },
      },
    },
  })
)

export const CustomPagination: React.FC<{
  page: number
  numberOfPages: number
  onChange: (e: React.ChangeEvent<unknown>, val: number) => void
  loading?: boolean
  siblingCount?: number
}> = ({ page, numberOfPages, onChange, loading = false, siblingCount = 1 }) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const screenXS = useMediaQuery(theme.breakpoints.down('xs'))

  const siblings = useMemo(() => {
    return screenXS ? 0 : siblingCount
  }, [screenXS, siblingCount])

  return (
    <>
      {numberOfPages > 1 && (
        <div className={styles.paginationContainer}>
          <Fade in={!loading}>
            <Pagination
              classes={{ ul: styles.pagination }}
              color="primary"
              count={numberOfPages}
              siblingCount={siblings}
              page={page}
              onChange={onChange}
              size={screenXS ? 'small' : 'medium'}
            />
          </Fade>
        </div>
      )}
    </>
  )
}
