import React from 'react'
import { useTranslation } from 'react-i18next'
import { Collapse } from '@material-ui/core'

import useStyles from './LabeledField.styles'
import { LabeledFieldProps } from './LabeledField.types'

const LabeledField = ({
  title,
  handleBackButton,
  errorMsg,
  touched,
  children,
}: LabeledFieldProps) => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.titlesAndButton}>
        <div className={styles.label}>
          <p>{title}</p>
          <Collapse in={Boolean(!!errorMsg && touched)}>
            <p>{errorMsg}</p>
          </Collapse>
        </div>
        {handleBackButton ? (
          <button
            className={styles.changeButton}
            onClick={() => handleBackButton()}
          >
            {t('CHANGE').toUpperCase()}
          </button>
        ) : (
          <></>
        )}
      </div>
      {children}
    </>
  )
}

export default LabeledField
