import React, { useCallback, useEffect, useState } from 'react'
import { NewPassData } from './static-data/NewPasswordData'
import { Formik, Form, Field, FieldProps } from 'formik'
import StyledButton from '../../shared/components/Button'
import { BackgroundWrapper } from '../../shared/BackgroundWrapper'
import { fieldNames } from '../Register/static-data/RegisterData'
import { CustomPasswordInput } from '../../shared/components/Input'
import { useApi } from '@core/apiClient/apiClient'
import { useLocation } from 'react-router'
import { isEmpty, isNil } from 'ramda'
import { useSnackbar } from 'notistack'
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined'
import { NavLink } from 'react-router-dom'
import { AppPaths } from '@core/routes/routesPaths'
import { errorToString } from '@shared/utils/errors'
import useStyles from './NewPassword.style'
import { CustomInput } from '../../shared/components/Input'

export interface InputType {
  name: string
  title: string
  placeholder: string
  errorMsg: (accType?: string) => string
  password?: boolean
  customNextField?: string
  validation?: (el: string, accType?: string) => boolean
  optional?: boolean
  helper?: {
    content: string
  }
  disable?: boolean
}

export const NewPassword: React.FC = () => {
  const styles = useStyles()

  const { PASSWORD, CONFIRMED_PASSWORD, MFA_CODE } = fieldNames
  const { header, subHeader, inputs } = NewPassData

  const query = new URLSearchParams(useLocation().search)
  const { enqueueSnackbar } = useSnackbar()

  const [password, confirmedPassword, mfaCode] = inputs

  const [executeGetToken, loadingToken] = useApi({
    config: {
      method: 'get',
      url: 'Accounts/resetPasswordMfaToken',
      params: {
        email: query.get('email'),
        code: query.get('code'),
      },
    },
    omitConsents: true,
    omitCustomerNumber: true,
    notSecured: true,
  })

  const [executeReset, loading] = useApi({
    config: {
      method: 'put',
      url: 'Accounts/resetPassword',
      data: {
        email: query.get('email'),
        code: query.get('code'),
      },
    },
    omitConsents: true,
    omitCustomerNumber: true,
    notSecured: true,
  })

  const [submited, setSubmited] = useState(false)

  const submit = useCallback(
    (values: {}, errors: {}) => {
      if (isEmpty(errors)) {
        executeReset({
          data: {
            ...values,
          },
        })
          .then(() => {
            setSubmited(true)
          })
          .catch(err => {
            enqueueSnackbar(errorToString(err), { variant: 'error' })
          })
      }
    },
    [executeReset, enqueueSnackbar]
  )

  const mfaInput: InputType = {
    name: fieldNames.MFA_CODE,
    title: 'WPROWADŹ KOD MFA',
    placeholder: 'Wprowadź kod MFA',
    errorMsg: () => 'Wpisz poprawny kod MFA',
  }

  const mfaDisabled = query.get('mfaEnabled')?.toLowerCase().includes('false')

  useEffect(() => {
    if (
      !isNil(query.get('email')) &&
      !isNil(query.get('code')) &&
      !mfaDisabled
    ) {
      executeGetToken()
        .then(() => {})
        .catch(err => {
          enqueueSnackbar(errorToString(err), { variant: 'error' })
        })
    }
    // eslint-disable-next-line
  }, [executeGetToken])

  const form = () => (
    <>
      <h1>{header}</h1>
      <h2>{subHeader}</h2>
      <Formik
        initialValues={{
          [PASSWORD]: '',
          [CONFIRMED_PASSWORD]: '',
          [MFA_CODE]: '',
        }}
        onSubmit={() => {}}
      >
        {({ values, getFieldProps, submitForm, validateForm }) => (
          <Form className={styles.formContainer}>
            <Field
              name={password.name}
              validate={(value: string) => {
                if (password.validation && password.validation(value)) {
                  return password.errorMsg()
                }
                return ''
              }}
            >
              {({ field, meta: { error, touched } }: FieldProps) => {
                const showError = Boolean(error && touched)
                return (
                  <CustomPasswordInput
                    field={field}
                    showError={showError}
                    inputData={password}
                  />
                )
              }}
            </Field>
            <Field
              name={confirmedPassword.name}
              validate={(value: string) => {
                const passwordValue: string = getFieldProps(PASSWORD).value
                if (
                  !value ||
                  value !== passwordValue ||
                  (password.validation && password.validation(passwordValue))
                ) {
                  return confirmedPassword.errorMsg()
                }
                return ''
              }}
            >
              {({ field, meta: { error, touched } }: FieldProps) => {
                const showError = Boolean(error && touched)
                return (
                  <CustomPasswordInput
                    field={field}
                    showError={showError}
                    inputData={confirmedPassword}
                    showRequirements={false}
                  />
                )
              }}
            </Field>
            {!mfaDisabled && (
              <Field
                name={mfaCode.name}
                validate={(value: string) => {
                  if (!/\d{6}$/.test(value)) {
                    return mfaCode.errorMsg()
                  }
                  return ''
                }}
              >
                {({
                  field,
                  meta: { error, touched },
                  form: { submitCount },
                }: FieldProps) => {
                  const showError =
                    Boolean(error) && (touched || submitCount > 0)
                  return (
                    <CustomInput
                      field={field}
                      showError={showError}
                      inputData={mfaInput}
                      errMessage={error}
                    />
                  )
                }}
              </Field>
            )}
            <StyledButton
              type="submit"
              className={styles.button}
              variant="contained"
              color="primary"
              disabled={loadingToken}
              loading={loading}
              onClick={async () => {
                await submitForm()
                const errors = await validateForm()
                submit(values, errors)
              }}
            >
              Zapisz
            </StyledButton>
          </Form>
        )}
      </Formik>
    </>
  )

  const successMsg = () => (
    <>
      <div className={styles.icon}>
        <DraftsOutlinedIcon />
      </div>
      <h1>Twoje hasło zostało zmienione !</h1>
      <NavLink to={AppPaths.home.path} style={{ textDecoration: 'none' }}>
        <StyledButton
          type="submit"
          className={styles.button}
          variant="contained"
          color="primary"
        >
          Przejdź do logowania
        </StyledButton>
      </NavLink>
    </>
  )

  return (
    <BackgroundWrapper>
      <div className={styles.newPasswordContainer}>
        {submited ? successMsg() : form()}
      </div>
    </BackgroundWrapper>
  )
}
