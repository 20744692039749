import { uiActions } from './ui'
export const {
  hideModal,
  showModal,
  addSavedFormField,
  addSaveInvoiceTypeRequests,
  removeSavedFormField,
  switchCookiesDialog,
  setIsChangePhoneAndModifyMfaPath,
} = uiActions
