import React, { useCallback, useState } from 'react'
import { Collapse, Grid, Typography, DialogContent } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'ramda'

import { useAppDispatch, useAppSelector } from '@core/store/store'
import { getAccessToken } from '@core/store/user/userAccountSelectors'
import { showModal } from '@core/store/ui/actions'
import {
  getUserAccount,
  mfaContactTypeChangeFirstStep,
  mfaContactTypeChangeSecondStep,
  mfaContactTypeChangeThirdStep,
} from '@core/store/user/userAccountThunks'
import { userAccountActions } from '@core/store/user/userAccount'
import useStyles from './ModifyMFAForm.style'
import {
  ModifyMFAFormProps,
  InitialFieldsType,
  MFAModalView,
} from './ModifyMFAForm.types'
import {
  returnNewMfaContactType,
  validateFormFields,
} from './ModifyMFAForm.utils'
import Button from '@shared/components/modals/Modal/Button'
import { Input } from '@shared/components/Input'
import PhoneNumberInput from '@shared/components/PhoneNumberInput/PhoneNumberInput'
import LabeledField from '@shared/components/InputWrapper/LabeledField'
import { MfaContactType } from '@shared/contracts/mfaContactType'
import { ResponseWithError } from '@shared/contracts/responseWithError'
import { getTrustedMfaDevices } from '@core/store/trustedMfaDevices/trustedMfaDevicesThunk'
import { uiActions } from '@core/store/ui/ui'

export const ModifyMFAForm: React.FC<ModifyMFAFormProps> = ({
  onCancel,
  mfaContactType,
  mainEmail,
  mainPhoneNumber,
  mainPhonePrefix,
  showUnexpectedErrorModal,
  openEditMainPhoneForm,
}) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getAccessToken)
  const [mfaModalView, setMfaModalView] = useState<MFAModalView>(
    MFAModalView.INTRODUCTION
  )
  const [isNewContactType, setIsNewContactType] = useState(false)

  const initialValues = {
    MFAContactType:
      mfaContactType === MfaContactType.Email
        ? MfaContactType.Sms
        : MfaContactType.Email,
    Email: mainEmail ?? '',
    Phone: mainPhoneNumber ?? '',
    PhonePrefix: mainPhonePrefix ?? '+48',
    MfaCode: '',
  }

  const showErrorModal = useCallback(
    (message: string) => {
      dispatch(
        showModal({
          type: 'error',
          title: t('MODAL_MESSAGES.SORRY'),
          subtitle: message,
          withCancelButton: true,
          withSubmitButton: true,
          onSubmit: () => {
            dispatch(
              showModal({
                type: 'report',
              })
            )
          },
        })
      )
    },
    [dispatch, t]
  )

  const handleOnCancel = () => {
    dispatch(userAccountActions.resetAccessToken)
    onCancel()
    setMfaModalView(MFAModalView.INTRODUCTION)
  }

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLDivElement>,
    values: InitialFieldsType,
    validateForm: (values: InitialFieldsType, mfaModalView: MFAModalView) => {},
    submitForm: (() => Promise<void>) & (() => Promise<any>),
    setFieldError: (field: string, message: string) => void
  ) => {
    if (e.key === 'Enter') {
      handleChangeMfaContactType(
        values,
        validateForm,
        submitForm,
        setFieldError
      )
    }
  }

  const handleFirstStep = async () => {
    if (!isNil(mainPhoneNumber)) {
      const res = await dispatch(mfaContactTypeChangeFirstStep())
      if ((res as ResponseWithError).error?.message) {
        showErrorModal((res as ResponseWithError).error!.message)
      }
      if (res.type.includes('rejected')) {
        showUnexpectedErrorModal()
      } else {
        setMfaModalView(MFAModalView.MFA_CODE)
      }
    } else {
      setMfaModalView(MFAModalView.PHONE_WARNING)
    }
  }

  const handleSecondStep = async (
    values: InitialFieldsType,
    newMfaContactType: MfaContactType,
    setFieldError: (field: string, message: string) => void
  ) => {
    const res = await dispatch(
      mfaContactTypeChangeSecondStep({
        newMfaContactType: newMfaContactType,
        mfaCode: values.MfaCode,
        accessToken: String(accessToken),
      })
    )
    if ((res as ResponseWithError).error?.message) {
      setFieldError('MfaCode', (res as ResponseWithError).error!.message)
    } else {
      newMfaContactType === MfaContactType.Sms
        ? setMfaModalView(MFAModalView.PHONE_INPUT)
        : setMfaModalView(MFAModalView.EMAIL_INPUT)
    }
  }

  const handleThirdStep = async (
    values: InitialFieldsType,
    newMfaContactType: MfaContactType,
    setFieldError: (field: string, message: string) => void
  ) => {
    const res = await dispatch(
      mfaContactTypeChangeThirdStep({
        newMfaContactType: newMfaContactType,
        mfaCode: values.MfaCode,
        accessToken: String(accessToken),
      })
    )
    if ((res as ResponseWithError).error?.message) {
      setFieldError('MfaCode', (res as ResponseWithError).error!.message)
    } else {
      dispatch(getUserAccount())
      handleOnCancel()
      dispatch(
        showModal({
          type: 'success',
          title: t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_MODIFICATION_SUCCESS'),
        })
      )
      dispatch(getTrustedMfaDevices())
    }
  }

  const handleChangeMfaContactType = async (
    values: InitialFieldsType,
    validateForm: (values: InitialFieldsType, mfaModalView: MFAModalView) => {},
    submitForm: (() => Promise<void>) & (() => Promise<any>),
    setFieldError: (field: string, message: string) => void
  ) => {
    const validate = validateForm(values, mfaModalView)
    switch (mfaModalView) {
      case MFAModalView.INTRODUCTION:
        handleFirstStep()
        break
      case MFAModalView.MFA_CODE:
        submitForm()
        const newMfaContactType = returnNewMfaContactType(mfaContactType)
        if (isEmpty(validate)) {
          if (!isNewContactType) {
            /** Logic for second step of mfa ContactType change */
            handleSecondStep(values, newMfaContactType, setFieldError)
          } else {
            /** Logic for third step of mfa ContactType change */
            handleThirdStep(values, newMfaContactType, setFieldError)
          }
        }
        break
      case MFAModalView.EMAIL_INPUT:
      case MFAModalView.PHONE_INPUT:
        submitForm()
        if (isEmpty(validate)) {
          values.MfaCode = ''
          setIsNewContactType(true)
          setMfaModalView(MFAModalView.MFA_CODE)
        }
        break
      case MFAModalView.PHONE_WARNING:
        handleOnCancel()
        dispatch(uiActions.setIsChangePhoneAndModifyMfaPath(true))
        openEditMainPhoneForm()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values: InitialFieldsType) =>
        validateFormFields(values, mfaModalView)
      }
      onSubmit={() => {}}
      enableReinitialize
    >
      {({
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        submitForm,
        setFieldError,
      }) => {
        return (
          <Form>
            <Grid container className={styles.wrapper} direction="column">
              <Typography className={styles.title}>
                {mfaModalView === MFAModalView.MFA_CODE
                  ? t('MY_SETTINGS.MY_ACCOUNT_DATA.ENTER_MFA_CODE')
                  : t('MY_SETTINGS.MY_ACCOUNT_DATA.MODIFY_MFA_PATH')}
              </Typography>
              <DialogContent className={styles.contentWrapper}>
                <Collapse in={mfaModalView === MFAModalView.INTRODUCTION}>
                  <Typography
                    className={`${styles.paragraph} ${styles.paragraphBold}`}
                  >
                    {t(
                      'MY_SETTINGS.MY_ACCOUNT_DATA.MFA_MODIFICATION_INSTRUCTIONS'
                    )}
                  </Typography>
                  <Typography className={styles.paragraph}>
                    {t(
                      'MY_SETTINGS.MY_ACCOUNT_DATA.MFA_MODIFICATION_INSTRUCTION_ONE',
                      {
                        mfaContactType:
                          mfaContactType === MfaContactType.Sms
                            ? 'numer telefonu'
                            : 'adres e-mail',
                      }
                    )}
                    <br />
                  </Typography>
                  <Typography className={styles.paragraph}>
                    {t(
                      'MY_SETTINGS.MY_ACCOUNT_DATA.MFA_MODIFICATION_INSTRUCTION_TWO',
                      {
                        newMfaContactType:
                          returnNewMfaContactType(mfaContactType) ===
                          MfaContactType.Sms
                            ? 'numeru telefonu'
                            : 'adresu e-mail',
                      }
                    )}
                    <br />
                  </Typography>
                  <Typography className={styles.paragraph}>
                    {t(
                      'MY_SETTINGS.MY_ACCOUNT_DATA.MFA_MODIFICATION_INSTRUCTION_THREE'
                    )}
                  </Typography>
                </Collapse>

                <Collapse
                  in={mfaModalView === MFAModalView.PHONE_INPUT}
                  timeout={{ enter: 650, exit: 350 }}
                >
                  <LabeledField
                    title={t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_PHONE_LABEL')}
                    errorMsg={errors?.Phone}
                    touched={touched['Phone']}
                  >
                    <PhoneNumberInput
                      name="Phone"
                      phonePrefixName="PhonePrefix"
                      optional={false}
                      persistedPrefix={String(values.PhonePrefix)}
                      persistedPhoneNumber={String(values.Phone)}
                      disabled={!isNil(mainPhoneNumber)}
                    />
                  </LabeledField>
                </Collapse>
                <Collapse
                  in={mfaModalView === MFAModalView.EMAIL_INPUT}
                  timeout={{ enter: 650, exit: 350 }}
                >
                  <LabeledField
                    title={t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_EMAIL_LABEL')}
                    errorMsg={errors?.Email}
                    touched={touched['Email']}
                  >
                    <div
                      className={`${styles.input} ${
                        errors.Email && touched['Email'] && styles.error
                      }`}
                    >
                      <Input
                        name="Email"
                        value={String(values.Email)}
                        placeholder={t(
                          'MY_SETTINGS.MY_ACCOUNT_DATA.EMAIL_PLACEHOLDER'
                        )}
                        onChange={e => setFieldValue('Email', e.target.value)}
                        onBlur={() => setTouched({ Email: true })}
                        disabled={!isNil(mainEmail)}
                      />
                    </div>
                  </LabeledField>
                </Collapse>
                <Collapse
                  in={mfaModalView === MFAModalView.MFA_CODE}
                  timeout={{ enter: 650, exit: 350 }}
                >
                  <LabeledField
                    title={t('MY_SETTINGS.MY_ACCOUNT_DATA.ENTER_MFA_CODE')}
                    errorMsg={errors.MfaCode}
                    touched={touched['MfaCode']}
                  >
                    <div
                      className={`${styles.input} ${
                        errors.MfaCode && touched['MfaCode'] && styles.error
                      }`}
                    >
                      <Input
                        name="MfaCode"
                        value={String(values.MfaCode)}
                        type="number"
                        placeholder={t(
                          'MY_SETTINGS.MY_ACCOUNT_DATA.ENTER_MFA_CODE'
                        )}
                        onChange={e => setFieldValue('MfaCode', e.target.value)}
                        onBlur={() => setTouched({ MfaCode: true })}
                        onKeyPress={e =>
                          handleKeyPress(
                            e,
                            values,
                            validateFormFields,
                            submitForm,
                            setFieldError
                          )
                        }
                        className={styles.numericInput}
                      />
                    </div>
                  </LabeledField>
                </Collapse>
                <Collapse in={mfaModalView === MFAModalView.PHONE_WARNING}>
                  <Typography className={styles.paragraph}>
                    {t(
                      'MY_SETTINGS.MY_ACCOUNT_DATA.MFA_MODIFICATION_PHONE_WARNING'
                    )}
                  </Typography>
                </Collapse>
                <div className={styles.spacer} />
                <Typography className={styles.paragraphBold}>
                  {t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_CAUTION_MODIFY_PATH')}
                </Typography>
              </DialogContent>

              <div className={styles.buttonsWrapper}>
                <Button onClick={handleOnCancel}>{t('CLOSE')}</Button>
                {mfaModalView === MFAModalView.MFA_CODE && (
                  <Button
                    style={{ marginRight: '28px' }}
                    onClick={() => {
                      const newMfaContactType = returnNewMfaContactType(
                        mfaContactType
                      )
                      !isNewContactType
                        ? handleFirstStep()
                        : handleSecondStep(
                            values,
                            newMfaContactType,
                            setFieldError
                          )
                      setFieldValue('MfaCode', '')
                      setFieldError('MfaCode', '')
                    }}
                  >
                    {t('MY_SETTINGS.MY_ACCOUNT_DATA.MFA_RESEND_TOKEN')}
                  </Button>
                )}
                <Button
                  type="save"
                  onClick={() => {
                    handleChangeMfaContactType(
                      values,
                      validateFormFields,
                      submitForm,
                      setFieldError
                    )
                  }}
                >
                  {mfaModalView === MFAModalView.PHONE_WARNING
                    ? t('MY_SETTINGS.MY_ACCOUNT_DATA.ENTER_PHONE')
                    : t('MY_SETTINGS.MY_ACCOUNT_DATA.MODIFY_MFA')}
                </Button>
              </div>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
