import { Fade, makeStyles } from '@material-ui/core'
import { primaryColor } from '@mbok/styles/constants'
import { useLoadingState } from '@shared/hooks/loadingState'

import React from 'react'
import Loader from 'react-loader-spinner'
export interface IContentLoaderProps {
  loading: boolean
}

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    top: '15px',
  },
  container: {
    minHeight: '30px',
  },
}))

export const TextLoader: React.FC<IContentLoaderProps> = ({
  loading,
  children,
}) => {
  const styles = useStyles()

  const isLoading = useLoadingState(loading)

  return (
    <div className={styles.container}>
      {
        <Fade in={!isLoading}>
          <div>{children}</div>
        </Fade>
      }
      {
        <Fade in={isLoading}>
          <div className={styles.loader}>
            <Loader
              type="ThreeDots"
              color={primaryColor}
              height={30}
              width={30}
            />
          </div>
        </Fade>
      }
    </div>
  )
}
