import React, { useMemo, useState } from 'react'
import Popover from '@material-ui/core/Popover'
import { useTheme, Theme, useMediaQuery } from '@material-ui/core'
import { ReactComponent as HintSvg } from '@images/icons/other/Hint.svg'
import useStyles from './HintPopover.style'
import { isMobile } from 'react-device-detect'
import HintModal from './modal'

interface HintPopoverProps {
  title?: string
  content: string | JSX.Element
  small?: boolean
  customHintTitle?: string | JSX.Element
  topCard?: boolean
  clickable?: boolean
}

export const HintPopover: React.FC<HintPopoverProps> = ({
  title,
  content,
  small,
  customHintTitle = null,
  topCard = false,
  clickable = false,
}) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [anchorEl, setAnchorEl] = useState<
    SVGSVGElement | HTMLDivElement | null
  >(null)
  const [showModal, setShowModal] = useState(false)

  const shouldDisplayModal = isMobile || smallScreen

  const svgProps = useMemo(() => {
    return {
      style: {
        width: topCard ? 15 : 17,
        height: topCard ? 22 : 20,
        minWidth: topCard ? 15 : 17,
        cursor: shouldDisplayModal || clickable ? 'pointer' : 'help',
      },
      onMouseEnter: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
        !shouldDisplayModal && !clickable && setAnchorEl(e.currentTarget),
      onMouseLeave: () =>
        !shouldDisplayModal && !clickable && setAnchorEl(null),
      onClick: () => (shouldDisplayModal || clickable) && setShowModal(true),
    }
  }, [shouldDisplayModal, topCard, clickable])

  return (
    <div>
      <div className={styles.container}>
        {!customHintTitle ? (
          <>
            {title && <p>{title}</p>}
            <HintSvg {...svgProps} />
          </>
        ) : (
          <div
            style={{
              cursor: shouldDisplayModal || clickable ? 'pointer' : 'help',
            }}
            onMouseEnter={e =>
              !shouldDisplayModal && !clickable && setAnchorEl(e.currentTarget)
            }
            onMouseLeave={() =>
              !shouldDisplayModal && !clickable && setAnchorEl(null)
            }
            onClick={() =>
              (shouldDisplayModal || clickable) && setShowModal(true)
            }
          >
            {customHintTitle}
          </div>
        )}
      </div>
      <Popover
        className={styles.popover}
        classes={{
          paper: styles.paper,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: topCard ? 'bottom' : 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: topCard ? 'top' : 'bottom',
          horizontal: topCard ? 'left' : 'center',
        }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        disableScrollLock
      >
        <div style={{ fontSize: small ? 12 : 14 }}>{content}</div>
      </Popover>
      <HintModal
        title={title ?? ''}
        content={content}
        show={showModal}
        close={() => setShowModal(false)}
      />
    </div>
  )
}
