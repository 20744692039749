import { useThunkApi } from '@core/apiClient/apiClient'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import {
  CreateTransactionResponse,
  SecuredOrderItem,
  OrderItem,
  Order,
} from '@shared/contracts/models'
import { handleLoadingAction } from '../entity/helpers/thunks/entityThunksFactory'
import i18n from '@i18n/index'
import { StoreReducers } from '../StoreReducers'
import store from '../store'
import { showModal } from '@core/store/ui/actions'
import { PaymentsState } from './payments'

interface ValidateHashPayload {
  hash: string
  values: string[]
}
interface ValidateHashResponse {
  isHashValid: boolean
}

export interface CreateTransactionFromBalancePayload {
  activeDocuments: number
  totalAmount: number
}

export const createTransaction = createAsyncThunk<
  CreateTransactionResponse,
  SecuredOrderItem[]
>(
  `${[StoreReducers.payments]}/createTransaction`,
  async (orderItems, thunkApi) => {
    const [execute] = useThunkApi<CreateTransactionResponse>(
      {
        config: {
          method: 'post',
          url: 'Payments',
          data: {
            orderItems,
          },
        },
      },
      thunkApi
    )
    const response = await execute()
    const {
      data: { paymentRedirectUrl },
    } = response
    if (paymentRedirectUrl) window.location.replace(paymentRedirectUrl)
    return response.data
  }
)

export const getOrderItems = createAsyncThunk<OrderItem[], string>(
  `${[StoreReducers.payments]}/getOrderItems`,
  async (orderId, thunkApi) => {
    const [execute] = useThunkApi<OrderItem[]>(
      {
        config: {
          method: 'get',
          url: `Payments/${orderId}/items`,
        },
      },
      thunkApi
    )
    const response = await execute()
    return response.data
  }
)

export const getOrder = createAsyncThunk<Order, string>(
  `${[StoreReducers.payments]}/getOrder`,
  async (orderId, thunkApi) => {
    const [execute] = useThunkApi<Order>(
      {
        config: {
          method: 'get',
          url: `Payments/${orderId}`,
        },
      },
      thunkApi
    )
    const response = await execute()
    return response.data
  }
)

export const validateHash = createAsyncThunk<
  ValidateHashResponse,
  ValidateHashPayload
>(`${[StoreReducers.payments]}/validateHash`, async (data, thunkApi) => {
  const [execute] = useThunkApi<ValidateHashResponse>(
    {
      config: {
        method: 'post',
        url: `Payments/hash/validate`,
        data,
      },
    },
    thunkApi
  )
  const response = await execute()
  return response.data
})

export const createTransactionFromBalance = createAsyncThunk<
  unknown,
  CreateTransactionFromBalancePayload
>(
  `${[StoreReducers.payments]}/createTransactionFromBalance`,
  async (data, thunkApi) => {
    const [execute] = useThunkApi<CreateTransactionResponse>(
      {
        config: {
          method: 'post',
          url: `Payments/balance`,
          data,
        },
      },
      thunkApi
    )
    try {
      const response = await execute()
      const {
        data: { paymentRedirectUrl },
      } = response

      if (paymentRedirectUrl) window.location.replace(paymentRedirectUrl)
      return response.data
    } catch (error) {
      if (error.status === 400) {
        store.dispatch(
          showModal({
            title: i18n().t('MODAL_MESSAGES.SORRY'),
            subtitle: i18n().t('MODAL_MESSAGES.BALANCE_PAYMENT_UNAVAILABLE'),
            type: 'error',
            withCancelButton: true,
            withSubmitButton: false,
          })
        )
        throw new Error()
      }
    }
  }
)

export const getPaymentsExtraReducers = (
  builder: ActionReducerMapBuilder<PaymentsState>
) => {
  handleLoadingAction(createTransaction, builder)
  builder.addCase(createTransaction.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
    state.currentOrderId = payload.orderId
    state.paymentRedirectUrl = payload.paymentRedirectUrl
  })
  builder.addCase(createTransactionFromBalance.pending, state => {
    state.loadingPending++
  })
  builder.addCase(
    createTransactionFromBalance.rejected,
    (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
    }
  )
  builder.addCase(
    createTransactionFromBalance.fulfilled,
    (state, { payload }) => {
      state.loadingPending > 0 && state.loadingPending--
      state.currentOrderId = (payload as CreateTransactionResponse).orderId
      state.paymentRedirectUrl = (payload as CreateTransactionResponse).paymentRedirectUrl
    }
  )
  handleLoadingAction(validateHash, builder)
  builder.addCase(validateHash.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
    state.isHashValid = payload.isHashValid
  })
  handleLoadingAction(getOrderItems, builder)
  builder.addCase(getOrderItems.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
    state.orderItems = payload
  })
  builder.addCase(getOrder.pending, state => {
    state.loadingPending++
  })
  builder.addCase(getOrder.rejected, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
  })
  builder.addCase(getOrder.fulfilled, (state, { payload }) => {
    state.loadingPending > 0 && state.loadingPending--
    state.order = payload
    if (
      payload.orderStatus !== 'Completed' &&
      payload.orderStatus !== 'CompletedWithErrors' &&
      payload.orderId
    ) {
      setTimeout(() => {
        store.dispatch(getOrder(payload.orderId || ''))
      }, 3000)
    }
  })
}
