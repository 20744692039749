import { amountParser } from '@shared/utils/balanceParser'
import React, { useState, useEffect } from 'react'
import { animated, to, useSpring } from 'react-spring'

export const NumberChangeAnimation: React.FC<{
  value: number
  precision?: number
}> = ({ value, precision = 0 }) => {
  const [values, setValues] = useState<number[]>([])

  useEffect(() => {
    if (value !== values[values.length - 1]) {
      setValues([...values, value])
    }
  }, [value, values])

  const numOfValues = values.length

  const props = useSpring({
    number: numOfValues > 0 ? values[numOfValues - 1] : 0,
    from: { number: numOfValues > 1 ? values[numOfValues - 2] : 0 },
  })

  return (
    <animated.span>
      {to(props.number, val =>
        amountParser(parseFloat(val.toString()), precision)
      )}
    </animated.span>
  )
}
