import { getAllThunk } from '../helpers/thunks/getAllThunk'
import { EntityReducers } from '../EntityReducers'
import { useThunkApi } from '../../../apiClient/apiClient'
import { ICustomerNumber } from '@shared/contracts/CustomerNumbers'

export const customerNumbersGetAll = getAllThunk<ICustomerNumber>(
  EntityReducers.customerNumbers,
  ({ thunkApi }) =>
    useThunkApi(
      {
        config: {
          method: 'get',
          url: `accounts/customerNumbers`,
        },
        omitCustomerNumber: true,
        omitConsents: true,
      },
      thunkApi
    )
)
