import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: theme.spacing(10.5, 0, 12, 0),
  },
  title: {
    fontSize: 25,
    lineHeight: '31px',
    marginBottom: theme.spacing(8),
    userSelect: 'none',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: '17px',
    fontWeight: 'normal',
    marginBottom: theme.spacing(1.75),
    marginTop: '0px !important',
    userSelect: 'none',
    textAlign: 'center',
  },
  errorTitle: {
    marginBottom: theme.spacing(3),
  },
  textArea: {
    marginBottom: theme.spacing(8),
  },
}))
