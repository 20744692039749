import { trackCustomEvent } from './trackCustomEvent'

const eventsQueue: any[] = []

export const pushToEventsQueue = (event: any) => eventsQueue.push(event)
export const getElementFromQueue = () => eventsQueue.shift()

export const sendAllEventsFromQueue = () => {
  let event = getElementFromQueue()

  if (!event) return
  trackCustomEvent(event, true)
  sendAllEventsFromQueue()
}
