import { useAppSelector } from '@core/store/store'
import { useEffect, useState } from 'react'

export const useLogoutUserUnloaded = () => {
  const { user } = useAppSelector(state => state.oidc)

  const [hadUser, setHadUser] = useState(false)

  useEffect(() => {
    if (Boolean(user)) {
      setHadUser(true)
    }
  }, [user])

  useEffect(() => {
    if (hadUser && !user) {
      window.location.reload()
      setHadUser(false)
    }
  }, [hadUser, user])
}
