import { makeStyles } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

export default makeStyles(theme => ({
  title: {
    color: tableTextColor,
    fontWeight: 'bold',
    letterSpacing: 0.1,
    fontSize: 25,
    lineHeight: '32px',
    textAlign: 'center',
  },
  titleSmall: {
    color: tableTextColor,
    fontWeight: 'bold',
    letterSpacing: 0.6,
    fontSize: 14,
    lineHeight: '17px',
  },
  subtitle: {
    color: '#7c8a97',
    fontWeight: 'bold',
    letterSpacing: 0.2,
    lineHeight: '30px',
    fontSize: 22,
    '&:not(:first-child)': {
      marginTop: theme.spacing(5.5),
    },
  },
  subtitleSmall: {
    color: '#7c8a97',
    letterSpacing: 0.6,
    fontSize: 14,
    lineHeight: '17px',
    '&:not(:first-child)': {
      marginTop: theme.spacing(5.5),
    },
  },
}))
