import React from 'react'
import { tableTextColor } from '@mbok/styles/constants'
import { Theme, makeStyles, createStyles } from '@material-ui/core'
import { animated, Transition } from 'react-spring'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      display: 'flex',
      flexDirection: 'column',
      '& p': {
        margin: '0 0 4px',
        fontSize: 14,
        opacity: 0.55,
        color: tableTextColor,
        letterSpacing: '0.61px',
      },
      '& div': {
        minHeight: '20px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 17,
        color: tableTextColor,
        letterSpacing: '0.64px',
        fontWeight: theme.typography.fontWeightBold,

        transition: 'color 0.2s ease-in-out',
      },
    },
  })
)

export const TableCellContent: React.FC<{
  header: string
  content: string | JSX.Element
  small?: boolean
  opacity?: boolean
  bold?: boolean
}> = ({ header, content, small, opacity, bold }) => {
  const styles = useStyles()

  return (
    <div className={styles.tableCell}>
      <p>{header}</p>
      <Transition
        items={content}
        from={{ opacity: 0 }}
        enter={{
          opacity: opacity ? 0.75 : 1,
          fontWeight: bold ? 700 : 600,
          fontSize: small ? 14 : 'inherit',
        }}
      >
        {(values, visible) => {
          return (
            visible && <animated.div style={values}>{content}</animated.div>
          )
        }}
      </Transition>
    </div>
  )
}
