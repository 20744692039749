import { getAllThunk } from '../helpers/thunks/getAllThunk'
import { EntityReducers } from '../EntityReducers'
import { useThunkApi } from '../../../apiClient/apiClient'
import { Reading } from '@shared/contracts/models'
import { endOfYear, startOfYear, addYears } from 'date-fns'

export const readingsGetAll = getAllThunk<Reading>(
  EntityReducers.readings,
  ({ thunkApi }) =>
    useThunkApi(
      {
        config: {
          method: 'get',
          url: `MeasurementPoints/readings`,
          params: {
            from: addYears(startOfYear(new Date()), -1),
            to: endOfYear(new Date()),
          },
        },
      },
      thunkApi
    )
)
