import { makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'
import waves from '@images/polenergiaWaves.png'
import { colorOpacityParser } from '@shared/utils/colorOpacityParser'

export const MFA_MODAL_WIDTH = 820
export const MFA_MODAL_HEIGHT = 720

export default makeStyles((theme: Theme) => ({
  newPasswordContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '720px',
    width: '100%',
    margin: '32px',
    padding: '68px 48px',
    borderRadius: '7px',
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0 7px 9px 3px rgba(105,105,105,0.03), 0 2px 3px 0 rgba(66,65,65,0.5)',

    backgroundSize: '100% 36%',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("${waves}")`,

    '& h1': {
      margin: '0 0 12px',
      fontSize: 30,
      letterSpacing: '0.29px',
      lineHeight: '38px',
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& h2': {
      width: '80%',
      margin: '0 auto 32px',
      fontSize: 18,
      fontWeight: theme.typography.fontWeightLight,
      letterSpacing: '0.05px',
      lineHeight: '23px',
      textAlign: 'center',
    },

    [theme.breakpoints.down('md')]: {
      padding: '48px',
      margin: '32px',
      backgroundSize: '100% 32%',
    },
    [theme.breakpoints.down('sm')]: {
      '& h2': {
        width: '90%',
      },
      backgroundSize: '100% 31%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '36px 24px',
      margin: '24px',
      backgroundImage: 'none',
      '& h2': {
        width: '100%',
      },
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 44px',
    width: '140px',
    height: '140px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main + colorOpacityParser(13),
    '& svg': {
      width: '56px',
      height: '56px',
      color: theme.palette.primary.dark,
    },

    [theme.breakpoints.down('sm')]: {
      width: '120px',
      height: '120px',
      margin: '0 0 32px',
      '& svg': {
        width: '52px',
        height: '52px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      height: '100px',
      margin: '0 0 24px',
      '& svg': {
        width: '44px',
        height: '44px',
      },
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  button: {
    alignSelf: 'center',
    width: '240px',
    height: '48px',
    fontSize: 17,
    margin: '16px 0 0',

    [theme.breakpoints.down('md')]: {
      margin: '16px 0 0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0 0',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '16px 0 0',
      width: '100%',
    },
  },
  wrapper: {
    width: 'auto',
    boxSizing: 'border-box',
    '&:not(:first-child)': {
      paddingLeft: theme.spacing(15),
    },
    '&:not(:last-child)': {
      paddingRight: theme.spacing(15),
      borderRight: '1.8px solid #5F76FD1A',
    },
    paddingTop: theme.spacing(10.5),
    paddingBottom: theme.spacing(12),
  },
  contentWrapper: {
    width: 'auto',
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    paddingTop: theme.spacing(10.5),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  input: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 16px 0 20px',
    height: '50px',
    border: '1.7px solid #D3CFCF',
    borderRadius: '7px',
    backgroundColor: '#f9f9f9',
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: ' 0.11px',
    lineHeight: '25px',
    overflow: 'hidden',
    transition: 'border 0.2s ease-in-out',
  },
  error: {
    border: `1.7px solid ${theme.palette.error.main}`,
  },
  titlesAndButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  title: {
    fontSize: 25,
    lineHeight: '31px',
    letterSpacing: 0.2,
    tableTextColor: tableTextColor,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  paragraph: {
    marginBottom: theme.spacing(6),
  },
  paragraphBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonsWrapper: {
    marginTop: theme.spacing(12),
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: 'column-reverse',
      '& button': {
        width: '100%',
      },
    },
  },
  secondField: {
    marginTop: theme.spacing(5),
  },
  spacer: {
    width: '100%',
    height: 1.8,
    backgroundColor: '#5F76FD1A',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 16),
  },
  messageWrapper: {
    paddingTop: theme.spacing(3),
  },
  changeButton: {
    textDecoration: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    padding: 0,
    marginTop: '2px',
    fontSize: 13,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0.09px',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'left',
    textAlign: 'left',
    minHeight: '17px',
    margin: '0 0 4px 20px',
    '& p': {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      gap: '3.5px',
      marginRight: '8px',
      fontSize: 12,
      fontWeight: 800,
      color: theme.palette.text.primary,
      letterSpacing: '0.08px',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
      '& span': {
        opacity: 0.25,
        [theme.breakpoints.down('xs')]: {
          fontSize: 10,
        },
      },
    },
    '& div > p': {
      margin: 0,
      fontSize: 12,
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightBold,

      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginLeft: 0,
    },
  },
  modalWrapper: {
    width: `${MFA_MODAL_WIDTH}px !important`,
    maxWidth: '90vw',
    maxHeight: `${MFA_MODAL_HEIGHT}px`,
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
    },
  },
  numericInput: {
    '& > div > input[type="number"]': {
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '-moz-appearance': 'textfield',
    },
  },
}))
