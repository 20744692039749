import { makeStyles, Theme } from '@material-ui/core'
import { tableTextColor } from '@mbok/styles/constants'

const COOKIE_ICON_SIZE = 55

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: "100%",
    color: tableTextColor,
    '& .MuiPaper-root': {
      maxWidth: '700px'
    },
    [theme.breakpoints.down('sm')]: {
      width: "85%",
      margin: '0 auto',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cookieIcon: {
    width: COOKIE_ICON_SIZE,
    margin: 0,
    marginRight: theme.spacing(7),
    "& svg": {
      width: "100%",
      fill: theme.palette.primary.main,
    }
  },
  mainContent: {
    fontSize: 18,
    '& a': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      transition: 'color 0.2s ease-in-out',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      textDecoration: 'none',
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    "& >*": {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: '100%',
        marginTop: theme.spacing(3),
      },
    },
    "& :first-child": {
      marginLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center'
    },
  },
  contentMsg: {
    fontSize: 14,
    margin: 0,
    '& a': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      transition: 'color 0.2s ease-in-out',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
    },
  },
}))
